import React, { useState, useRef, useEffect } from 'react';
import styles, { FlexRowDiv } from './style';
import FormControl from '../../components/FormControl';
import { HeadingLabel } from '../../components/Label';

import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FlatClearButton from '../../components/FlatClearButton';
import { v4 as uuid } from 'uuid';
import KeywordCards from './KeywordCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/Loader';
import { TranscriptHeading } from './style';
import { Box } from './style';
import Editor from './Editor';
import cssStyles from './microskill.module.css';
import ScrollableFeed from 'react-scrollable-feed';

const Page3_keywords = (props) => {
  const { setCurrentPage, pages } = props;
  const { microSkillState, setMicroSkillState } = props;
  const { addChallengeAction } = props;
  const [skills, setSkills] = useState(
    microSkillState.RequestKeyBox.length == 0
      ? [createEmptySkill()]
      : microSkillState.RequestKeyBox,
  );
  const classes = styles();
  const keywordListRef = useRef(null);

  function getWindowHeight() {
    const { innerHeight: height } = window;
    return height;
  }

  const [windowHeight, setWindowHeight] = useState(getWindowHeight());

  useEffect(() => {
    function handleResize() {
      setWindowHeight(getWindowHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('ye pakkda');
  }, [transcript]);

  useEffect(() => {
    console.log('inside useeffect');
    console.log(keywordListRef.current);

    keywordListRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });

    console.log([skills]);
  }, [skills]);

  function createEmptySkill() {
    const emptySkill = {
      id: uuid(),
      head: '',
      keywords: [],
      keywordInput: [],
      limit: 50,
    };
    return emptySkill;
  }
  const nextAction = () => {
    setCurrentPage(pages[3]);
  };

  const saveDraft = () => {
    addChallengeAction();
  };
  const quillRef = useRef();
  const [transcript, setTranscript] = useState(
    microSkillState.Formatted_transcript,
  );

  const changeTranscript = (title) => {
    setTranscript(title);
    setMicroSkillState({ ...microSkillState, Formatted_transcript: title });
  };
  const onEditorBlue = () => {
    const editor = quillRef.current.getEditor();
    var value = editor.getText();
    setTranscript(value);
    setMicroSkillState((prevState) => ({
      ...prevState,
      Sample_transcript: value,
    }));
  };
  const TranscriptContainer = () => {
    return (
      <Box
        height={windowHeight}
        className={`${
          transcript.length == 0 ? classes.box : classes.withoutBorderBox
        }`}>
        {transcript.length == 0 && (
          <TranscriptHeading>
            Upload Best Practice Sample Pitch To Generate Transcript
          </TranscriptHeading>
        )}
        {transcript.length > 0 && (
          <Editor
            quillRef={quillRef}
            handleChange={changeTranscript}
            onBlur={onEditorBlue}
            value={transcript}
            style={{ width: '100%', height: '90%' }}
            readOnly={true}
          />
        )}
      </Box>
    );
  };

  const SkillLists = () => {
    const addMoreAction = () => {
      const newEmptySkill = createEmptySkill();
      setSkills([...skills, newEmptySkill]);
    };

    const updateSkills = (value) => {
      const newBox = value.filter((item) => item.keywords.length > 0);
      setMicroSkillState({ ...microSkillState, RequestKeyBox: newBox });
      setSkills(value);
    };

    const listItems = skills.map((skill) => (
      <KeywordCards
        skill={skill}
        setSkills={(value) => updateSkills(value)}
        skills={skills}
      />
    ));
    return (
      <ul
        className={cssStyles.keyword}
        style={{
          listStyle: 'none',
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          flexWrap: 'wrap',
          overflow: 'auto',
          height: '97%',
          width: '100%',
        }}>
        <ScrollableFeed className={classes.scrollFeed}>
          {listItems}
          <li
            onClick={addMoreAction}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginRight: '13px',
              marginTop: '13px',
              maxHeight: '275px',
            }}>
            <FontAwesomeIcon
              icon={faCirclePlus}
              // className={classes.competeStepStyle}
              style={{
                fontSize: '24px',
                color: '#F4511E',
                cursor: 'pointer',
              }}
            />
            <label
              style={{
                width: '115px',
                height: '18px',
                background: 'transparent',
                color: '#F4511E',
                font: 'normal normal bold 13px Open Sans',
                marginTop: '16px',
              }}>
              Add Skill Tag
            </label>
          </li>
        </ScrollableFeed>
      </ul>
    );
  };

  return (
    <>
      {/* <div className={classes.heading_container}>
        <HeadingLabel>Create Roleplay Challenge Below</HeadingLabel>
      </div> */}
      <FlexRowDiv className={classes.keywordContainer}>
        <div className={classes.leftPage3Container}>
          <div className={classes.leftTopContainer} style={{ height: '100%' }}>
            <div
              style={{
                height: '90%',
                width: '100%',
              }}>
              <div>
                <HeadingLabel>Add Skill Tags with Keywords</HeadingLabel>
              </div>
              <div ref={keywordListRef} style={{ height: 'calc(100% - 85px)' }}>
                <SkillLists />
              </div>
              <div
                style={{
                  height: '10%',
                  width: '100%',
                  background: '#fff',
                  marginTop: 'auto',
                  marginBottom: '0',
                }}></div>
            </div>
            <FormControl>
              <div className={classes.smTranscriptContainer}>
                {TranscriptContainer()}
              </div>
            </FormControl>
          </div>
        </div>
        <div className={classes.rightpage3Container}>
          {TranscriptContainer()}
        </div>
      </FlexRowDiv>
      {/* <div
        className={`${classes.action_btn_container}  ${classes.keyword_action_btn}`}>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          onClick={nextAction}>
          Next
        </FlatPrimaryButton>
        <div className={classes.save_btn_container}>
          <FlatClearButton style={{ padding: '15px 30px', minWidth: '20%' }}>
            Save Draft
          </FlatClearButton>
        </div>
      </div> */}
      <FormControl className={classes.action_btn_container}>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          onClick={nextAction}>
          Next
        </FlatPrimaryButton>
        <div className={classes.save_btn_container}>
          <FlatClearButton
            style={{ padding: '15px 30px', minWidth: '20%' }}
            onClick={saveDraft}>
            Save Draft
          </FlatClearButton>
        </div>
      </FormControl>
    </>
  );
};

export default Page3_keywords;
