import { Grid, makeStyles } from '@material-ui/core';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React from 'react';
import FormControl from '../../components/FormControl';
import StyledLabel from '../../components/Label';
import MODatePicker from '../../components/MODatePicker';
import FormDatePicker from './FormDatePicker';
import FormTimePicker from './FormTimePicker';

const useStyles = makeStyles({
  selectorContainer: {
    padding: '10px',
  },
});

const FormDelivery = ({ delivery, startDate, endDate, editable, onUpdate }) => {
  const classes = useStyles();

  const handleStartDateChange = (date) => {
    const newDelivery = {
      ...delivery,
      startDate: date,
    };
    onUpdate(newDelivery);
  };

  const handleEndDateChange = (date) => {
    const newDelivery = {
      ...delivery,
      endDate: date,
    };
    onUpdate(newDelivery);
  };

  const handleStartTimeChange = (time) => {
    const newDelivery = {
      ...delivery,
      startTime: time,
    };
    onUpdate(newDelivery);
  };

  const handleEndTimeChange = (time) => {
    const newDelivery = {
      ...delivery,
      endTime: time,
    };
    onUpdate(newDelivery);
  };

  return (
    <div style={{ marginTop: '50px', width: '600px' }}>
      <FormControl>
        <StyledLabel style={{ padding: '0px', marginBottom: '30px' }}>
          Select Delivery Type:
        </StyledLabel>
        <RadioGroup value="date">
          <Grid container alignItems="center">
            <Grid item sm={6}>
              <FormControlLabel
                value="date"
                label="Delivery by date"
                control={<Radio />}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <StyledLabel
          style={{ padding: '0px', marginBottom: '30px', marginTop: '30px' }}>
          Start:{' '}
          {editable && (
            <span style={{ color: 'red' }}>
              Current Start: {new Date(startDate).toLocaleString('en-IN')}
            </span>
          )}
        </StyledLabel>
        <Grid container>
          <Grid item sm={6}>
            <FormDatePicker
              text={
                delivery.startDate && delivery.startDate.format('DD-MM-YYYY')
              }
              value={delivery.startDate}
              onChange={handleStartDateChange}
            />
          </Grid>
          <Grid item sm={6}>
            <FormTimePicker
              text={delivery.startTime && delivery.startTime.format('hh:mm A')}
              value={delivery.startTime}
              onChange={handleStartTimeChange}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl>
        <StyledLabel
          style={{ padding: '0px', marginBottom: '30px', marginTop: '30px' }}>
          End:{' '}
          {editable && (
            <span style={{ color: 'red' }}>
              Current End: {new Date(endDate).toLocaleString('en-IN')}
            </span>
          )}
        </StyledLabel>
        <Grid container>
          <Grid item sm={6}>
            <FormDatePicker
              text={delivery.endDate && delivery.endDate.format('DD-MM-YYYY')}
              value={delivery.endDate}
              onChange={handleEndDateChange}
            />
          </Grid>
          <Grid item sm={6}>
            <FormTimePicker
              text={delivery.endTime && delivery.endTime.format('hh:mm A')}
              value={delivery.endTime}
              onChange={handleEndTimeChange}
            />
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
};

export default FormDelivery;
