import React, { useState, useRef } from 'react';
import TopBarWrapper from '../../components/TopBarWrapper';
import MOTopBar from '../../components/MOTopBar';
import TopBarHeading from '../../components/TopBarHeading';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import CoursePathwayElement from '../../components/CoursePathwayElement';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FlatClearButton from '../../components/FlatClearButton';
import { Dialog } from '@material-ui/core';
import AddCourseEvent from './AddCourseEvent';
import styled from 'styled-components';
import ClearButton from '../../components/ClearButton';
import PlusIcon from '../../components/MDLIcon/icons/Plus';
import Loader from '../../components/Loader';
import Button2 from '../../components/Button2';
import SuccessTick from '../../assets/success.svg';
import FailureTick from '../../assets/failure.svg';

const courseElementBGConstants = ['#1a56bd', '#f63b34', '#56dab8', '#ffdd00'];

const FormWrapper = styled.div`
  padding: 30px;
  text-align: left;
`;

const StyledGenericButton = styled(ClearButton)`
  margin-top: 15px;
  color: #4f0060;
  padding: 0;
  font-size: 10px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  > #mastero_icon_add .cls-plus-icon {
    fill: #f4511e !important;
  }
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

function NewCourse(props) {
  const course_types = [
    {
      id: 'standalone',
      display_text: 'Standalone Journey',
    },
    {
      id: 'blended',
      display_text: 'Blended Learning Journey',
    },
    {
      id: 'webinar',
      display_text: 'Webinar',
    },
    {
      id: 'internal',
      display_text: 'Internal',
    },
    {
      id: 'inhouse',
      display_text: 'In-house',
    },
    {
      id: 'external',
      display_text: 'External',
    }
  ];

  const newEventModuleOptions = [];

  if (
    props.hasOwnProperty('companyInfo') &&
    props.companyInfo.hasOwnProperty('modules')
  ) {
    for (let microskill of props.companyInfo.modules) {
      newEventModuleOptions.push({
        id: microskill.id,
        display_text: microskill.name,
      });
    }
  }

  let trainingHoursOptions = [];

  for (let i = 0; i <= 8; i++) {
    trainingHoursOptions.push({
      id: i,
      display_text: i.toString(),
    });
  }

  const [progressText, setProgressText] = useState('Please wait...');
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogText, setErrorDialogText] = useState(
    'There was an unknown error, please try again?',
  );

  const [courseName, setCourseName] = useState(null);
  const [categoryId, setCategoryId] = useState(0);
  const [categoryNameErrorText, setCategoryNameErrorText] = useState(null);

  const courseNameRef = useRef(null);

  const [courseNameErrorText, setCourseNameErrorText] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState('blended');

  const [addCourseEventDialogOpen, setAddCourseEventDialogOpen] = useState(
    false,
  );
  const [newEventName, setNewEventName] = useState(null);
  const [newEventSelectedModules, setNewEventSelectedModules] = useState([]);
  const [newEventTrainingHours, setNewEventTrainingHours] = useState(8);

  const [editCourseEventDialogOpen, setEditCourseEventDialogOpen] = useState(
    false,
  );
  const [editCourseEventIndex, setEditCourseEventIndex] = useState(0);
  const [editEventName, setEditEventName] = useState(null);
  const [editEventSelectedModules, setEditEventSelectedModules] = useState([]);
  const [editEventTrainingHours, setEditEventTrainingHours] = useState(8);

  const newEventModuleOptionsObject = {};
  const editEventModuleOptionsObject = {};

  const defaultCoursePathwayElements = [
    {
      event_name: 'Pre-Module Assessment',
      modules: [],
      training_hours: 8,
    },
    {
      event_name: 'Training Workshop',
      modules: [],
      training_hours: 8,
    },
    {
      event_name: 'Post-Workshop Module',
      modules: [],
      training_hours: 8,
    },
    {
      event_name: 'Post-Workshop Module',
      modules: [],
      training_hours: 8,
    },
  ];

  let [coursePathwayElements, setCoursePathwayElements] = useState(
    defaultCoursePathwayElements,
  );

  let category_object = {
    1: {
      name: 'Sales Training',
    },
    2: {
      name: 'Talent Management',
    },
    3: {
      name: 'Old',
    },
  };

  let categoryOptions = [
    {
      id: 1,
      display_text: 'Sales Training',
    },
    {
      id: 2,
      display_text: 'Talent Management',
    },
    {
      id: 3,
      display_text: 'Old',
    },
  ];

  for (let m of newEventModuleOptions) {
    editEventModuleOptionsObject[m.id] = m;
  }

  for (let m of newEventModuleOptions) {
    newEventModuleOptionsObject[m.id] = m;
  }

  let newEventModulesSelectedText = '';

  for (let selected of newEventSelectedModules) {
    if (newEventModuleOptionsObject.hasOwnProperty(selected)) {
      newEventModulesSelectedText +=
        newEventModuleOptionsObject[selected].display_text + ', ';
    }
  }

  let editEventModulesSelectedText = '';

  for (let selected of editEventSelectedModules) {
    if (editEventModuleOptionsObject.hasOwnProperty(selected)) {
      editEventModulesSelectedText +=
        editEventModuleOptionsObject[selected].display_text + ', ';
    }
  }

  function runFormValidation() {
    if (courseName === '' || courseName === null) {
      setCourseNameErrorText('Course Name is required');
      courseNameRef.current.focus();
      return false;
    } else {
      setCourseNameErrorText(null);
    }

    return true;
  }

  React.useEffect(() => {
    if (courseName === '') {
      setCourseNameErrorText('Course Name is required');
      courseNameRef.current.focus();
    } else {
      setCourseNameErrorText('');
    }
  }, [courseName]);

  console.log(props);

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Create new Training Course</TopBarHeading>
        </MOTopBar>
        <div>
          <FormWrapper>
            {props.companyInfo.company_id == 90 && (
              <FormControl>
                <Label>Category Name</Label>

                <SimpleFormSelect
                  autoClose={true}
                  options={categoryOptions}
                  text={categoryId > 0 ? category_object[categoryId].name : ''}
                  onSelect={setCategoryId}
                />
              </FormControl>
            )}
            {console.log(categoryId)}
            <FormControl>
              <Label>
                Course Name <span style={{ color: 'red' }}>*</span>
              </Label>
              <TextInput
                ref={courseNameRef}
                value={courseName}
                onChangeText={(text) => {
                  setCourseName(text);
                }}
                errorText={courseNameErrorText}
              />
            </FormControl>

            <FormControl>
              <Label>Course Type</Label>

              <SimpleFormSelect
                autoClose={true}
                options={course_types}
                text={
                  course_types.filter((v) => v.id === selectedCourse)[0]
                    .display_text
                }
                onSelect={setSelectedCourse}
              />
            </FormControl>

            <FormControl>
              <Label>Course Pathway</Label>
              {coursePathwayElements.map((e, k) => {
                return (
                  <CoursePathwayElement
                    key={k}
                    disableClone={true}
                    backgroundColor={courseElementBGConstants[k % 4]}
                    text={e.event_name}
                    onEdit={() => {
                      setEditCourseEventIndex(k);
                      setEditEventName(coursePathwayElements[k].event_name);
                      setEditEventTrainingHours(
                        coursePathwayElements[k].training_hours,
                      );
                      setEditEventSelectedModules(
                        coursePathwayElements[k].modules,
                      );
                      setEditCourseEventDialogOpen(true);
                    }}
                    onCopy={() => {
                      let copy = [...coursePathwayElements];
                      copy.push(coursePathwayElements[k]);
                      setCoursePathwayElements(copy);
                    }}
                    onDelete={() => {
                      let copy = [...coursePathwayElements];
                      copy.splice(k, 1);
                      setCoursePathwayElements(copy);
                    }}
                  />
                );
              })}

              <StyledGenericButton
                onClick={() => {
                  setAddCourseEventDialogOpen(true);
                }}>
                <div style={{ marginRight: 5, display: 'flex' }}>
                  <StyledPlusIcon />
                </div>
                Add Course Event
              </StyledGenericButton>
            </FormControl>

            <FormControl>
              <FlatPrimaryButton
                onClick={() => {
                  let isFormValid = runFormValidation();

                  if (isFormValid !== true) {
                    return;
                  }

                  setProgressDialogOpen(true);

                  let networkRequestBodyObject = {
                    name: courseName,
                    course_type: selectedCourse,
                    course_events: coursePathwayElements,
                    project_id: props.activeProject,
                    category: categoryId > 0 ? categoryId : null,
                  };
                  let action = props.MOClient.create_training_course({
                    token: props.jwtToken,
                    data: networkRequestBodyObject,
                  });
                  action.then((response) => {
                    setProgressDialogOpen(false);

                    if (response.status === 'success') {
                      setSuccessDialogOpen(true);
                      props.onCourseAdded();
                    } else {
                      if (response.hasOwnProperty('description')) {
                        setErrorDialogText(response.description);
                      }
                      setErrorDialogOpen(true);
                    }
                  });
                }}>
                Schedule Course
              </FlatPrimaryButton>

              <div style={{ marginLeft: 10, display: 'inline-block' }}>
                <FlatClearButton
                  onClick={() => {
                    setCoursePathwayElements([]);
                    setCourseName('');
                  }}>
                  Clear and Delete
                </FlatClearButton>
              </div>
            </FormControl>
          </FormWrapper>
        </div>

        {/* Add Course Event Dialog */}
        <div id="create-course-event-dialog">
          <Dialog
            open={addCourseEventDialogOpen}
            onClose={() => {
              setAddCourseEventDialogOpen(false);
            }}>
            <AddCourseEvent
              eventName={newEventName}
              onChangeEventName={(text) => {
                setNewEventName(text);
              }}
              moduleOptions={newEventModuleOptions}
              onModuleSelect={(id) => {
                let copy = [...newEventSelectedModules];
                if (copy.indexOf(id) === -1) {
                  copy.push(id);
                  setNewEventSelectedModules(copy);
                } else {
                  copy = copy.filter((e) => e !== id);
                  setNewEventSelectedModules(copy);
                }
              }}
              modulesSelected={newEventSelectedModules}
              selectedModulesText={newEventModulesSelectedText}
              trainingHoursOptions={trainingHoursOptions}
              trainingHoursSelected={newEventTrainingHours}
              onTrainingHoursSelect={(id) => {
                setNewEventTrainingHours(id);
              }}
              trainingHoursText={
                trainingHoursOptions.filter(
                  (i) => i.id == newEventTrainingHours,
                )[0].display_text
              }
              onAddEvent={() => {
                if (newEventName === '' || newEventName === null) {
                  setErrorDialogOpen(true);
                  setErrorDialogText('Error: Event Name cannot be empty');
                  return;
                }
                let o = {
                  event_name: newEventName,
                  modules: newEventSelectedModules,
                  training_hours: newEventTrainingHours,
                };

                let copy = [...coursePathwayElements];
                copy.push(o);
                setCoursePathwayElements(copy);

                setNewEventName('');
                setNewEventSelectedModules([]);
                setNewEventTrainingHours(8);
                setAddCourseEventDialogOpen(false);
              }}
              onDelete={() => {
                setAddCourseEventDialogOpen(false);
              }}
            />
          </Dialog>
        </div>

        {/* Edit Course Event Dialog */}
        <div id="edit-course-event-dialog">
          <Dialog
            open={editCourseEventDialogOpen}
            onClose={() => {
              setEditCourseEventDialogOpen(false);
            }}>
            <AddCourseEvent
              eventName={editEventName}
              onChangeEventName={(text) => {
                setEditEventName(text);
              }}
              moduleOptions={newEventModuleOptions}
              onModuleSelect={(id) => {
                let copy = [...editEventSelectedModules];
                if (copy.indexOf(id) === -1) {
                  copy.push(id);
                  setEditEventSelectedModules(copy);
                } else {
                  copy = copy.filter((e) => e !== id);
                  setEditEventSelectedModules(copy);
                }
              }}
              modulesSelected={editEventSelectedModules}
              selectedModulesText={editEventModulesSelectedText}
              trainingHoursOptions={trainingHoursOptions}
              trainingHoursSelected={editEventTrainingHours}
              onTrainingHoursSelect={(id) => {
                setEditEventTrainingHours(id);
              }}
              trainingHoursText={
                trainingHoursOptions.filter(
                  (i) => i.id == editEventTrainingHours,
                )[0].display_text
              }
              onAddEvent={() => {
                if (editEventName === null || editEventName === '') return;
                let o = {
                  event_name: editEventName,
                  modules: editEventSelectedModules,
                  training_hours: editEventTrainingHours,
                };

                let copy = [...coursePathwayElements];

                copy[editCourseEventIndex] = o;

                setCoursePathwayElements(copy);

                setEditEventName('');
                setEditEventSelectedModules([]);
                setEditCourseEventDialogOpen(false);
              }}
              onDelete={() => {
                setEditCourseEventDialogOpen(false);
              }}
            />
          </Dialog>
        </div>

        {/* Progress Dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={progressDialogOpen}
          onClose={() => {
            setProgressDialogOpen(false);
          }}>
          <ProgressContainer>
            <Loader fill="#f4511e" />
            <ProgressText>Please wait...</ProgressText>
          </ProgressContainer>
        </Dialog>

        {/* Success Dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={successDialogOpen}
          onClose={() => {
            setSuccessDialogOpen(false);
          }}>
          <ProgressContainer>
            <img src={SuccessTick} style={{ width: '100px' }} alt="Success" />
            <div style={{ margin: '12px 0' }}>
              <ProgressText>Course Created Successfully!</ProgressText>
            </div>
            <div style={{ margin: '12px 0' }}>
              <Button2
                onClick={() => {
                  props.history.push({
                    pathname: '/courses-and-training/course-log',
                    state: {},
                  });
                }}>
                Go to Course Log
              </Button2>
            </div>
          </ProgressContainer>
        </Dialog>

        {/* Failure Dialog */}
        <Dialog
          open={errorDialogOpen}
          onClose={() => {
            setErrorDialogOpen(false);
          }}>
          <ProgressContainer>
            <img src={FailureTick} style={{ width: '80px' }} />
            <div style={{ margin: '12px 0' }}>
              <ProgressText>{errorDialogText}</ProgressText>
            </div>
            <div style={{ margin: '12px 0' }}>
              <Button2
                onClick={() => {
                  setErrorDialogOpen(false);
                }}>
                Close
              </Button2>
            </div>
          </ProgressContainer>
        </Dialog>
      </TopBarWrapper>
    </div>
  );
}

export default NewCourse;
