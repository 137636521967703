import React from "react";

function Course(props) {
  let fill = "#FFF";

  if(props.hasOwnProperty("fill")) {
    fill = props.fill;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <defs>
        <style>
          {`.cls-1{fill:#fff;opacity:0}.cls-2{fill:#f4511e}`}
        </style>
      </defs>
      <g id="mastero_icon_course_training" transform="translate(-10 -302)">
        <path id="Rectangle_1547" d="M0 0h15v15H0z" className="cls-1" data-name="Rectangle 1547"
              transform="translate(10 302)"/>
        <g id="manual" transform="translate(12.257 305.433)">
          <path id="Path_5314"
                d="M4.278 30.035h-.743v2.822l-1.107-.63-1.107.63v-2.822H0v6.2h4.278a1.176 1.176 0 0 1 .579.152v-5.773a.58.58 0 0 0-.579-.579zm0 0"
                className="cls-2" data-name="Path 5314" transform="translate(0 -29.43)"/>
          <path id="Path_5315" d="M271 368.247v.177h4.857v-.756h-4.277a.58.58 0 0 0-.58.579zm0 0" className="cls-2"
                data-name="Path 5315" transform="translate(-265.539 -360.259)"/>
          <path id="Path_5316" d="M4.277 367.668H0v.756h4.857v-.177a.58.58 0 0 0-.579-.579zm0 0" className="cls-2"
                data-name="Path 5316" transform="translate(0 -360.259)"/>
          <path id="Path_5317"
                d="M271.579 30.035a.58.58 0 0 0-.579.579v5.772a1.176 1.176 0 0 1 .579-.152h4.277v-6.2zm3.559 5.217h-3.421v-.6h3.421zm0-1.21h-3.421v-.6h3.421zm0-1.21h-3.421v-.6h3.421zm0-1.21h-3.421v-.6h3.421zm0 0"
                className="cls-2" data-name="Path 5317" transform="translate(-265.539 -29.43)"/>
          <path id="Path_5318" d="M95.57 2.388l.5-.286.5.286V0h-1zm0 0" className="cls-2" data-name="Path 5318"
                transform="translate(-93.644)"/>
        </g>
      </g>
    </svg>
  )
}

export default Course;