function objectify(records, mapping_key) {
  let o = {};

  if (!Array.isArray(records)) return o;

  if (records.length === 0) return o;

  for (let record of records) {
    let key = record[mapping_key];
    o[key] = record;
  }

  return o;
}

function syncTimer(comp_id) {
  let date = new Date();
  let hour = date.getHours() > 7 ? 7 : 12;
  let time = 'AM'

  if (comp_id === 100) {
    if (date.getHours() > 7 && date.getHours() < 14) {
      hour = 7;
    } else if (date.getHours() >= 14) {
      hour = 2;
      time = 'PM';
    } else {
      hour = 12;
    }
  }

  const Str =
    (hour) +
    ':' +
    '00 ' +
    time + ' ' +
    ('00' + date.getDate()).slice(-2) +
    '/' +
    ('00' + (date.getMonth() + 1)).slice(-2) +
    '/' +
    date.getFullYear();
  return Str;
}

const Utils = {
  objectify,
  capitalize,
  syncTimer,
};

function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export default Utils;
