import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import TextInput from '../../components/TextInput';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import ToggleSwitch from '../../components/ToggleSwitch';
import Button2 from '../../components/Button2';
import styles from './style';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';
import MOClient from '../../MOClient';
import Loader from '../../components/Loader';

const AddKeywordEvent = (props) => {
  const keywordInputRef = useRef();
  const classes = styles();
  const { keyword } = props;
  const heading = keyword ? 'Edit Keyword:' : 'Add Keyword:';
  const matchType = keyword ? keyword.matchType : 'exact';
  const [isSimilar, setIsSimilar] = useState(matchType == 'similar');
  const [keywordText, setKeywordText] = useState(keyword ? keyword.name : '');
  const [similarKeywords, setSimilarKeywords] = useState(
    keyword ? keyword.similarKeywords : [],
  );
  const [savingInProgress, setsavingInProgress] = useState(false);
  function getEmptySimilarKeyword() {
    const emptySimilarKeyword = {
      id: uuid(),
      name: '',
    };
    return emptySimilarKeyword;
  }
  useEffect(() => {
    // const str = JSON.stringify(keyword);
    // console.log(str);
    keywordInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (isSimilar && keywordText.length > 0 && similarKeywords.length == 0) {
      getSimilarWordsFor(keywordText);
    }
  }, [keywordText, isSimilar]);

  const toggleSwitchChange = () => {
    const updateSimilar = !isSimilar;
    setIsSimilar(updateSimilar);
  };

  const getSimilarWordsFor = (keyword) => {
    try {
      setsavingInProgress(true);
      const fetch_result = MOClient.getSimilarWords(keyword);
      fetch_result.then((result) => {
        if (result.status !== 200) {
          const result_json = result.json();
          result_json.then((result_json) => {
            //do nothing for now
            console.log(result_json);
          });
        } else {
          const async_result_json = result.json();
          async_result_json.then((result_json) => {
            console.log(result_json);
            if (result_json.synonyms) {
              const similarWords = result_json.synonyms.map((item) => {
                return { name: item, id: uuid() };
              });
              setSimilarKeywords(similarWords);
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setsavingInProgress(false);
    }
  };

  const deleteSimilarEvent = (item) => {
    const updatedItems = similarKeywords.filter((obj) => obj.id != item.id);
    setSimilarKeywords(updatedItems);
  };

  const updateSimilarKeywordEvent = (item, text) => {
    const updatedItem = { ...item, name: text };
    const updatedSimilarKeywords = similarKeywords.map((obj) =>
      obj.id == updatedItem.id ? updatedItem : obj,
    );
    setSimilarKeywords(updatedSimilarKeywords);
  };

  const addMoreAction = () => {
    setSimilarKeywords([...similarKeywords, getEmptySimilarKeyword()]);
  };
  const renderSimilarKeywords = () => {
    return (
      <>
        <Label>Similar Keywords:</Label>
        <ul
          style={{
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            overflow: 'auto',
            maxheight: '85%',
            alignItems: 'center',
            margin: 0,
          }}>
          {renderSimilarKeywordItems}
        </ul>
        <div
          style={{
            width: '131px',
            minHeight: '30px',
            height: '30px',
            background: 'transparent',
            color: '#F4511E',
            border: '0.5px solid #F4511E',
            borderRadius: '5px',
            font: 'normal normal bold 13px/17px Open Sans',
            marginTop: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={addMoreAction}>
          Add More +
        </div>
      </>
    );
  };
  const renderSimilarKeywordItems = similarKeywords.map((item) => (
    <li key={item.id} style={{ marginBottom: '3px' }}>
      <div
        style={{
          height: '30px',
          background: '#fff',
          border: '0.5px solid #F4511E',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'hidden',
        }}>
        <TextInput
          width={'90%'}
          border={'0.5px solid transparent'}
          value={item.name}
          onChangeText={(text) => {
            updateSimilarKeywordEvent(item, text);
          }}
        />
        <FontAwesomeIcon
          icon={faClose}
          style={{
            color: '#F4511E',
            fontSize: '0.8rem',
            marginRight: '10px',
            cursor: 'pointer',
          }}
          onClick={() => deleteSimilarEvent(item)}
        />
      </div>
    </li>
  ));
  return (
    <>
      <FontAwesomeIcon
        onClick={props.onClose}
        icon={faClose}
        style={{
          color: '#F4511E',
          fontSize: '1.2rem',
          marginTop: '15px',
          marginRight: '15px',
          alignSelf: 'flex-end',
          cursor: 'pointer',
        }}
      />
      <ComponentWrapper>
        <FormControl>
          <TitleLabel>{heading}</TitleLabel>
        </FormControl>
        <div style={{ display: 'flex', flexDirection: 'row', height: '164px' }}>
          <div style={{ width: '50%', paddingTop: '40px' }}>
            <TextInput
              ref={keywordInputRef}
              width={'90%'}
              border={'0.5px solid #F4511E'}
              value={keywordText}
              onChangeText={(text) => {
                setKeywordText(text);
              }}
            />
            <FormControl style={{ marginTop: '30px', marginBottom: '30px' }}>
              <div
                style={{
                  justifyContent: 'flex-start',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Label style={{ paddingBottom: 0, marginRight: '20px' }}>
                  Exact
                </Label>
                <ToggleSwitch
                  bidirectional={'true'}
                  checked={isSimilar}
                  onChange={() => {
                    toggleSwitchChange();
                  }}
                  name="keyword"
                />
                <Label style={{ paddingBottom: 0, marginLeft: '20px' }}>
                  Similar
                </Label>
              </div>
            </FormControl>
          </div>
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              alignItems: 'flex-start',
              paddingLeft: '70px',
              justifyContent: 'flex-start',
            }}>
            {isSimilar && renderSimilarKeywords()}
          </div>
        </div>

        <FormControl>
          <Actions>
            <Button2
              onClick={() => {
                const trimmedKeyword = keywordText.trim();
                if (trimmedKeyword.length > 0) {
                  let synonyms = [];
                  if (isSimilar) {
                    synonyms = similarKeywords
                      .map((item) => {
                        return { ...item, name: item.name.trim() };
                      })
                      .filter((item) => item.name.length > 0);
                  }
                  const newKeyword = {
                    id: keyword ? keyword.id : uuid(),
                    matchType: isSimilar ? 'similar' : 'exact',
                    name: trimmedKeyword,
                    similarKeywords: synonyms,
                  };
                  props.onAddEvent(newKeyword);
                }
              }}>
              Save Changes
            </Button2>
          </Actions>
        </FormControl>
      </ComponentWrapper>
      {savingInProgress && (
        <div
          style={{
            width: '54px',
            position: 'absolute',
            backgroundColor: 'lightgrey',
            top: 'calc(50% - 27px)',
            left: 'calc(50% - 27px)',
            borderRadius: 4,
            height: 54,
          }}>
          <Loader fill="#4f0060" />
        </div>
      )}
    </>
  );
};

export default AddKeywordEvent;
const keywordStyles = makeStyles({
  titleStyle: {
    font: 'normal normal bold 39px/48px Montserrat',
    color: '#080037',
    textAlign: 'left',
  },
});
const ComponentWrapper = styled.div`
  padding: 10px 20px 10px 20px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const TitleLabel = styled(Label)`
  padding-bottom: 0px;
  font: normal normal bold 1.2rem Montserrat;
  color: #080037;
  text-align: 'left';
  ${'' /* margin-bottom: 30px; */}
`;
