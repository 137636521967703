import React from 'react';
import styled from 'styled-components';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import MODatePicker from '../../components/MODatePicker';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FormControl from '../../components/FormControl';
import Switch from '@material-ui/core/Switch';
import MOClient from '../../MOClient';
import Label from '../../components/Label';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs';

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

function ToggleRewards(props) {
  const [updateRewards, setUpdateRewards] = React.useState(true);
  const [startDateDialogOpen, setStartDateDialogOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);

  const [
    fast_microskill_completion,
    set_fast_microskill_completion,
  ] = React.useState({
    lower_limit: 200,
    upper_limit: 490,
    rewards_coins: 4,
  });

  const handleChangeFast_microskill_completion = (event) => {
    set_fast_microskill_completion({
      ...fast_microskill_completion,
      [event.target.name]: +event.target.value,
    });
  };

  const [selfie_upload, set_selfie_upload] = React.useState({
    limit: 3,
    rewards_coins: 4,
  });

  const handleChangeSelfie_upload = (event) => {
    set_selfie_upload({
      ...selfie_upload,
      [event.target.name]: +event.target.value,
    });
  };

  const [incentive_upload, set_incentive_upload] = React.useState({
    limit: 3,
    rewards_coins: 4,
  });

  const handleChangeIncentive_upload = (event) => {
    set_incentive_upload({
      ...incentive_upload,
      [event.target.name]: +event.target.value,
    });
  };

  const [microskill_completed, set_microskill_completed] = React.useState({
    limit: 5,
    rewards_coins: 5,
  });

  const handleChangeMicroskill_completed = (event) => {
    set_microskill_completed({
      ...microskill_completed,
      [event.target.name]: +event.target.value,
    });
  };

  const [challenge_completed, set_challenge_completed] = React.useState({
    limit: 5,
    rewards_coins: 5,
  });

  const handleChangeChallenge_completed = (event) => {
    set_challenge_completed({
      ...challenge_completed,
      [event.target.name]: +event.target.value,
    });
  };

  const [app_announcements, set_app_announcements] = React.useState({
    limit: 5,
    rewards_coins: 4,
  });

  const handleChangeApp_announcements = (event) => {
    set_app_announcements({
      ...app_announcements,
      [event.target.name]: +event.target.value,
    });
  };

  const [forms_submits, set_forms_submits] = React.useState({
    limit: 3,
    rewards_coins: 4,
  });

  const handleChangeForms_submits = (event) => {
    set_forms_submits({
      ...forms_submits,
      [event.target.name]: +event.target.value,
    });
  };

  const [docs_downloaded, set_docs_downloaded] = React.useState({
    limit: 3,
    rewards_coins: 4,
  });

  const handleChangeDocs_downloaded = (event) => {
    set_docs_downloaded({
      ...docs_downloaded,
      [event.target.name]: +event.target.value,
    });
  };
  const [timespent, set_timespent] = React.useState({
    limit: 3,
    rewards_coins: 6,
  });

  const handleChangeTimespent = (event) => {
    set_timespent({
      ...timespent,
      [event.target.name]: +event.target.value,
    });
  };

  const [assessment_accuracy, set_assessment_accuracy] = React.useState({
    lower_limit: 65,
    upper_limit: 100,
    rewards_coins: 6,
  });

  const handleChangeAssessment_accuracy = (event) => {
    set_assessment_accuracy({
      ...assessment_accuracy,
      [event.target.name]: +event.target.value,
    });
  };

  const [
    repeated_microskill_completion,
    set_repeated_microskill_completion,
  ] = React.useState({
    limit: 2,
    rewards_coins: 6,
  });

  const handleChangeRepeated_microskill_completion = (event) => {
    set_repeated_microskill_completion({
      ...repeated_microskill_completion,
      [event.target.name]: +event.target.value,
    });
  };

  const [mastery_points, set_mastery_points] = React.useState({
    limit: 3,
    rewards_coins: 6,
  });

  const handleChangeMastery_points = (event) => {
    set_mastery_points({
      ...mastery_points,
      [event.target.name]: +event.target.value,
    });
  };


  // props.MOClient

  const [state, setState] = React.useState({
    fast_microskill_completion_checked: true,
    selfie_upload_checked: true,
    incentive_upload_checked: true,
    microskill_completed_checked: true,
    challenge_completed_checked: true,
    app_announcements_checked: true,
    timespent_checked: true,
    forms_submits_checked: true,
    docs_downloaded_checked: true,
    assessment_accuracy_checked: true,
    repeated_microskill_completion_checked: true,
    mastery_points_checked: true,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  React.useEffect(() => {
    let opt = { comp_id: props.companyInfo.company_id };

    let action = MOClient.get_rewards_data(opt);
    action.then((result) => {
      setStartDate(dayjs(result.created_at));
      const data = result.rewardsData;

      fast_microskill_completion.lower_limit =
        data.evolution.fast_microskill_completion.lower_limit;
      fast_microskill_completion.upper_limit =
        data.evolution.fast_microskill_completion.upper_limit;
      fast_microskill_completion.rewards_coins =
        data.evolution.fast_microskill_completion.rewards_coins;
      set_fast_microskill_completion({
        ...fast_microskill_completion,
      });
      selfie_upload.limit = data.evolution.selfie_upload.limit;
      selfie_upload.rewards_coins = data.evolution.selfie_upload.rewards_coins;
      set_selfie_upload({ ...selfie_upload });
      incentive_upload.limit = data.evolution.incentive_upload.limit;
      incentive_upload.rewards_coins =
        data.evolution.incentive_upload.rewards_coins;
      set_incentive_upload({ ...incentive_upload });
      microskill_completed.limit = data.evolution.microskill_completed.limit;
      microskill_completed.rewards_coins =
        data.evolution.microskill_completed.rewards_coins;
      set_microskill_completed({
        ...microskill_completed,
      });
      challenge_completed.limit = data.evolution.challenge_completed.limit;
      challenge_completed.rewards_coins =
        data.evolution.challenge_completed.rewards_coins;
      set_challenge_completed({
        ...challenge_completed,
      });
      app_announcements.limit = data.engagement.app_announcements.limit;
      app_announcements.rewards_coins =
        data.engagement.app_announcements.rewards_coins;
      set_app_announcements({ ...app_announcements });
      forms_submits.limit = data.engagement.forms_submits.limit;
      forms_submits.rewards_coins = data.engagement.forms_submits.rewards_coins;
      set_forms_submits({ ...forms_submits });
      docs_downloaded.limit = data.engagement.docs_downloaded.limit;
      docs_downloaded.rewards_coins =
        data.engagement.docs_downloaded.rewards_coins;
      set_docs_downloaded({ ...docs_downloaded });
      timespent.limit = data.engagement.timespent.limit;
      timespent.rewards_coins =
        data.engagement.timespent.rewards_coins;
      set_timespent({ ...timespent });
      assessment_accuracy.lower_limit =
        data.effectiveness.assessment_accuracy.lower_limit;
      assessment_accuracy.upper_limit =
        data.effectiveness.assessment_accuracy.upper_limit;
      assessment_accuracy.rewards_coins =
        data.effectiveness.assessment_accuracy.rewards_coins;
      set_assessment_accuracy({
        ...assessment_accuracy,
      });
      repeated_microskill_completion.limit =
        data.effectiveness.repeated_microskill_completion.limit;
      repeated_microskill_completion.rewards_coins =
        data.effectiveness.repeated_microskill_completion.rewards_coins;
      set_repeated_microskill_completion({
        ...repeated_microskill_completion,
      });
      mastery_points.limit = data.effectiveness.mastery_points.limit;
      mastery_points.rewards_coins =
        data.effectiveness.mastery_points.rewards_coins;
      set_mastery_points({ ...mastery_points });

      let checkbox = {
        fast_microskill_completion_checked: Boolean(
          data.evolution.fast_microskill_completion.feature_active ? 1 : 0,
        ),
        selfie_upload_checked: Boolean(
          data.evolution.selfie_upload.feature_active ? 1 : 0,
        ),
        incentive_upload_checked: Boolean(
          data.evolution.incentive_upload.feature_active ? 1 : 0,
        ),
        microskill_completed_checked: Boolean(
          data.evolution.microskill_completed.feature_active ? 1 : 0,
        ),
        challenge_completed_checked: Boolean(
          data.evolution.challenge_completed.feature_active ? 1 : 0,
        ),
        app_announcements_checked: Boolean(
          data.engagement.app_announcements.feature_active ? 1 : 0,
        ),
        forms_submits_checked: Boolean(
          data.engagement.forms_submits.feature_active ? 1 : 0,
        ),
        docs_downloaded_checked: Boolean(
          data.engagement.docs_downloaded.feature_active ? 1 : 0,
        ),
        timespent_checked: Boolean(
          data.engagement.timespent.feature_active ? 1 : 0,
        ),
        assessment_accuracy_checked: Boolean(
          data.effectiveness.assessment_accuracy.feature_active ? 1 : 0,
        ),
        repeated_microskill_completion_checked: Boolean(
          data.effectiveness.repeated_microskill_completion.feature_active
            ? 1
            : 0,
        ),
        mastery_points_checked: Boolean(
          data.effectiveness.mastery_points.feature_active ? 1 : 0,
        ),
      };
      setState(checkbox);
    });
  }, []);

  const saveUpdateRewardData = async () => {
    fast_microskill_completion.feature_active = state.fast_microskill_completion_checked
      ? 1
      : 0;
    selfie_upload.feature_active = state.selfie_upload_checked ? 1 : 0;
    incentive_upload.feature_active = state.incentive_upload_checked ? 1 : 0;
    microskill_completed.feature_active = state.microskill_completed_checked
      ? 1
      : 0;
    challenge_completed.feature_active = state.challenge_completed_checked
      ? 1
      : 0;
    app_announcements.feature_active = state.app_announcements_checked ? 1 : 0;
    forms_submits.feature_active = state.forms_submits_checked ? 1 : 0;
    docs_downloaded.feature_active = state.docs_downloaded_checked ? 1 : 0;
    timespent.feature_active = state.timespent_checked ? 1 : 0;
    assessment_accuracy.feature_active = state.assessment_accuracy_checked
      ? 1
      : 0;
    repeated_microskill_completion.feature_active = state.repeated_microskill_completion_checked
      ? 1
      : 0;
    mastery_points.feature_active = state.mastery_points_checked ? 1 : 0;

    const REWARD_DATA = {
      evolution: {
        fast_microskill_completion,
        selfie_upload,
        incentive_upload,
        microskill_completed,
        challenge_completed,
      },
      engagement: {
        app_announcements,
        forms_submits,
        docs_downloaded,
        timespent,
        five_min_session: {
          limit: 3,
          rewards_coins: 5,
          feature_active: 0,
        },
      },
      effectiveness: {
        assessment_accuracy,
        repeated_microskill_completion,
        mastery_points,
        full_score_microskill: {
          limit: 3,
          rewards_coins: 6,
          feature_active: 0,
        },
      },
    };

    const data = {
      comp_id: props.companyInfo.company_id,
      drive_user_id: props.userInfo.id,
      rewards_data: REWARD_DATA,
      update_on: startDate,
    };
    try {
      const result = await MOClient.update_rewards_data(data);
      console.log(result);
    } catch (error) {
      console.log(error);
    }

    setUpdateRewards(!updateRewards);
  };

  return (
    <div>
      <Wrapper>
        <Flex>
          <div>
            <Header color="#0043b5">Rewards & Recognition</Header>
            <HeaderCaption>
              You can toggle the metrics On/Off and define the threshold
              criteria for the basis of rewarding a learner
            </HeaderCaption>
          </div>
        </Flex>

        <div style={{ marginTop: 15 }}>
          <Table
            style={{
              border: '1px solid rgba(0,0,0,0.1)',
            }}>
            <TableHead>
              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  Metric
                </CustomTableCell>
                <CustomTableCell align="center">Threshold</CustomTableCell>
                <CustomTableCell align="center">Reward Coins</CustomTableCell>
                <CustomTableCell align="center">Toggle</CustomTableCell>
              </CustomTableRow>
            </TableHead>
            <TableBody>
              {/* {rows_filtered.map((row, index) => ( */}
              <CustomTableRow>
                <CustomTableCell
                  align="left"
                  colSpan={4}
                  style={{
                    backgroundColor: '#1E88E5',
                  }}>
                  <SpanText>Evolution</SpanText>
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Fast Microskill Completion</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Lower limit"
                        variant="outlined"
                        value={fast_microskill_completion.lower_limit}
                        onChange={handleChangeFast_microskill_completion}
                        name="lower_limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Upper limit"
                        variant="outlined"
                        value={fast_microskill_completion.upper_limit}
                        onChange={handleChangeFast_microskill_completion}
                        name="upper_limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={fast_microskill_completion.rewards_coins}
                        onChange={handleChangeFast_microskill_completion}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.fast_microskill_completion_checked}
                        onChange={handleChange}
                        name="fast_microskill_completion_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>
              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Selfie upload</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={selfie_upload.limit}
                        onChange={handleChangeSelfie_upload}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={selfie_upload.rewards_coins}
                        onChange={handleChangeSelfie_upload}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.selfie_upload_checked}
                        onChange={handleChange}
                        name="selfie_upload_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>
              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Incentive upload</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={incentive_upload.limit}
                        onChange={handleChangeIncentive_upload}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={incentive_upload.rewards_coins}
                        onChange={handleChangeIncentive_upload}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.incentive_upload_checked}
                        onChange={handleChange}
                        name="incentive_upload_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>
              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Microskills completed</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={microskill_completed.limit}
                        onChange={handleChangeMicroskill_completed}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={microskill_completed.rewards_coins}
                        onChange={handleChangeMicroskill_completed}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.microskill_completed_checked}
                        onChange={handleChange}
                        name="microskill_completed_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Challenges completed</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={challenge_completed.limit}
                        onChange={handleChangeChallenge_completed}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={challenge_completed.rewards_coins}
                        onChange={handleChangeChallenge_completed}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.challenge_completed_checked}
                        onChange={handleChange}
                        name="challenge_completed_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell
                  align="left"
                  colSpan={4}
                  style={{
                    backgroundColor: '#00CC2E',
                  }}>
                  <SpanText>Engagement</SpanText>
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>App Announcements</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={app_announcements.limit}
                        onChange={handleChangeApp_announcements}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={app_announcements.rewards_coins}
                        onChange={handleChangeApp_announcements}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.app_announcements_checked}
                        onChange={handleChange}
                        name="app_announcements_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Forms Submissions</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={forms_submits.limit}
                        onChange={handleChangeForms_submits}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={forms_submits.rewards_coins}
                        onChange={handleChangeForms_submits}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.forms_submits_checked}
                        onChange={handleChange}
                        name="forms_submits_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Documents Downloaded</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={docs_downloaded.limit}
                        onChange={handleChangeDocs_downloaded}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={docs_downloaded.rewards_coins}
                        onChange={handleChangeDocs_downloaded}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.docs_downloaded_checked}
                        onChange={handleChange}
                        name="docs_downloaded_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>
              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Timespent</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={timespent.limit}
                        onChange={handleChangeTimespent}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={timespent.rewards_coins}
                        onChange={handleChangeTimespent}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.timespent_checked}
                        onChange={handleChange}
                        name="timespent_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell
                  align="left"
                  colSpan={4}
                  style={{
                    backgroundColor: '#F4511E',
                  }}>
                  <SpanText>Effectiveness</SpanText>
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Assessment Accuracy</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Lower limit"
                        variant="outlined"
                        value={assessment_accuracy.lower_limit}
                        onChange={handleChangeAssessment_accuracy}
                        name="lower_limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Upper limit"
                        variant="outlined"
                        value={assessment_accuracy.upper_limit}
                        onChange={handleChangeAssessment_accuracy}
                        name="upper_limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={assessment_accuracy.rewards_coins}
                        onChange={handleChangeAssessment_accuracy}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.assessment_accuracy_checked}
                        onChange={handleChange}
                        name="assessment_accuracy_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Repeated Modules Completion</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={repeated_microskill_completion.limit}
                        onChange={handleChangeRepeated_microskill_completion}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={repeated_microskill_completion.rewards_coins}
                        onChange={handleChangeRepeated_microskill_completion}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.repeated_microskill_completion_checked}
                        onChange={handleChange}
                        name="repeated_microskill_completion_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>

              <CustomTableRow>
                <CustomTableCell component="th" colSpan={2} scope="row">
                  <span>Mastery Points</span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Enter limit"
                        variant="outlined"
                        value={mastery_points.limit}
                        onChange={handleChangeMastery_points}
                        name="limit"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={mastery_points.rewards_coins}
                        onChange={handleChangeMastery_points}
                        name="rewards_coins"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>

                <CustomTableCell align="center">
                  <CustomFormControlLabel
                    control={
                      <IOSSwitch
                        checked={state.mastery_points_checked}
                        onChange={handleChange}
                        name="mastery_points_checked"
                        disabled={updateRewards}
                      />
                    }
                  />
                </CustomTableCell>
              </CustomTableRow>
            </TableBody>
          </Table>
        </div>
        <div
          style={{ justifyContent: 'center', display: 'flex', marginTop: 20 }}>
          <Label style={{ padding: 6 }}>Start Date : </Label>
          <div style={{ textAlign: 'start' }}>
            <MODatePicker
              open={startDateDialogOpen}
              text={startDate === null ? '' : startDate.format('DD-MM-YYYY')}
              value={dayjs(startDate)}
              onClick={() => {
                !updateRewards && setStartDateDialogOpen(!startDateDialogOpen);
              }}
              onClose={() => {
                setStartDateDialogOpen(false);
              }}
              handleDateChange={(d) => {
                setStartDate(d);
              }}
              minDate={dayjs(new Date()).subtract(1, 'year')}
            />
          </div>
        </div>
        <FormControl>
          <FlatPrimaryButton
            onClick={() => {
              updateRewards
                ? setUpdateRewards(!updateRewards)
                : saveUpdateRewardData();
            }}>
            {updateRewards ? 'Edit Rewards Values' : 'Save Changes'}
          </FlatPrimaryButton>
        </FormControl>
      </Wrapper>
    </div>
  );
}

const Header = styled.h4`
  font-size: 30px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
  font: bold normal 800 35px/387px Montserrat;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
`;

const HeaderCaption = styled.h5`
  font-size: 14px;
  text-align: left;
  color: #484848;
  text-align: left;
  font: normal normal normal Montserrat;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
`;

const Flex = styled.div`
  div {
    margin: 10px 30px;
  }

  div:first-child {
    margin-left: 0;
  }

  div&:last-child {
    margin-right: 0;
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
`;

const SpanText = styled.span`
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
`;

const CustomTableCell = withStyles((theme) => ({
  root: {
    padding: '15px 10px !important',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '12px',
    color: '#000000',
    borderBottom: 'none',
    textAlign: 'center',
  },
  head: {
    backgroundColor: '#fbfbfb',
    color: '#000000',
    fontWeight: 'bold',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    flexGrow: '1',
    fontSize: '14px',
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
    color: '#000000',
    borderBottom: '1px solid #f2f2f2',
  },
  head: {
    display: 'flex',
    color: '#000000',
    borderBottom: '1px solid #f2f2f2',
  },
}))(TableRow);

const CustomFormControlLabel = withStyles((theme) => ({
  root: {
    display: 'flex',
    height: 20,
    padding: 10,
  },
  input: {
    display: 'flex',
    height: 20,
    padding: 10,
  },
}))(FormControlLabel);

const Wrapper = styled.div``;

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default ToggleRewards;