import React from "react";

function Audience(props) {
  let fill = "#FFF";

  if(props.hasOwnProperty("fill")) {
    fill = props.fill;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <defs>
        <style>
          {`.cls-1{fill:#fff;opacity:0}.cls-2{fill:#f4511e}`}
        </style>
      </defs>
      <g id="mastero_icon_audience" transform="translate(-19 -171)">
        <path id="Rectangle_1547" d="M0 0h15v15H0z" className="cls-1" data-name="Rectangle 1547"
              transform="translate(19 171)"/>
        <g id="Group_2690" data-name="Group 2690" transform="translate(20.5 174)">
          <g id="friends">
            <g id="Group_2345" data-name="Group 2345" transform="translate(1.391)">
              <g id="Group_2344" data-name="Group 2344">
                <path id="Path_5272" d="M63.974 57.142a2.232 2.232 0 1 0 2.1 2.228 2.17 2.17 0 0 0-2.1-2.228z"
                      className="cls-2" data-name="Path 5272" transform="translate(-61.873 -57.142)"/>
              </g>
            </g>
            <g id="Group_2347" data-name="Group 2347" transform="translate(6.467 .319)">
              <g id="Group_2346" data-name="Group 2346">
                <path id="Path_5273"
                      d="M289.439 71.321h-.014a1.743 1.743 0 0 0-1.279.578 1.99 1.99 0 0 0 .022 2.683 1.744 1.744 0 0 0 1.274.557h.015a1.743 1.743 0 0 0 1.279-.578 1.956 1.956 0 0 0 .509-1.346 1.862 1.862 0 0 0-1.806-1.894z"
                      className="cls-2" data-name="Path 5273" transform="translate(-287.637 -71.321)"/>
              </g>
            </g>
            <g id="Group_2349" data-name="Group 2349" transform="translate(6.07 4.502)">
              <g id="Group_2348" data-name="Group 2348">
                <path id="Path_5274"
                      d="M272.248 257.358h-.174a3.142 3.142 0 0 0-2.114.815 4.056 4.056 0 0 1 1.584 3.01h3.856v-.672a3.157 3.157 0 0 0-3.152-3.153z"
                      className="cls-2" data-name="Path 5274" transform="translate(-269.96 -257.358)"/>
              </g>
            </g>
            <g id="Group_2351" data-name="Group 2351" transform="translate(0 5.153)">
              <g id="Group_2350" data-name="Group 2350">
                <path id="Path_5275"
                      d="M6.978 289.492a3.387 3.387 0 0 0-3.38-3.174h-.21A3.387 3.387 0 0 0 0 289.705v.4h6.984v-.4q0-.105-.006-.213z"
                      className="cls-2" data-name="Path 5275" transform="translate(0 -286.318)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Audience;