import styled from 'styled-components';

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f4511e;
  text-transform: uppercase;
  cursor: pointer;
  
  &:active {
    transform: scale(0.98);
  }
`;


export default ClearButton;