import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import styled from 'styled-components';
import TrashIcon from './MDLIcon/icons/Trash';
import TextInput from './TextInput';

const ActionController = styled.div`
  margin-bottom: 15px;
`;

const CurvedButtonBase = styled(ButtonBase)`
  border-radius: 20px;
  margin: 3px;
  padding: 5px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  .class-trash-icon-2 {
    fill: #707070;
  }
`;

function CustomOptions({ options, onChange, type }) {
  return (
    <div>
      {options && options.map((o, index) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextInput
              value={o}
              onChangeText={(t) => {
                const options_copy = [...options];
                options_copy[index] = t;
                onChange(options_copy);
              }}
              type={type}
            />

            <ActionController style={{ marginBottom: 0 }}>
              <CurvedButtonBase
                tabIndex={-1}
                onClick={() => {
                  const options_copy = [...options];
                  options_copy.splice(index, 1);
                  onChange(options_copy);
                }}>
                <StyledTrashIcon />
              </CurvedButtonBase>
            </ActionController>
          </div>
        );
      })}
    </div>
  );
}

export default CustomOptions
