import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Sort from "./MDLIcon/icons/Sort";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonBase from "@material-ui/core/ButtonBase";

const customStyles = makeStyles({
  buttonBaseRoot: {
    width: '100%',
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  root: {
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 24,
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between"

  },
  content: {

  },
  heading: {
    color: "white",
    textAlign: "left",
    fontWeight: 700,
    fontSize: '1rem',
    fontFamily: "Montserrat",
    textOverflow: "ellipsis",
    maxWidth: "155px",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  subheading: {
    color: "white",
    textAlign: "left",
    fontSize: '0.7rem',
    fontFamily: "Open Sans"
  },
  icon: {

  }
});

export default function NavProjectSelector(props) {
  let styles = customStyles(props);
  return (
    <ButtonBase classes={{root: styles.buttonBaseRoot}} onClick={props.onClick}>
    <div className={styles.root}>

      <div className={styles.content}>
        <div className={styles.heading}>
          {props.heading}
        </div>
        <div className={styles.subheading}>
          {props.subheading}
        </div>
      </div>
      <div className={styles.icon}>
        <Sort />
      </div>
    </div>
    </ButtonBase>
  )
}