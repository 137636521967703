import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TopBarWrapper from '../../components/TopBarWrapper';
import TopBarHeading from '../../components/TopBarHeading';
import MOTopBar from '../../components/MOTopBar';
import Flex from '../../components/Flex';
import FlexItem from '../../components/FlexItem';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import TextInput from '../../components/TextInput';
import HorizontalRuler from '../../components/HorizontalRuler';
import ClearButton from '../../components/ClearButton';
import PlusIcon from '../../components/MDLIcon/icons/Plus';
import TrashIcon from '../../components/MDLIcon/icons/Trash';
import MOSelect from '../../components/MOSelect';
import StarOutline from '../../assets/star-outline.svg';
import StarFilled from '../../assets/star-filled.svg';
import SuccessTick from '../../assets/success.svg';
import WarningSign from '../../assets/warning_sign.svg';
import FailureTick from '../../assets/failure.svg';
import Button2 from '../../components/Button2';
import Loader from '../../components/Loader';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import EditIcon from '../../components/MDLIcon/icons/EditIcon';
import { ReactComponent as CopyIcon } from '../../components/MDLIcon/icons/CopyIconSVG.svg';
import TextArea from '../../components/TextArea';
import NumericOptionsQuestion from '../../components/NumericOptionsQuestion';
import CustomOptions from '../../components/CustomOptions';
const form_types = {
  feedback: {
    id: 'feedback',
    display_text: 'Feedback',
  },
  observation: {
    id: 'observation',
    display_text: 'Observation',
  },
  manager_feedback: {
    id: 'manager_feedback',
    display_text: 'Manager Feedback',
  },
  incentive_commitment: {
    id: 'incentive_commitment',
    display_text: 'Incentive Commitment',
  },
  action_plan: {
    id: 'action_plan',
    display_text: 'Action Plan',
  },
};

const checkIncentivePermission = (permissions) => {
  let hasPermission = false;
  permissions.features.forEach((feature) => {
    let { key } = feature;
    if (key === 'create_incentive_target_form') {
      hasPermission = true;
    }
  });
  if (!hasPermission) {
    delete form_types.incentive_commitment;
  }
};

function CourseFeedback({
  courses,
  courseEvents,
  history,
  jwtToken,
  MOClient,
  userInfo,
}) {
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [selectedTargetCourse, setSelectedTargetCourse] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [selectedTargetEvent, setSelectedTargetEvent] = React.useState(null);
  const [formType, setFormType] = React.useState('feedback');
  const [formName, setFormName] = React.useState('');
  const [formObjective, setFormObjective] = React.useState('');
  const [feedbackQuestions, setFeedbackQuestions] = React.useState([]);
  const [progressDialogOpen, setProgressDialogOpen] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [cloneSuccessDialogOpen, setCloneSuccessDialogOpen] = React.useState(
    false,
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [cloneErrorDialogOpen, setCloneErrorDialogOpen] = React.useState(false);
  const [errorDialogText, setErrorDialogText] = React.useState(
    'There was an unknown error, please try again?',
  );

  const [feedbackForms, setFeedbackForms] = React.useState([]);
  const [editFormIndex, setEditFormIndex] = React.useState(-1);
  const [cloneFormId, setCloneFormId] = React.useState(-1);
  const [editFormDialog, setEditFormDialog] = React.useState(false);
  const [cloneFormDialogOpen, setCloneFormDialogOpen] = React.useState(false);

  const [editFormQuestions, setEditFormQuestions] = React.useState([]);
  let editFormTypeText = '';
  let editFormType = '';
  checkIncentivePermission(userInfo.permissions);
  if (editFormIndex > -1) {
    //editFormQuestions = feedbackForms[editFormIndex].form_data;
    editFormTypeText =
      form_types[feedbackForms[editFormIndex].form_type].display_text;
    editFormType = feedbackForms[editFormIndex].form_type;
  }

  const formTypeText = form_types[formType].display_text;

  let selectedCourseData = {
    name: '',
  };

  let selectedTargetCourseData = {
    name: '',
  };

  let selectedEventData = {
    name: '',
  };

  let selectedTargetEventData = {
    name: '',
  };

  if (selectedCourse !== null) {
    const course = courses.filter((c) => c.id === selectedCourse);

    if (course.length > 0) {
      [selectedCourseData] = course;
    }
  }

  if (selectedTargetCourse !== null) {
    const course = courses.filter((c) => c.id === selectedTargetCourse);

    if (course.length > 0) {
      [selectedTargetCourseData] = course;
    }
  }

  const courseOptions = courses
    .map((course) => ({
      id: course.id,
      display_text: course.name,
    }))
    .sort((a, b) => (a.id > b.id ? -1 : 1));

  const filteredCourseEvents = courseEvents.filter(
    (e) => e.course_id === selectedCourse,
  );
  const filteredTargetCourseEvents = courseEvents.filter(
    (e) => e.course_id === selectedTargetCourse,
  );

  if (selectedEvent !== null) {
    const event = filteredCourseEvents.filter((v) => v.id === selectedEvent);
    if (event.length > 0) [selectedEventData] = event;
  }

  if (selectedTargetEvent !== null) {
    const event = filteredTargetCourseEvents.filter(
      (v) => v.id === selectedTargetEvent,
    );
    if (event.length > 0) [selectedTargetEventData] = event;
  }

  const courseEventOptions = filteredCourseEvents.map((courseEvent) => ({
    id: courseEvent.id,
    display_text: courseEvent.name,
  }));

  const targetCourseEventOptions = filteredTargetCourseEvents.map(
    (courseEvent) => ({
      id: courseEvent.id,
      display_text: courseEvent.name,
    }),
  );

  /* ==== Functions ==== */

  function refetch_feedback_forms() {
    if (selectedCourse === null || selectedEvent === null) {
      return;
    }

    const action = fetch_feedback_forms(
      MOClient,
      jwtToken,
      selectedCourse,
      selectedEvent,
    );

    action.then((outcome) => {
      if (outcome.status !== 'success') {
        return;
      }

      if (Array.isArray(outcome.data)) setFeedbackForms(outcome.data);
    });
  }

  function handleTabChange(event, value) {
    setTabValue(value);
  }

  function remove_question(index) {
    const copyQuestions = [...feedbackQuestions];
    copyQuestions.splice(index, 1);
    setFeedbackQuestions(copyQuestions);
  }

  function remove_edit_form_question(index) {
    const copyQuestions = [...editFormQuestions];
    copyQuestions.splice(index, 1);
    setEditFormQuestions(copyQuestions);
  }

  function updateQuestionText(index, text) {
    const copyQuestions = [...feedbackQuestions];
    copyQuestions[index].question = text;
    setFeedbackQuestions(copyQuestions);
  }

  function updateEditFormQuestionText(index, text) {
    const copyQuestions = [...editFormQuestions];
    copyQuestions[index].question = text;
    setEditFormQuestions(copyQuestions);
  }

  function updateQuestionType(index, id) {
    const copyQuestions = [...feedbackQuestions];
    copyQuestions[index].question_type = id;
    if (id === 'custom') {
      copyQuestions[index].options = ['Option 1', 'Option 2', 'Option 3'];
    }
    setFeedbackQuestions(copyQuestions);
  }

  function updateEditFormQuestionType(index, id) {
    const copyQuestions = [...editFormQuestions];
    copyQuestions[index].question_type = id;

    if (id === 'custom') {
      copyQuestions[index].options = ['Option 1', 'Option 2', 'Option 3'];
    }
    setEditFormQuestions(copyQuestions);
  }

  function updateObservationStep(index, text) {
    const copyQuestions = [...feedbackQuestions];
    copyQuestions[index].observation_step = text;
    setFeedbackQuestions(copyQuestions);
  }

  function updateEditFormObservationStep(index, text) {
    const copyQuestions = [...editFormQuestions];
    copyQuestions[index].observation_step = text;
    setEditFormQuestions(copyQuestions);
  }

  function updateObservationAction(index, text) {
    const copyQuestions = [...feedbackQuestions];
    copyQuestions[index].observation_action = text;
    setFeedbackQuestions(copyQuestions);
  }

  function updateEditFormObservationAction(index, text) {
    const copyQuestions = [...editFormQuestions];
    copyQuestions[index].observation_action = text;
    setEditFormQuestions(copyQuestions);
  }

  function updateObservationPoints(index, text) {
    const copyQuestions = [...feedbackQuestions];
    copyQuestions[index].observation_max_points = text;
    setFeedbackQuestions(copyQuestions);
  }

  function updateEditFormObservationPoints(index, text) {
    const copyQuestions = [...editFormQuestions];
    copyQuestions[index].observation_max_points = text;
    setEditFormQuestions(copyQuestions);
  }

  function on_submit() {
    let is_valid = false;

    if (
      formType === 'feedback' ||
      formType === 'manager_feedback' ||
      formType === 'action_plan'
    ) {
      is_valid = run_validation_feedback();
    } else if (formType === 'incentive_commitment') {
      is_valid = true;
    } else {
      is_valid = run_validation_observation();
    }

    if (!is_valid) return;

    setProgressDialogOpen(true);
    const action = addFeedbackForm({
      MOClient,
      token: jwtToken,
      course_id: selectedCourse,
      event_id: selectedEvent,
      form_name: formName,
      form_data: feedbackQuestions,
      form_type: formType,
      form_objective: formObjective,
    });

    action.then((outcome) => {
      setProgressDialogOpen(false);
      if (outcome === true) {
        setSuccessDialogOpen(true);
        refetch_feedback_forms();
      } else {
        setErrorDialogOpen(true);
      }
    });
  }

  function on_submit_edit() {
    let is_valid = false;
    if (
      editFormType === 'feedback' ||
      editFormType === 'manager_feedback' ||
      editFormType === 'action_plan'
    ) {
      is_valid = run_validation_feedback_edit();
    } else {
      is_valid = run_validation_observation_edit();
    }

    if (!is_valid) return;

    setEditFormDialog(false);
    setProgressDialogOpen(true);
    const action = MOClient.update_feedback_form({
      token: jwtToken,
      form_id: feedbackForms[editFormIndex].id,
      form_data: editFormQuestions,
      form_type: editFormType,
    });

    action.then((outcome) => {
      setProgressDialogOpen(false);
      if (outcome.status === 'success') {
        setSuccessDialogOpen(true);
        refetch_feedback_forms();
      } else {
        setErrorDialogOpen(true);
      }
    });
  }

  function clone_feedback_form() {
    if (selectedTargetEvent === -1 || selectedTargetCourse === -1) return;

    setCloneFormDialogOpen(false);
    setProgressDialogOpen(true);
    const action = MOClient.clone_feedback_form({
      token: jwtToken,
      form_id: cloneFormId,
      course_id: selectedTargetCourse,
      event_id: selectedTargetEvent,
    });

    action.then((outcome) => {
      setProgressDialogOpen(false);
      if (outcome.status === 'success') {
        setCloneSuccessDialogOpen(true);
        refetch_feedback_forms();
      } else {
        setCloneErrorDialogOpen(true);
      }
    });
  }

  function run_validation_feedback() {
    if (selectedCourse === null) {
      setErrorDialogText('Please select a course');
      setErrorDialogOpen(true);
      return false;
    }

    if (selectedEvent === null) {
      setErrorDialogText('Please select an event');
      setErrorDialogOpen(true);
      return false;
    }

    if (feedbackQuestions.length === 0) {
      setErrorDialogText('Please add some questions');
      setErrorDialogOpen(true);
      return false;
    }

    // check each of the question

    for (const [index, question] of feedbackQuestions.entries()) {
      if (question.question === '') {
        setErrorDialogText(
          `Question statement cannot be empty for Question ${index +
            1}, please type your question.`,
        );
        setErrorDialogOpen(true);
        return false;
      }
    }

    return true;
  }

  function run_validation_feedback_edit() {
    if (editFormQuestions.length === 0) {
      setErrorDialogText('Please add some questions');
      setErrorDialogOpen(true);
      return false;
    }

    // check each of the question

    for (const [index, question] of editFormQuestions.entries()) {
      if (question.question === '') {
        setErrorDialogText(
          `Question statement cannot be empty for Question ${index +
            1}, please type your question.`,
        );
        setErrorDialogOpen(true);
        return false;
      }
    }

    return true;
  }

  function run_validation_observation() {
    for (const [index, question] of feedbackQuestions.entries()) {
      if (
        question.observation_action === '' ||
        question.observation_step === '' ||
        question.observation_max_points === ''
      ) {
        setErrorDialogText(
          `Action, Step and Max points cannot be empty for Observation ${index +
            1}, please correct!`,
        );
        setErrorDialogOpen(true);
        return false;
      }
    }

    return true;
  }

  function run_validation_observation_edit() {
    for (const [index, question] of editFormQuestions.entries()) {
      if (
        question.observation_action === '' ||
        question.observation_step === '' ||
        question.observation_max_points === ''
      ) {
        setErrorDialogText(
          `Action, Step and Max points cannot be empty for Observation ${index +
            1}, please correct!`,
        );
        setErrorDialogOpen(true);
        return false;
      }
    }

    return true;
  }

  function delete_feedback_form_button_onclick(index) {
    setEditFormIndex(index);
    setDeleteDialogOpen(true);
  }

  function delete_feedback_form() {
    setDeleteDialogOpen(false);
    setProgressDialogOpen(true);
    const action_delete = MOClient.delete_feedback_form({
      token: jwtToken,
      form_id: feedbackForms[editFormIndex].id,
    });

    action_delete.then((outcome) => {
      setProgressDialogOpen(false);
      setEditFormIndex(-1);
      if (outcome.status === 'success') {
        refetch_feedback_forms();
      } else {
        setErrorDialogText(outcome.description);
        setErrorDialogOpen(true);
        //alert(outcome.description);
      }
    });
  }

  /* ==== Effects ==== */

  // FormType change
  React.useEffect(() => {
    setFeedbackQuestions([]);
    setFormObjective('');
  }, [formType]);

  React.useEffect(() => {
    refetch_feedback_forms();
  }, [selectedCourse, selectedEvent]);

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Course Feedback</TopBarHeading>
        </MOTopBar>
      </TopBarWrapper>
      <CustomTabs
        variant="scrollable"
        scrollButtons="off"
        value={tabValue}
        onChange={handleTabChange}>
        <CustomTab label="Create new form" />
        <CustomTab label="All Forms" />
      </CustomTabs>

      {/* Create new form */}
      {tabValue === 0 ? (
        <div>
          <div style={{ padding: 30 }}>
            <Flex alignItems="top" gap={24}>
              <FlexItem gap={24}>
                <FormControl>
                  <Label>
                    Select Course:
                    <span style={{ color: 'red' }}> *</span>
                  </Label>
                  <SimpleFormSelect
                    options={courseOptions}
                    autoClose
                    onSelect={(id) => {
                      setSelectedCourse(id);
                    }}
                    text={selectedCourseData.name}
                  />
                </FormControl>

                <FormControl>
                  <Label>
                    Select Course Event:
                    <span style={{ color: 'red' }}> *</span>
                  </Label>
                  <SimpleFormSelect
                    options={courseEventOptions}
                    onSelect={(id) => setSelectedEvent(id)}
                    text={selectedEventData.name}
                    autoClose
                  />
                </FormControl>
              </FlexItem>

              <FlexItem gap={24}>
                <FormControl>
                  <Label>Form Name:</Label>
                  <TextInput
                    value={formName}
                    onChangeText={(t) => setFormName(t)}
                  />
                </FormControl>

                <FormControl>
                  <Label>
                    Form Type:
                    <span style={{ color: 'red' }}> *</span>
                  </Label>
                  <SimpleFormSelect
                    options={Object.values(form_types)}
                    autoClose
                    onSelect={(id) => {
                      console.log(id);
                      console.log(feedbackQuestions);
                      setFormType(id);
                    }}
                    text={formTypeText}
                  />
                </FormControl>
              </FlexItem>
            </Flex>
          </div>

          <HorizontalRuler />

          {formType === 'manager_feedback' ? (
            <div style={{ padding: 30 }}>
              <FormControl>
                <Label>Learning Objective</Label>
                <TextArea
                  text={formObjective}
                  onChangeText={(text) => {
                    setFormObjective(text);
                  }}></TextArea>
              </FormControl>
            </div>
          ) : null}

          {feedbackQuestions.map((question, index) => (
            <div style={{ marginTop: 15 }} key={index}>
              <QuestionWrapper>
                <QuestionHeader>
                  <Label>{`${formTypeText} Question ${index + 1}`}</Label>

                  <ActionController>
                    <CurvedButtonBase
                      tabIndex={-1}
                      onClick={() => {
                        remove_question(index);
                      }}>
                      <StyledTrashIcon />
                    </CurvedButtonBase>
                  </ActionController>
                </QuestionHeader>

                {(formType === 'feedback' ||
                  formType === 'manager_feedback' ||
                  formType === 'action_plan') &&
                typeof question.question_type !== 'undefined' ? (
                  <div>
                    <MOSelect
                      hideFilterIcon
                      options={Object.values(questionTypes)}
                      text={questionTypes[question.question_type].display_text}
                      onSelect={(id) => {
                        updateQuestionType(index, id);
                      }}
                    />

                    <div style={{ marginTop: 10 }} />

                    <TextInput
                      tabIndex={index}
                      value={question.question}
                      onChangeText={(t) => {
                        updateQuestionText(index, t);
                      }}
                    />

                    <div style={{ marginTop: 10 }} />

                    {question.question_type === 'agree_disagree' ? (
                      <AgreeDisagreeSample />
                    ) : null}

                    {question.question_type === 'rating' ? (
                      <RatingSample />
                    ) : null}

                    {question.question_type === 'yes_no' ? (
                      <YesNoSample />
                    ) : null}

                    {question.question_type === 'custom' ? (
                      <div>
                        <CustomOptions
                          options={question.options}
                          onChange={(options) => {
                            const copyQuestions = [...feedbackQuestions];
                            copyQuestions[index].options = options;
                            setFeedbackQuestions(copyQuestions);
                          }}
                        />

                        <StyledGenericButton
                          onClick={() => {
                            const feedbackQuestionsCopy = [
                              ...feedbackQuestions,
                            ];
                            feedbackQuestionsCopy[index].options.push('');
                            setFeedbackQuestions(feedbackQuestionsCopy);
                          }}>
                          <div style={{ marginRight: 5, display: 'flex' }}>
                            <StyledPlusIcon />
                          </div>
                          Add New Option
                        </StyledGenericButton>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {formType === 'observation' ? (
                  <div>
                    <FormControl>
                      <Label>
                        Step to be taken:
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <TextInput
                        value={question.observation_step}
                        onChangeText={(t) => updateObservationStep(index, t)}
                      />
                    </FormControl>

                    <FormControl>
                      <Label>
                        Action to be taken:
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <TextInput
                        value={question.observation_action}
                        onChangeText={(t) => updateObservationAction(index, t)}
                      />
                    </FormControl>

                    <FormControl>
                      <Label>
                        Max Points:
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <TextInput
                        width="50px"
                        value={question.observation_max_points}
                        onChangeText={(t) => updateObservationPoints(index, t)}
                        errorText={
                          isNaN(question.observation_max_points)
                            ? 'Should be a Number'
                            : null
                        }
                      />
                    </FormControl>
                  </div>
                ) : null}

                {formType === 'incentive_commitment' ? (
                  <NumericOptionsQuestion
                    tabIndex={index}
                    question={question.question}
                    onChangeText={(t) => {
                      updateQuestionText(index, t);
                    }}
                    options={question.options || []}
                    onChange={(options) => {
                      const copyQuestions = [...feedbackQuestions];
                      copyQuestions[index].options = options || [];
                      setFeedbackQuestions(copyQuestions);
                    }}
                    onClick={() => {
                      const feedbackQuestionsCopy = [...feedbackQuestions];
                      if (feedbackQuestionsCopy[index].options === undefined) {
                        feedbackQuestionsCopy[index].options = [];
                      }
                      feedbackQuestionsCopy[index].options.push('');
                      setFeedbackQuestions(feedbackQuestionsCopy);
                    }}
                  />
                ) : null}
              </QuestionWrapper>
            </div>
          ))}

          <div style={{ padding: '10px 30px', textAlign: 'left' }}>
            <StyledGenericButton
              disabled={
                formType === 'incentive_commitment' &&
                feedbackQuestions.length > 0
              }
              onClick={() => {
                const feedbackQuestionsCopy = [...feedbackQuestions];
                if (
                  formType === 'feedback' ||
                  formType === 'manager_feedback' ||
                  formType === 'action_plan'
                ) {
                  feedbackQuestionsCopy.push({
                    question_type: 'agree_disagree',
                    question: '',
                  });
                } else {
                  feedbackQuestionsCopy.push({
                    observation_step: '',
                    observation_action: '',
                    observation_max_points: '',
                  });
                }

                setFeedbackQuestions(feedbackQuestionsCopy);
              }}>
              <div style={{ marginRight: 5, display: 'flex' }}>
                <StyledPlusIcon />
              </div>
              Add New Question
            </StyledGenericButton>

            <div style={{ marginTop: 10 }} />

            <FormControl>
              <FlatPrimaryButton onClick={on_submit}>
                Add {formTypeText} Form
              </FlatPrimaryButton>
            </FormControl>
          </div>
        </div>
      ) : null}

      {/* All Feedback Forms */}

      {tabValue === 1 ? (
        <div>
          <div style={{ padding: 30 }}>
            <Flex alignItems="top" gap={24}>
              <FlexItem gap={24}>
                <FormControl>
                  <Label>
                    Select Course:
                    <span style={{ color: 'red' }}> *</span>
                  </Label>
                  <SimpleFormSelect
                    options={courseOptions}
                    autoClose
                    onSelect={(id) => {
                      setSelectedCourse(id);
                    }}
                    text={selectedCourseData.name}
                  />
                </FormControl>

                <FormControl>
                  <Label>
                    Select Course Event:
                    <span style={{ color: 'red' }}> *</span>
                  </Label>
                  <SimpleFormSelect
                    options={courseEventOptions}
                    onSelect={(id) => setSelectedEvent(id)}
                    text={selectedEventData.name}
                    autoClose
                  />
                </FormControl>
              </FlexItem>
            </Flex>
          </div>
          <HorizontalRuler />

          <div style={{ padding: 30 }}>
            <Table style={{ border: '1px solid rgba(0,0,0,0.1)' }}>
              <TableHead>
                <CustomTableRow>
                  <CustomTableCell align="center">
                    <TableSortLabel hideSortIcon>Form Name</TableSortLabel>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <TableSortLabel hideSortIcon>Form Type</TableSortLabel>
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <TableSortLabel hideSortIcon>Questions</TableSortLabel>
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <TableSortLabel hideSortIcon>Created On</TableSortLabel>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <StyledTabelHeaderLabel hideSortIcon>
                      Control Panel
                    </StyledTabelHeaderLabel>
                  </CustomTableCell>
                </CustomTableRow>
              </TableHead>
              <TableBody>
                {feedbackForms.map((row, index) => (
                  <CustomTableRow key={row.first_name}>
                    <CustomTableCell align="center">
                      <span style={{ textAlign: 'left' }}>{row.form_name}</span>
                    </CustomTableCell>

                    <CustomTableCell align="center">
                      {form_types[row.form_type].display_text}
                    </CustomTableCell>

                    <CustomTableCell align="center">
                      <span
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}>
                        {row.form_data.length}
                      </span>
                    </CustomTableCell>

                    <CustomTableCell align="center">
                      {dayjs(new Date(row.created_at)).format('MMM DD, YYYY')}
                    </CustomTableCell>

                    <CustomTableCell align="center">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}>
                        <CurvedButtonBase
                          onClick={() => {
                            setEditFormDialog(true);
                            setEditFormIndex(index);
                            setEditFormQuestions(
                              feedbackForms[index].form_data,
                            );
                          }}>
                          <EditIcon fill="#707070" />
                        </CurvedButtonBase>
                        <CurvedButtonBase
                          style={{ width: 22 }}
                          title="Copy Form"
                          onClick={() => {
                            setCloneFormDialogOpen(true);
                            setCloneFormId(feedbackForms[index].id);
                          }}>
                          <CopyIcon fill="#707070" />
                        </CurvedButtonBase>
                        <CurvedButtonBase
                          onClick={() => {
                            delete_feedback_form_button_onclick(index);
                          }}>
                          <StyledTrashIcon />
                        </CurvedButtonBase>
                      </div>
                    </CustomTableCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      ) : null}

      {/* Edit Form Dialog */}

      <StyledDialog
        open={editFormDialog}
        onClose={() => {
          setEditFormDialog(false);
        }}>
        <div>
          {editFormQuestions.map((question, index) => (
            <div style={{ marginTop: 15 }} key={index}>
              <QuestionWrapper>
                <QuestionHeader>
                  <Label>{`${editFormTypeText} Question ${index + 1}`}</Label>

                  <ActionController>
                    <CurvedButtonBase
                      tabIndex={-1}
                      onClick={() => {
                        remove_edit_form_question(index);
                      }}>
                      <StyledTrashIcon />
                    </CurvedButtonBase>
                  </ActionController>
                </QuestionHeader>

                {(editFormType === 'feedback' ||
                  editFormType === 'manager_feedback') &&
                typeof question.question_type !== 'undefined' ? (
                  <div>
                    <MOSelect
                      hideFilterIcon
                      options={Object.values(questionTypes)}
                      text={questionTypes[question.question_type].display_text}
                      onSelect={(id) => {
                        updateEditFormQuestionType(index, id);
                      }}
                    />

                    <div style={{ marginTop: 10 }} />

                    <TextInput
                      tabIndex={index}
                      value={question.question}
                      onChangeText={(t) => {
                        updateEditFormQuestionText(index, t);
                      }}
                    />

                    <div style={{ marginTop: 10 }} />

                    {question.question_type === 'agree_disagree' ? (
                      <AgreeDisagreeSample />
                    ) : null}

                    {question.question_type === 'rating' ? (
                      <RatingSample />
                    ) : null}

                    {question.question_type === 'yes_no' ? (
                      <YesNoSample />
                    ) : null}

                    {question.question_type === 'custom' ? (
                      <div>
                        <CustomOptions
                          options={question.options}
                          onChange={(options) => {
                            const copyQuestions = [...editFormQuestions];
                            copyQuestions[index].options = options;
                            setEditFormQuestions(copyQuestions);
                          }}
                        />

                        <StyledGenericButton
                          onClick={() => {
                            const feedbackQuestionsCopy = [
                              ...editFormQuestions,
                            ];
                            feedbackQuestionsCopy[index].options.push('');
                            setEditFormQuestions(feedbackQuestionsCopy);
                          }}>
                          <div style={{ marginRight: 5, display: 'flex' }}>
                            <StyledPlusIcon />
                          </div>
                          Add New Option
                        </StyledGenericButton>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {editFormType === 'observation' ? (
                  <div>
                    <FormControl>
                      <Label>
                        Step to be taken:
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <TextInput
                        value={question.observation_step}
                        onChangeText={(t) => {
                          updateEditFormObservationStep(index, t);
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <Label>
                        Action to be taken:
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <TextInput
                        value={question.observation_action}
                        onChangeText={(t) => {
                          updateEditFormObservationAction(index, t);
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <Label>
                        Max Points:
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <TextInput
                        width="50px"
                        value={question.observation_max_points}
                        onChangeText={(t) => {
                          updateEditFormObservationPoints(index, t);
                        }}
                        errorText={
                          isNaN(question.observation_max_points)
                            ? 'Should be a Number'
                            : null
                        }
                      />
                    </FormControl>
                  </div>
                ) : null}
              </QuestionWrapper>
            </div>
          ))}

          <div style={{ padding: '10px 30px', textAlign: 'left' }}>
            <StyledGenericButton
              onClick={() => {
                const copy = [...editFormQuestions];

                if (
                  formType === 'feedback' ||
                  formType === 'manager_feedback'
                ) {
                  copy.push({
                    question_type: 'agree_disagree',
                    question: '',
                  });
                } else {
                  copy.push({
                    observation_step: '',
                    observation_action: '',
                    observation_max_points: '',
                  });
                }

                setEditFormQuestions(copy);
              }}>
              <div style={{ marginRight: 5, display: 'flex' }}>
                <StyledPlusIcon />
              </div>
              Add New Question
            </StyledGenericButton>

            <div style={{ marginTop: 10 }} />

            <FormControl>
              <FlatPrimaryButton onClick={() => on_submit_edit()}>
                Save
              </FlatPrimaryButton>
            </FormControl>
          </div>
        </div>
      </StyledDialog>

      {/* Clone Dialog*/}
      <StyledDialog
        open={cloneFormDialogOpen}
        onClose={() => {
          setCloneFormDialogOpen(false);
        }}>
        <div style={{ padding: '0 40px' }}>
          <h2>Copy Feedback Form</h2>
          <p>
            Select the Training Course and Event to which this feedback form is
            to be added:
          </p>
          <div style={{ padding: 30 }}>
            <Flex alignItems="top" gap={24}>
              <FlexItem gap={24}>
                <FormControl>
                  <Label>
                    Select Course:
                    <span style={{ color: 'red' }}> *</span>
                  </Label>
                  <SimpleFormSelect
                    options={courseOptions}
                    autoClose
                    onSelect={(id) => {
                      setSelectedTargetCourse(id);
                    }}
                    text={selectedTargetCourseData.name}
                  />
                </FormControl>

                <FormControl>
                  <Label>
                    Select Course Event:
                    <span style={{ color: 'red' }}> *</span>
                  </Label>
                  <SimpleFormSelect
                    options={targetCourseEventOptions}
                    onSelect={(id) => setSelectedTargetEvent(id)}
                    text={selectedTargetEventData.name}
                    autoClose
                  />
                </FormControl>

                <FormControl>
                  <FlatPrimaryButton
                    onClick={() => {
                      clone_feedback_form();
                    }}>
                    Copy Form
                  </FlatPrimaryButton>
                </FormControl>
              </FlexItem>
            </Flex>
          </div>
        </div>
      </StyledDialog>

      {/* Failure Dialog */}
      <Dialog
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
        }}>
        <ProgressContainer>
          <img src={FailureTick} style={{ width: '60px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorDialogText}</ProgressText>
            <div style={{ margin: '22px 0 0', textAlign: 'center' }}>
              <Button2
                onClick={() => {
                  setErrorDialogOpen(false);
                }}>
                Close
              </Button2>
            </div>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Clone Failure Dialog */}
      <Dialog
        open={cloneErrorDialogOpen}
        onClose={() => {
          setCloneErrorDialogOpen(false);
        }}>
        <ProgressContainer>
          <img src={FailureTick} style={{ width: '60px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>
              There was an error in copying the form, please try again!
            </ProgressText>
            <div style={{ margin: '22px 0 0', textAlign: 'center' }}>
              <Button2
                onClick={() => {
                  setCloneErrorDialogOpen(false);
                }}>
                Close
              </Button2>
            </div>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={() => {
          setSuccessDialogOpen(false);
        }}>
        <ProgressContainer>
          <img src={SuccessTick} style={{ width: '100px' }} alt="Success" />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{formTypeText} form added successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setSuccessDialogOpen(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Clone Success Dialog */}
      <Dialog
        open={cloneSuccessDialogOpen}
        onClose={() => {
          setCloneSuccessDialogOpen(false);
        }}>
        <ProgressContainer>
          <img src={SuccessTick} style={{ width: '100px' }} alt="Success" />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Feedback form copied successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setCloneSuccessDialogOpen(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialogOpen}
        onClose={() => {
          setProgressDialogOpen(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />
          <ProgressText>Please wait...</ProgressText>
        </ProgressContainer>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
        }}>
        <ProgressContainer>
          <img src={WarningSign} style={{ width: '80px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>
              Are you sure you want to delete this form?
            </ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                delete_feedback_form();
              }}>
              Delete
            </Button2>
            <div style={{ width: 20, height: 20 }}></div>
            <Button2
              transparent={true}
              onClick={() => {
                setDeleteDialogOpen(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

async function addFeedbackForm({
  MOClient,
  token,
  course_id,
  event_id,
  form_name,
  form_data,
  form_type,
  form_objective,
}) {
  try {
    const action = await MOClient.add_feedback_form({
      token,
      course_id,
      event_id,
      form_name,
      form_data,
      form_type,
      form_objective,
    });

    if (action.status !== 'success') {
      return false;
    }

    return true;
  } catch (ex) {
    return false;
  }
}

async function fetch_feedback_forms(MOClient, token, course_id, event_id) {
  const action = await MOClient.get_feedback_forms_for_event({
    event_id,
    token,
    course_id,
  });

  return action;
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const questionTypes = {
  open_ended: {
    display_text: 'Open Ended',
    id: 'open_ended',
  },
  rating: {
    display_text: 'Rating',
    id: 'rating',
  },
  agree_disagree: {
    display_text: 'Agree/Disagree',
    id: 'agree_disagree',
  },
  yes_no: {
    display_text: 'Yes/No',
    id: 'yes_no',
  },
  custom: {
    display_text: 'Custom Options',
    id: 'custom',
  },
};

const CustomTabs = styled(Tabs)`
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 5px;
  background-color: white;
  z-index: 99999;

  .MuiTabs-indicator {
    height: 3px;
  }
`;

const CustomTab = styled(Tab)`
  text-transform: initial !important;
  font-family: Montserrat !important;
  font-weight: 600 !important;
  color: #484848;
  width: 236px;
`;

const QuestionWrapper = styled.div`
  text-align: left;
  padding: 10px 30px;
  width: 100%;
  max-width: 330px;
`;

const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledGenericButton = styled(ClearButton)`
  margin-top: 15px;
  color: #4f0060;
  padding: 0;
  font-size: 10px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  > #mastero_icon_add .cls-plus-icon {
    fill: #f4511e !important;
  }
`;

const CurvedButtonBase = styled(ButtonBase)`
  border-radius: 20px;
  margin: 3px;
  padding: 5px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  .class-trash-icon-2 {
    fill: #707070;
  }
`;

const ActionController = styled.div`
  margin-bottom: 15px;
`;

const AgreeDisagreeElement = styled.div`
  height: 27.3px;
  width: 310px;
  border: solid 0.5px #dde0e2;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  padding-left: 10px;
  font-family: 'Open Sans';
  display: flex;
  align-items: center;

  p {
    font-size: 11px;
    font-family: 'Open Sans';
    color: #707070;
  }
`;

function AgreeDisagreeSample() {
  return (
    <div>
      <AgreeDisagreeElement>
        <p>Strongly Agree</p>
      </AgreeDisagreeElement>
      <AgreeDisagreeElement>
        <p>Agree</p>
      </AgreeDisagreeElement>
      <AgreeDisagreeElement>
        <p>Neutral</p>
      </AgreeDisagreeElement>
      <AgreeDisagreeElement>
        <p>Disagree</p>
      </AgreeDisagreeElement>

      <AgreeDisagreeElement>
        <p>Strongly Disagree</p>
      </AgreeDisagreeElement>
    </div>
  );
}

function YesNoSample() {
  return (
    <div>
      <AgreeDisagreeElement>
        <p>Yes</p>
      </AgreeDisagreeElement>
      <AgreeDisagreeElement>
        <p>No</p>
      </AgreeDisagreeElement>
    </div>
  );
}

function RatingSample() {
  return <StarRating value={3} totalStars={5} />;
}

function Star({ filled }) {
  if (filled === true) {
    return (
      <div style={{ cursor: 'pointer' }}>
        <StarImg src={StarFilled} />
      </div>
    );
  }
  return (
    <div style={{ cursor: 'pointer' }}>
      <StarImg src={StarOutline} />
    </div>
  );
}

function StarRating(props) {
  function stars() {
    const stars_jsx = [];

    for (let i = 0; i < props.totalStars; i += 1) {
      stars_jsx.push(<Star key={i} filled={i + 1 <= props.value} />);
    }

    return stars_jsx;
  }

  return <div style={{ display: 'flex', alignItems: 'center' }}>{stars()}</div>;
}

const StarImg = styled.img`
  margin-right: 10px;
  width: 25px;
  display: block;
`;

const CustomTableCell = withStyles((theme) => ({
  root: {
    padding: '10px 10px !important',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: '',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '12px',
    color: '#707070',
  },
  head: {
    backgroundColor: '#fbfbfb',
    color: '#484848',
    fontWeight: 'bold',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '11px',
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
  },
  head: {
    display: 'flex',
  },
}))(TableRow);

const StyledTabelHeaderLabel = styled(TableSortLabel)`
  display: block;
  margin: auto;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 750px;
  }
`;

export default CourseFeedback;
