import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import TopBarWrapper from '../../components/TopBarWrapper';
import MOTopBar from '../../components/MOTopBar';
import CreateForm from './CreateForm';
import FormResponses from './FormResponses';
import AllForms from './AllForms';

const unload = (recaptchaSiteKey) => {
  const nodeBadge = document.querySelector('.grecaptcha-badge');
  if (nodeBadge) {
    document.body.removeChild(nodeBadge.parentNode);
  }

  const scriptSelector =
    "script[src='https://www.google.com/recaptcha/api.js?render=" +
    recaptchaSiteKey +
    "']";
  const script = document.querySelector(scriptSelector);
  if (script) {
    script.remove();
  }
};

const Header = styled.h4`
  font-size: 30px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
  font: bold normal 800 35px/387px Montserrat;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
`;

function FormsTab(props) {
  // console.log(props);
  let permissions = [];

  if (props.userInfo.permissions.hasOwnProperty('features')) {
    for (let permission of props.userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }

  unload('6LeMwbMUAAAAAIf56AAL27kmmU7ZalWZPkM81ka-');

  const formData = {
    form: { name: '', description: '', is_assessment: 0, notification_content: '', meta_info: { isTimed: true, scoreVisibility: true } },
    form_mode: 'NEW_FORM', form_active: true,
    target: { type: 'project' },
    questions: []
  };

  return (
    <div>
      <Router>
        <div>
          <Route
            path="/forms/create-new-form"
            render={() =>
              <div>
                <TopBarWrapper><MOTopBar><Header color="#0043b5">Create Form</Header></MOTopBar></TopBarWrapper>
                <Box p={3}><CreateForm {...formData} /></Box>
              </div>
            }
          />
          <Route
            path="/forms/responses"
            render={() =>
              <div>
                <TopBarWrapper><MOTopBar><Header color="#0043b5">Responses</Header></MOTopBar></TopBarWrapper>
                <Box p={3}><FormResponses /></Box>
              </div>
            }
          />
          <Route
            path="/forms/all-forms"
            render={() => <AllForms />}
          />
        </div>
      </Router>
    </div>
  );
}

export default FormsTab;
