import React from "react";

function FilterIcon(props) {
  let fill = "#FFF";

  if(props.hasOwnProperty("fill")) {
    fill = props.fill;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <defs>
        <style>
          {`.cls1-filter-icon{fill:#707070}.cls2-filter-icon{fill:none;opacity:.1}`}
        </style>
      </defs>
      <g id="mastero_icon_filter" transform="translate(-223 -225)">
        <g id="filter-results-button" transform="translate(224 228)">
          <g id="filter">
            <path id="Path_5303"
                  d="M5.186 85.39h2.963v-1.481H5.186zM0 76.5v1.482h13.335V76.5zm2.223 5.186h8.89V80.2h-8.89z"
                  className="cls1-filter-icon" data-name="Path 5303" transform="translate(0 -76.5)"/>
          </g>
        </g>
        <path id="Rectangle_1691" d="M0 0h15v15H0z" className="cls2-filter-icon" data-name="Rectangle 1691"
              transform="translate(223 225)"/>
      </g>
    </svg>
  )
}

export default FilterIcon;