import React from "react";

export default function PlusIcon(props) {

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='7.863' height='7.632' viewBox='0 0 7.863 7.632' className={props.className}>
      <defs />
      <g id='mastero_icon_add' transform='translate(-122.057 -53)'>
        <path id='Path_5555' d='M8.731-5.326H5.857v-2.808H3.726v2.808H.868v2.016h2.858V-.5h2.131v-2.81h2.874z'
              className='cls-plus-icon' data-name='Path 5555' transform='translate(121.188 61.134)'
        />
      </g>
    </svg>
  )
}