import { makeStyles } from '@material-ui/styles';
import React from 'react';
import QuestionTypes from './QuestionTypes';

const useStyles = makeStyles({
  root: {
    marginTop: '50px',
  },
  container: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
});

const QuestionContainer = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};

const Questions = ({
  questions,
  onSelectQuestionType,
  onQuestionUpdate,
  isAssessment,
  onDeleteQuestion,
}) => {
  console.log(questions);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <hr />
      {questions.map((question, index) => (
        <QuestionContainer key={index}>
          <QuestionTypes
            question={question}
            onSelect={(type) => {
              onSelectQuestionType(type, index);
            }}
            isAssessment={isAssessment}
            onUpdateQuestion={(question) => {
              onQuestionUpdate(question, index);
            }}
            onDelete={(question) => {
              onDeleteQuestion(question, index);
            }}
            index={index}
          />
        </QuestionContainer>
      ))}
    </div>
  );
};

export default Questions;
