import React from "react";

function ArrowRightIcon(props) {
  let fill = "#FFF";

  if(props.hasOwnProperty("fill")) {
    fill = props.fill;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
      <defs>
        <style>
          {`.cls-arrow-right{fill:#f4511e}.cls-arrow-right-2{fill:#fff;opacity:0}`}
        </style>
      </defs>
      <g id="mastero_depict_arrow" transform="translate(-42.5 -339)">
        <path id="DownArrow"
              d="M2.427 2.913V.485A.459.459 0 0 0 1.942 0a.459.459 0 0 0-.485.485v2.428H0l1.942 1.942 1.942-1.942z"
              className="cls-arrow-right" transform="rotate(-90 193.442 150.442)"/>
        <path id="Rectangle_1549" d="M0 0h6v6H0z" className="cls-arrow-right-2" data-name="Rectangle 1549"
              transform="translate(42.5 339)"/>
      </g>
    </svg>

  )
}

export default ArrowRightIcon;