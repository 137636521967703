import React, { useState, useEffect } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './drive.module.css';
import Accordion from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import './drive.css';
import LoginsTracker from '../LoginsTracker';
import CompletionTracker from '../CompletionTracker';
import Leaderboard from '../Leaderboard';
import LearnerEngagement from '../LearnerEngagement';
import SkillGapAnalysis from '../SkillGapAnalysis';
import ToughQuestionAnalysis from '../ToughQuestionAnalysis';
import RewardsTracker from '../RewardsTracker';
import LearnerJourneyTracker from '../LearnerJourneyTracker';
import TeamReportTracker from '../TeamReportTracker';
import MISLeaderboard from '../MISLeaderboard';
import MISRetention from '../MISRetentionAnalysis';
import { HashRouter as Router, Route, Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReloadIcon from '../../components/MDLIcon/icons/Reload';
import AnalyticsCourseLearnerFeedback from '../AnalyticsCourseLearnerFeedback.js';
import AnalyticsCourseTrainerFeedback from '../AnalyticsCourseTrainerFeedback.js';
import Utils from '../../utils';

const report_mapping = {
  mrn_2: MISLeaderboard,
  mrn_4: MISRetention,
};

const custom_styles = makeStyles({
  top_bar_wrapper: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },
  top_bar: {
    backgroundColor: '#fcfcfc',
    padding: '10px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: '999',
  },
  tab_bar_indicator: {
    height: '3px',
  },
  top_bar_heading: {
    fontWeight: 700,
    fontSize: '0.85rem',
    color: '#484848',
    margin: 0,
    textAlign: 'left',
  },
  top_bar_actions: {
    display: 'flex',
  },
  top_bar_action_button: {
    padding: '10px !important',
    borderRadius: '25px !important',
    transitionProperty: 'background-color',
    transitionDuration: 350,
    transitionTimingFunction: 'ease',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
  tabs_root: {
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
    marginBottom: '5px',
    backgroundColor: 'white',
    zIndex: '99999',
  },
  tab_label: {
    textTransform: 'initial !important',
    fontFamily: 'Montserrat !important',
    fontWeight: '600 !important',
    color: '#484848',
    width: '236px',
  },
  top_bar_action_icon: {
    cursor: 'pointer',
  },
  expansion_logins_completion: {
    backgroundColor: '#ffb300',
  },
  expansion_learning_engagement: {
    backgroundColor: '#4f0060',
  },
  expansion_learning_effectiveness: {
    backgroundColor: '#f63b34',
  },
  expansion_spreadsheet_reports: {
    backgroundColor: '#43a047',
  },
  expansion_panel_details: {
    padding: 30,
    paddingTop: 10,
    display: 'block',
  },
  sub_section_header: {
    fontWeight: 'bold',
  },
  sub_section_action: {
    fontWeight: 'bold',
  },

  icon: {
    color: 'white',
  },
  table_wrapper: {
    overflowX: 'auto',
  },
});

let AnalyticsTab = function(props) {
  const classes = custom_styles();
  let project_id = props.activeProject;
  let categoryFeature = props.categoryFeature;
  let classificationFeature = props.classificationFeature;
  let timestampFeature = props.timestampFeature;
  let showChallengeType = props.showChallengeType;
  let showLevelFilters = props.showLevelFilters;
  let showProjectFilter = props.showProjectFilter;

  let permissions = [];

  if (props.userInfo.permissions.hasOwnProperty('features')) {
    for (let permission of props.userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }

  let [tabValue, setTabValue] = useState(0);

  let [courses, setCourses] = useState([]);
  let [courseEvents, setCourseEvents] = useState([]);
  const [batches, setBatches] = React.useState([]);

  function fetch_courses() {
    let action = props.MOClient.get_training_courses({
      project_id: props.activeProject,
      token: props.jwtToken,
    });

    action
      .then((response) => {
        let parse_response_json = response.fetch_request.json();
        parse_response_json
          .then((data) => {
            if (data.status === 'success') {
              setCourses(data.courses);
              setCourseEvents(data.course_events);
            } else {
              // process error on fetching training courses
            }
          })
          .catch((ex) => {});
      })
      .catch((ex) => {});
  }

  React.useEffect(() => {
    let options = {
      token: props.jwtToken,
    };

    if (permissions.indexOf('view_all_training_batches') > -1) {
      options.get_all = true;
    }

    let action = props.MOClient.get_training_batches(options);

    action.then((result) => {
      if (result.status === 'success') {
        let fetch_request = result.fetch_request;

        let extract_data = fetch_request.json();

        extract_data.then((json_data) => {
          if (json_data.status === 'success') {
            setBatches(json_data.data);
          }
        });
      }
    });
  }, []);

  React.useEffect(() => {
    // fetch courses for this project
    fetch_courses();
  }, [props.activeProject]);

  let meta = {
    demographics: [],
  };

  if (props.companyInfo.hasOwnProperty('meta')) {
    let user_level_demographics = [];

    for (let i = 0; i < props.companyInfo.meta.demographics.length; i++) {
      let elem = props.companyInfo.meta.demographics[i];
      let demographic = elem['name'].toLowerCase();
      user_level_demographics.push(elem);
    }

    meta.demographics = user_level_demographics;
  }

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  // layout elements
  let mis_reports = null;
  if (
    props.companyInfo.hasOwnProperty('meta') &&
    props.companyInfo.meta.hasOwnProperty('mis_reports')
  ) {
    mis_reports = [];

    for (let report of props.companyInfo.meta.mis_reports) {
      if (report_mapping.hasOwnProperty(report.key)) {
        if (props.userInfo.permissions.hasOwnProperty('mis_reports')) {
          if (props.userInfo.permissions.mis_reports.indexOf(report.key) > -1) {
            mis_reports.push(
              <div className={styles.report}>
                {React.createElement(report_mapping[report.key], {
                  companyId: props.companyInfo.company_id,
                  projects: props.companyInfo.projects,
                  modules: props.companyInfo.modules,
                  challenges: props.companyInfo.challenges,
                  jwtToken: props.jwtToken,
                  reportTitle: report.name,
                  reportDescription: report.description,
                })}
              </div>,
            );
          }
        }
      }
    }
  }

  return (
    <div id="DriveApp">
      <div className={styles.main}>
        <Router>
          <div className={styles.container}>
            {/*== Logins and Completion === */}
            <Route
              path="/analytics/login-completion"
              render={(routeProps) => (
                <div>
                  <div className={classes.top_bar_wrapper}>
                    <div className={classes.top_bar}>
                      <h2 className={classes.top_bar_heading}>
                        Login & Completion Reports
                      </h2>
                      <div className={classes.top_bar_actions}>
                        <div className={classes.top_bar_action_icon}>
                          <div className={classes.top_bar_action_button}>
                            Last Updated on:{' '}
                            {Utils.syncTimer(props.companyInfo.company_id)}
                          </div>
                        </div>
                        {/* <div className={classes.top_bar_action_icon}>
                          <ButtonBase className={classes.top_bar_action_button}>
                            <ReloadIcon />
                          </ButtonBase>
                        </div> */}
                        {/*<div className={classes.top_bar_action_icon} style={{paddingLeft: "15px"}}>*/}
                        {/*  <ButtonBase className={classes.top_bar_action_button}>*/}
                        {/*    <IssueIcon/>*/}
                        {/*  </ButtonBase>*/}
                        {/*</div>*/}
                      </div>
                    </div>

                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      classes={{
                        root: classes.tabs_root,
                        indicator: classes.tab_bar_indicator,
                      }}>
                      <Tab
                        label="Login Reports"
                        classes={{
                          root: classes.tab_label,
                        }}
                      />
                      <Tab
                        label="Completion Reports"
                        classes={{
                          root: classes.tab_label,
                        }}
                      />
                    </Tabs>
                  </div>

                  <ExpansionPanelDetails
                    classes={{
                      root: classes.expansion_panel_details,
                    }}>
                    {tabValue === 0 && (
                      <div className={styles.sub_section_wrapper}>
                        <div className={styles.sub_section}>
                          <LoginsTracker
                            {...props}
                            demographics={meta.demographics}
                            showLevelFilters={showLevelFilters}
                            projects={props.companyInfo.projects}
                            batches={batches}
                            courses={courses}
                          />
                        </div>
                      </div>
                    )}
                    {/* classifications, classificationFeature, showChallengeType, showLevelFilters, */}
                    {tabValue === 1 && (
                      <div className={styles.sub_section_wrapper}>
                        <div className={styles.sub_section}>
                          <CompletionTracker
                            categories={props.companyInfo.categories}
                            classifications={props.companyInfo.classifications}
                            categoryFeature={categoryFeature}
                            classificationFeature={classificationFeature}
                            timestampFeature={timestampFeature}
                            showChallengeType={showChallengeType}
                            showLevelFilters={showLevelFilters}
                            showProjectFilter={showProjectFilter}
                            modules={props.companyInfo.modules}
                            challenges={props.companyInfo.challenges}
                            activeProject={project_id}
                            jwtToken={props.jwtToken}
                            userInfo={props.userInfo}
                            projects={props.companyInfo.projects}
                            demographics={meta.demographics}
                            batches={batches}
                            courses={courses}
                            {...props}
                          />
                        </div>
                      </div>
                    )}
                  </ExpansionPanelDetails>
                </div>
              )}
            />

            {/*== Learning Engagemement === */}
            {props.companyInfo.company_id !== 106 && (
              <Route
                path="/analytics/learning-and-engagement"
                render={(routeProps) => (
                  <div>
                    <div className={classes.top_bar_wrapper}>
                      <div className={classes.top_bar}>
                        <h2 className={classes.top_bar_heading}>
                          Learning & Engagement Reports
                        </h2>
                        <div className={classes.top_bar_actions}>
                          <div className={classes.top_bar_action_icon}>
                            <div className={classes.top_bar_action_button}>
                              Last Updated on:{' '}
                              {Utils.syncTimer(props.companyInfo.company_id)}
                            </div>
                          </div>
                          {/* <div className={classes.top_bar_action_icon}>
                          <ButtonBase className={classes.top_bar_action_button}>
                            <ReloadIcon />
                          </ButtonBase>
                        </div> */}
                          {/*<div className={classes.top_bar_action_icon} style={{paddingLeft: "15px"}}>*/}
                          {/*  <ButtonBase className={classes.top_bar_action_button}>*/}
                          {/*    <IssueIcon/>*/}
                          {/*  </ButtonBase>*/}
                          {/*</div>*/}
                        </div>
                      </div>

                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        classes={{
                          root: classes.tabs_root,
                          indicator: classes.tab_bar_indicator,
                        }}>
                        <Tab
                          label="Leaderboard"
                          classes={{
                            root: classes.tab_label,
                          }}
                        />
                        <Tab
                          label="Learner Engagement"
                          classes={{
                            root: classes.tab_label,
                          }}
                        />
                      </Tabs>
                    </div>

                    <ExpansionPanelDetails
                      classes={{
                        root: classes.expansion_panel_details,
                      }}>
                      {tabValue === 0 && (
                        <div className={styles.sub_section_wrapper}>
                          <div className={styles.sub_section}>
                            <Leaderboard
                              modules={props.companyInfo.modules}
                              challenges={props.companyInfo.challenges}
                              classifications={
                                props.companyInfo.classifications
                              }
                              classificationFeature={classificationFeature}
                              showChallengeType={showChallengeType}
                              showLevelFilters={showLevelFilters}
                              timestampFeature={timestampFeature}
                              activeProject={project_id}
                              jwtToken={props.jwtToken}
                              userInfo={props.userInfo}
                              projects={props.companyInfo.projects}
                              demographics={meta.demographics}
                              batches={batches}
                              courses={courses}
                              {...props}
                            />
                          </div>
                        </div>

                  

                      )}

                      
                    </ExpansionPanelDetails>

                  </div>)}/>)}                
              


            {/*== Learning Effectiveness === */}
            <Route
              path="/analytics/learning-effectiveness"
              render={(routeProps) => (
                <div>
                  <div className={classes.top_bar_wrapper}>
                    <div className={classes.top_bar}>
                      <h2 className={classes.top_bar_heading}>
                        Learning Effectiveness Reports
                      </h2>
                      <div className={classes.top_bar_actions}>
                        <div className={classes.top_bar_action_icon}>
                          <div className={classes.top_bar_action_button}>
                            Last Updated on:{' '}
                            {Utils.syncTimer(props.companyInfo.company_id)}
                          </div>
                        </div>
                        {/* <div className={classes.top_bar_action_icon}>
                          <ButtonBase className={classes.top_bar_action_button}>
                            <ReloadIcon />
                          </ButtonBase>
                        </div> */}
                        {/*<div className={classes.top_bar_action_icon} style={{paddingLeft: "15px"}}>*/}
                        {/*  <ButtonBase className={classes.top_bar_action_button}>*/}
                        {/*    <IssueIcon/>*/}
                        {/*  </ButtonBase>*/}
                        {/*</div>*/}
                      </div>
                    </div>

                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      classes={{
                        root: classes.tabs_root,
                        indicator: classes.tab_bar_indicator,
                      }}>
                      <Tab
                        label="Skill Gap Analysis"
                        classes={{
                          root: classes.tab_label,
                        }}
                      />
                      <Tab
                        label="Tough Question Analysis"
                        classes={{
                          root: classes.tab_label,
                        }}
                      />
                    </Tabs>
                  </div>

                  <ExpansionPanelDetails
                    classes={{
                      root: classes.expansion_panel_details,
                    }}>
                    {tabValue === 0 && (
                      <div className={styles.sub_section_wrapper}>
                        <div className={styles.sub_section}>
                          <SkillGapAnalysis
                            modules={props.companyInfo.modules}
                            challenges={props.companyInfo.challenges}
                            classifications={props.companyInfo.classifications}
                            activeProject={project_id}
                            jwtToken={props.jwtToken}
                            userInfo={props.userInfo}
                            demographics={meta.demographics}
                            classificationFeature={classificationFeature}
                            timestampFeature={timestampFeature}
                            showChallengeType={showChallengeType}
                            showLevelFilters={showLevelFilters}
                            projects={props.companyInfo.projects}
                            batches={batches}
                            courses={courses}
                            {...props}
                          />
                        </div>
                      </div>
                    )}

                    {tabValue === 1 && (
                      <div className={styles.sub_section_wrapper}>
                        <div className={styles.sub_section}>
                          <ToughQuestionAnalysis
                            modules={props.companyInfo.modules}
                            challenges={props.companyInfo.challenges}
                            activeProject={project_id}
                            jwtToken={props.jwtToken}
                            userInfo={props.userInfo}
                            demographics={meta.demographics}
                            classifications={props.companyInfo.classifications}
                            timestampFeature={timestampFeature}
                            classificationFeature={classificationFeature}
                            showChallengeType={showChallengeType}
                            showLevelFilters={showLevelFilters}
                            projects={props.companyInfo.projects}
                            batches={batches}
                            courses={courses}
                            {...props}
                          />
                        </div>
                      </div>
                    )}
                  </ExpansionPanelDetails>
                </div>
              )}
            />

            {/*== Course Feedback === */}

            <Route
              path="/analytics/course-feedback"
              render={(routeProps) => (
                <div>
                  <div className={classes.top_bar_wrapper}>
                    <div className={classes.top_bar}>
                      <h2 className={classes.top_bar_heading}>
                        Course Feedback
                      </h2>
                      <div className={classes.top_bar_actions}>
                        <div className={classes.top_bar_action_icon}>
                          <div className={classes.top_bar_action_button}>
                            Last Updated on:{' '}
                            {Utils.syncTimer(props.companyInfo.company_id)}
                          </div>
                        </div>
                        {/* <div className={classes.top_bar_action_icon}>
                          <ButtonBase className={classes.top_bar_action_button}>
                            <ReloadIcon />
                          </ButtonBase>
                        </div> */}
                        {/*<div className={classes.top_bar_action_icon} style={{paddingLeft: "15px"}}>*/}
                        {/*  <ButtonBase className={classes.top_bar_action_button}>*/}
                        {/*    <IssueIcon/>*/}
                        {/*  </ButtonBase>*/}
                        {/*</div>*/}
                      </div>
                    </div>

                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      classes={{
                        root: classes.tabs_root,
                        indicator: classes.tab_bar_indicator,
                      }}>
                      <Tab
                        label="Learner Feedback"
                        classes={{
                          root: classes.tab_label,
                        }}
                      />
                      <Tab
                        label="Trainer Feedback"
                        classes={{
                          root: classes.tab_label,
                        }}
                      />
                    </Tabs>
                  </div>

                  <ExpansionPanelDetails
                    classes={{
                      root: classes.expansion_panel_details,
                    }}>
                    {tabValue === 0 && (
                      <div className={styles.sub_section_wrapper}>
                        <div className={styles.sub_section}>
                          <AnalyticsCourseLearnerFeedback
                            classes={classes}
                            MOClient={props.MOClient}
                            userInfo={props.userInfo}
                            driveUsers={props.driveUsers}
                            jwtToken={props.jwtToken}
                            courses={courses}
                            batches={batches}
                            courseEvents={
                              courseEvents
                            }></AnalyticsCourseLearnerFeedback>
                        </div>
                      </div>
                    )}

                    {tabValue === 1 && (
                      <div className={styles.sub_section_wrapper}>
                        <div className={styles.sub_section}>
                          <AnalyticsCourseTrainerFeedback
                            classes={classes}
                            MOClient={props.MOClient}
                            jwtToken={props.jwtToken}
                            userInfo={props.userInfo}
                            driveUsers={props.driveUsers}
                            courses={courses}
                            batches={batches}
                            courseEvents={
                              courseEvents
                            }></AnalyticsCourseTrainerFeedback>
                        </div>
                      </div>
                    )}
                  </ExpansionPanelDetails>
                </div>
              )}
            />

            <Route
              path="/analytics/spreadsheet-reports"
              render={(routeProps) => {
                if (mis_reports != null && mis_reports.length > 0) {
                  return (
                    <Accordion
                      square={true}
                      classes={{ root: classes.expansion_spreadsheet_reports }}>
                      <ExpansionPanelSummary
                        expandIcon={
                          <ExpandMoreIcon className={classes.icon} />
                        }>
                        <div className={styles.expansion_panel_title}>
                          <h5 className={styles.panel_header}>
                            Spreadsheet Reports
                          </h5>
                        </div>
                      </ExpansionPanelSummary>

                      <ExpansionPanelDetails
                        classes={{
                          root: classes.expansion_panel_details,
                        }}>
                        <div className={styles.sub_section_wrapper}>
                          <div className={styles.sub_section}>
                            {/*== MIS Reports === */}

                            {mis_reports != null && mis_reports.length > 0 ? (
                              <div className={styles.mis_app_header}>
                                <div className={styles.report_container}>
                                  {mis_reports}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </ExpansionPanelDetails>
                    </Accordion>
                  );
                } else {
                  return null;
                }
              }}
            />

            {props.showRewardSection && (
              <Route
                path="/analytics/reward-analytics"
                render={(routeProps) => (
                  <RewardsTracker
                    {...props}
                    userInfo={props.userInfo}
                    timestampFeature={timestampFeature}
                    demographics={meta.demographics}
                    projects={props.companyInfo.projects}
                    activeProject={project_id}
                  />
                )}
              />
            )}

            {props.showLearnerJourney && (
              <Route
                path="/analytics/learner-journey"
                render={(routeProps) => (
                  <LearnerJourneyTracker
                    {...props}
                    userInfo={props.userInfo}
                    timestampFeature={timestampFeature}
                    categories={props.companyInfo.categories}
                    demographics={meta.demographics}
                    projects={props.companyInfo.projects}
                    modules={props.companyInfo.modules}
                    challenges={props.companyInfo.challenges}
                    activeProject={project_id}
                  />
                )}
              />
            )}

            {props.showTeamReport && (
              <Route
                path="/analytics/team-report"
                render={(routeProps) => (
                  <TeamReportTracker
                    {...props}
                    timestampFeature={timestampFeature}
                    projects={props.companyInfo.projects}
                    company_id={props.companyInfo.company_id}
                    activeProject={project_id}
                  />
                )}
              />
            )}
          </div>
        </Router>
      </div>
    </div>
  );
};

export default AnalyticsTab;
