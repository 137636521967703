import React from 'react';

function DuplicateIcon(props) {
  let fill = '#f4511e';

  if (props.hasOwnProperty('fill')) {
    fill = props.fill;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="mastero_icon_duplicate"
      width="22"
      height="22"
      viewBox="0 0 13.352 13.352"
      className={props.className}>
      <defs>
        <style>
          {`.class-duplicate-icon-1,.class-duplicate-icon-2{fill:${fill}}.class-duplicate-icon-1{opacity:0}`}
        </style>
      </defs>
      <path
        id="Rectangle_927"
        d="M0 0h13.352v13.352H0z"
        className="class-duplicate-icon-1"
        data-name="Rectangle 927"
      />
      <g id="duplicate" transform="translate(2.499 1.086)">
        <path
          id="Path_5256"
          d="M8.5 2.02a.332.332 0 0 0-.018-.09.324.324 0 0 0-.009-.03.339.339 0 0 0-.066-.1L6.706.1a.339.339 0 0 0-.1-.066c-.01 0-.02-.007-.031-.011A.336.336 0 0 0 6.484 0H1.7a.34.34 0 0 0-.34.34V1.7H.34a.34.34 0 0 0-.34.34v8.506a.34.34 0 0 0 .34.34h6.125a.34.34 0 1 0 0-.68H.681V2.382h.68v6.8a.34.34 0 0 0 .34.34h6.465a.34.34 0 0 0 .34-.34V2.041 2.02zm-1.7-.859l.54.54H6.8zM2.042 8.846V.68h4.083v1.361a.34.34 0 0 0 .34.34h1.361v6.465zm0 0"
          className="class-duplicate-icon-2"
          data-name="Path 5256"
        />
        <path
          id="Path_5257"
          d="M147.4 176.68h-3.06a.34.34 0 1 1 0-.68h3.06a.34.34 0 1 1 0 .68zm0 0"
          className="class-duplicate-icon-2"
          data-name="Path 5257"
          transform="translate(-140.938 -172.257)"
        />
        <path
          id="Path_5258"
          d="M147.4 240.68h-3.06a.34.34 0 0 1 0-.681h3.06a.34.34 0 1 1 0 .681zm0 0"
          className="class-duplicate-icon-2"
          data-name="Path 5258"
          transform="translate(-140.938 -234.896)"
        />
        <path
          id="Path_5259"
          d="M147.4 304.68h-3.06a.34.34 0 0 1 0-.681h3.06a.34.34 0 1 1 0 .681zm0 0"
          className="class-duplicate-icon-2"
          data-name="Path 5259"
          transform="translate(-140.938 -297.535)"
        />
      </g>
    </svg>
  );
}

export default DuplicateIcon;
