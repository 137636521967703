import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  RadioGroup,
  Select,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControl from '../../components/FormControl';
import StyledLabel from '../../components/Label';
import TextInput from '../../components/TextInput';
import StarRatingComponent from 'react-star-rating-component';
import { DateRange, InsertDriveFile } from '@material-ui/icons';

const useStyles = makeStyles({
  selectRoot: {
    width: '100%',
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-root': {
      height: '2rem',
    },
  },
});

const RadioChoice = ({ choice, checked, onChange }) => (
  <Grid container style={{ width: '460px' }} alignItems="center">
    <Grid item sm={1}>
      <Radio checked={checked} />
    </Grid>
    <Grid item sm={11}>
      <TextInput
        value={choice.value}
        disabled={choice.type === 'open'}
        onChangeText={(text) => onChange(text)}
        placeholder={choice.type === 'open' ? 'Open Ended ...' : ''}
      />
    </Grid>
  </Grid>
);

const NumberRating = ({
  question,
  onUpdateMinValue,
  onUpdateMaxValue,
  onUpdateLabel,
}) => {
  const classes = useStyles();
  const handleChangeMin = ({ target }) => {
    onUpdateMinValue(target.value);
  };
  const handleChangeMax = ({ target }) => {
    onUpdateMaxValue(target.value);
  };

  return (
    <>
      <div
        style={{
          alignItems: 'baseline',
          display: 'flex',
          paddingLeft: '2.6rem',
        }}>
        <FormControl>
          <Select
            variant="outlined"
            classes={{
              root: classes.selectRoot,
            }}
            style={{ width: '100%', height: '2.5rem' }}
            labelId="form-dropdown-label"
            id="form-dropdown"
            value={0}
            onChange={handleChangeMin}>
            {[0, 1, 2, 3, 4, 5].map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ padding: '0 1rem' }}>to</div>
        <FormControl>
          <Select
            variant="outlined"
            style={{ width: '100%', height: '2.5rem' }}
            labelId="form-dropdown-label"
            id="form-dropdown"
            value={5}
            onChange={handleChangeMax}>
            {[0, 1, 2, 3, 4, 5].map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Grid style={{ width: '500px', alignItems: 'center' }} container>
        <Grid item sm={1}>
          {question.minValue ? question.minValue : 0}
        </Grid>
        <Grid item sm={10}>
          <TextField
            onChange={(e) => onUpdateLabel('label1', e.target.value)}
            placeholder="Label 1"
            classes={{
              root: classes.textFieldRoot,
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid
        style={{ width: '500px', alignItems: 'center', marginTop: '1rem' }}
        container>
        <Grid item sm={1}>
          {question.maxValue ? question.maxValue : 5}
        </Grid>
        <Grid item sm={10}>
          <TextField
            onChange={(e) => onUpdateLabel('label2', e.target.value)}
            placeholder="Label 2"
            classes={{
              root: classes.textFieldRoot,
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </>
  );
};

const StarRating = ({ value, isAssessment, onUpdate }) => {
  const handleChange = (nextValue, prevValue, name) => {
    console.log(nextValue);
    onUpdate(nextValue);
  };

  return (
    <>
      <StarRatingComponent
        name={'star_rating'}
        value={isAssessment ? value : 3}
        starCount={5}
        onStarClick={(nextValue, prevValue, name) =>
          handleChange(nextValue, prevValue, name)
        }
        editing={isAssessment ? true : false}
      />
    </>
  );
};

export const FileUploadQuestion = ({ question, index, onUpdate }) => {
  const classes = useStyles();

  const questionUpdate = (key, value) => {
    const updatedQuestion = {
      ...question,
      [key]: value,
    };
    onUpdate(updatedQuestion);
  };

  const handleQuestionChange = (text) => {
    questionUpdate('question', text);
  };

  const handleQuestionTypeChange = ({ target }) => {
    questionUpdate('type', target.value);
  };

  const handleRequiredChange = ({ target }) => {
    questionUpdate('mandatory', target.checked);
  };

  const handleAssessmentChange = ({ target }) => {
    questionUpdate('assessment', target.checked);
  };

  const handleLabelChange = (labelName, value) => {
    questionUpdate(labelName, value);
  };

  const handleStarRatingChange = (starValue) => {
    questionUpdate('value', starValue);
  };

  const handleChangeMin = (minValue) => {
    questionUpdate('minValue', minValue);
  };

  const handleChangeMax = (maxValue) => {
    questionUpdate('maxValue', maxValue);
  };

  return (
    <div>
      <FormControl>
        <Grid container alignItems="center">
          <Grid item sm={8}>
            <StyledLabel style={{ padding: '0px' }}>
              Question {index + 1}:
            </StyledLabel>
          </Grid>
          <Grid container sm={4}>
            <Grid item sm={6}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={question.mandatory}
                      onChange={handleRequiredChange}
                    />
                  }
                  label="Required"
                />
              </FormControl>
            </Grid>
            {/* <Grid item sm={6}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={question.assessment}
                      onChange={handleAssessmentChange}
                    />
                  }
                  label="Assessment"
                />
              </FormControl>
            </Grid> */}
          </Grid>
        </Grid>
        <TextInput
          value={question.question}
          onChangeText={handleQuestionChange}
        />
      </FormControl>

      <Grid
        container
        style={{
          width: '500px',
          fontFamily: 'montserrat',
          color: '#484848',
          paddingLeft: '8rem',
        }}
        alignItems="center">
        <Grid
          style={{
            fontSize: '0.8rem',
          }}
          item
          sm={4}>
          File Upload Limit:
        </Grid>
        <Grid item sm={4}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <InsertDriveFile
              style={{ fontSize: '1rem', marginRight: '0.5rem' }}
            />
            <div
              style={{
                fontSize: '0.8rem',
              }}>
              2 MB
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
