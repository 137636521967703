import React, { useState, useRef, useEffect } from 'react';
import styles, {
  FlexRowDiv,
  Box,
  TranscriptHeading,
  TranscriptTextArea,
} from './style';
import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import FileSelect from '../../components/FileSelect';
import FormControl from '../../components/FormControl';
import Label, { SmallFontLabel, HeadingLabel } from '../../components/Label';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FlatClearButton from '../../components/FlatClearButton';
import ToggleSwitch from '../../components/ToggleSwitch';
import NumericInput from '../../components/NumericInput';
import CustomDropdown from '../../components/CustomDropdown';
import Loader from '../../components/Loader';
import Editor from './Editor';
import MSDialog from './MSDialog';
import MOClient from '../../MOClient';
import { Snackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const Page1_challange = (props) => {
  const { innerWidth: width, innerHeight: height } = window;

  const quillRef = useRef();
  const { setCurrentPage, pages } = props;
  const { microSkillState, setMicroSkillState } = props;
  const { addChallengeAction } = props;
  const classes = styles();
  //microskill states
  const [challangeTitle, setChallangeTitle] = useState(
    microSkillState.Challenge_Name,
  );
  const [challengeTitleLimit, setChallangeTitleLimit] = useState(
    80 - microSkillState.Challenge_Name.length,
  );
  const [scenario, setScenario] = useState(microSkillState.Scenar);
  const [scenarioLimit, setScenarioLimit] = useState(
    250 - microSkillState.Scenar.length,
  );
  const [transcript, setTranscript] = useState(
    microSkillState.Formatted_transcript,
  );
  const [isVideoBased, setIsVideoBased] = useState(
    microSkillState.Input_key == 'video',
  );
  const [uploadFile, setUploadFile] = useState(microSkillState.Video_name);

  function getWindowHeight() {
    const { innerHeight: height } = window;
    return height;
  }

  const [windowHeight, setWindowHeight] = useState(getWindowHeight());

  useEffect(() => {
    function handleResize() {
      setWindowHeight(getWindowHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const changeTranscript = (title) => {
    setTranscript(title);
    setMicroSkillState({ ...microSkillState, Formatted_transcript: title });
  };
  const updateChallengeTitle = (title) => {
    const remainingChar = 80 - title.length;
    if (remainingChar < 0) {
      return;
    }
    setChallangeTitleLimit(remainingChar);
    setChallangeTitle(title);

    setMicroSkillState({ ...microSkillState, Challenge_Name: title });
  };
  const updateScenario = (title) => {
    const remainingChar = 250 - title.length;
    if (remainingChar < 0) {
      return;
    }
    setScenarioLimit(remainingChar);
    setScenario(title);
    setMicroSkillState({ ...microSkillState, Scenar: title });
  };
  const updateTranscript = (title) => {
    setTranscript(title);
    setMicroSkillState((prevState) => ({
      ...prevState,
      Sample_transcript: title,
    }));
  };

  const updateIsVideoBased = (item) => {
    setIsVideoBased(item);
    setMicroSkillState({
      ...microSkillState,
      Input_key: item == true ? 'video' : 'audio',
    });
  };

  const updateUploadFile = (name) => {
    setUploadFile(name);
    // let newMicroSkillState = { ...microSkillState, Video_name: name };
    // setMicroSkillState(newMicroSkillState);
  };

  //microskill states end
  const [challangeTitleErrorText, setChallangeTitleErrorText] = useState(null);
  const [scenarioErrorText, setScenarioErrorText] = useState(null);
  const [confirmContinueDialog, setConfirmContinueDialog] = useState(false);

  const TranscriptStatus = {
    EMPTY: 'Upload Best Practice Sample Pitch To Generate Transcript',
    LOADING: 'Loading Transcript',
    LOADED: 'Sample Pitch Transcript:',
  };
  const [transcriptProgress, setTranscriptProgress] = useState(
    microSkillState.Sample_transcript.length == 0
      ? TranscriptStatus.EMPTY
      : TranscriptStatus.LOADED,
  );
  const [forward_xls_toast, set_forward_xls_toast] = useState(false);
  const [forward_xls_toast_msg, set_forward_xls_toast_msg] = useState('');
  const updateTranscriptProgress = (status) => {
    setTranscriptProgress(status);
  };

  const nextAction = () => {
    if (challangeTitle.length == 0) {
      setChallangeTitleErrorText('Challenge Title cannot be Empty.');
      return;
    }
    if (scenario.length == 0) {
      setScenarioErrorText('Scenario cannot be Empty.');
      return;
    }
    if (transcriptProgress == TranscriptStatus.LOADING) {
      set_forward_xls_toast(true);
      set_forward_xls_toast_msg('Uploading in progress. Please wait...');
      return;
    }
    if (
      transcriptProgress == TranscriptStatus.EMPTY &&
      transcript.length == 0
    ) {
      // set_forward_xls_toast(true);
      // set_forward_xls_toast_msg('Transcript text can not be empty.');
      setConfirmContinueDialog(true);
      return;
    }
    let currentTranscriptValue = setCurrentTranscriptValue();
    if (currentTranscriptValue.length == 0) {
      set_forward_xls_toast(true);
      set_forward_xls_toast_msg('Transcript text can not be empty.');
      return;
    }

    goToNextPage();
  };
  const [shouldsSaveDraft, setShouldSaveDraft] = useState(false);
  const saveDraft = () => {
    setCurrentTranscriptValue();
    setShouldSaveDraft(true);
  };

  useEffect(() => {
    if (shouldsSaveDraft) {
      setShouldSaveDraft(false);
      addChallengeAction();
    }
  }, [microSkillState]);

  const setCurrentTranscriptValue = () => {
    let currentTranscriptValue = transcript;
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      currentTranscriptValue = editor.getText().trim();
    }
    setTranscript(currentTranscriptValue);
    setMicroSkillState({
      ...microSkillState,
      Sample_transcript: currentTranscriptValue,
    });
    return currentTranscriptValue;
  };

  const continueAction = () => {
    setTranscriptProgress(TranscriptStatus.LOADED);
    set_forward_xls_toast(true);
    set_forward_xls_toast_msg('Transcript text can not be empty.');
  };
  const goToNextPage = () => {
    setCurrentPage(pages[1]);
  };

  const onFileSelect = (file) => {
    updateTranscript('');
    let newMicroSkillState = { ...microSkillState, Video_name: '' };
    updateUploadFile(file[0].name);
    try {
      const formdata = new FormData();
      formdata.append('', file[0], file[0].name);
      formdata.append('Project_id', microSkillState.Proj_Id);
      formdata.append('Company_id', microSkillState.Company_id);
      formdata.append('Module_id', microSkillState.Mod_Id);
      formdata.append('Challenge_type', microSkillState.Challenge_Type);
      const fetch_result = MOClient.uploadSampleTranscript(formdata);
      updateTranscriptProgress(TranscriptStatus.LOADING);
      fetch_result.then((result) => {
        if (result.status !== 200) {
          const result_json = result.json();
          result_json.then((result_json) => {
            //do nothing for now
            updateTranscriptProgress(TranscriptStatus.EMPTY);
          });
        } else {
          const async_result_json = result.json();
          async_result_json.then((result_json) => {
            console.log('ins success ' + result_json.transcript);
            setMicroSkillState((prevState) => ({
              ...prevState,
              Video_name: result_json.fileName,
            }));
            const { jobId, conversationId } = result_json.processVideoResponse;
            getTranscriptResponse(conversationId, jobId);
            // updateTranscript(result_json.transcript);
            // updateTranscriptProgress(TranscriptStatus.LOADED);
          });
        }
      });
    } catch (error) {
      console.error(error);
      updateTranscriptProgress(TranscriptStatus.EMPTY);
    }
  };

  const getTranscriptResponse = async (conversionId, jobId) => {
    const data = { conversionId, jobId };
    const fetch_result = MOClient.getTranscriptText(data);
    fetch_result.then((result) => {
      if (result.status !== 200) {
        const result_json = result.json();
        result_json.then((result_json) => {
          //do nothing for now
          updateTranscriptProgress(TranscriptStatus.EMPTY);
        });
      } else {
        const async_result_json = result.json();
        async_result_json.then((result_json) => {
          if (result_json.status == 'success') {
            updateTranscript(result_json.transcript);
            updateTranscriptProgress(TranscriptStatus.LOADED);
          } else if (result_json.status == 'failed') {
            updateTranscriptProgress(TranscriptStatus.EMPTY);
          } else {
            getTranscriptResponse(conversionId, jobId);
          }
        });
      }
    });
  };
  const TranscriptContainer = () => {
    return (
      <Box
        height={windowHeight}
        className={`${
          transcriptProgress == TranscriptStatus.LOADED
            ? classes.withoutBorderBox
            : classes.box
        }`}>
        {transcriptProgress == TranscriptStatus.LOADING && (
          <Loader fill="#f4511e" />
        )}
        {transcriptProgress != TranscriptStatus.LOADED && (
          <TranscriptHeading>{transcriptProgress}</TranscriptHeading>
        )}
        {transcriptProgress == TranscriptStatus.LOADED && (
          <Editor
            quillRef={quillRef}
            handleChange={changeTranscript}
            value={transcript}
            style={{ width: '100%', height: '90%' }}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      {/* <div className={classes.heading_container}>
        <HeadingLabel>Create Roleplay Challenge</HeadingLabel>
      </div> */}
      <FlexRowDiv className={classes.challangeContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.leftTopContainer}>
            {/* challange title */}
            <FormControl
              className={classes.full_Width}
              style={{ textAlign: 'left' }}>
              <Label>Challenge Title</Label>
              <div
                style={{
                  border: 'solid 0.5px #dde0e2',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                <TextInput
                  placeholder={'Enter Challenge Title Here'}
                  style={{ width: '78%' }}
                  border={'transparent'}
                  width={classes.full_Width}
                  value={challangeTitle}
                  onChangeText={(t) => {
                    setChallangeTitleErrorText(null);
                    updateChallengeTitle(t);
                  }}
                  // errorText={challangeTitleErrorText}
                />
                <label
                  style={{
                    marginLeft: '5px',
                    marginRight: '10px',
                    font: 'normal normal bold 6px/8px Montserrat',
                    color: '#707070',
                  }}>
                  {`Character limit: ${challengeTitleLimit}`}
                </label>
              </div>
              {challangeTitleErrorText && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  {challangeTitleErrorText}
                </label>
              )}
            </FormControl>
            {/* scenario */}
            <FormControl
              className={classes.full_Width}
              style={{ textAlign: 'left' }}>
              <Label>Scenario</Label>
              <div
                style={{
                  border: 'solid 0.5px #dde0e2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  width: '100%',
                }}>
                <TextArea
                  placeholder={'Enter Scenario Description Here'}
                  resize={'none'}
                  border={'transparent'}
                  width={'100%'}
                  text={scenario}
                  onChangeText={(text) => {
                    setScenarioErrorText(null);
                    updateScenario(text);
                  }}></TextArea>
                <label
                  style={{
                    marginRight: 10,
                    marginBottom: 5,
                    font: 'normal normal bold 6px/8px Montserrat',
                    color: '#707070',
                  }}>
                  {`Character limit: ${scenarioLimit}`}
                </label>
              </div>
              {scenarioErrorText && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  {scenarioErrorText}
                </label>
              )}
            </FormControl>
            {/* sample pitch */}
            <FormControl className={classes.full_Width}>
              <Label>Best Practices Sample Pitch:</Label>
              <FileSelect
                width={'100%'}
                accept={'video/*'}
                filename={uploadFile}
                disabled={transcriptProgress == TranscriptStatus.LOADING}
                onFileSelect={onFileSelect}
              />
              <div className={classes.smTranscriptContainer}>
                {TranscriptContainer()}
              </div>
            </FormControl>
            {/* learner input */}
            <FormControl style={{ display: 'none' }}>
              <Label>Learner Input:</Label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}>
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontWeight: '300',
                    fontSize: '0.60rem',
                  }}>
                  Audio-based
                </SmallFontLabel>
                <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                  <ToggleSwitch
                    bidirectional={'true'}
                    checked={isVideoBased}
                    onChange={(e) => {
                      updateIsVideoBased(!isVideoBased);
                    }}
                  />
                </div>
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontWeight: '300',
                    fontSize: '0.60rem',
                  }}>
                  Video-based
                </SmallFontLabel>
              </div>
            </FormControl>
          </div>
        </div>
        <div className={classes.rightContainer}>{TranscriptContainer()}</div>
      </FlexRowDiv>
      <FormControl className={classes.action_btn_container}>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          // disabled={transcriptProgress == TranscriptStatus.LOADING}
          onClick={() => {
            nextAction();
          }}>
          Next
        </FlatPrimaryButton>
        <div className={classes.save_btn_container}>
          <FlatClearButton
            style={{ padding: '15px 30px', minWidth: '20%' }}
            onClick={() => {
              saveDraft();
            }}>
            Save Draft
          </FlatClearButton>
        </div>
      </FormControl>
      <MSDialog
        message={
          "You haven't uploaded a sample video and generated a transcript, do you still wish to continue?"
        }
        open={confirmContinueDialog}
        setOpen={setConfirmContinueDialog}
        leftButtonTitle={'UPLOAD NOW'}
        rightButtonTitle={'CONTINUE ANYWAY'}
        leftButtonAction={() => setConfirmContinueDialog(false)}
        rightButtonAction={() => {
          setConfirmContinueDialog(false);
          continueAction();
        }}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => {
          set_forward_xls_toast(false);
          set_forward_xls_toast_msg('');
        }}
        message={<span id="message-id">{forward_xls_toast_msg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              set_forward_xls_toast(false);
              set_forward_xls_toast_msg('');
            }}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};
