import React from "react";

export default function CalendarIcon(props) {

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' className={props.className}>
      <defs />
      <g id='mastero_icon_calendars' transform='translate(-220 -292)'>
        <path id='Rectangle_1550' d='M0 0h19v19H0z' className='cls-1' data-name='Rectangle 1550'
              transform='translate(220 292)' />
        <path id='Path_5319' d='M1.371 3.427V9.6H9.6V3.427zm7.54-2.056h1.371a.648.648 0 0 1 .685.685v8.225a.648.648 0 0 1-.685.685H.685A.648.648 0 0 1 0 10.282V2.056a.648.648 0 0 1 .685-.685h1.371V.685A.648.648 0 0 1 2.742 0a.648.648 0 0 1 .685.685v.685H7.54V.685a.685.685 0 1 1 1.371 0zm-.686 6.854H6.854V6.854h1.371zm-2.056 0H4.8V6.854h1.369zm2.056-2.056H6.854V4.8h1.371zm-2.056 0H4.8V4.8h1.369zM4.113 8.225H2.742V6.854h1.371z'
              className='cls-2' data-name='Path 5319' transform='translate(224 296)'
        />
      </g>
    </svg>
  )
}