import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

const FileName = styled.h4`
  padding-left: 10px;
  font-family: OpenSans;
  font-size: 12px;
  text-align: left;
  color: #707070;
`;

export const Box = styled.div`
  width: 320px;
  max-width: 100%;
  height: 27.3px;
  border: solid 0.5px #dde0e2;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BrowseText = styled.p`
  font-family: 'Open Sans';
  font-size: 10px;
  padding-right: 10px;
  font-weight: 600;
  color: #f4511e;
  cursor: pointer;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export default function MultipleFileSelect(props) {
  let inputRef = useRef(null);
  let [filename, setFileName] = useState('');

  function handleClick() {
    inputRef.current.click();
  }

  function handleFileInput() {
    setFileName(`${inputRef.current.files.length} selected`);
    props.onFileSelect(inputRef.current.files);
  }
  return (
    <ButtonBase onClick={handleClick}>
      <Box>
        <div>
          <FileName>{filename}</FileName>
          <input
            multiple
            ref={inputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileInput}
          />
        </div>
        <div>
          <BrowseText>Browse</BrowseText>
        </div>
      </Box>
    </ButtonBase>
  );
}
