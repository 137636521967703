import React from 'react';
import styled from 'styled-components';
import TableHead from '@material-ui/core/TableHead';
import { saveAs } from 'file-saver';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  Dialog,
  TableContainer,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextInput from '../../components/TextInput';
import dayjs from 'dayjs';
import Excel from 'exceljs';
import utc from 'dayjs/plugin/utc';
import Accordion from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Utils from '../../utils';
import MODatePicker from '../../components/MODatePicker';
import MODateTimePicker from '../../components/MODateTimePicker';
import KababMenu from '../../components/KababMenu';
import LinearProgress from '../../components/LinearProgress';
import EditIcon from '../../components/MDLIcon/icons/EditIcon';
import TrashIcon from '../../components/MDLIcon/icons/Trash';
import MOSelect from '../../components/MOSelect';
import TopBarHeading from '../../components/TopBarHeading';
import MOTopBar from '../../components/MOTopBar';
import TopBarWrapper from '../../components/TopBarWrapper';
import CoursePathwayElement from '../../components/CoursePathwayElement';
import PauseIcon from '../../components/MDLIcon/icons/PauseIcon';
import Loader from '../../components/Loader';
import Button2 from '../../components/Button2';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import MOTimePicker from '../../components/MOTimePicker';
import TextArea from '../../components/TextArea.js';

dayjs.extend(utc);

const courseElementBGConstants = ['#1a56bd', '#f63b34', '#56dab8', '#ffdd00'];

function TrainingLog(props) {
  const permissions = [];

  let driveUsersObj = Utils.objectify(props.driveUsers, 'id');

  if (props.userInfo.permissions.hasOwnProperty('features')) {
    for (const permission of props.userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }

  const [loadingSessions, setLoadingSessions] = React.useState(false);
  const [launchingModule, setLaunchingModule] = React.useState(false);
  const [launchingFeedback, setLaunchingFeedback] = React.useState(false);
  const [editingManagerFeedback, setEditingManagerFeedback] = React.useState(
    false,
  );
  const [launchedFeedback, setLaunchedFeedback] = React.useState(false);
  const [pausingModule, setPausingModule] = React.useState(false);
  const [moduleManageError, setModuleManageError] = React.useState(false);
  const [launchedModule, setLaunchedModule] = React.useState(false);
  const [pausedModule, setPausedModule] = React.useState(false);

  const [
    pushNotificationDialogOpen,
    setPushNotificationDialogOpen,
  ] = React.useState(false);

  const [
    sendPushNotificationStage,
    setSendPushNotificationStage,
  ] = React.useState(0);
  const [
    pushNotificationSchedule,
    setPushNotificationSchedule,
  ] = React.useState('send_now');
  const [pushNotificationDate, setPushNotificationDate] = React.useState(
    dayjs(new Date()),
  );
  const [
    pushNotificationDatePickerOpen,
    setPushNotificationDatePickerOpen,
  ] = React.useState(false);
  const [
    pushNotificationSuccessDialog,
    setPushNotificationSuccessDialog,
  ] = React.useState(false);
  const [
    pushNotificationFailureDialog,
    setPushNotificationFailureDialog,
  ] = React.useState(false);
  const [
    pushNotificationFailureErrorText,
    setPushNotificationFailureErrorText,
  ] = React.useState(false);
  const [pushNotificationTitle, setPushNotificationTitle] = React.useState('');
  const [pushNotificationBody, setPushNotificationBody] = React.useState('');
  const [pushNotificationTime, setPushNotificationTime] = React.useState(
    dayjs(new Date()),
  );
  const [
    pushNotificationTimePickerOpen,
    setPushNotificationTimePickerOpen,
  ] = React.useState(false);
  const [batchLearners, setBatchLearners] = React.useState([]);
  const [targetBatchLearners, setTargetBatchLearners] = React.useState([]);
  const [loadingBatchLearners, setLoadingBatchLearners] = React.useState(false);

  const [
    emailNotificationDialogOpen,
    setEmailNotificationDialogOpen,
  ] = React.useState(false);
  const [
    sendEmailNotificationStage,
    setSendEmailNotificationStage,
  ] = React.useState(0);
  const [emailNotificationTitle, setEmailNotificationTitle] = React.useState(
    '',
  );
  const [emailNotificationBody, setEmailNotificationBody] = React.useState('');
  const [emailNotificationTime, setEmailNotificationTime] = React.useState(
    dayjs(new Date()),
  );
  const [emailNotificationDate, setEmailNotificationDate] = React.useState(
    dayjs(new Date()),
  );
  const [
    emailNotificationSchedule,
    setEmailNotificationSchedule,
  ] = React.useState('send_now');
  const [
    emailNotificationDatePickerOpen,
    setEmailNotificationDatePickerOpen,
  ] = React.useState(false);
  const [
    emailNotificationTimePickerOpen,
    setEmailNotificationTimePickerOpen,
  ] = React.useState(false);
  const [
    emailNotificationSuccessDialog,
    setEmailNotificationSuccessDialog,
  ] = React.useState(false);
  const [
    emailNotificationFailureDialog,
    setEmailNotificationFailureDialog,
  ] = React.useState(false);
  const [
    emailNotificationFailureErrorText,
    setEmailNotificationFailureErrorText,
  ] = React.useState(false);

  const [
    editManagerFeedbackDialogOpen,
    setEditManagerFeedbackDialogOpen,
  ] = React.useState(false);
  const [
    editManagerFeedbackDialogSuccess,
    setEditManagerFeedbackDialogSuccess,
  ] = React.useState(false);
  const [
    loadingManagerFeedbackExclusions,
    setLoadingManagerFeedbackExclusions,
  ] = React.useState([]);
  const [
    activeEngagementTraining,
    setActiveEngagementTraining,
  ] = React.useState(0);

  const [activeEngagementEvent, setActiveEngagementEvent] = React.useState(0);
  const [activeEngagementForm, setActiveEngagementForm] = React.useState(0);

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('score');
  const [trainingSessions, setTrainingSessions] = React.useState([]);
  const [expandedTraining, setExpandedTraining] = React.useState(-1);

  const courses_object = Utils.objectify(props.courses, 'id');
  const batches_object = Utils.objectify(props.batches, 'id');
  const course_events_object = Utils.objectify(props.courseEvents, 'id');
  const training_session_object = Utils.objectify(trainingSessions, 'id');
  const [feedbackFormsObject, setFeedbackFormsObject] = React.useState({});

  const [filterTrainer, setFilterTrainer] = React.useState(0);
  const [filterBatch, setFilterBatch] = React.useState(0);
  const [filterCourse, setFilterCourse] = React.useState(0);
  const [filterCategory, setFilterCategory] = React.useState(0);

  let [startDate, setStartDate] = React.useState(null);
  let [endDate, setEndDate] = React.useState(null);
  let [startDateDialogOpen, setStartDateDialogOpen] = React.useState(false);
  const [endDateDialogOpen, setEndDateDialogOpen] = React.useState(false);
  let [
    download_xls_dialog_state,
    set_download_xls_dialog_state,
  ] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function fetch_training_sessions() {
    const options = {
      token: props.jwtToken,
    };

    if (permissions.indexOf('view_all_training_sessions') > -1) {
      options.get_all = true;
    }

    const fetch_training_sessions = props.MOClient.get_training_sessions(
      options,
    );

    fetch_training_sessions.then((outcome) => {
      if (outcome.status === 'success') {
        setTrainingSessions(outcome.data.data);
      }
    });
  }

  async function fetch_feedback_forms() {
    const options = {
      token: props.jwtToken,
      fields: 'id,event_id,form_name,form_type',
    };

    const fetch_action = await props.MOClient.get_all_feedback_forms(options);

    if (fetch_action.status === 'success') {
      return fetch_action.data;
    }

    return [];
  }

  function fetch_learners() {
    if (activeEngagementTraining === 0) return;

    let batchId =
      trainingSessionObj[activeEngagementTraining]['training_batches'][0];

    setLoadingBatchLearners(true);
    const action = props.MOClient.get_training_batch_learners({
      token: props.jwtToken,
      batch_id: batchId,
    });

    action.then((response) => {
      setLoadingBatchLearners(false);
      if (response.status === 'success') {
        setBatchLearners(response.data.data);

        let batchLearnerIds = response.data.data.map((learner) => {
          return learner.id;
        });

        if (!loadingManagerFeedbackExclusions)
          setTargetBatchLearners(batchLearnerIds);
      }
    });
  }

  function fetch_feedback_exclusions(training_session_id, event_id, form_id) {
    const action = props.MOClient.get_feedback_excluded_participants({
      token: props.jwtToken,
      training_session_id,
      event_id,
      form_id,
    });

    action.then((response) => {
      setLoadingManagerFeedbackExclusions(false);
      if (response.status === 'success') {
        setTargetBatchLearners(response.participant_ids);
      }
    });
  }

  function update_feedback_exclusions() {
    setEditManagerFeedbackDialogOpen(false);

    setEditingManagerFeedback(true);

    const action = props.MOClient.update_feedback_excluded_participants(
      props.jwtToken,
      activeEngagementTraining,
      activeEngagementEvent,
      activeEngagementForm,
      targetBatchLearners,
    );

    action.then((response) => {
      setEditingManagerFeedback(false);
      setEditManagerFeedbackDialogSuccess(true);
      setActiveEngagementTraining(0);
      setActiveEngagementEvent(0);
      setActiveEngagementForm(0);
    });
  }

  React.useEffect(() => {
    fetch_learners();
  }, [activeEngagementTraining]);

  React.useEffect(() => {
    let action_feedback_forms = fetch_feedback_forms();
    action_feedback_forms.then((feedback_forms) => {
      let feedback_forms_object = {};
      for (let form of feedback_forms) {
        feedback_forms_object[form.id] = form;
      }

      setFeedbackFormsObject(feedback_forms_object);
    });
  }, []);

  React.useEffect(() => {
    fetch_training_sessions();
  }, []);

  const event_forms_mapping = {};
  const event_manager_forms_mapping = {};

  for (const form of Object.values(feedbackFormsObject)) {
    if (
      form.form_type === 'feedback' ||
      form.form_type === 'incentive_commitment' ||
      form.form_type === 'action_plan'
    ) {
      if (!event_forms_mapping.hasOwnProperty(form.event_id)) {
        event_forms_mapping[form.event_id] = [];
      }
      event_forms_mapping[form.event_id].push(form.id);
    } else if (form.form_type === 'manager_feedback') {
      if (!event_manager_forms_mapping.hasOwnProperty(form.event_id)) {
        event_manager_forms_mapping[form.event_id] = [];
      }
      event_manager_forms_mapping[form.event_id].push(form.id);
    }
  }

  function handleSortClick(sorter) {
    if (order === 'desc') {
      setOrder('asc');
    } else setOrder('desc');

    setOrderBy(sorter);
  }

  const rows = trainingSessions
    .map((session) => {
      const o = {
        id: session.id,
        course_id: session.course_id,
        course_name: '',
        start_date: session.start_date,
        end_date: session.end_date,
        training_batches: session.training_batches,
        batches: '',
        drive_user_id: session.drive_user_id,
      };

      if (courses_object.hasOwnProperty(session.course_id)) {
        o.course_name = courses_object[session.course_id].name;
      }

      if (batches_object.hasOwnProperty(session.training_batches[0])) {
        o.batches = batches_object[session.training_batches[0]].batch_code;

        if (batches_object[session.training_batches[0]].batch_name !== null) {
          o.batches += ` ${batches_object[session.training_batches[0]].batch_name
            }`;
        }
      }

      return o;
    })
    .sort((firstEl, secondEl) => (firstEl.id > secondEl.id ? -1 : 1));

  let trainingSessionObj = Utils.objectify(rows, 'id');

  let rows_filtered = rows.filter((r) => {
    let status = false;
    let filter_selected = false;

    if (filterCategory > 0) {
      if (courses_object[r.course_id].category === filterCategory)
        status = true;
      else status = false;
      filter_selected = true;
    }

    if (filterCourse > 0) {
      if (r.course_id === filterCourse) status = true;
      else status = false;
      filter_selected = true;
    }

    if (filterBatch > 0) {
      if (r.training_batches.indexOf(filterBatch) >= 0) {
        if (!filter_selected) status = true;
      } else status = false;

      filter_selected = true;
    }

    if (filterTrainer > 0) {
      if (r.drive_user_id === filterTrainer) {
        if (!filter_selected) status = true;
      } else status = false;
      filter_selected = true;
    }

    if (startDate !== null) {
      let v1 = dayjs(new Date(r.start_date));
      let v2 = dayjs(startDate.format('YYYY-MM-DD'));
      if (v1.isAfter(v2) || v1.isSame(v2)) {
        if (!filter_selected) status = true;
      } else status = false;
      filter_selected = true;
    }

    if (endDate !== null) {
      let v1 = dayjs.utc(new Date(r.end_date));
      let v2 = dayjs.utc(endDate.format('YYYY-MM-DD'));

      if (v1.isBefore(v2) || v1.isSame(v2)) {
        if (!filter_selected) status = true;
      } else status = false;
      filter_selected = true;
    }

    if (filter_selected === false) return true;

    return status;
  });

  function process_pause_module(training_id, event_id) {
    setPausingModule(true);

    const action = props.MOClient.pause_training_module({
      token: props.jwtToken,
      training_session_id: training_id,
      event_id,
    });

    action.then((outcome) => {
      setPausingModule(false);
      if (outcome.status !== 'success') {
        setModuleManageError(true);
      } else {
        setPausedModule(true);
        fetch_training_sessions();
      }
    });
  }

  function process_launch_module(training_id, event_id) {
    console.log('will launch', training_id, event_id);
    setLaunchingModule(true);

    const action = props.MOClient.launch_training_module({
      token: props.jwtToken,
      training_session_id: training_id,
      event_id,
    });

    action.then((outcome) => {
      setLaunchingModule(false);
      if (outcome.status !== 'success') {
        setModuleManageError(true);
      } else {
        setLaunchedModule(true);
        fetch_training_sessions();
      }
    });
  }

  function process_enable_feedback(training_session_id, event_id) {
    setLaunchingFeedback(true);
    const action = props.MOClient.launch_feedback_form({
      token: props.jwtToken,
      training_session_id,
      event_id,
    });

    action.then((outcome) => {
      setLaunchingFeedback(false);
      if (outcome.status !== 'success') {
        // process failure on feedback launch
      } else {
        setLaunchingFeedback(false);
        fetch_training_sessions();
      }
    });
  }

  function get_feedback_launch_pause_button(
    training_session_id,
    event_id,
    launched = false,
  ) {
    if (event_forms_mapping.hasOwnProperty(event_id)) {
      return (
        <button
          style={{
            textAlign: 'left',
            border: 'none',
            backgroundColor: 'white',
            cursor: 'pointer',
            margin: 0,
            padding: 0,
          }}
          onClick={() => {
            if (!launched)
              process_enable_feedback(training_session_id, event_id);
          }}>
          {launched === true ? (
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                margin: 0,
                fontSize: '0.7rem',
                color: '#484848',
              }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={require('../../assets/complete_mark.svg')}
                  style={{
                    display: 'block',
                    marginRight: '5px',
                    width: '10px',
                  }}
                />
                Feedback Launched
              </span>
            </p>
          ) : (
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                margin: 0,
                fontSize: '0.7rem',
                color: '#4f0060',
              }}>
              <span>▶ Launch Feedback</span>
            </p>
          )}
        </button>
      );
    }

    return null;
  }

  function get_manager_feedback_button(training_session_id, event_id) {
    if (event_manager_forms_mapping.hasOwnProperty(event_id)) {
      return (
        <button
          style={{
            textAlign: 'left',
            border: 'none',
            backgroundColor: 'white',
            cursor: 'pointer',
            margin: 0,
            padding: 0,
          }}
          onClick={() => {
            setEditManagerFeedbackDialogOpen(true);
            setLoadingBatchLearners(true);
            setLoadingManagerFeedbackExclusions(true);
            fetch_feedback_exclusions(
              training_session_id,
              event_id,
              event_manager_forms_mapping[event_id][0],
            );
            setActiveEngagementTraining(training_session_id);
            setActiveEngagementEvent(event_id);
            setActiveEngagementForm(event_manager_forms_mapping[event_id][0]);
            setTargetBatchLearners([]);
          }}>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              margin: 0,
              fontSize: '0.7rem',
              color: '#4f0060',
            }}>
            <span>✎ Edit Manager Feedback</span>
          </p>
        </button>
      );
    }

    return null;
  }
  function get_launch_pause_button(
    training_session_launched_modules,
    event_modules,
    training_id,
    event_id,
  ) {
    let pause_microskill = true;

    if (!Array.isArray(training_session_launched_modules)) {
      training_session_launched_modules = [];
    }

    for (const mod of event_modules) {
      if (training_session_launched_modules.indexOf(mod) === -1) {
        pause_microskill = false;
        break;
      }
    }

    if (pause_microskill) {
      return (
        <button
          style={{
            textAlign: 'left',
            border: 'none',
            backgroundColor: 'white',
            cursor: 'pointer',
            margin: 0,
            padding: 0,
          }}
          onClick={() => process_pause_module(training_id, event_id)}>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              margin: 0,
              fontSize: '0.7rem',
              color: '#4f0060',
            }}>
            <StyledPauseIcon />
            <span>Pause Microskill</span>
          </p>
        </button>
      );
    }
    return (
      <button
        style={{
          textAlign: 'left',
          border: 'none',
          backgroundColor: 'white',
          cursor: 'pointer',
          margin: 0,
          padding: 0,
        }}
        onClick={() => process_launch_module(training_id, event_id)}>
        <p
          style={{
            textAlign: 'left',
            margin: 0,
            fontSize: '0.7rem',
            color: '#4f0060',
          }}>
          ▶ Launch Microskill
        </p>
      </button>
    );
  }

  function get_pathway(training_session_id) {
    const training_session = training_session_object[training_session_id];
    const { training_events } = training_session;

    const coursePathWayElements = [];

    for (const [i, value] of training_events.entries()) {
      // debugger;
      if (!course_events_object.hasOwnProperty(value.event_id)) continue;
      const event_name = course_events_object[value.event_id].name;
      let event_modules = course_events_object[value.event_id].modules;
      const event_start_date = dayjs(
        new Date(value.event_days[0].event_date),
      ).format('DD-MM-YYYY');

      if (event_modules === null) {
        event_modules = [];
      }

      coursePathWayElements.push(
        <div
          style={{
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CoursePathwayElement
              disableEdit
              disableClone
              disableDelete
              backgroundColor={courseElementBGConstants[i % 4]}
              text={event_name}
              date={event_start_date}
            />
            {i !== training_events.length - 1 ? (
              <hr
                style={{
                  height: '2px',
                  width: '30px',
                  border: '1px solid #f2f2f2',
                  backgroundColor: '#f2f2f2',
                }}
              />
            ) : null}
          </div>

          {event_modules.length > 0
            ? get_launch_pause_button(
              training_session.modules_launched,
              event_modules,
              training_session.id,
              value.event_id,
            )
            : null}

          {get_feedback_launch_pause_button(
            training_session_id,
            value.event_id,
            value.feedback_launched,
          )}

          {get_manager_feedback_button(training_session_id, value.event_id)}
        </div>,
      );
    }

    return coursePathWayElements;
  }

  const digits = {
    completed: 0,
    live: 0,
    upcoming: 0,
  };

  for (let row of rows) {
    const progress = get_progress(row.start_date, row.end_date);
    if (progress === 100) {
      digits.completed += 1;
    } else if (progress === 0) {
      digits.upcoming += 1;
    } else digits.live += 1;
  }

  let trainers = props.driveUsers.filter((u) => {
    let access = false;
    for (let permission of u.permissions.features) {
      if (permission.key === 'create_training_session') {
        access = true;
        break;
      }
    }
    return access;
  });

  let trainerOptions = trainers.map((t) => {
    return {
      id: t.id,
      display_text: t.first_name + ' ' + t.last_name,
    };
  });

  let batchOptions = props.batches.map((b) => {
    return {
      id: b.id,
      display_text: b.batch_code + ' ' + b.batch_name,
    };
  });

  let courseOptions = [];
  props.courses.forEach((c) => {
    if (filterCategory > 0) {
      if (c.category === filterCategory) {
        courseOptions.push({
          id: c.id,
          display_text: c.name,
        });
      }
    } else {
      courseOptions.push({
        id: c.id,
        display_text: c.name,
      });
    }
  });

  let category_object = {
    1: {
      name: 'Sales Training',
    },
    2: {
      name: 'Talent Management',
    },
    3: {
      name: 'Old',
    },
  };

  let categoryOptions = [
    {
      id: 1,
      display_text: 'Sales Training',
    },
    {
      id: 2,
      display_text: 'Talent Management',
    },
    {
      id: 3,
      display_text: 'Old',
    },
  ];

  function clearFilters() {
    setFilterCourse(0);
    setFilterBatch(0);
    setStartDate(null);
    setEndDate(null);
    setFilterTrainer(0);
    setFilterCategory(0);
  }

  async function download_as_excel() {
    set_download_xls_dialog_state(true);

    let workbook = new Excel.Workbook();

    let sheet = workbook.addWorksheet('Training Log');

    let columns = [
      {
        header: 'Course',
        key: 'course_name',
      },
      {
        header: 'Progress',
        key: 'progress',
      },
      {
        header: 'Start Date',
        key: 'start_date',
      },
      {
        header: 'End date',
        key: 'end_date',
      },
      {
        header: 'Assigned Learners',
        key: 'batches',
      },
    ];

    if (permissions.indexOf('view_all_training_sessions') > -1) {
      columns.push({
        header: 'Assigned Trainer',
        key: 'trainer',
      });
    }

    sheet.columns = columns;

    for (let row of rows_filtered) {
      let o = {};
      o.course_name = row.course_name;
      o.progress = get_progress(row.start_date, row.end_date);
      o.start_date = dayjs(new Date(row.start_date)).format('DD-MM-YYYY');
      o.end_date = dayjs(new Date(row.end_date)).format('DD-MM-YYYY');

      o.batches = row.batches;

      if (permissions.indexOf('view_all_training_sessions') > -1) {
        o.trainer = '';

        if (driveUsersObj.hasOwnProperty(row.drive_user_id.toString())) {
          o.trainer =
            driveUsersObj[row.drive_user_id.toString()].first_name +
            ' ' +
            driveUsersObj[row.drive_user_id.toString()].last_name;
        }
      }

      sheet.addRow(o);
    }

    let array_buff = await workbook.xlsx.writeBuffer();

    let buff = new Blob([array_buff]);
    //console.log(new Blob(buff));

    saveAs(buff, 'Training-Log.xlsx', { autoBOM: true });
    set_download_xls_dialog_state(false);
  }

  async function send_push_notification() {
    // run validations here

    if (pushNotificationTitle === '') {
      setLaunchingModule(false);
      setPushNotificationFailureErrorText('Notification title is required!');
      setPushNotificationFailureDialog(true);
      return;
    }

    if (pushNotificationBody === '') {
      setLaunchingModule(false);
      setPushNotificationFailureErrorText('Notification body is required!');
      setPushNotificationFailureDialog(true);
      return;
    }
    setPushNotificationDialogOpen(false);
    setLaunchingModule(true);

    let batchId =
      trainingSessionObj[activeEngagementTraining]['training_batches'][0];
    let options = {
      token: props.jwtToken,
      training_session_id: activeEngagementTraining,
      data: {
        batch_id: batchId,
        batch_learner_ids: targetBatchLearners,
        notification_title: pushNotificationTitle,
        notification_body: pushNotificationBody,
        notification_schedule: pushNotificationSchedule,
        notification_time: pushNotificationTime,
        notification_date: pushNotificationDate,
      },
    };
    props.MOClient.send_push_notification(options).then((response) => {
      setLaunchingModule(false);
      setTargetBatchLearners([]);
      setSendPushNotificationStage(0);
      setPushNotificationTitle('');
      setPushNotificationBody('');
      setActiveEngagementTraining(0);

      if (response.status === 'success') {
        setPushNotificationSuccessDialog(true);
      } else {
        setPushNotificationFailureErrorText(response.description);
        setPushNotificationFailureDialog(true);
      }
    });
  }

  async function send_email_notification() {
    // run validations here

    if (emailNotificationTitle === '') {
      setLaunchingModule(false);
      setEmailNotificationFailureErrorText('Email Subject is required!');
      setEmailNotificationFailureDialog(true);
      return;
    }

    if (emailNotificationBody === '') {
      setLaunchingModule(false);
      setEmailNotificationFailureErrorText('Email body is required!');
      setEmailNotificationFailureDialog(true);
      return;
    }
    setEmailNotificationDialogOpen(false);
    setLaunchingModule(true);

    let batchId =
      trainingSessionObj[activeEngagementTraining]['training_batches'][0];

    let options = {
      token: props.jwtToken,
      training_session_id: activeEngagementTraining,
      data: {
        batch_id: batchId,
        batch_learner_ids: targetBatchLearners,
        email_title: emailNotificationTitle,
        email_body: emailNotificationBody,
        email_schedule: emailNotificationSchedule,
        email_date: emailNotificationDate,
        email_time: emailNotificationTime,
      },
    };

    props.MOClient.send_email_notification(options).then((response) => {
      setLaunchingModule(false);
      setTargetBatchLearners([]);
      setSendEmailNotificationStage(0);
      setEmailNotificationTitle('');
      setEmailNotificationBody('');
      setActiveEngagementTraining(0);

      if (response.status === 'success') {
        setEmailNotificationSuccessDialog(true);
      } else {
        setEmailNotificationFailureDialog(true);
        setEmailNotificationFailureErrorText(response.description);
      }
    });
  }

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Training Log</TopBarHeading>
        </MOTopBar>
      </TopBarWrapper>

      <Wrapper>
        <Flex>
          <div>
            <Digit color="#0043b5">{digits.live}</Digit>
            <DigitCaption>Live Sessions</DigitCaption>
          </div>

          <div>
            <Digit color="#ff2596">{digits.completed}</Digit>
            <DigitCaption>Completed Sessions</DigitCaption>
          </div>

          <div>
            <Digit color="#56dab8">{digits.upcoming}</Digit>
            <DigitCaption>Upcoming Sessions</DigitCaption>
          </div>
        </Flex>
      </Wrapper>

      <MOTopBar>
        <TopBarHeading>All Training Sessions</TopBarHeading>
      </MOTopBar>

      <Wrapper>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {props.companyInfo.company_id == 90 && (
            <FormControl style={{ marginRight: 20 }}>
              <Label>Category:</Label>
              <MOSelect
                onSelect={(id) => {
                  setFilterCategory(id);
                }}
                options={categoryOptions}
                text={
                  filterCategory > 0 ? category_object[filterCategory].name : ''
                }
              />
            </FormControl>
          )}

          <FormControl style={{ marginRight: 20 }}>
            <Label>Course:</Label>
            <MOSelect
              onSelect={(id) => {
                setFilterCourse(id);
              }}
              options={courseOptions}
              text={filterCourse > 0 ? courses_object[filterCourse].name : ''}
            />
          </FormControl>

          <FormControl style={{ marginRight: 20 }}>
            <Label>Batch:</Label>
            <MOSelect
              onSelect={(id) => {
                setFilterBatch(id);
              }}
              options={batchOptions}
              text={
                filterBatch > 0
                  ? batches_object[filterBatch].batch_code +
                  ' ' +
                  batches_object[filterBatch].batch_name
                  : ''
              }
            />
          </FormControl>

          {permissions.indexOf('view_all_training_sessions') > -1 ? (
            <FormControl style={{ marginRight: 20 }}>
              <Label>Trainer:</Label>
              <MOSelect
                options={trainerOptions}
                onSelect={(id) => {
                  setFilterTrainer(id);
                }}
                text={
                  filterTrainer > 0
                    ? driveUsersObj[filterTrainer].first_name +
                    ' ' +
                    driveUsersObj[filterTrainer].last_name
                    : ''
                }
              />
            </FormControl>
          ) : null}

          <FormControl style={{ marginRight: 20 }}>
            <Label>Training Duration:</Label>
            <DurationWrapper>
              <MODatePicker
                open={startDateDialogOpen}
                text={startDate === null ? '' : startDate.format('DD-MM-YYYY')}
                value={startDate}
                onClick={() => {
                  setStartDateDialogOpen(!startDateDialogOpen);
                }}
                onClose={() => {
                  setStartDateDialogOpen(false);
                }}
                handleDateChange={(d) => {
                  setStartDate(d);
                }}
                minDate={dayjs(new Date()).subtract(1, 'year')}
              />

              <CourseFromToLabel>TO</CourseFromToLabel>

              <MODatePicker
                open={endDateDialogOpen}
                text={endDate === null ? '' : endDate.format('DD-MM-YYYY')}
                value={endDate}
                onClick={() => {
                  setEndDateDialogOpen(!endDateDialogOpen);
                }}
                onClose={() => {
                  setEndDateDialogOpen(false);
                }}
                handleDateChange={(d) => {
                  setEndDate(d);
                }}
                minDate={dayjs(new Date()).subtract(1, 'year')}
              />
            </DurationWrapper>
          </FormControl>

          <FormControl>
            <Label>Options:</Label>
            <KababMenu
              onClick={async (id) => {
                if (id === 1) {
                  download_as_excel();
                }
              }}
              options={[
                {
                  display_text: 'Download as xlsx',
                  id: 1,
                },
              ]}
            />
          </FormControl>
        </div>

        <div style={{ textAlign: 'left' }}>
          <FormControl>
            <Button2 transparent={true} onClick={clearFilters}>
              Clear Filters
            </Button2>
          </FormControl>
        </div>

        <div style={{ marginTop: 15 }}>
          <TableContainer style={{ paddingBottom: '40px' }}>
            <Table style={{ border: '1px solid rgba(0,0,0,0.1)' }}>
              <TableHead>
                <CustomTableRow>
                  <CustomTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'course_name'}
                      direction={order}
                      onClick={() => handleSortClick('course_name')}>
                      Name
                    </TableSortLabel>
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'progress'}
                      direction={order}
                      onClick={() => handleSortClick('progress')}>
                      Progress
                    </TableSortLabel>
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'email'}
                      direction={order}
                      onClick={() => handleSortClick('start_date')}>
                      Start Date
                    </TableSortLabel>
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'launch_date'}
                      direction={order}
                      onClick={() => handleSortClick('end_date')}>
                      End Date
                    </TableSortLabel>
                  </CustomTableCell>
                  {/*
                <CustomTableCell align="center">
                  <TableSortLabel
                    active={orderBy === 'next_event'}
                    direction={order}
                    onClick={() => handleSortClick('next_event')}>
                    Next Event
                    </TableSortLabel>
                    </CustomTableCell>
                    */}
                  {permissions.indexOf('view_all_training_sessions') > -1 ? (
                    <CustomTableCell align="center">
                      <TableSortLabel
                        active={orderBy === 'trainer'}
                        direction={order}
                        onClick={() => handleSortClick('trainer')}>
                        Assigned Trainer
                      </TableSortLabel>
                    </CustomTableCell>
                  ) : null}
                  <CustomTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'assigned_learners'}
                      direction={order}
                      onClick={() => handleSortClick('assigned_learners')}>
                      Assigned Learners
                    </TableSortLabel>
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <StyledTabelHeaderLabel hideSortIcon={true}>
                      Control Panel
                    </StyledTabelHeaderLabel>
                  </CustomTableCell>
                </CustomTableRow>
              </TableHead>
              <TableBody>
                {rows_filtered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRowWrapper>
                      <CustomTableRow
                        key={row.id}
                        onClick={() => {
                          if (expandedTraining === index) {
                            setExpandedTraining(-1);
                          } else setExpandedTraining(index);
                        }}>
                        <CustomTableCell align="center">
                          <span>{row.course_name}</span>
                        </CustomTableCell>

                        <CustomTableCell align="center">
                          <LinearProgress
                            width={get_progress(row.start_date, row.end_date)}
                          />
                        </CustomTableCell>

                        <CustomTableCell align="center">
                          {dayjs(new Date(row.start_date)).format('DD-MM-YYYY')}
                        </CustomTableCell>

                        <CustomTableCell align="center">
                          {dayjs(new Date(row.end_date)).format('DD-MM-YYYY')}
                        </CustomTableCell>

                        {/*
                    <CustomTableCell align="center">
                      <span
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}>
                        Post-Workshop Module
                        </span>
                        </CustomTableCell>
                        */}

                        {permissions.indexOf('view_all_training_sessions') >
                          -1 ? (
                          <CustomTableCell align="center">
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}>
                              {driveUsersObj.hasOwnProperty(
                                row.drive_user_id.toString(),
                              ) ? (
                                <span>
                                  {driveUsersObj[row.drive_user_id.toString()]
                                    .first_name +
                                    ' ' +
                                    driveUsersObj[row.drive_user_id.toString()]
                                      .last_name}
                                </span>
                              ) : (
                                'N/A'
                              )}
                            </span>
                          </CustomTableCell>
                        ) : null}

                        <CustomTableCell align="center">
                          {row.batches}
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                            }}>
                            <CurvedButtonBase
                              onClick={() => {
                                props.history.push({
                                  pathname:
                                    '/training/training-sessions/edit/' +
                                    row.id,
                                });
                              }}>
                              <EditIcon fill="#707070" />
                            </CurvedButtonBase>
                          </div>
                        </CustomTableCell>
                      </CustomTableRow>

                      <StyledExpansionPanel
                        expanded={index === expandedTraining}
                        square>
                        <StyledExpansionPanelSummary />
                        <StyledExpansionPanelDetails>
                          <div style={{ width: '100%' }}>
                            <TrainingControl>
                              {get_pathway(row.id)}
                            </TrainingControl>

                            <div
                              style={{
                                textAlign: 'left',
                                marginTop: 20,
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <h4
                                style={{
                                  color: 'rgb(72,72,72)',
                                  margin: 0,
                                  fontSize: '0.85rem',
                                  fontFamily: 'Montserrat',
                                  fontWeight: 'bold',
                                }}>
                                Engagement
                              </h4>

                              <button
                                style={{
                                  textAlign: 'left',
                                  border: 'none',
                                  backgroundColor: 'white',
                                  cursor: 'pointer',
                                  margin: 0,
                                  marginTop: 10,
                                  padding: 0,
                                  outline: 0,
                                }}
                                onClick={() => {
                                  setPushNotificationDialogOpen(true);
                                  setLoadingBatchLearners(true);
                                  setActiveEngagementTraining(row.id);
                                  setTargetBatchLearners([]);
                                }}>
                                <p
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    margin: 0,
                                    fontSize: '0.7rem',
                                    color: '#4f0060',
                                  }}>
                                  {/*<StyledPauseIcon />*/}
                                  <span>Send Push Notification</span>
                                </p>
                              </button>

                              <button
                                style={{
                                  textAlign: 'left',
                                  border: 'none',
                                  backgroundColor: 'white',
                                  cursor: 'pointer',
                                  margin: 0,
                                  marginTop: 10,
                                  padding: 0,
                                  outline: 0,
                                }}
                                onClick={() => {
                                  setEmailNotificationDialogOpen(true);
                                  setLoadingBatchLearners(true);
                                  setActiveEngagementTraining(row.id);
                                  setTargetBatchLearners([]);
                                }}>
                                <p
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    margin: 0,
                                    fontSize: '0.7rem',
                                    color: '#4f0060',
                                  }}>
                                  {/*<StyledPauseIcon />*/}
                                  <span>Send Email</span>
                                </p>
                              </button>
                            </div>
                          </div>
                        </StyledExpansionPanelDetails>
                      </StyledExpansionPanel>
                    </TableRowWrapper>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={rows_filtered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </Wrapper>

      {/* Progress on Launch/Pause */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={
          launchingModule ||
          pausingModule ||
          launchingFeedback ||
          editingManagerFeedback
        }>
        <ProgressContainer>
          <Loader fill="#f4511e" />
          <ProgressText>Please wait...</ProgressText>
        </ProgressContainer>
      </Dialog>

      {/* Failure on Pause/Launch */}
      <Dialog
        open={moduleManageError}
        onClose={() => {
          setModuleManageError(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>
              There was an error processing your request, please contact
              support!
            </ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setModuleManageError(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Success on Launch Module */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={launchedModule}
        onClose={() => {
          setLaunchedModule(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/tick.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Microskill launched successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setLaunchedModule(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Push Notification Success */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={pushNotificationSuccessDialog}
        onClose={() => {
          setPushNotificationSuccessDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/tick.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Push Notification sent successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setPushNotificationSuccessDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Edit Manager Feedback Success */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={editManagerFeedbackDialogSuccess}
        onClose={() => {
          setEditManagerFeedbackDialogSuccess(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/tick.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Manager Feedback updated successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setEditManagerFeedbackDialogSuccess(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Push Notification Failure */}
      <Dialog
        open={pushNotificationFailureDialog}
        onClose={() => {
          setPushNotificationFailureDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{pushNotificationFailureErrorText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setPushNotificationFailureDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Success on Pause Module */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={pausedModule}
        onClose={() => {
          setPausedModule(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/tick.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Microskill paused successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setPausedModule(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Dialog for Push Notification */}
      <Dialog
        open={pushNotificationDialogOpen}
        onClose={() => {
          setPushNotificationDialogOpen(false);
          setTargetBatchLearners([]);
          setSendPushNotificationStage(0);
          setActiveEngagementTraining(0);
        }}>
        <DialogTitle id="scroll-dialog-title">
          Send Push Notification
        </DialogTitle>
        <DialogContent dividers="paper">
          {sendPushNotificationStage === 0 ? (
            <div>
              {loadingBatchLearners === true ? (
                <Loader fill={'f63b34'} />
              ) : (
                <div>
                  <h4 style={{ margin: 0 }}>Select Participants</h4>
                  {batchLearners.length === 0 ? (
                    <p style={{ fontSize: '0.8rem' }}>
                      No participants in this batch
                    </p>
                  ) : null}
                  <List>
                    {batchLearners.map((value) => {
                      const labelId = `checkbox-list-label-${value.id}`;

                      return (
                        <ListItem
                          key={value.id}
                          role={undefined}
                          dense
                          button
                          onClick={() => {
                            const currentIndex = targetBatchLearners.indexOf(
                              value.id,
                            );
                            const newChecked = [...targetBatchLearners];

                            if (currentIndex === -1) {
                              newChecked.push(value.id);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }

                            setTargetBatchLearners(newChecked);
                          }}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={
                                targetBatchLearners.indexOf(value.id) > -1
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            secondary={value.participant_code}
                            primary={
                              <p
                                style={{
                                  fontSize: '0.7rem',
                                  margin: 0,
                                  marginTop: 5,
                                }}>{`${value.demographics.first_name
                                    ? value.demographics.first_name
                                    : ''
                                  } ${value.demographics.last_name
                                    ? value.demographics.last_name
                                    : ''
                                  }`}</p>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              )}
            </div>
          ) : null}

          {sendPushNotificationStage === 1 ? (
            <div>
              <FormControl>
                <Label>
                  Notification Title<span style={{ color: 'red' }}>*</span>
                </Label>
                <TextInput
                  onChangeText={(t) => setPushNotificationTitle(t)}
                  value={pushNotificationTitle}
                />
              </FormControl>
              <FormControl>
                <Label>
                  Notification Body<span style={{ color: 'red' }}>*</span>
                </Label>
                <TextInput
                  onChangeText={(t) => setPushNotificationBody(t)}
                  value={pushNotificationBody}
                />
              </FormControl>
              <FormControl>
                <Label>Select Time</Label>
                <RadioGroup
                  aria-label="gender"
                  name="push_notif_send_time"
                  value={pushNotificationSchedule}
                  onChange={(event) => {
                    setPushNotificationSchedule(event.target.value);
                  }}>
                  <FormControlLabel
                    value="send_now"
                    control={<Radio />}
                    label="Send Now"
                  />
                  <FormControlLabel
                    value="send_later"
                    control={<Radio />}
                    label="Send Later"
                  />
                </RadioGroup>
              </FormControl>

              {pushNotificationSchedule === 'send_later' ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl style={{ flexBasis: 0, flexGrow: 1 }}>
                    <Label>Date and Time</Label>
                    <MODateTimePicker
                      onClick={() => {
                        setPushNotificationDatePickerOpen(true);
                      }}
                      open={pushNotificationDatePickerOpen}
                      value={pushNotificationDate}
                      onClose={() => setPushNotificationDatePickerOpen(false)}
                      text={pushNotificationDate.format(
                        'YYYY-MM-DD HH:ss [Hrs]',
                      )}
                      handleDateChange={(d) => setPushNotificationDate(d)}
                    />
                  </FormControl>
                </div>
              ) : null}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPushNotificationDialogOpen(false);
              setTargetBatchLearners([]);
              setSendPushNotificationStage(0);
              setActiveEngagementTraining(0);
            }}
            color="primary">
            Cancel
          </Button>

          {sendPushNotificationStage === 0 && targetBatchLearners.length > 0 ? (
            <Button
              color="primary"
              onClick={() => {
                setSendPushNotificationStage(1);
              }}>
              Next
            </Button>
          ) : null}

          {sendPushNotificationStage === 1 ? (
            <Button
              color="primary"
              onClick={() => {
                setSendPushNotificationStage(0);
              }}>
              Previous
            </Button>
          ) : null}
          {sendPushNotificationStage === 1 ? (
            <Button
              color="primary"
              onClick={() => {
                send_push_notification();
              }}>
              {pushNotificationSchedule === 'send_now'
                ? 'Send Notification'
                : 'Schedule Notification'}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>

      {/* Dialog for Email Notification*/}
      <Dialog
        open={emailNotificationDialogOpen}
        onClose={() => {
          setEmailNotificationDialogOpen(false);
          setTargetBatchLearners([]);
          setSendEmailNotificationStage(0);
          setActiveEngagementTraining(0);
        }}>
        <DialogTitle id="scroll-dialog-title">Send Email</DialogTitle>
        <DialogContent dividers="paper">
          {sendEmailNotificationStage === 0 ? (
            <div>
              {loadingBatchLearners === true ? (
                <Loader fill={'f63b34'} />
              ) : (
                <div>
                  <h4 style={{ margin: 0 }}>Select Participants</h4>
                  {batchLearners.length === 0 ? (
                    <p style={{ fontSize: '0.8rem' }}>
                      No participants in this batch
                    </p>
                  ) : null}
                  <List>
                    {batchLearners.map((value) => {
                      const labelId = `checkbox-list-label-${value.id}`;

                      return (
                        <ListItem
                          key={value.id}
                          role={undefined}
                          dense
                          button
                          onClick={() => {
                            const currentIndex = targetBatchLearners.indexOf(
                              value.id,
                            );
                            const newChecked = [...targetBatchLearners];

                            if (currentIndex === -1) {
                              newChecked.push(value.id);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }

                            setTargetBatchLearners(newChecked);
                          }}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={
                                targetBatchLearners.indexOf(value.id) > -1
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            secondary={value.participant_code}
                            primary={
                              <p
                                style={{
                                  fontSize: '0.7rem',
                                  margin: 0,
                                  marginTop: 5,
                                }}>{`${value.demographics.first_name
                                    ? value.demographics.first_name
                                    : ''
                                  } ${value.demographics.last_name
                                    ? value.demographics.last_name
                                    : ''
                                  }`}</p>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              )}
            </div>
          ) : null}

          {sendEmailNotificationStage === 1 ? (
            <div>
              <FormControl>
                <Label>
                  Email Subject<span style={{ color: 'red' }}>*</span>
                </Label>
                <TextInput
                  onChangeText={(t) => setEmailNotificationTitle(t)}
                  value={emailNotificationTitle}
                />
              </FormControl>
              <FormControl>
                <Label>
                  Email Body<span style={{ color: 'red' }}>*</span>
                </Label>
                <TextArea
                  onChangeText={(t) => setEmailNotificationBody(t)}
                  value={emailNotificationBody}
                />
              </FormControl>
              <FormControl>
                <Label>Select Time</Label>
                <RadioGroup
                  aria-label="gender"
                  name="push_notif_send_time"
                  value={emailNotificationSchedule}
                  onChange={(event) => {
                    setEmailNotificationSchedule(event.target.value);
                  }}>
                  <FormControlLabel
                    value="send_now"
                    control={<Radio />}
                    label="Send Now"
                  />
                  <FormControlLabel
                    value="send_later"
                    control={<Radio />}
                    label="Send Later"
                  />
                </RadioGroup>
              </FormControl>

              {emailNotificationSchedule === 'send_later' ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl style={{ flexBasis: 0, flexGrow: 1 }}>
                    <Label>Date and Time</Label>
                    <MODateTimePicker
                      onClick={() => {
                        setEmailNotificationDatePickerOpen(true);
                      }}
                      open={emailNotificationDatePickerOpen}
                      value={emailNotificationDate}
                      onClose={() => setEmailNotificationDatePickerOpen(false)}
                      text={emailNotificationDate.format(
                        'YYYY-MM-DD HH:mm [Hrs]',
                      )}
                      handleDateChange={(d) => setEmailNotificationDate(d)}
                    />
                  </FormControl>
                </div>
              ) : null}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEmailNotificationDialogOpen(false);
              setTargetBatchLearners([]);
              setSendEmailNotificationStage(0);
              setActiveEngagementTraining(0);
            }}
            color="primary">
            Cancel
          </Button>

          {sendEmailNotificationStage === 0 &&
            targetBatchLearners.length > 0 ? (
            <Button
              color="primary"
              onClick={() => {
                setSendEmailNotificationStage(1);
              }}>
              Next
            </Button>
          ) : null}

          {sendEmailNotificationStage === 1 ? (
            <Button
              color="primary"
              onClick={() => {
                setSendEmailNotificationStage(0);
              }}>
              Previous
            </Button>
          ) : null}
          {sendEmailNotificationStage === 1 ? (
            <Button
              color="primary"
              onClick={() => {
                send_email_notification();
              }}>
              {emailNotificationSchedule === 'send_now'
                ? 'Send Email'
                : 'Schedule Email'}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>

      {/* Dialog for Edit Manager Feedback*/}
      <Dialog
        open={editManagerFeedbackDialogOpen}
        onClose={() => {
          setEditManagerFeedbackDialogOpen(false);
          setTargetBatchLearners([]);
          setActiveEngagementTraining(0);
        }}>
        <DialogTitle id="scroll-dialog-title">Update Managers</DialogTitle>
        <DialogContent dividers="paper">
          <div>
            {loadingBatchLearners === true ||
              loadingManagerFeedbackExclusions === true ? (
              <Loader fill={'f63b34'} />
            ) : (
              <div>
                <h4 style={{ margin: 0 }}>
                  Select Managers who should receive Feedback Link on Email
                </h4>
                {batchLearners.length === 0 ? (
                  <p style={{ fontSize: '0.8rem' }}>
                    No participants in this batch
                  </p>
                ) : null}

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>

                      <TableCell>Manager Name</TableCell>
                      <TableCell>Manager Email</TableCell>
                      <TableCell>Participant Name</TableCell>
                      <TableCell>Participant Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {batchLearners.map((value) => {
                      const labelId = `checkbox-list-label-${value.id}`;

                      return (
                        <TableRow
                          key={value.id}
                          role={undefined}
                          button
                          onClick={() => {
                            const currentIndex = targetBatchLearners.indexOf(
                              value.id,
                            );
                            const newChecked = [...targetBatchLearners];

                            if (currentIndex === -1) {
                              newChecked.push(value.id);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }

                            setTargetBatchLearners(newChecked);
                          }}>
                          <TableCell>
                            <Checkbox
                              edge="start"
                              checked={
                                targetBatchLearners.indexOf(value.id) === -1
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell>
                            {value.mastero_details.supervisor}
                          </TableCell>
                          <TableCell>
                            {value.mastero_details.segment_head}
                          </TableCell>
                          <TableCell>{`${value.demographics.first_name
                              ? value.demographics.first_name
                              : ''
                            } ${value.demographics.last_name
                              ? value.demographics.last_name
                              : ''
                            }`}</TableCell>
                          <TableCell>{value.participant_code}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditManagerFeedbackDialogOpen(false);
              setTargetBatchLearners([]);
              setActiveEngagementTraining(0);
            }}
            color="primary">
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={() => {
              update_feedback_exclusions();
            }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {/* Email Notification Success */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={emailNotificationSuccessDialog}
        onClose={() => {
          setEmailNotificationSuccessDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/tick.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Email sent successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setEmailNotificationSuccessDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Email Notification Failure */}
      <Dialog
        open={emailNotificationFailureDialog}
        onClose={() => {
          setEmailNotificationFailureDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{emailNotificationFailureErrorText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setEmailNotificationFailureDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const ErrorText = styled.span`
  font-size: 0.7rem;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #f63b34;
  margin-top: 5px;
`;

const Digit = styled.h4`
  font-family: 'Montserrat';
  font-size: 52px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  color: ${(props) => props.color};
`;

const Wrapper = styled.div`
  padding: 30px;
`;

const DigitCaption = styled.h5`
  font-family: 'Montserrat';
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  color: #484848;
`;

const Flex = styled.div`
  div {
    margin: 10px 30px;
  }

  div:first-child {
    margin-left: 0;
  }

  div&:last-child {
    margin-right: 0;
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
`;

const CurvedButtonBase = styled(ButtonBase)`
  border-radius: 20px;
  margin: 3px;
  padding: 5px;
`;

const StyledExpansionPanel = styled(Accordion)`
  border: none;
  box-shadow: none;
  margin: 0 !important;
`;

const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  display: none;
`;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  padding: 15px;
  border-bottom: 1px solid #f2f2f2;
`;

const CustomTableCell = withStyles((theme) => ({
  root: {
    padding: '15px 10px !important',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: '',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '12px',
    color: '#707070',
    borderBottom: 'none',
    textAlign: 'left',
  },
  head: {
    backgroundColor: '#fbfbfb',
    color: '#484848',
    fontWeight: 'bold',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '11px',
  },
}))(TableCell);

const TableRowWrapper = styled.div``;

const CustomTableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
  },
  head: {
    display: 'flex',
  },
}))(TableRow);

const TrainingControl = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const StyledTrashIcon = styled(TrashIcon)`
  .class-trash-icon-2 {
    fill: #707070;
  }
`;

const StyledTabelHeaderLabel = styled(TableSortLabel)`
  display: block;
  margin: auto;
`;

const StyledPauseIcon = styled(PauseIcon)`
  width: 25px;
  path {
    fill: #4f0060;
  }
`;

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;

function get_progress(start_date, end_date) {
  const duration = dayjs(new Date(end_date)).diff(new Date(start_date), 'day');
  const days_untill_completion = dayjs(new Date(end_date))
    .add(5, 'hour')
    .diff(new Date(), 'day');

  if (duration < days_untill_completion) {
    return 0;
  }

  const progress = (1 - days_untill_completion / duration) * 100;

  if (progress >= 100) return 100;

  return progress;
}

export default TrainingLog;
