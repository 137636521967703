import { Grid } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  baseButton: {
    border: '2px solid #F4511E',
    width: '200px',
    color: '#F4511E',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.97px',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  highlightButton: {
    background: '#F4511E',
    color: '#FFF',
  },
  baseIcon: {
    textAlign: 'center',
  },
  baseLabel: {
    textAlign: 'left',
    paddingLeft: '20px',
  },
}));

const QuestionTypeBase = ({
  icon,
  label,
  highlight,
  questionType,
  onClick,
}) => {
  const classes = useStyles();
  const baseButtonClasses = `${classes.baseButton} ${highlight ? classes.highlightButton : ''
    }`;

  const handleClick = () => {
    onClick(questionType);
  };

  return (
    <div className={baseButtonClasses} onClick={handleClick}>
      <Grid container alignItems="center">
        <Grid item md={3} className={classes.baseIcon}>
          {icon}
        </Grid>
        <Grid item md={9} className={classes.baseLabel}>
          {label}
        </Grid>
      </Grid>
    </div>
  );
};

export const RatingQuestionOption = ({ selected, onSelect }) => {
  return (
    <QuestionTypeBase
      highlight={selected}
      icon={<ThumbUp />}
      label="RATING"
      questionType="STAR_RATING"
      onClick={onSelect}
    />
  );
};
