import React from 'react';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

const ComponentWrapper = styled.div`
  min-width: 153.6px;
  padding: 0 15px;
  max-width: 250px;
  height: 32.5px;
  border-radius: 2px;
  background-color: ${props => props.transparent ? 'transparent' : '#f4511e'};
  border: 1px solid;
  border-color: ${props => props.transparent ? '#f4511e' : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Text = styled.h5`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 27px;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${props => props.transparent ? '#f4511e' : 'white'};
  text-transform: uppercase;
`;

function Button2(props) {
  return (
    <ButtonBase onClick={props.onClick}>
      <ComponentWrapper {...props}>
        <Text {...props}>
          {props.children}
        </Text>
      </ComponentWrapper>
    </ButtonBase>
  )
}

export default Button2;