import React from 'react';
import styled from 'styled-components';
import TopBarWrapper from '../../components/TopBarWrapper';
import MOTopBar from '../../components/MOTopBar';
import TopBarHeading from '../../components/TopBarHeading';
import MOSelect from '../../components/MOSelect';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Dialog } from '@material-ui/core';
import TrashIcon from '../../components/MDLIcon/icons/Trash';
import LinearProgress from '../../components/LinearProgress';
import EditIcon from '../../components/MDLIcon/icons/EditIcon';
import DuplicateIcon from '../../components/MDLIcon/icons/Duplicate';
import Utils from '../../utils';
import FormControl from '../../components/FormControl';
import Button2 from '../../components/Button2';
import Loader from '../../components/Loader';

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

function CourseLog(props) {
  let [order, setOrder] = React.useState('desc');
  let [orderBy, setOrderBy] = React.useState('score');
  const [filterModule, setFilterModule] = React.useState('All');
  const [activeCourse, setActiveCourse] = React.useState(0);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorDialogText, setErrorDialogText] = React.useState(
    'There was an unknown error, please try again?',
  );

  function handleSortClick(sorter) {
    if (order === 'desc') {
      setOrder('asc');
    } else setOrder('desc');

    setOrderBy(sorter);
  }

  function deleteCourse() {
    setDeletePopup(false);
    setProgressDialogOpen(true);

    let action_delete = props.MOClient.delete_training_course({
      course_id: activeCourse,
      token: props.jwtToken,
    });

    action_delete.then((result) => {
      setProgressDialogOpen(false);
      if (result.status !== 'success') {
        setErrorDialogOpen(true);
        setErrorDialogText(result.description);
      } else {
        props.onDelete();
      }
    });
  }

  let rows = props.courses.map((course) => {
    return {
      id: course.id,
      name: course.name,
      course_type: course.course_type,
    };
  });

  let coursesObject = Utils.objectify(props.courses, 'id');
  let courseEventsObject = Utils.objectify(props.courseEvents, 'id');
  let modulesObject = Utils.objectify(props.companyInfo.modules, 'id');

  function get_filtered_records() {
    let filtered_course_ids = [];
    if (filterModule !== 'All') {
      // process moduleFilter
      for (let courseEvent of props.courseEvents) {
        if (
          courseEvent.modules !== null &&
          courseEvent.modules.indexOf(Number(filterModule)) > -1
        ) {
          filtered_course_ids.push(courseEvent.course_id);
        }
      }

      let rows_filtered = rows.filter((row) => {
        return filtered_course_ids.indexOf(row.id) > -1;
      });

      return rows_filtered.sort((a, b) => {
        return a.id > b.id ? -1 : 1;
      });
    } else {
      return rows.sort((a, b) => {
        return a.id > b.id ? -1 : 1;
      });
    }
  }

  let course_types = {
    0: 'Blended',
    1: 'Standalone',
    2: 'Webinar',
    3: 'Internal',
    4: 'In-house',
    5: 'External',
  };

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Course Log</TopBarHeading>
        </MOTopBar>
      </TopBarWrapper>

      <Dialog
        open={deletePopup}
        onClose={() => {
          setDeletePopup(false);
        }}>
        <ProgressContainer>
          <ProgressText>
            Are you sure you want to delete this Course?
          </ProgressText>
          <ProgressText>
            <FormControl>
              <Actions>
                <Button2 onClick={deleteCourse}>Yes Delete</Button2>
                <Button2
                  onClick={() => setDeletePopup(false)}
                  transparent={true}
                  style={{ marginLeft: '5px' }}>
                  Cancel
                </Button2>
              </Actions>
            </FormControl>
          </ProgressText>
        </ProgressContainer>
      </Dialog>

      <Dialog
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorDialogText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setErrorDialogOpen(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialogOpen}
        onClose={() => {
          setProgressDialogOpen(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />
          <ProgressText>Please wait...</ProgressText>
        </ProgressContainer>
      </Dialog>

      <Wrapper>
        <Label>Microskill</Label>
        <MOSelect
          options={[
            { id: 'All', display_text: 'All' },
            ...props.companyInfo.modules.map((m) => ({
              id: m.id,
              display_text: m.name,
            })),
          ]}
          onSelect={(id) => {
            setFilterModule(id);
          }}
          text={((props) => {
            if (filterModule === 'All') {
              return 'All';
            } else {
              let filtered = props.companyInfo.modules.filter((m) => {
                return Number(m.id) === Number(filterModule);
              });
              return filtered[0].name;
            }
          })(props)}
        />

        <div style={{ marginTop: 15 }}>
          <Table style={{ border: '1px solid rgba(0,0,0,0.1)' }}>
            <TableHead>
              <CustomTableRow>
                <CustomTableCell align="center">
                  <TableSortLabel
                    hideSortIcon={true}
                    active={orderBy === 'name'}
                    direction={order}
                    onClick={() => handleSortClick('name')}>
                    Name
                  </TableSortLabel>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <TableSortLabel hideSortIcon={true}>
                    Course Type
                  </TableSortLabel>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <TableSortLabel
                    hideSortIcon={true}
                    active={orderBy === 'no_of_microskills'}
                    direction={order}
                    onClick={() => handleSortClick('no_of_microskills')}>
                    Microskills
                  </TableSortLabel>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <TableSortLabel hideSortIcon={true}>
                    Training Events
                  </TableSortLabel>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <StyledTabelHeaderLabel hideSortIcon={true}>
                    Control Panel
                  </StyledTabelHeaderLabel>
                </CustomTableCell>
              </CustomTableRow>
            </TableHead>
            <TableBody>
              {get_filtered_records().map((row) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell align="center">
                    <span>{row.name}</span>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <span>
                      {course_types[row.course_type]}
                    </span>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <p
                      style={{
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}>
                      {(() => {
                        let microskills = 0;
                        let events = props.courseEvents.filter(
                          (c) => c.course_id === row.id,
                        );
                        for (let event of events) {
                          if (event.modules === null) continue;
                          microskills += event.modules.length;
                        }
                        return microskills;
                      })()}
                    </p>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <span>
                      {(() => {
                        let events = props.courseEvents.filter(
                          (c) => c.course_id === row.id,
                        );
                        return events.length;
                      })()}
                    </span>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}>
                      <CurvedButtonBase
                        onClick={() => {
                          props.history.push({
                            pathname:
                              '/courses-and-training/courses/edit/' + row.id,
                            state: {
                              course_id: row.id,
                            },
                          });
                        }}>
                        <EditIcon fill="#707070" />
                      </CurvedButtonBase>
                      <CurvedButtonBase
                        onClick={() => {
                          setActiveCourse(row.id);
                          setDeletePopup(true);
                        }}>
                        <StyledTrashIcon />
                      </CurvedButtonBase>
                    </div>
                  </CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Wrapper>
    </div>
  );
}

const Digit = styled.h4`
  font-family: 'Montserrat';
  font-size: 52px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  color: ${(props) => props.color};
`;

const Wrapper = styled.div`
  padding: 30px;
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const DigitCaption = styled.h5`
  font-family: 'Montserrat';
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  color: #484848;
`;

const CurvedButtonBase = styled(ButtonBase)`
  border-radius: 20px;
  margin: 3px;
  padding: 5px;
`;

const CustomTableCell = withStyles((theme) => ({
  root: {
    padding: '8px 10px !important',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '12px',
    color: '#707070',
  },
  head: {
    backgroundColor: '#fbfbfb',
    color: '#484848',
    fontWeight: 'bold',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '11px',
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
  },
  head: {
    display: 'flex',
  },
}))(TableRow);

const StyledTrashIcon = styled(TrashIcon)`
  .class-trash-icon-2 {
    fill: #707070;
  }
`;

const Label = styled.h5`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 10px;
  margin: 0;
  margin-bottom: 5px;
  color: #484848;
  text-align: left;
`;

const StyledTabelHeaderLabel = styled(TableSortLabel)`
  display: block;
  margin: auto;
`;
export default CourseLog;
