import React, { useState, useRef } from 'react';
import styled from 'styled-components';

export const Box = styled.div`
  width: ${(props) => (props.width ? props.width : '420px')};
  max-width: 100%;
  min-height: 90.3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: rows;
`;

export const StyledTextArea = styled.textarea`
  border: ${(props) => (props.border ? props.border : 'solid 0.5px #dde0e2')};
  width: 100%;
  background-color: transparent;
  display: block;
  height: 90.3px;
  margin: 0;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  flex: 1;
  box-sizing: border-box;
  resize: none;

  &:focus {
    outline: 0 !important;
  }
`;

export const PCharLimit = styled.p`
  font-size:8px;
  width:62px;
  color:#F4511E;
  margin-left:-15%;
  align-self:end
`;

export default function TextArea(props) {
  const [numOfCharacter, setNumOfCharacter] = useState(500);
  let inputRef = useRef(null);

  let text = '';

  if (props.hasOwnProperty('text')) {
    text = props.text;
  }

  let resize = 'auto';

  if (props.hasOwnProperty('resize')) {
    resize = props.resize;
  }

  function handleInput() {
    if (inputRef.hasOwnProperty('current') && inputRef.current !== null) {
      if (props.hasOwnProperty('onChangeText')) {
        props.onChangeText(inputRef.current.value);
      }
      setNumOfCharacter(500 - inputRef.current.value.length);
    }
  }
  return (
    <Box width={props.width} border={props.border}>
      <StyledTextArea
        maxLength={500}
        resize={props.resize}
        ref={inputRef}
        onChange={handleInput}>
        {text}
      </StyledTextArea>
      <PCharLimit>{numOfCharacter} Characters</PCharLimit>
    </Box>
  );
}
