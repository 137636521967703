import React from "react";

function Analytics(props) {
  let fill = "#FFF";

  if(props.hasOwnProperty("fill")) {
    fill = props.fill;
  }
  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <defs>
        <style>
          {`.cls-1{fill:#fff;opacity:0}.cls-2{fill:#f4511e}`}
        </style>
      </defs>
      <g id="mastero_icon_analytics" transform="translate(-10 -267)">
        <path id="Rectangle_1547" d="M0 0h15v15H0z" className="cls-1" data-name="Rectangle 1547"
              transform="translate(10 267)"/>
        <g id="Group_2696" data-name="Group 2696" transform="translate(12.19 269.181)">
          <path id="Path_96"
                d="M7.914 0H6.638A.6.6 0 0 0 6 .638v8.93a.6.6 0 0 0 .638.638h1.276a.6.6 0 0 0 .638-.638V.638A.6.6 0 0 0 7.914 0z"
                className="cls-2" data-name="Path 96" transform="translate(-2.173)"/>
          <path id="Path_97"
                d="M1.914 10H.638a.6.6 0 0 0-.638.638v2.551a.6.6 0 0 0 .638.638h1.276a.6.6 0 0 0 .638-.638v-2.551A.6.6 0 0 0 1.914 10z"
                className="cls-2" data-name="Path 97" transform="translate(0 -3.621)"/>
          <path id="Path_98"
                d="M13.914 5h-1.276a.6.6 0 0 0-.638.638v5.741a.6.6 0 0 0 .638.638h1.276a.6.6 0 0 0 .638-.638V5.638A.6.6 0 0 0 13.914 5z"
                className="cls-2" data-name="Path 98" transform="translate(-4.346 -1.811)"/>
        </g>
      </g>
    </svg>
  )
}

export default Analytics;