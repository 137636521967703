import React from 'react';
import styled from 'styled-components';
import {makeStyles} from "@material-ui/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DayUtils from '@date-io/dayjs';
import CalendarIcon from "./MDLIcon/icons/Calendar";

const custom_styles = makeStyles({
  text: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    paddingLeft: 5,
    fontSize: "0.7rem",
    color: "#707070",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "170px",
    display: "block"
  },
  container: {
    display: 'flex',
    flexDirection: "column"
  },
  textField: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  label: {
    color: "#707070",
    fontSize: "0.75rem",
    fontFamily: "Montserrat",
  },
  selector_wrapper:{
    border: "0.5px solid #dde0e2",
    width: "200px",
    maxWidth: "100%",
    height: "28px"
  },
  selector_button_base: {
    width: "100% !important",
    height: "100% !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important"
  },
  selector_first_flex: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px"
  },
  selector_second_flex: {
    display: "flex",
    alignItems: "center"
  },
  expand_more: {
    fontSize: "0.9rem !important",
    fill: "#f4511e !important",
    paddingRight: "8px !important"
  }
});

const StyledCalendarIcon = styled(CalendarIcon)`
  .cls-1{
    fill:#fff;opacity:0
  }
  
  .cls-2{
    fill:#707070;
    fill-rule:evenodd
  }
  
`;

export default function MODateTimePicker(props) {
  let styles = custom_styles(props);

  let min_date = new Date();


  if(props.hasOwnProperty('minDate')) {
    min_date = props.minDate;
  }

  return (
    <div className={styles.container} >
      <span className={styles.label}>{props.label}</span>
      <div className={styles.selector_wrapper}>
        <ButtonBase  className={styles.selector_button_base} onClick={props.onClick}>
          <div className={styles.selector_first_flex}>
            <StyledCalendarIcon />
            <span className={styles.text}>{props.text}</span>
          </div>
        </ButtonBase>
      </div>
      <div style={{display: "none"}}>
        <MuiPickersUtilsProvider utils={DayUtils}>

        <DateTimePicker
          open={props.open}
          label="Basic example"
          value={props.value}
          minDate={min_date}
          onChange={props.handleDateChange}
          animateYearScrolling
          onClose={props.onClose}
        />
        </MuiPickersUtilsProvider>
      </div>

    </div>
  )

}
