import React from 'react';
import {makeStyles} from "@material-ui/styles";

let customStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingRight: '24px',
    cursor: 'pointer'
  },
  content: {
    display: 'flex',
    marginTop: '7px',
    marginBottom: '7px',
    flex: 1,
    alignItems: "center",
    userSelect: "none"
  },
  icon: {
    minWidth: '30px',
    textAlign: 'left'
  },
  text: {
    color: 'white',
    fontSize: '0.7rem',
    fontWeight: 600,
    fontFamily: "Montserrat"
  }
});

export default function MenuItem(props) {
  let styles = customStyles();

  return (
    <div className={styles.root} onClick={props.onClick}>
      <div className={styles.content}>
        <div className={styles.icon}>
          {props.icon}
        </div>
        <div className={styles.text}>
          {props.text}
        </div>
      </div>
    </div>
  )
}
