import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import styles from './LoginsTracker.module.css';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button2 from '../../components/Button2';
import MOSelect2 from '../../components/MOSelect2';
import ClearButton from '../../components/ClearButton';
import FormControl from '@material-ui/core/FormControl';
import Loader from '../../components/Loader';
import MOTable from '../../components/MOTable';
import KababMenu from '../../components/KababMenu';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import MOClient from '../../MOClient';
import { saveAs } from 'file-saver';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MOSelect from '../../components/MOSelect';
import Label from '../../components/Label';
import MODatePicker from '../../components/MODatePicker';
import { TableContainer, TablePagination } from '@material-ui/core';

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;
var custom_styles = {
  table_header_icon_style: {
    fill: 'black',
  },
  form_control: {
    minWidth: 150,
    marginTop: 0,
    marginLeft: '10px',
    maxWidth: '170px',
  },
  input_label: {
    fontSize: '0.7rem',
  },
  filled_input: {
    backgroundColor: 'white',
    fontSize: '0.8rem',
  },
  filled_input_menu: {
    fontSize: '0.8rem',
  },
  sub_section_header: {
    fontWeight: 'bold',
  },
  sub_section_action: {
    fontWeight: 'bold',
  },

  icon: {
    color: 'white',
  },
  table_wrapper: {
    overflowX: 'auto',
  },
};

function objectify(collection_array, key) {
  let obj = {};
  for (let i = 0; i < collection_array.length; i++) {
    let k = collection_array[i][key];
    obj[k] = collection_array[i];
  }

  return obj;
}

function LoginTable(props) {
  if (props.loading === true) {
    return <Loader fill="#4f0060" />;
  }

  let { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = props;
  let renderKeys = [
    {
      name: 'demographic',
    },
    {
      name: 'logged_learnes',
    },
    {
      name: 'total_learners',
    },
    {
      name: 'percentage',
      suffix: '%',
    },
  ];
  let headerItems = props.headerItems;

  let rows = [];

  for (let i = 0; i < Object.keys(props.total).length; i++) {
    let k = Object.keys(props.total)[i];

    if (!props.total.hasOwnProperty(k)) continue;

    let total_learners = Number(props.total[k]);
    let logged_learnes = Number(props.items[k]);

    if (typeof logged_learnes == 'undefined') {
      logged_learnes = 0;
    }

    if (isNaN(logged_learnes)) {
      logged_learnes = 0;
    }

    let login_rate = logged_learnes + ' of ' + total_learners;
    let login_percent = Math.round(
      Number((logged_learnes / total_learners) * 100),
    );

    let o = {
      id: i,
      demographic: k,
      value: login_rate.toString(),
      logged_learnes: logged_learnes,
      total_learners: total_learners,
      percentage: login_percent,
    };

    rows.push(o);
  }

  if (Object.keys(props.total).length === 0) {
    return (
      <Typography
        variant="caption"
        className={styles.no_data_available_caption}>
        No data is available at the moment
      </Typography>
    );
  } else {
    return (
      <TableContainer style={{ paddingBottom: '40px' }}>
        <MOTable
          renderKeys={renderKeys}
          order="desc"
          rows={rows.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
          )}
          headerItems={headerItems}
          orderByColumn={3}
        />
        <TablePagination
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableContainer>
    );
  }
}

function LoginsTracker(props) {
  const {
    classes,
    jwtToken,
    activeProject,
    projects,
    showLevelFilters,
  } = props;

  const permissions = [];

  if (props.userInfo.permissions.hasOwnProperty('features')) {
    for (const permission of props.userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let [filter_demographic, set_demographic] = useState('');
  let [loading, set_loading] = useState(false);
  let [total, set_total] = useState({});
  let [core_values, set_core_values] = useState({});
  let [download_xls_dialog_state, set_download_xls_dialog_state] = useState(
    false,
  );
  let [forward_xls_dialog_state, set_forward_xls_dialog_state] = useState(
    false,
  );
  let [forward_email_to, set_forward_email_to] = useState(null);
  let [forward_xls_toast_message, set_forward_xls_toast_message] = useState('');
  let [forward_xls_toast, set_forward_xls_toast] = useState(false);
  let [forward_email_to_error, set_forward_email_to_error] = useState(false);
  let [
    forward_email_to_helper_text,
    set_forward_email_to_helper_text,
  ] = useState('');
  let [forward_xls_progress, set_forward_xls_progress] = useState(false);
  let [forward_xls_button_disabled, set_forward_xls_button_disabled] = useState(
    false,
  );
  let [forward_xls_button_text, set_forward_xls_button_text] = useState('Send');
  let [selected_projects, set_selected_projects] = useState([activeProject]);
  let [allProjectsSelected, setAllProjectsSelected] = useState(false);

  let [startDate, setStartDate] = React.useState(null);
  let [endDate, setEndDate] = React.useState(null);
  let [startDateDialogOpen, setStartDateDialogOpen] = React.useState(false);
  const [endDateDialogOpen, setEndDateDialogOpen] = React.useState(false);

  let [levelOneOptionsMap, setLevelOneOptionsMap] = useState({});
  let [levelTwoOptionsMap, setLevelTwoOptionsMap] = useState({});
  let [levelThreeOptionsMap, setLevelThreeOptionsMap] = useState({});
  let [levelFourOptionsMap, setLevelFourOptionsMap] = useState({});
  let [levelFiveOptionsMap, setLevelFiveOptionsMap] = useState({});

  let [selectedLevelOneOption, setSelectedLevelOneOption] = useState('All');
  let [selectedLevelTwoOption, setSelectedLevelTwoOption] = useState('');
  let [selectedLevelThreeOption, setSelectedLevelThreeOption] = useState('');
  let [selectedLevelFourOption, setSelectedLevelFourOption] = useState('');
  let [selectedLevelFiveOption, setSelectedLevelFiveOption] = useState('');

  let [levelOneOptions, setLevelOneOptions] = useState([]);
  let [levelTwoOptions, setLevelTwoOptions] = useState([]);
  let [levelThreeOptions, setLevelThreeOptions] = useState([]);
  let [levelFourOptions, setLevelFourOptions] = useState([]);
  let [levelFiveOptions, setLevelFiveOptions] = useState([]);

  let [optionsLabels, setOptionsLabels] = useState([]);
  let [mainOptionsLabels, setMainOptionsLabels] = useState([{}]);

  let [mainOptionsOne, setMainOptionsOne] = useState([]);
  let [mainOptionsTwo, setMainOptionsTwo] = useState([]);
  let [selectedMainOptionOne, setSelectedMainOptionOne] = useState('All');
  let [selectedMainOptionTwo, setSelectedMainOptionTwo] = useState('All');

  let [headColumn, setHeadColumn] = useState('');

  if (!props.hasOwnProperty('demographics')) {
    props.demographics = [];
  }

  let projects_obj = objectify(projects, 'id');

  useEffect(() => {
    async function fetchData() {
      let data = await MOClient.get_demographic_filter_options({
        token: jwtToken,
        project_id: activeProject,
      });
      if (data.status === 'success') {
        const { demographicOptions, accessLevels, demographics, filtersOptions } = data;
        const setMapOptions = [setLevelTwoOptionsMap, setLevelThreeOptionsMap, setLevelFourOptionsMap, setLevelFiveOptionsMap];
        const setSelectedOptions = [setSelectedLevelTwoOption, setSelectedLevelThreeOption, setSelectedLevelFourOption, setSelectedLevelFiveOption];
        const options = demographicOptions[0];
        const levelOneOption = [];
        for (let option in options)
          levelOneOption.push(...Object.keys(options[option]));
        setLevelOneOptionsMap({});
        if (levelOneOption.length == 1) setSelectedLevelOneOption(levelOneOption[0]);
        else setSelectedLevelOneOption('All');
        let newOptions = ['All', ...levelOneOption].map((option) => { return { display_text: option, id: option }; });
        setLevelOneOptions(newOptions);
        for (let demographicOption in demographicOptions) {
          const key = Object.keys(demographicOptions[demographicOption])[0];
          const setMapOption = setMapOptions[demographicOption];
          const mapOptions = demographicOptions[demographicOption][key];
          setMapOption(mapOptions);
          const setSelectedOption = setSelectedOptions[demographicOption];
          setSelectedOption('All');
        }
        let keys = [];
        for (let access of accessLevels) {
          keys.push(demographics[access]);
        }
        setOptionsLabels(keys);
        const setMapMainOptions = [setMainOptionsOne, setMainOptionsTwo];
        const setSelectedMainOptions = [setSelectedMainOptionOne, setSelectedMainOptionTwo];
        const mainOptionLabels = [];
        // Set Label for mainOptionsOne
        for (let index in filtersOptions) {
          const { key, values, mapping } = filtersOptions[index];
          const setMapMainOption = setMapMainOptions[index];
          const mapMainOptions = ['All', ...values].map((option) => { return { display_text: option, id: option }; });;
          setMapMainOption(mapMainOptions);
          const setSelectedMainOption = setSelectedMainOptions[index];
          setSelectedMainOption('All');
          mainOptionLabels.push({ key, mapping });
        }
        setMainOptionsLabels(mainOptionLabels);
      }
    }
    if (showLevelFilters) fetchData();
    return () => {};
  }, [activeProject]);

  useEffect(() => {
    const keys = [];
    const values = [];
    for (let queryOption of queryOptions) {
      keys.push(Object.keys(queryOption)[0]);
      values.push(Object.values(queryOption)[0]);
    }
    const index = values.indexOf('All');
    let head_column = filter_demographic;

    if (showLevelFilters) head_column = keys[index];

    setHeadColumn(head_column);
  }, [
    selectedLevelOneOption,
    selectedLevelTwoOption,
    selectedLevelThreeOption,
    selectedLevelFourOption,
    selectedLevelFiveOption,
  ]);

  useEffect(() => {
    if (selectedLevelOneOption == 'All') {
      setSelectedLevelTwoOption('All');
      setSelectedLevelThreeOption('All');
      setSelectedLevelFourOption('All');
      setSelectedLevelFiveOption('All');
      setLevelTwoOptions([]);
      setLevelThreeOptions([]);
      setLevelFourOptions([]);
      setLevelFiveOptions([]);
    }
  }, [selectedLevelOneOption]);

  useEffect(() => {
    let updatedOptions = levelTwoOptionsMap[selectedLevelOneOption];
    if (updatedOptions !== undefined) {
      // console.log('levelTwoOptionsMap[selectedLevelOneOption]', selectedLevelOneOption, updatedOptions);
      setLevelTwoOptions(
        ['All', ...updatedOptions].map((option) => {
          return { id: option, display_text: option };
        }),
      );
      setSelectedLevelTwoOption('All');
      setSelectedLevelThreeOption('All');
      setSelectedLevelFourOption('All');
      setSelectedLevelFiveOption('All');
      setLevelThreeOptions([]);
      setLevelFourOptions([]);
      setLevelFiveOptions([]);
    }
  }, [selectedLevelOneOption]);

  useEffect(() => {
    let updatedOptions = levelThreeOptionsMap[selectedLevelTwoOption];
    if (updatedOptions !== undefined) {
      // console.log('levelThreeOptionsMap[selectedLevelTwoOption]', selectedLevelTwoOption, updatedOptions);
      if (selectedLevelTwoOption === 'All') {
        setSelectedLevelThreeOption('All');
        setSelectedLevelFourOption('All');
        setSelectedLevelFiveOption('All');
        setLevelThreeOptions([]);
        setLevelFourOptions([]);
        setLevelFiveOptions([]);
      } else {
        setSelectedLevelThreeOption('All');
        setLevelThreeOptions(
          ['All', ...updatedOptions].map((option) => {
            return { id: option, display_text: option };
          }),
        );
      }
    }
  }, [selectedLevelTwoOption]);

  useEffect(() => {
    let updatedOptions = levelFourOptionsMap[selectedLevelThreeOption];
    if (updatedOptions !== undefined) {
      // console.log('levelFourOptionsMap[selectedLevelThreeOption]', selectedLevelThreeOption, updatedOptions);
      if (selectedLevelThreeOption === 'All') {
        setSelectedLevelFourOption('All');
        setSelectedLevelFiveOption('All');
        setLevelFiveOptions([]);
        setLevelFourOptions([]);
      } else {
        setSelectedLevelFourOption('All');
        setLevelFourOptions(
          ['All', ...updatedOptions].map((option) => {
            return { id: option, display_text: option };
          }),
        );
      }
    }
  }, [selectedLevelThreeOption]);

  useEffect(() => {
    let updatedOptions = levelFiveOptionsMap[selectedLevelFourOption];
    if (updatedOptions !== undefined) {
      // console.log('levelFiveOptionsMap[selectedLevelFourOption]', selectedLevelFourOption, updatedOptions);
      setLevelFiveOptions(
        ['All', ...updatedOptions].map((option) => {
          return { id: option, display_text: option };
        }),
      );
      setSelectedLevelFiveOption('All');
    }
  }, [selectedLevelFourOption]);

  const optionSelected = [
    selectedLevelOneOption,
    selectedLevelTwoOption,
    selectedLevelThreeOption,
    selectedLevelFourOption,
    selectedLevelFiveOption,
  ];
  const queryOptions = [];

  for (let optionsLabel in optionsLabels) {
    const queryOption = {};
    queryOption[optionsLabels[optionsLabel]] = optionSelected[optionsLabel];
    queryOptions.push(queryOption);
  }

  const selectedMainOptions = [selectedMainOptionOne, selectedMainOptionTwo];
  for (let index in mainOptionsLabels) {
    let { mapping } = mainOptionsLabels[index];
    let selectedMainOption = selectedMainOptions[index];
    queryOptions.push({ [mapping]: selectedMainOption });
  }
  //handlers

  useEffect(() => {
    set_selected_projects([activeProject]);
  }, [activeProject]);

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  async function forward_in_email() {
    let opt = {
      token: jwtToken,
      demographic: filter_demographic,
      project_id: activeProject,
      project_ids: selected_projects,
      demographic_value: 'All',
      to_email_address: forward_email_to
    };

    if (showLevelFilters) {
      opt.queryOptions = queryOptions;
    }

    if (startDate !== null) {
      opt.data_filters.start_date = startDate;
    }

    if (endDate !== null) {
      opt.data_filters.end_date = endDate;
    }

    if (forward_email_to === '' || forward_email_to === null) {
      // special error handling
      set_forward_email_to_error(true);
      set_forward_email_to_helper_text('Email is requried');
    } else {
      set_forward_xls_progress(true);
      let action = await MOClient.forward_email_login_data_excel(opt);

      if (action === true) {
        set_forward_xls_dialog_state(false);
        set_forward_xls_toast_message('Email sent successfully!');
        set_forward_xls_toast(true);
      } else {
        set_forward_xls_toast_message(
          'Email could not be sent, please try again?',
        );
        set_forward_xls_toast(true);
      }

      set_forward_xls_progress(false);
    }
  }

  async function download_as_excel() {
    set_download_xls_dialog_state(true);

    let opt = {
      token: jwtToken,
      demographic: filter_demographic,
      project_id: activeProject,
      project_ids: selected_projects,
      demographic_value: 'All',
      data_filters: {}
    };

    if (showLevelFilters) {
      opt.queryOptions = queryOptions;
    }

    if (startDate !== null) {
      opt.data_filters.start_date = startDate;
    }

    if (endDate !== null) {
      opt.data_filters.end_date = endDate;
    }

    let result = await MOClient.get_login_data_excel(opt);

    saveAs(result, 'Login-data.xlsx', { autoBOM: true });

    set_download_xls_dialog_state(false);
  }

  useEffect(() => {
    if (forward_email_to === '') {
      set_forward_email_to_error(true);
      set_forward_email_to_helper_text('Email is requried');
    } else {
      set_forward_email_to_error(false);
      set_forward_email_to_helper_text('');
    }
  }, [forward_email_to]);

  let gettingLoginData = false;

  const getLoginData = async () => {
    if (gettingLoginData === false) {
      gettingLoginData = true;
      let isMounted = true;
      if (filter_demographic !== '') {
        let opt = {
          token: jwtToken,
          demographic: filter_demographic,
          demographic_value: 'All',
          project_id: activeProject,
          project_ids: selected_projects,
          data_filters: {},
        };

        if (showLevelFilters) {
          opt.queryOptions = queryOptions;
        }

        if (startDate !== null) {
          opt.data_filters.start_date = startDate;
        }

        if (endDate !== null) {
          opt.data_filters.end_date = endDate;
        }

        let demographic_key = filter_demographic.toLowerCase();

        if (
          props.userInfo.permissions.demographics.hasOwnProperty(
            demographic_key,
          )
        ) {
          opt.demographic_value =
            props.userInfo.permissions.demographics[demographic_key];
        }

        set_loading(true);
        let result = await MOClient.get_login_data(opt);
        if (isMounted === true) {
          if (
            result != null &&
            result.hasOwnProperty('total') &&
            result['total'] != null
          ) {
            set_total(result.total);
            set_core_values(result.logged);
          } else {
            set_total({});
            set_core_values({});
          }
          set_loading(false);
        }
      }
      gettingLoginData = false;
    }
  };

  useEffect(() => {
    if (forward_xls_progress === true) {
      set_forward_xls_button_disabled(true);
      set_forward_xls_button_text('Sending');
    } else {
      set_forward_xls_button_disabled(false);
      set_forward_xls_button_text('Send');
    }
  }, [forward_xls_progress]);

  useEffect(() => {
    if (props.demographics.length > 0) {
      set_demographic(props.demographics[0].name);
    }
  }, [props.demographics]);

  useEffect(() => {
    if (!showLevelFilters) setHeadColumn(filter_demographic);
  }, [filter_demographic]);

  const selectAllProjects = () => {
    let selected_id = projects.map((mod) => mod.id);
    set_selected_projects(selected_id);
    setAllProjectsSelected(true);
  };

  const deselectAllProjects = () => {
    let selected_id = [];
    selected_id.push(activeProject);
    set_selected_projects(selected_id);
    setAllProjectsSelected(false);
  };

  let demographic_options = props.demographics.map((demographic) => {
    return { id: demographic.name, display_text: demographic.name };
  });

  let project_options = projects.map((project) => {
    return { display_text: project.name, id: project.id };
  });

  let header_items = [
    {
      id: 0,
      name: headColumn,
    },
    {
      id: 1,
      name: 'Logged in',
    },
    {
      id: 2,
      name: 'Total Learners',
    },
    {
      id: 3,
      name: 'Percentage Value',
    },
  ];

  return (
    <div>
      <div className={styles.sub_section_header}>
        <div className={styles.sub_section_header_title}>
          <div className={styles.sub_section_header_filters}>
            {showLevelFilters &&
              <>{optionsLabels.length > 0 &&
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  {optionsLabels[0] !== undefined && <FormControl style={{ marginRight: 20 }}>
                    <Label>{optionsLabels[0]}:</Label>
                    <MOSelect
                      disabled={levelOneOptions.length === 0}
                      options={levelOneOptions}
                      onSelect={(value) => setSelectedLevelOneOption(value)}
                      text={selectedLevelOneOption}
                      style={{ filter: levelOneOptions.length === 0 ? 'grayscale(1)' : 'grayscale(0)' }}
                    />
                  </FormControl>}
                  {optionsLabels[1] !== undefined && <FormControl style={{ marginRight: 20 }}>
                    <Label>{optionsLabels[1]}:</Label>
                    <MOSelect
                      disabled={levelTwoOptions.length === 0}
                      options={levelTwoOptions}
                      onSelect={(value) => setSelectedLevelTwoOption(value)}
                      text={selectedLevelTwoOption}
                      style={{ filter: levelTwoOptions.length === 0 ? 'grayscale(1)' : 'grayscale(0)' }}
                    />
                  </FormControl>}
                  {optionsLabels[2] !== undefined && <FormControl style={{ marginRight: 20 }}>
                    <Label>{optionsLabels[2]}:</Label>
                    <MOSelect
                      disabled={levelThreeOptions.length === 0}
                      options={levelThreeOptions}
                      onSelect={(value) => setSelectedLevelThreeOption(value)}
                      text={selectedLevelThreeOption}
                      style={{ filter: levelThreeOptions.length === 0 ? 'grayscale(1)' : 'grayscale(0)' }}
                    />
                  </FormControl>}
                  {optionsLabels[3] !== undefined && <FormControl style={{ marginRight: 20 }}>
                    <Label>{optionsLabels[3]}:</Label>
                    <MOSelect
                      disabled={levelFourOptions.length === 0}
                      options={levelFourOptions}
                      onSelect={(value) => setSelectedLevelFourOption(value)}
                      text={selectedLevelFourOption}
                      style={{ filter: levelFourOptions.length === 0 ? 'grayscale(1)' : 'grayscale(0)' }}
                    />
                  </FormControl>}
                  {optionsLabels[4] !== undefined && <FormControl style={{ marginRight: 20 }}>
                    <Label>{optionsLabels[4]}:</Label>
                    <MOSelect
                      disabled={levelFiveOptions.length === 0}
                      options={levelFiveOptions}
                      onSelect={(value) => setSelectedLevelFiveOption(value)}
                      text={selectedLevelFiveOption}
                      style={{ filter: levelFiveOptions.length === 0 ? 'grayscale(1)' : 'grayscale(0)' }}
                    />
                  </FormControl>}
                  {mainOptionsLabels[0] !== undefined && <FormControl style={{ marginRight: 20 }}>
                    <Label>{mainOptionsLabels[0].mapping}:</Label>
                    <MOSelect
                      options={mainOptionsOne}
                      onSelect={(value) => setSelectedMainOptionOne(value)}
                      text={selectedMainOptionOne}
                    />
                  </FormControl>}
                  {mainOptionsLabels[1] !== undefined && <FormControl style={{ marginRight: 20 }}>
                    <Label>{mainOptionsLabels[1].mapping}:</Label>
                    <MOSelect
                      options={mainOptionsTwo}
                      onSelect={(value) => setSelectedMainOptionTwo(value)}
                      text={selectedMainOptionTwo}
                    />
                  </FormControl>}
                </div>}
              </>}
            {showLevelFilters && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>Project By:</Label>
                  <MOSelect2
                    multiple={true}
                    selected={selected_projects}
                    text={selected_projects
                      .map((s) => projects_obj[s]['name'])
                      .join(', ')}
                    options={project_options}
                    onSelect={(values) => {
                      set_selected_projects(values);
                    }}
                  />
                  <ClearButton
                    onClick={
                      allProjectsSelected
                        ? deselectAllProjects
                        : selectAllProjects
                    }>
                    {allProjectsSelected ? `Undo Selection` : `Select All`}
                  </ClearButton>
                </FormControl>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.sub_section_header}>
        {!showLevelFilters && (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <FormControl style={{ marginRight: 20 }}>
              <Label>Demographic:</Label>
              <MOSelect
                options={demographic_options}
                onSelect={(value) => set_demographic(value)}
                text={filter_demographic}
              />
            </FormControl>
          </div>
        )}
        <div style={{ marginRight: 30 }}>
          <FormControl>
            <Label>Duration:</Label>
            <DurationWrapper>
              <MODatePicker
                open={startDateDialogOpen}
                text={startDate === null ? '' : startDate.format('DD-MM-YYYY')}
                value={startDate}
                onClick={() => setStartDateDialogOpen(!startDateDialogOpen)}
                onClose={() => setStartDateDialogOpen(false)}
                handleDateChange={(d) => setStartDate(d)}
                minDate={dayjs(new Date()).subtract(5, 'year')}
              />

              <CourseFromToLabel>TO</CourseFromToLabel>

              <MODatePicker
                open={endDateDialogOpen}
                text={endDate === null ? '' : endDate.format('DD-MM-YYYY')}
                value={endDate}
                onClick={() => setEndDateDialogOpen(!endDateDialogOpen)}
                onClose={() => setEndDateDialogOpen(false)}
                handleDateChange={(d) => setEndDate(d)}
                minDate={dayjs(new Date()).subtract(5, 'year')}
              />
            </DurationWrapper>
          </FormControl>
        </div>
        <div className={styles.sub_section_header_action_wrapper}>
          <KababMenu
            onClick={async (id) => {
              if (id === 1) {
                download_as_excel();
              } else if (id === 2) {
                set_forward_xls_dialog_state(true);
              }
            }}
            options={[
              { display_text: 'Download as xlsx', id: 1 },
              { display_text: 'Forward in e-mail', id: 2 },
            ]}
          />
        </div>
      </div>

      <div className={styles.sub_section_header}>
        <div className={styles.sub_section_header_title}>
          <div className={styles.sub_section_header_filters}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <FormControl style={{ marginRight: 20 }}>
                <Button2 transparent={false} onClick={() => getLoginData()}>
                  Submit
                </Button2>
              </FormControl>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <FormControl style={{ marginRight: 20 }}>
                <Button2 transparent={true} onClick={clearFilters}>
                  Clear Filters
                </Button2>
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sub_section_content}>
        <Paper elevation={2}>
          <div className={classes.table_wrapper}>
            <LoginTable
              loading={loading}
              total={total}
              items={core_values}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              headerItems={header_items}
            />
          </div>
        </Paper>
      </div>

      <Dialog
        open={download_xls_dialog_state}
        onClose={(e) => set_download_xls_dialog_state(false)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px', textAlign: 'center' }}>
              <Loader fill="#4f0060" />
              <p>Generating and Downloading...</p>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={forward_xls_dialog_state}
        onClose={(e) => set_forward_xls_dialog_state(false)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px' }}>
              <Typography variant="h5" style={{ marginTop: '25px' }}>
                Forward the data to your e-mail
              </Typography>
              <p>The e-mail contains a spreadsheet attachment.</p>
              <TextField
                id="standard-name"
                label="Email"
                error={forward_email_to_error}
                value={forward_email_to}
                onChange={(e) => set_forward_email_to(e.target.value)}
                margin="normal"
                type="email"
                helperText={forward_email_to_helper_text}
                required
                onKeyPress={(event) => {
                  if (event.key === 'Enter') forward_in_email();
                }}
              />

              <br />

              <Button
                style={{ marginTop: '25px' }}
                variant="contained"
                color="primary"
                disabled={forward_xls_button_disabled}
                onClick={forward_in_email}>
                {forward_xls_button_text}
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => set_forward_xls_toast(false)}
        message={<span id="message-id">{forward_xls_toast_message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => set_forward_xls_toast(false)}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}

export default withStyles(custom_styles)(LoginsTracker);
