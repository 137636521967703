import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Add, ExpandLessSharp } from '@material-ui/icons';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FormControl from '../../components/FormControl';
import StyledLabel from '../../components/Label';
import TextArea from '../../components/TextArea';
import TextInput from '../../components/TextInput';
import FormTargetting from './FormTargetting';
import FormDetails from './FormDetails';
import MOClient from '../../MOClient';
import styled from 'styled-components';
import Button2 from '../../components/Button2';

const SuccessTick = require('../../assets/success.svg');

const useStyles = makeStyles({
  timeContainer: {
    background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
  },
  timeText: {
    margin: 0,
    paddingRight: '50px',
    fontWeight: '500',
  },
  timeInput: {
    border: '2px solid #707070',
    background: 'white',
    borderRadius: '5px',
    margin: '0.2rem 0',
    width: '80px',
    '& input': {
      padding: '0.5rem',
    },
  },
});

function AddQuestion({ onClick }) {
  return (
    <FlatPrimaryButton onClick={onClick}>
      <Grid container alignItems="center" style={{ width: '250px' }}>
        <Grid item md={2}>
          <Add />
        </Grid>
        <Grid item md={10}>
          Add Question
        </Grid>
      </Grid>
    </FlatPrimaryButton>
  );
}

const undefinedQuestion = {
  type: null,
  question: '',
  choices: [],
};

const viewModes = [
  'FORM_DETAILS_MODE',
  'TARGETTING_MODE',
  'FORM_DETAILS_EDIT_MODE',
];

function CreateForm(props) {
  // console.log('CreateForm props: ', props);
  const startDate = props.form.start || null;
  const endDate = props.form.end || null;
  const classes = useStyles();
  const form_id = props.form.id || '';
  const formMode = props.form_mode || 'NEW_FORM';
  const [name, setName] = useState(props.form.name || '');
  const [description, setDescription] = useState(props.form.description || '');
  const [questions, setQuestions] = useState(props.questions || []);
  const [viewMode, setViewMode] = useState(viewModes[0]);
  const [target, setTarget] = useState(props.target || {});
  const [learners, setLearners] = useState([]);
  const [delivery, setDelivery] = useState({
    startTime: null,
    endTime: null,
    startDate: null,
    endDate: null,
  });
  const [usersList, setUsersList] = useState([]);
  const [radioValue, setRadioValue] = useState(props.target.type || 'project');
  const [isAssessment, setIsAssessment] = useState(
    !!props.form.is_assessment || false,
  );
  const [time, setTime] = useState(props.form.time || 60);
  const [metaInfo, setMetaInfo] = useState(
    props.form.meta_info || {
      isTimed: true,
      scoreVisibility: true,
    },
  );

  const [notificationContent, setNotificationContent] = useState(
    props.form.notification_content || '',
  );
  const [showSuccessDialog, setShowsuccessDialog] = useState(false);

  const handleNameChange = (text) => {
    if (text.length <= 80) {
      setName(text);
    }
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleDescriptionChange = (text) => {
    if (text.length <= 500) {
      setDescription(text);
    }
  };

  const clearAll = () => {
    setName('');
    setDescription('');
    setQuestions([]);
    setViewMode(viewModes[0]);
    setTarget({});
    setDelivery({
      startTime: null,
      endTime: null,
      startDate: null,
      endDate: null,
    });
    setNotificationContent('');
  };

  const handleTextChange = (setFunc) => {
    return (text) => {
      setFunc(text);
    };
  };

  const handleAddQuestion = () => {
    undefinedQuestion.type = isAssessment ? 'SINGLE_CHOICE_QUESTION' : null;
    const updatedQuestions = [...questions, undefinedQuestion];
    setQuestions(updatedQuestions);
  };

  const handleSelectQuestionType = (type, index) => {
    const updatedQuestion = {
      ...questions[index],
      type,
    };
    const updatedQuestions = [
      ...questions.slice(0, index),
      updatedQuestion,
      ...questions.slice(index + 1),
    ];
    setQuestions(updatedQuestions);
  };

  const handleQuestionUpdate = (question, index) => {
    const updatedQuestions = [
      ...questions.slice(0, index),
      question,
      ...questions.slice(index + 1),
    ];
    setQuestions(updatedQuestions);
  };

  const handleDeleteQuestion = (question, index) => {
    const updatedQuestions = [...questions.filter((q, ind) => index != ind)];
    setQuestions(updatedQuestions);
  };

  const handleDetailsSubmit = () => {
    changeViewMode();
  };

  const changeViewMode = () => {
    const newMode = viewMode === viewModes[0] ? viewModes[1] : viewModes[0];
    setViewMode(newMode);
  };

  const onChangeAssessment = () => {
    let newQuestions = questions.filter(
      (question) =>
        question.type === 'SINGLE_CHOICE_QUESTION' ||
        question.type === 'MULTI_CHOICE_QUESTION',
    );
    setQuestions([...newQuestions]);
    setIsAssessment(!isAssessment);
    if (isAssessment) {
      setMetaInfo({ isTimed: false, scoreVisibility: false });
      setTime(0);
    } else {
      setMetaInfo({ isTimed: true, scoreVisibility: true });
      setTime(60);
    }
    setOpenPopup(false);
  };
  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    const companyId = localStorage.getItem('company-id');
    let updatedTarget = {
      ...target,
    };
    console.log(target);

    setMetaInfo({ isTimed: false, scoreVisibility: false });
    setTime(0);

    const allProjectsSelected =
      (updatedTarget.proj_id && updatedTarget.proj_id === '*') ||
      updatedTarget.proj_id == null;
    console.log(allProjectsSelected);
    const companyInfo = JSON.parse(localStorage.getItem('company-info'));
    const projects = companyInfo.projects;

    if (allProjectsSelected) {
      const projectIds = projects.map((project) => project.id);
      updatedTarget = {
        ...target,
        proj_id: projectIds,
      };
    } else {
      updatedTarget = {
        ...target,
        proj_id: [
          projects.find((project) => project.name === target.proj_id).id,
        ],
      };
    }
    console.log(updatedTarget);

    MOClient.getTargetLearnersForForm(token, {
      target: updatedTarget,
      companyId,
    }).then(({ data }) => {
      setLearners(data.map((learner) => learner.email));
    });
  }, [target]);

  const handleFormLaunch = () => {
    let start = new Date(`${delivery.startDate}`).setHours(
      new Date(delivery.startTime).getHours(),
      new Date(delivery.startTime).getMinutes(),
    );
    let end = new Date(`${delivery.endDate}`).setHours(
      new Date(delivery.endTime).getHours(),
      new Date(delivery.endTime).getMinutes(),
    );

    let updatedTarget = {
      ...target,
    };

    const allProjectsSelected =
      (updatedTarget.proj_id && updatedTarget.proj_id === '*') ||
      updatedTarget.proj_id == null;
    const companyInfo = JSON.parse(localStorage.getItem('company-info'));
    const projects = companyInfo.projects;

    if (radioValue === 'project') {
      if (allProjectsSelected) {
        const projectIds = projects.map((project) => project.id);
        updatedTarget = {
          ...target,
          proj_id: projectIds,
        };
      } else {
        updatedTarget = {
          ...target,
          proj_id: [
            projects.find((project) => project.name === target.proj_id).id,
          ],
        };
      }
    } else {
      updatedTarget = { usersList: usersList };
    }

    let data = {
      companyId: localStorage.getItem('company-id'),
      form: {
        name,
        description,
        mandatory: false,
        target: {
          ...updatedTarget,
          type: radioValue,
        },
        delivery: {
          start: new Date(start),
          end: new Date(end),
          repeat: false,
        },
        questions,
        notificationContent,
        isAssessment,
        time,
        metaInfo,
      },
    };
    // console.log(data);
    const token = localStorage.getItem('auth-token');
    MOClient.createForm(token, data).then((data) => {
      clearAll();
      setShowsuccessDialog(true);
    });
  };

  const handleFormEdit = () => {
    let start = new Date(`${delivery.startDate}`).setHours(
      new Date(delivery.startTime).getHours(),
      new Date(delivery.startTime).getMinutes(),
    );
    let end = new Date(`${delivery.endDate}`).setHours(
      new Date(delivery.endTime).getHours(),
      new Date(delivery.endTime).getMinutes(),
    );

    let data = {
      companyId: localStorage.getItem('company-id'),
      form: {
        id: form_id,
        name,
        description,
        mandatory: false,
        delivery: {
          start: new Date(start),
          end: new Date(end),
          repeat: false,
        },
        questions,
        notificationContent,
        isAssessment,
        time,
        metaInfo,
      },
    };

    const token = localStorage.getItem('auth-token');
    MOClient.updateForm(token, data).then((data) => {
      clearAll();
      setShowsuccessDialog(true);
    });
  };

  let [openPopup, setOpenPopup] = useState(false);
  const openPopupFunc = () => {
    if (isAssessment) {
      onChangeAssessment();
    } else if (questions.length == 0) {
      onChangeAssessment();
    } else {
      setOpenPopup(true);
    }
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <FormControl>
        <StyledLabel>Form Name</StyledLabel>
        <Grid container spacing={5} alignItems="flex-start">
          <Grid item>
            <TextInput
              width={'420px'}
              value={name}
              onChangeText={handleTextChange(handleNameChange)}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl>
        <StyledLabel>Form Description</StyledLabel>
        <TextArea
          value={description}
          text={description}
          onChangeText={handleTextChange(handleDescriptionChange)}
        />
        <Grid item>
          <FormControlLabel
            style={{ paddingTop: '20px' }}
            label="Assessment"
            control={
              <Checkbox
                disabled={formMode !== 'NEW_FORM'}
                checked={isAssessment}
                onChange={openPopupFunc}
              />
            }
          />
        </Grid>
        {isAssessment && (
          <Grid item>
            <div className={classes.timeContainer}>
              <FormControlLabel
                style={{ margin: '0.0rem' }}
                control={
                  <Checkbox
                    checked={metaInfo.isTimed}
                    onChange={() =>
                      setMetaInfo({ ...metaInfo, isTimed: !metaInfo.isTimed })
                    }
                  />
                }
              />
              <span
                className={classes.timeText}
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '1 rem',
                }}>
                Time Limit (Seconds)
              </span>
              <TextField
                disabled={!metaInfo.isTimed}
                onChange={(e) => setTime(e.target.value)}
                className={classes.timeInput}
                value={time}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <FormControlLabel
              style={{ marginLeft: '0.0rem' }}
              label="Show score to the learner"
              control={
                <Checkbox
                  checked={metaInfo.scoreVisibility}
                  onChange={() =>
                    setMetaInfo({
                      ...metaInfo,
                      scoreVisibility: !metaInfo.scoreVisibility,
                    })
                  }
                />
              }
            />
          </Grid>
        )}
      </FormControl>
      {viewMode === viewModes[0] || viewMode === viewModes[2] ? (
        <FormDetails
          questions={questions}
          formMode={formMode}
          onSelectQuestionType={handleSelectQuestionType}
          onQuestionUpdate={handleQuestionUpdate}
          onAddQuestion={handleAddQuestion}
          onSubmit={handleDetailsSubmit}
          onDeleteQuestion={handleDeleteQuestion}
          isAssessment={isAssessment}
          name={name}
        />
      ) : (
        <FormTargetting
          target={target}
          formMode={formMode}
          setTarget={setTarget}
          startDate={startDate}
          endDate={endDate}
          delivery={delivery}
          setDelivery={setDelivery}
          notificationContent={notificationContent}
          setNotificationContent={setNotificationContent}
          onSchedule={handleFormLaunch}
          updateForm={handleFormEdit}
          onBack={changeViewMode}
          learners={learners}
          setUsersList={setUsersList}
          onHandleRadioChange={handleRadioChange}
          radioValue={radioValue}
        />
      )}
      <Dialog open={openPopup} onClose={() => setIsAssessment(false)}>
        <ProgressContainer>
          <ProgressText>
            Choosing this option will remove all
            <br />
            questions except choice based
          </ProgressText>
          <ProgressText>
            <FormControl>
              <Actions>
                <Button2 onClick={onChangeAssessment}>Proceed</Button2>
                <Button2
                  onClick={() => setOpenPopup(false)}
                  transparent={true}
                  style={{ marginLeft: '5px' }}>
                  Cancel
                </Button2>
              </Actions>
            </FormControl>
          </ProgressText>
        </ProgressContainer>
      </Dialog>
      <Dialog
        open={showSuccessDialog}
        onClose={() => setShowsuccessDialog(false)}
        fullWidth
        maxWidth="sm">
        <DialogTitle>
          <div style={{ textAlign: 'center', paddingTop: '20px' }}>Success</div>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
            <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
              <img src={SuccessTick} style={{ width: '200px' }} />
            </div>
            <div>Form was created successfully. Please check after 5 mins.</div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 15px;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

export default CreateForm;
