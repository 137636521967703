import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Link } from 'react-router-dom';
import MODatePicker from '../../components/MODatePicker';
import styled from 'styled-components';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import dayjs from 'dayjs';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import ClearButton from '../../components/ClearButton';
import PlusIcon from '../../components/MDLIcon/icons/Plus';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FlatClearButton from '../../components/FlatClearButton';
import CoursePathwayElement from '../../components/CoursePathwayElement';
import { Dialog } from '@material-ui/core';
import AddCourseEvent from './AddCourseEvent';
import AttendanceTool from '../../components/AttendanceTool';
import TopBarWrapper from '../../components/TopBarWrapper';
import MOTopBar from '../../components/MOTopBar';
import TopBarHeading from '../../components/TopBarHeading';
import NewCourse from './NewCourse';
import NewTrainingSession from './NewTrainingSession';
import EditTrainingSession from './EditTrainingSession';
import CourseLog from './CourseLog';
import TrainingLog from './TrainingLog';
import EditCourse from './EditCourse';
import CourseFeedback from './CourseFeedback';

const unload = (recaptchaSiteKey) => {
  const nodeBadge = document.querySelector('.grecaptcha-badge');
  if (nodeBadge) {
    document.body.removeChild(nodeBadge.parentNode);
  }

  const scriptSelector =
    "script[src='https://www.google.com/recaptcha/api.js?render=" +
    recaptchaSiteKey +
    "']";
  const script = document.querySelector(scriptSelector);
  if (script) {
    script.remove();
  }
};

const FormWrapper = styled.div`
  padding: 30px;
  text-align: left;
`;

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;

const course_types = [
  {
    id: 'standalone',
    display_text: 'Standalone Journey',
  },
  {
    id: 'blended',
    display_text: 'Blended Learning Journey',
  },
  {
    id: 'webinar',
    display_text: 'Webinar',
  },
  {
    id: 'internal',
    display_text: 'Internal',
  },
  {
    id: 'inhouse',
    display_text: 'In-house',
  },
  {
    id: 'external',
    display_text: 'External',
  }
];

const StyledPlusIcon = styled(PlusIcon)`
  > #mastero_icon_add .cls-plus-icon {
    fill: #f4511e !important;
  }
`;

const StyledGenericButton = styled(ClearButton)`
  margin-top: 15px;
  color: #4f0060;
  padding: 0;
  font-size: 10px;
`;

function CourseTab(props) {
  let permissions = [];

  if (props.userInfo.permissions.hasOwnProperty('features')) {
    for (let permission of props.userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }

  let user_info = props.userInfo;
  let jwt_token = props.jwtToken;
  let [courses, setCourses] = useState([]);
  let [courseEvents, setCourseEvents] = useState([]);
  const [batches, setBatches] = React.useState([]);

  function fetch_courses() {
    let action = props.MOClient.get_training_courses({
      project_id: props.activeProject,
      token: props.jwtToken,
    });

    action
      .then((response) => {
        let parse_response_json = response.fetch_request.json();
        parse_response_json
          .then((data) => {
            if (data.status === 'success') {
              setCourses(data.courses);
              setCourseEvents(data.course_events);
            } else {
              // process error on fetching training courses
            }
          })
          .catch((ex) => { });
      })
      .catch((ex) => { });
  }

  React.useEffect(() => {
    let options = {
      token: props.jwtToken,
    };

    if (permissions.indexOf('view_all_training_batches') > -1) {
      options.get_all = true;
    }

    let action = props.MOClient.get_training_batches(options);

    action.then((result) => {
      if (result.status === 'success') {
        let fetch_request = result.fetch_request;

        let extract_data = fetch_request.json();

        extract_data.then((json_data) => {
          if (json_data.status === 'success') {
            setBatches(json_data.data);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    // fetch courses for this project
    fetch_courses();
  }, [props.activeProject]);

  function onCourseAdded() {
    fetch_courses();
  }

  let meta = {
    demographics: [],
  };

  if (props.companyInfo.hasOwnProperty('meta')) {
    let user_level_demographics = [];

    for (let i = 0; i < props.companyInfo.meta.demographics.length; i++) {
      let elem = props.companyInfo.meta.demographics[i];
      let demographic = elem['name'].toLowerCase();
      if (
        user_info.hasOwnProperty('permissions') &&
        user_info.permissions.hasOwnProperty('demographics') &&
        user_info.permissions.demographics.hasOwnProperty(demographic)
      ) {
        user_level_demographics.push(elem);
      }
    }

    meta.demographics = user_level_demographics;
  }

  unload('6LeMwbMUAAAAAIf56AAL27kmmU7ZalWZPkM81ka-');

  return (
    <div>
      <div>
        <Router>
          <div>
            {/*== Create new Course === */}
            <Route
              path="/courses-and-training/create-new-course"
              render={(routeProps) => (
                <NewCourse
                  {...routeProps}
                  {...props}
                  onCourseAdded={onCourseAdded}
                />
              )}
            />

            {/*== Create new Training Session === */}
            <Route
              path="/training/create-new-training-session"
              render={(routeProps) => (
                <NewTrainingSession
                  {...props}
                  courses={courses}
                  courseEvents={courseEvents}
                  batches={batches}
                />
              )}
            />
            {/*== Edit new Training Session === */}
            <Route
              path="/training/training-sessions/edit/:id"
              render={(routeProps) => (
                <EditTrainingSession
                  {...props}
                  {...routeProps}
                  courses={courses}
                  courseEvents={courseEvents}
                  batches={batches}
                />
              )}
            />

            {/*== Course Log === */}
            <Route
              path="/courses-and-training/course-log"
              render={(routeProps) => (
                <CourseLog
                  {...props}
                  courses={courses}
                  courseEvents={courseEvents}
                  onDelete={() => {
                    fetch_courses();
                  }}
                />
              )}
            />

            <Route
              path="/courses-and-training/course-feedback"
              render={(routeProps) => (
                <CourseFeedback
                  {...props}
                  courses={courses}
                  courseEvents={courseEvents}
                />
              )}
            />

            {/*== Training Log === */}
            <Route
              path="/training/training-log"
              render={(routeProps) => (
                <TrainingLog
                  {...props}
                  courses={courses}
                  courseEvents={courseEvents}
                  batches={batches}
                />
              )}
            />

            {/*== Attendance Tool === */}
            <Route
              path="/training/training-tool"
              render={(routeProps) => (
                <AttendanceTool
                  {...props}
                  courses={courses}
                  courseEvents={courseEvents}
                  batches={batches}
                />
              )}
            />

            {/*== Edit Training Course === */}
            <Route
              path="/courses-and-training/courses/edit/:id"
              render={(routeProps) => (
                <EditCourse
                  {...props}
                  {...routeProps}
                  courses={courses}
                  courseEvents={courseEvents}
                  batches={batches}
                  onSave={() => {
                    fetch_courses();
                  }}
                />
              )}
            />
          </div>
        </Router>
      </div>
    </div>
  );
}

export default CourseTab;
