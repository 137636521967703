import styled from 'styled-components';

const StyledDownloadLink = styled.a`
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  color: #707070;
  text-decoration: underline;
`;

export default StyledDownloadLink;
