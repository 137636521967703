import React from 'react';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import { Dialog, withStyles } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import dayjs from 'dayjs';
import FormControl from './FormControl';
import Label from './Label';
import SimpleFormSelect from './SimpleFormSelect';
import HorizontalRuler from './HorizontalRuler';
import TopBarWrapper from './TopBarWrapper';
import MOTopBar from './MOTopBar';
import TopBarHeading from './TopBarHeading';
import Flex from './Flex';
import FlexItem from './FlexItem';
import CoursePathwayElementLabel from './CoursePathwayElementLabel';
import MOSelect from './MOSelect';
import FlatPrimaryButton from './FlatPrimaryButton';
import Utils from '../utils';
import TextInput from './TextInput';
import TextArea from './TextArea';
import Loader from './Loader';
import Button2 from './Button2';
import ClearButton from './ClearButton';
import MOSelect3 from './MOSelect3';
import { format } from 'prettier';

const GreenTick = require('../assets/complete_mark.svg');
const SuccessTick = require('../assets/success.svg');
const FailureCross = require('../assets/failure.svg');

function get_training_session_lock_unlock_jsx(training_session_data) {
  if (typeof training_session_data === 'undefined') {
    return null;
  }

  if (training_session_data.hasOwnProperty('locked')) {
    if (training_session_data.locked === 1) {
      return (
        <span
          style={{
            backgroundColor: 'red',
            padding: '8px',
            fontSize: '0.75rem',
            borderRadius: '2px',
            color: 'white',
          }}>
          Locked
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: 'green',
            padding: '8px',
            fontSize: '0.75rem',
            borderRadius: '2px',
            color: 'white',
          }}>
          Open
        </span>
      );
    }
  } else return '';
}

export default function AttendanceTool({
  userInfo,
  jwtToken,
  MOClient,
  courses,
  courseEvents,
  driveUsers,
  batches,
}) {
  const permissions = [];
  if (userInfo.permissions.hasOwnProperty('features')) {
    for (const permission of userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }

  let trainers = driveUsers.filter((u) => {
    let access = false;
    for (let permission of u.permissions.features) {
      if (permission.key === 'create_training_session') {
        access = true;
        break;
      }
    }
    return access;
  });

  let trainers_obj = Utils.objectify(trainers, 'id');
  const batches_object = Utils.objectify(batches, 'id');
  const [progressDialog, setProgressDialog] = React.useState(false);
  let [
    download_xls_dialog_state,
    set_download_xls_dialog_state,
  ] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedEventDates, setSelectedEventDates] = React.useState([]);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [selectedTraining, setSelectedTraining] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [batchLearners, setBatchLearners] = React.useState([]);
  const [activeBatchLearner, setActiveBatchLearner] = React.useState(0);
  const [attendanceData, setAttendanceData] = React.useState([]);
  const [trainingSessions, setTrainingSessions] = React.useState([]);
  const [feedbackForms, setFeedbackForms] = React.useState([]);
  const [observationStatus, setObservationStatus] = React.useState([]);
  const [feedbackSubmissions, setFeedbackSubmissions] = React.useState([]);
  const [showMarkAllValid, setShowMarkAllValid] = React.useState(false);
  const [activeObservationData, setActiveObservationData] = React.useState([
    {
      observation_points: 0,
      action_status: 'not_performed',
    },
  ]);
  const [
    activeObservationRemarks,
    setActiveObservationRemarks,
  ] = React.useState('');
  let training_session_object = Utils.objectify(trainingSessions, 'id');
  const courses_object = Utils.objectify(courses, 'id');
  const [addObservationDialog, setAddObservationDialog] = React.useState(false);
  const [editObservationDialog, setEditObservationDialog] = React.useState(
    false,
  );

  const [
    feedbackSubmissionActiveQuestion,
    setFeedbackSubmissionActiveQuestion,
  ] = React.useState('');
  const [
    managerFeedbackSubmissionActiveQuestion,
    setManagerFeedbackSubmissionActiveQuestion,
  ] = React.useState('');
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [successDialogText, setSuccessDialogText] = React.useState(
    'The training session was closed successfully!',
  );
  const [failureDialog, setFailureDialog] = React.useState(false);
  const [failureText, setFailureDialogText] = React.useState(
    'There was an unknown error, please try again?',
  );

  const [filterTrainer, setFilterTrainer] = React.useState(0);
  const [filterBatch, setFilterBatch] = React.useState(0);
  const [filterCourse, setFilterCourse] = React.useState(0);
  const [dates_optionsData, setDates_optionsData] = React.useState([]);
  const course_events_object = Utils.objectify(courseEvents, 'id');
  const attendance_data_object = Utils.objectify(
    attendanceData,
    'batch_learner_id',
  );

  const feedbackFormsObject = Utils.objectify(feedbackForms, 'id');

  let observation_forms = feedbackForms.filter(
    (form) => form.form_type === 'observation',
  );

  let feedback_forms = feedbackForms.filter(
    (form) => form.form_type === 'feedback',
  );

  let manager_feedback_forms = feedbackForms.filter(
    (form) => form.form_type === 'manager_feedback',
  );

  let feedbackSubmissionActiveQuestionText = '';

  if (feedbackSubmissionActiveQuestion !== '') {
    if (
      !(
        feedback_forms.length > 0 &&
        feedback_forms[0].hasOwnProperty('form_data')
      )
    ) {
      feedbackSubmissionActiveQuestionText = '';
    } else {
      let activeQuestion = feedback_forms[0].form_data.filter(
        (q) => q.id === feedbackSubmissionActiveQuestion,
      );
      if (activeQuestion.length > 0) {
        feedbackSubmissionActiveQuestionText = activeQuestion[0].question;
      }
    }
  }

  let managerFeedbackSubmissionActiveQuestionText = '';

  if (managerFeedbackSubmissionActiveQuestion !== '') {
    if (
      !(
        manager_feedback_forms.length > 0 &&
        manager_feedback_forms[0].hasOwnProperty('form_data')
      )
    ) {
      managerFeedbackSubmissionActiveQuestionText = '';
    } else {
      let activeQuestion = manager_feedback_forms[0].form_data.filter(
        (q) => q.id === managerFeedbackSubmissionActiveQuestion,
      );
      if (activeQuestion.length > 0) {
        managerFeedbackSubmissionActiveQuestionText =
          activeQuestion[0].question;
      }
    }
  }
  const selectedTrainingData = training_session_object[selectedTraining];

  const feedbackSubmissionsObject = Utils.objectify(
    feedbackSubmissions,
    'batch_learner_id',
  );

  let feedbackSubmissionQuestions = [];
  let managerFeedbackSubmissionQuestions = [];

  if (
    feedback_forms.length > 0 &&
    feedback_forms[0].hasOwnProperty('form_data')
  ) {
    feedbackSubmissionQuestions = feedback_forms[0].form_data.map(
      (question) => {
        return {
          id: question.id,
          display_text: question.question,
        };
      },
    );
  }

  if (
    manager_feedback_forms.length > 0 &&
    manager_feedback_forms[0].hasOwnProperty('form_data')
  ) {
    managerFeedbackSubmissionQuestions = manager_feedback_forms[0].form_data.map(
      (question) => {
        return {
          id: question.id,
          display_text: question.question,
        };
      },
    );
  }
  let selectedCourseData = {
    name: '',
  };

  let selectedEventData = {
    name: '',
  };

  let courseOptions = courses.map((c) => {
    return {
      id: c.id,
      display_text: c.name,
    };
  });
  if (selectedCourse !== null) {
    const course = courses.filter((c) => c.id === selectedCourse);

    if (course.length > 0) {
      [selectedCourseData] = course;
    }
  }

  if (selectedEvent !== null) {
    const event = courseEvents.filter((v) => v.id === selectedEvent);
    [selectedEventData] = event;
  }

  const filteredCourseEvents = courseEvents.filter(
    (e) => e.course_id === selectedCourse,
  );

  const trainingOptions = trainingSessions
    .map((training_session) => {
      const o = {
        display_text: '',
        ...training_session,
      };

      if (courses_object.hasOwnProperty(training_session.course_id)) {
        o.display_text = courses_object[training_session.course_id].name;
        o.course_id = courses_object[training_session.course_id].id;
        o.meta = [];

        // for Admins, add Trainer name for relevance
        if (permissions.indexOf('view_all_training_sessions') > -1) {
          let trainer_name =
            trainers_obj[training_session.drive_user_id].first_name;
          o.meta.push(`Trainer: ${trainer_name}`);
        }

        // Add Batch name in the meta
        let batch_id = training_session.training_batches[0].toString();
        if (batches_object.hasOwnProperty(batch_id)) {
          let batch_code = batches_object[batch_id].batch_code;
          let batch_name = batches_object[batch_id].batch_name;

          o.meta.push(`[${batch_code}] - ${batch_name}`);
        }

        // Add Date

        o.meta.push(
          `${new dayjs(training_session.start_date).format('MMM DD')}`,
        );
      }

      return o;
    })
    .sort((a, b) => (a.id > b.id ? -1 : 1));

  const courseEventOptions = filteredCourseEvents.map((courseEvent) => ({
    id: courseEvent.id,
    display_text: courseEvent.name,
  }));

  let trainingEventData = {
    event_days: [],
  };

  let trainerOptions = trainers.map((t) => {
    return {
      id: t.id,
      display_text: t.first_name + ' ' + t.last_name,
    };
  });

  let batchOptions = batches.map((b) => {
    return {
      id: b.id,
      display_text: b.batch_code + ' ' + b.batch_name,
    };
  });
  if (selectedEvent !== null && selectedTraining !== null) {
    const filtered = training_session_object[
      selectedTraining
    ].training_events.filter((v) => v.event_id === selectedEvent);
    [trainingEventData] = filtered;
  }

  let rows_filtered = trainingOptions.filter((r) => {
    let status = false;
    let filter_selected = false;

    if (filterCourse > 0) {
      if (r.course_id === filterCourse) status = true;
      else status = false;
      filter_selected = true;
    }

    if (filterBatch > 0) {
      if (r.training_batches.indexOf(filterBatch) >= 0) {
        if (!filter_selected) status = true;
      } else status = false;

      filter_selected = true;
    }

    if (filterTrainer > 0) {
      if (r.drive_user_id === filterTrainer) {
        if (!filter_selected) status = true;
      } else status = false;
      filter_selected = true;
    }

    if (filter_selected === false) return true;

    return status;
  });
  /* ========= Functions ============= */

  function fetch_training_sessions() {
    const options = {
      token: jwtToken,
    };

    if (permissions.indexOf('view_all_training_sessions') > -1) {
      options.get_all = true;
    }

    const action_training_sessions = MOClient.get_training_sessions(options);

    action_training_sessions.then((outcome) => {
      if (outcome.status === 'success') {
        setTrainingSessions(outcome.data.data);
      }
    });
  }
  function fetch_feedback_submissions() {
    if (selectedTraining === null || selectedEvent === null) return;
    const options = {
      token: jwtToken,
      training_session_id: selectedTraining,
      event_id: selectedEvent,
    };

    const action_training_sessions = MOClient.get_feedback_form_submissions(
      options,
    );

    action_training_sessions
      .then((outcome) => {
        if (outcome.status === 'success') {
          setFeedbackSubmissions(outcome.data);
        } else {
        }
      })
      .catch((ex) => {
        console.log('Failure to fetch feedback form submissions');
      });
  }
  function download_feedback_submissions() {
    if (selectedTraining === null || selectedEvent === null) return;
    const options = {
      token: jwtToken,
      training_session_id: selectedTraining,
      event_id: selectedEvent,
      response_type: 'excel',
    };

    set_download_xls_dialog_state(true);

    const action_training_sessions = MOClient.get_feedback_form_submissions(
      options,
    );

    action_training_sessions
      .then((outcome) => {
        set_download_xls_dialog_state(false);
        let file_name = `Manager_Feedback.xlsx`;
        saveAs(outcome, file_name, { autoBOM: true });
      })
      .catch((ex) => {
        console.log('Failure to fetch feedback form submissions');
      });
  }
  function fetch_attendance() {
    if (selectedTraining === null || selectedEvent === null) {
      return;
    }

    const action_fetch_attendance = MOClient.get_attendance({
      token: jwtToken,
      training_session_id: selectedTraining,
      event_id: selectedEvent,
    });

    action_fetch_attendance.then((outcome) => {
      setAttendanceData(outcome.data.data);
    });
  }
  function is_attendance_present(batch_learner_id, event_date) {
    if (attendance_data_object.hasOwnProperty(batch_learner_id)) {
      const per_learner_attendance =
        attendance_data_object[batch_learner_id].attendance_data;
      for (const event_attendance of per_learner_attendance) {
        if (event_attendance.event_date === event_date) {
          if (event_attendance.attendance === true) return true;
          return false;
        }
      }
      return false;
    }

    return false;
  }
  function mark_attendance(
    batch_learner_id,
    training_session_id,
    event_id,
    event_date,
    attendance,
  ) {
    let learner_attendance = [];

    if (attendance_data_object.hasOwnProperty(batch_learner_id)) {
      learner_attendance =
        attendance_data_object[batch_learner_id].attendance_data;
    }

    const new_attendance = [...learner_attendance];

    if (new_attendance.length > 0) {
      let present_and_replaced = false;
      for (const [index, event_attendance] of new_attendance.entries()) {
        if (event_attendance.event_date === event_date) {
          new_attendance[index].attendance = attendance;
          present_and_replaced = true;
        }
      }

      if (!present_and_replaced) {
        new_attendance.push({
          event_date,
          attendance,
        });
      }
    } else {
      new_attendance[0] = {
        event_date,
        attendance,
      };
    }

    const action = MOClient.mark_attendance({
      token: jwtToken,
      training_session_id,
      event_id,
      batch_learner_id,
      attendance: new_attendance,
    });

    action.then((outcome) => {
      console.log(outcome);
      if (outcome.status !== 'success') {
        setFailureDialogText(outcome.description);
        setFailureDialog(true);
      }
      fetch_attendance();
    });
  }
  function bulk_mark_attendance() {
    const action = MOClient.bulk_mark_attendance({
      token: jwtToken,
      training_session_id: selectedTraining,
      event_id: selectedEvent,
      event_dates: filtered_date,
    });

    action.then((outcome) => {
      console.log(outcome);
      if (outcome.status !== 'success') {
        setFailureDialogText(outcome.description);
        setFailureDialog(true);
      }
      fetch_attendance();
    });
  }

  function invalidCheck() {
    setFailureDialog(true);
    setFailureDialogText('All dates cannot be marked invalid');
  }

  function mark_as_valid_attendance(valid) {
    let checkValidDates = dates_optionsData.filter((date) => date.valid)
    // console.log(filtered_date)
    // console.log(checkValidDates);
    if (checkValidDates.length != filtered_date.length) {
      const action = MOClient.mark_as_valid_attendance({
        token: jwtToken,
        training_session_id: selectedTraining,
        event_id: selectedEvent,
        event_days: filtered_date.map(event => event.event_date),
        valid
      });

      action.then((outcome) => {
        // console.log("OutCome :", outcome);
        if (outcome.status !== 'success') {
          setFailureDialogText(outcome.description);
          setFailureDialog(true);
        }

        if (outcome.data !== undefined) {
          let trainingEventData = outcome.data.data.filter(event => event.event_id === selectedEvent)
          let dates_options_date = trainingEventData[0].event_days.map((days, index) => {
            return {
              display_text: dayjs(days.event_date).format('Do MMMM, YYYY'),
              id: days.event_date,
              valid: days.valid,
              selection: true,
            };
          });

          for (const date of dates_options_date) {
            let counter = false;
            if (date.valid === false) {
              counter = true;
            } else {
              counter = false;
            }
            setShowMarkAllValid(counter);
            setDates_optionsData(dates_options_date)
          }
        }
        else {
          action.then((outcome) => {
            console.log(outcome);
            if (outcome.status !== 'success') {
              setFailureDialog(true);
              setFailureDialogText('Please select at least one date to mark!');
            }
          }
          )
        }
      });
    } else {
      invalidCheck();
    }
  }

  function get_event_name(event_id) {
    const event_name = '';
    if (course_events_object.hasOwnProperty(event_id)) {
      return course_events_object[event_id].name;
    }
    return event_name;
  }
  function get_event_start_date() {
    return dayjs(new Date(trainingEventData.event_days[0].event_date)).format(
      'MMM DD',
    );
  }
  function clear_attendance() {
    if (selectedTraining === null || selectedEvent === null) {
      return;
    }

    const action_clear_attendance = MOClient.clear_attendance({
      token: jwtToken,
      training_session_id: selectedTraining,
      event_id: selectedEvent,
    });

    action_clear_attendance.then((outcome) => {
      fetch_attendance();
    });
  }
  function handleTabChange(event, value) {
    setTabValue(value);
  }
  function refetch_feedback_forms() {
    if (selectedCourse === null || selectedEvent === null) {
      return;
    }

    const action = fetch_feedback_forms(
      MOClient,
      jwtToken,
      selectedCourse,
      selectedEvent,
    );

    action.then((outcome) => {
      if (outcome.status !== 'success') {
        return;
      }

      if (Array.isArray(outcome.data)) {
        setFeedbackForms(outcome.data);
        if (
          outcome.data.length > 0 &&
          outcome.data[0].hasOwnProperty('form_data')
        ) {
          let feedback_forms = outcome.data.filter(
            (form) => form.form_type === 'feedback',
          );

          if (feedback_forms.length > 0) {
            setFeedbackSubmissionActiveQuestion(
              feedback_forms[0].form_data[0].id,
            );
          }
          let manager_feedback_forms = outcome.data.filter(
            (form) => form.form_type === 'manager_feedback',
          );

          if (manager_feedback_forms.length > 0) {
            setManagerFeedbackSubmissionActiveQuestion(
              manager_feedback_forms[0].form_data[0].id,
            );
          }
        }
      }
    });
  }
  function refetch_observation_status() {
    if (selectedTraining === null || selectedEvent === null) {
      return;
    }

    const action = fetch_event_observation_status(
      MOClient,
      jwtToken,
      selectedTraining,
      selectedEvent,
    );

    action.then((outcome) => {
      if (outcome.status !== 'success') {
        return;
      }

      if (Array.isArray(outcome.data)) setObservationStatus(outcome.data);
    });
  }
  function handle_edit_observation(batch_learner_id) {
    setActiveBatchLearner(batch_learner_id);
    setProgressDialog(true);
    let action = MOClient.read_event_observation({
      training_session_id: selectedTraining,
      event_id: selectedEvent,
      batch_learner_id: batch_learner_id,
      form_id: observation_forms[0].id,
      token: jwtToken,
    });

    action.then((outcome) => {
      setActiveObservationData(outcome.data.data);
      setActiveObservationRemarks(outcome.data.remarks);
      setProgressDialog(false);
      setEditObservationDialog(true);
    });
  }
  function handle_add_observation(batch_learner_id) {
    setActiveBatchLearner(batch_learner_id);
    if (observation_forms.length === 0) return;
    const observations = observation_forms[0].form_data.map((r) => {
      return {
        id: r.id,
        observation_points: 0,
        action_status: 'not_performed',
      };
    });
    setActiveObservationData(observations);
    setAddObservationDialog(true);
  }
  function saveObservation() {
    // VALIDATION: Points should not be greater than

    for (let [index, observation] of observation_forms[0].form_data.entries()) {
      if (
        Number(observation.observation_max_points) <
        Number(activeObservationData[index].observation_points)
      ) {
        setFailureDialog(true);
        setFailureDialogText(
          'Error: Observation points not within the specified limits',
        );
        return;
      }
    }

    setProgressDialog(true);
    const action = MOClient.update_event_observation({
      token: jwtToken,
      batch_learner_id: activeBatchLearner,
      training_session_id: selectedTraining,
      event_id: selectedEvent,
      form_id: observation_forms[0].id,
      form_data: activeObservationData,
      remarks: activeObservationRemarks,
    });

    action.then((outcome) => {
      setProgressDialog(false);

      if (outcome.status !== 'success') {
        setFailureDialog(true);
        setFailureDialogText(outcome.description);
      }

      refetch_observation_status();
      setEditObservationDialog(false);
    });
  }
  function addObservation() {
    for (let [index, observation] of observation_forms[0].form_data.entries()) {
      if (
        Number(observation.observation_max_points) <
        Number(activeObservationData[index].observation_points)
      ) {
        setFailureDialog(true);
        setFailureDialogText(
          'Error: Observation points not within the specified limits',
        );
        return;
      }
    }

    setProgressDialog(true);
    const action = MOClient.add_event_observation({
      token: jwtToken,
      batch_learner_id: activeBatchLearner,
      training_session_id: selectedTraining,
      event_id: selectedEvent,
      form_id: observation_forms[0].id,
      form_data: activeObservationData,
      remarks: activeObservationRemarks,
    });

    action.then((outcome) => {
      setProgressDialog(false);
      refetch_observation_status();

      if (outcome.status !== 'success') {
        setFailureDialog(true);
        setFailureDialogText(outcome.description);
      }

      setAddObservationDialog(false);
    });
  }
  function get_feedback_by_learner(batch_learner_id) {
    if (feedback_forms.length <= 0) return '';
    if (feedbackSubmissionsObject.hasOwnProperty(batch_learner_id + '')) {
      const submissions = feedbackSubmissionsObject[batch_learner_id];

      const question = submissions.form_data.filter(
        (q) => q.id === feedbackSubmissionActiveQuestion,
      );

      if (
        !(
          feedback_forms.length > 0 &&
          feedback_forms[0].hasOwnProperty('form_data')
        )
      )
        return '';

      const [question_meta] = feedback_forms[0].form_data.filter(
        (q) => q.id === feedbackSubmissionActiveQuestion,
      );

      if (Array.isArray(question) && question.length > 0) {
        if (question_meta.question_type === 'agree_disagree') {
          return Utils.capitalize(question[0].answer.replace('_', ' '));
        } else if (question_meta.question_type === 'open_ended') {
          return question[0].answer;
        } else if (question_meta.question_type === 'rating') {
          return <span>{question[0].answer} ⭐</span>;
        } else if (question_meta.question_type === 'yes_no') {
          return <span>{question[0].answer}</span>;
        } else if (question_meta.question_type === 'custom') {
          return <span>{question[0].answer}</span>;
        }
      } else return 'Not Submitted';
      return 'Not Submitted';
    } else return 'Not Submitted';

    return 'Not Submitted';
  }
  function get_manager_feedback_by_learner(batch_learner_id) {
    if (manager_feedback_forms.length <= 0) return '';
    if (feedbackSubmissionsObject.hasOwnProperty(batch_learner_id + '')) {
      const submissions = feedbackSubmissionsObject[batch_learner_id];

      const question = submissions.form_data.filter(
        (q) => q.id === managerFeedbackSubmissionActiveQuestion,
      );

      if (
        !(
          manager_feedback_forms.length > 0 &&
          manager_feedback_forms[0].hasOwnProperty('form_data')
        )
      )
        return '';

      const [question_meta] = manager_feedback_forms[0].form_data.filter(
        (q) => q.id === managerFeedbackSubmissionActiveQuestion,
      );

      if (Array.isArray(question) && question.length > 0) {
        if (question_meta.question_type === 'agree_disagree') {
          return Utils.capitalize(question[0].answer.replace('_', ' '));
        } else if (question_meta.question_type === 'open_ended') {
          return question[0].answer;
        } else if (question_meta.question_type === 'rating') {
          return <span>{question[0].answer} ⭐</span>;
        } else if (question_meta.question_type === 'yes_no') {
          return <span>{question[0].answer}</span>;
        } else if (question_meta.question_type === 'custom') {
          return <span>{question[0].answer}</span>;
        }
      } else return 'Not Submitted';
      return 'Not Submitted';
    } else return 'Not Submitted';

    return 'Not Submitted';
  }
  function lock_training_session() {
    setProgressDialog(true);
    const action = MOClient.lock_training_session({
      token: jwtToken,
      training_session_id: selectedTraining,
    });

    action
      .then((result) => {
        fetch_training_sessions();
        setProgressDialog(false);
        setSuccessDialogText('The training session was closed successfully!');
        setSuccessDialog(true);
      })
      .catch((ex) => {
        setProgressDialog(false);
      });
  }

  function clearFilters() {
    setFilterCourse(0);
    setFilterBatch(0);
    setFilterTrainer(0);
    setSelectedTraining(null);
    setSelectedCourse(null);
    setSelectedEvent(null);
  }
  /* ========= Effects ============= */

  React.useEffect(() => {
    fetch_training_sessions();
  }, []);

  // Effect for fetching batch learners
  React.useEffect(() => {
    if (selectedTraining === null) return;

    if (training_session_object[selectedTraining].training_batches.length === 0)
      return;

    const batch_id =
      training_session_object[selectedTraining].training_batches[0];

    const action = MOClient.get_training_batch_learners({
      token: jwtToken,
      batch_id,
    });

    action.then((response) => {
      if (response.status !== 'success') {
        setBatchLearners([]);
      } else {
        setBatchLearners(response.data.data);
      }
    });
  }, [selectedTraining]);

  React.useEffect(() => {
    setSelectedCourse(null);
    setSelectedTraining(null);
    setSelectedEvent(null);
  }, [filterTrainer, filterCourse, filterBatch]);

  // effect to fetch attendance

  React.useEffect(() => {
    fetch_attendance();
    fetch_feedback_submissions();
  }, [selectedTraining, selectedEvent]);

  React.useEffect(() => {
    refetch_feedback_forms();
    refetch_observation_status();
  }, [selectedCourse, selectedEvent]);

  let observation_marked = observationStatus.map((ob) => ob.batch_learner_id);

  const activeBatchLearnerData = {
    demographics: {
      first_name: '',
      last_name: '',
    },
  };

  const activeBatchLearnerDataArray = batchLearners.filter(
    (l) => l.id === activeBatchLearner,
  );

  if (activeBatchLearnerDataArray.length > 0) {
    Object.assign(activeBatchLearnerData, activeBatchLearnerDataArray[0]);
  }

  let dates_options = [
    { display_text: 'Valid Dates', id: 'VALID_DATE', selection: false },
    { display_text: 'Invalid Dates', id: 'INVALID_DATE', selection: false },
  ];

  React.useEffect(() => {
    let dates_options_date = trainingEventData.event_days.map((days, index) => {
      return {
        display_text: dayjs(days.event_date).format('Do MMMM, YYYY'),
        id: days.event_date,
        valid: days.valid,
        selection: true,
      };
    });
    setDates_optionsData(dates_options_date);
  }, [selectedEvent]);

  React.useEffect(() => {
    setSelectedEventDates([]);
  }, [selectedEvent]);


  for (const dates_optionData of dates_optionsData) {
    const { valid = true } = dates_optionData
    if (valid) {
      const invalidIndex = dates_options.findIndex((item) => item.id === 'INVALID_DATE');
      dates_options.splice(invalidIndex, 0, dates_optionData);
    } else {
      dates_options.splice(dates_options.length + 1, 0, dates_optionData);
    }
  }

  let filtered_date = [];

  if (selectedEvent !== null) {
    filtered_date = trainingEventData.event_days.filter((e) => {
      if (selectedEventDates.indexOf(e.event_date) > -1) return true;
      return false;
    });
  }

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Training Tool</TopBarHeading>
        </MOTopBar>

        <div style={{ textAlign: 'left' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              padding: 30,
              paddingBottom: 0,
            }}>
            <FormControl style={{ marginRight: 20 }}>
              <Label>Course:</Label>
              <MOSelect
                onSelect={(id) => {
                  setFilterCourse(id);
                }}
                options={courseOptions}
                text={filterCourse > 0 ? courses_object[filterCourse].name : ''}
              />
            </FormControl>
            <FormControl style={{ marginRight: 20 }}>
              <Label>Batch:</Label>
              <MOSelect
                onSelect={(id) => {
                  setFilterBatch(id);
                }}
                options={batchOptions}
                text={
                  filterBatch > 0
                    ? batches_object[filterBatch].batch_code +
                    ' ' +
                    batches_object[filterBatch].batch_name
                    : ''
                }
              />
            </FormControl>
            {permissions.indexOf('view_all_training_sessions') > -1 ? (
              <FormControl style={{ marginRight: 20 }}>
                <Label>Trainer:</Label>
                <MOSelect
                  options={trainerOptions}
                  onSelect={(id) => {
                    setFilterTrainer(id);
                  }}
                  text={
                    filterTrainer > 0
                      ? trainers_obj[filterTrainer].first_name +
                      ' ' +
                      trainers_obj[filterTrainer].last_name
                      : ''
                  }
                />
              </FormControl>
            ) : null}
          </div>
          <div style={{ paddingLeft: 30, textAlign: 'left' }}>
            <FormControl>
              <Button2 transparent={true} onClick={clearFilters}>
                Clear Filters
              </Button2>
            </FormControl>
          </div>
          <div
            style={{
              padding: 30,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}>
            <Flex alignItems="top" gap={24}>
              <FlexItem gap={24}>
                <FormControl>
                  <Label>Select Training Session:</Label>
                  <SimpleFormSelect
                    options={rows_filtered}
                    autoClose
                    onSelect={(id) => {
                      setSelectedCourse(training_session_object[id].course_id);
                      setSelectedTraining(id);
                      setSelectedEvent(null);
                    }}
                    text={selectedCourseData.name}
                  />
                </FormControl>

                <FormControl>
                  <Label>Select Course Event:</Label>
                  <SimpleFormSelect
                    options={courseEventOptions}
                    onSelect={(id) => setSelectedEvent(id)}
                    text={selectedEventData.name}
                    autoClose
                  />
                </FormControl>
              </FlexItem>

              {selectedCourse !== null && selectedEvent !== null ? (
                <FlexItem gap={24}>
                  <FormControl>
                    <Label>Your Selection:</Label>
                    <DateText>{get_event_start_date()}</DateText>
                    <CoursePathwayElementLabel
                      multiplier={1.577}
                      backgroundColor="#56dab8"
                      text={get_event_name(selectedEvent)}
                    />
                    <SmallDateText>
                      {dayjs(
                        new Date(trainingEventData.event_days[0].event_time),
                      ).format('hh:mm A')}
                    </SmallDateText>
                  </FormControl>
                </FlexItem>
              ) : null}
            </Flex>
            <Flex>
              <FlexItem gap={24}>
                {selectedCourse !== null && selectedEvent !== null
                  ? get_training_session_lock_unlock_jsx(selectedTrainingData)
                  : null}
              </FlexItem>
            </Flex>
          </div>
          <HorizontalRuler />
        </div>

        {selectedTraining === null || selectedEvent === null ? null : (
          <div>
            {batchLearners.length === 0 ? (
              <p>No learners are there in this training session</p>
            ) : (
              <div>
                <CustomTabs
                  variant="scrollable"
                  scrollButtons="off"
                  value={tabValue}
                  onChange={handleTabChange}>
                  <CustomTab label="Course Attendance" />
                  <CustomTab label="Learner Review" />
                  <CustomTab label="Course Feedback" />
                  <CustomTab label="Manager Feedback" />
                  <CustomTab label="End Course for Batch" />
                </CustomTabs>

                {tabValue === 0 ? (
                  <div style={{ textAlign: 'left' }}>
                    <div style={{ padding: '30px', paddingRight: 80 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <MOSelect
                          options={[{ id: 1, display_text: 'By Employee ID' }]}
                          value={1}
                          text="By Employee ID"
                        />
                        {showMarkAllValid && <ClearButton
                          style={{ display: 'inline' }}
                          onClick={() => mark_as_valid_attendance(true)}>
                          Mark as Valid
                        </ClearButton>}
                        <ClearButton
                          style={{ display: 'inline' }}
                          onClick={() => { mark_as_valid_attendance(false) }}>
                          Mark as Invalid
                        </ClearButton>
                        <ClearButton
                          style={{ display: 'inline' }}
                          onClick={() => bulk_mark_attendance()}>
                          Mark all as Present
                        </ClearButton>
                        <MOSelect3
                          multiple={true}
                          selected={selectedEventDates}
                          options={dates_options}
                          text={selectedEventDates
                            .map((date) => dayjs(date).format('MMM DD'))
                            .join(', ')}
                          onSelect={(values) => setSelectedEventDates(values)}
                        />
                      </div>
                      <TableWrapper>
                        <Table
                          style={{
                            border: '1px solid rgba(0,0,0,0.1)',
                            marginTop: 30,
                          }}>
                          <TableHead>
                            <CustomTableRow>
                              <CustomTableCell align="center">
                                <TableSortLabel>Name</TableSortLabel>
                              </CustomTableCell>

                              <CustomTableCell align="center">
                                <TableSortLabel>Employee ID</TableSortLabel>
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <TableSortLabel>E-mail Address</TableSortLabel>
                              </CustomTableCell>
                              {dates_optionsData.map((event_day, index) => {
                                const { valid = true } = event_day
                                if (valid) {
                                  return (
                                    <CustomTableCell align="center" key={index}>
                                      <StyledTabelHeaderLabel hideSortIcon>
                                        {dayjs(
                                          new Date(event_day.id),
                                        ).format('MMM DD')}
                                      </StyledTabelHeaderLabel>
                                    </CustomTableCell>)
                                }
                              })}
                            </CustomTableRow>
                          </TableHead>
                          <TableBody>
                            {batchLearners.map((row) => (
                              <CustomTableRow key={row.participant_code}>
                                <CustomTableCell align="center">
                                  <span>
                                    {`${row.demographics.first_name} `}
                                    {row.demographics.last_name}
                                  </span>
                                </CustomTableCell>

                                <CustomTableCell align="center">
                                  {row.participant_code}
                                </CustomTableCell>

                                <CustomTableCell align="center">
                                  <span
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                    }}>
                                    {row.demographics.email}
                                  </span>
                                </CustomTableCell>
                                {dates_optionsData.map((event_day, index) => {
                                  const { valid = true } = event_day
                                  if (valid) {
                                    return (
                                      <CustomTableCell align="center" key={index}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                          }}>
                                          {is_attendance_present(
                                            row.id,
                                            event_day.id,
                                          ) ? (
                                            <PresentBox
                                              onClick={() => {
                                                mark_attendance(
                                                  row.id,
                                                  selectedTraining,
                                                  selectedEvent,
                                                  event_day.id,
                                                  false,
                                                );
                                              }}>
                                              <PresentBoxLabel>
                                                Present
                                              </PresentBoxLabel>
                                            </PresentBox>
                                          ) : (
                                            <AbsentBox>
                                              <AbsentBoxLabel
                                                onClick={() => {
                                                  mark_attendance(
                                                    row.id,
                                                    selectedTraining,
                                                    selectedEvent,
                                                    event_day.id,
                                                    true,
                                                  );
                                                }}>
                                                Absent
                                              </AbsentBoxLabel>
                                            </AbsentBox>
                                          )}
                                        </div>
                                      </CustomTableCell>)
                                  }
                                })}
                              </CustomTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableWrapper>

                      <div style={{ marginTop: 30 }}>
                        <FlatPrimaryButton
                          onClick={() => {
                            setTabValue(tabValue + 1);
                          }}>
                          Go Next
                        </FlatPrimaryButton>

                        <span
                          style={{
                            marginLeft: 10,
                            color: '#707070',
                            fontSize: '0.9rem',
                          }}>
                          Attendance is Auto-saved
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Observation tool */}

                {tabValue === 1 ? (
                  <div>
                    {observation_forms.length > 0 ? (
                      <div style={{ textAlign: 'left' }}>
                        <div style={{ padding: '30px', paddingRight: 80 }}>
                          <TableWrapper>
                            <Table
                              style={{
                                border: '1px solid rgba(0,0,0,0.1)',
                                marginTop: 30,
                              }}>
                              <TableHead>
                                <CustomTableRow>
                                  <CustomTableCell align="center">
                                    <TableSortLabel>Name</TableSortLabel>
                                  </CustomTableCell>

                                  <CustomTableCell align="center">
                                    <TableSortLabel>Employee ID</TableSortLabel>
                                  </CustomTableCell>
                                  <CustomTableCell align="center">
                                    <TableSortLabel>
                                      E-mail Address
                                    </TableSortLabel>
                                  </CustomTableCell>

                                  <CustomTableCell align="center">
                                    <StyledTabelHeaderLabel hideSortIcon>
                                      Observation Status
                                    </StyledTabelHeaderLabel>
                                  </CustomTableCell>
                                </CustomTableRow>
                              </TableHead>
                              <TableBody>
                                {batchLearners.map((row) => (
                                  <CustomTableRow key={row.participant_code}>
                                    <CustomTableCell align="center">
                                      <span>
                                        {`${row.demographics.first_name} `}
                                        {row.demographics.last_name}
                                      </span>
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      {row.demographics.employee_id}
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      <span
                                        style={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                        }}>
                                        {row.demographics.email}
                                      </span>
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      {observation_marked.indexOf(row.id) >
                                        -1 ? (
                                        <WriteObservation
                                          onClick={() => {
                                            handle_edit_observation(row.id);
                                          }}>
                                          Edit Observation
                                        </WriteObservation>
                                      ) : (
                                        <WriteObservation
                                          onClick={() => {
                                            handle_add_observation(row.id);
                                          }}>
                                          Write Observation
                                        </WriteObservation>
                                      )}
                                    </CustomTableCell>
                                  </CustomTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableWrapper>

                          <div style={{ marginTop: 30 }}>
                            <FlatPrimaryButton
                              onClick={() => {
                                setTabValue(tabValue + 1);
                              }}>
                              Go Next
                            </FlatPrimaryButton>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p style={{ fontSize: '0.9rem', padding: '30px' }}>
                          No Observation Forms are added for this event.
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}

                {/* Feedback tool */}

                {tabValue === 2 ? (
                  <div>
                    {feedback_forms.length > 0 ? (
                      <div style={{ padding: '30px', paddingRight: 80 }}>
                        <FormControl>
                          <Label>Show feedback for:</Label>
                          <SimpleFormSelect
                            text={feedbackSubmissionActiveQuestionText}
                            options={feedbackSubmissionQuestions}
                            onSelect={(id) => {
                              setFeedbackSubmissionActiveQuestion(id);
                            }}
                            autoClose
                          />
                        </FormControl>
                        <TableWrapper>
                          <Table
                            style={{
                              border: '1px solid rgba(0,0,0,0.1)',
                              marginTop: 30,
                            }}>
                            <TableHead>
                              <CustomTableRow>
                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    Name
                                  </TableSortLabel>
                                </CustomTableCell>

                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    Employee ID
                                  </TableSortLabel>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    E-mail Address
                                  </TableSortLabel>
                                </CustomTableCell>

                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    {feedbackSubmissionActiveQuestionText}
                                  </TableSortLabel>
                                </CustomTableCell>
                              </CustomTableRow>
                            </TableHead>
                            <TableBody>
                              {batchLearners.map((row) => {
                                return (
                                  <CustomTableRow>
                                    <CustomTableCell align="center">
                                      <span>
                                        {`${row.demographics.first_name} `}
                                        {row.demographics.last_name}
                                      </span>
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      {row.demographics.employee_id}
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      <span
                                        style={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                        }}>
                                        {row.demographics.email}
                                      </span>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                      {get_feedback_by_learner(row.id)}
                                    </CustomTableCell>
                                  </CustomTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableWrapper>
                      </div>
                    ) : (
                      <div>
                        <p style={{ fontSize: '0.9rem', padding: '30px' }}>
                          No Feedback Forms are added for this event.
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}

                {/* Manager Feedback tool */}

                {tabValue === 3 ? (
                  <div>
                    {manager_feedback_forms.length > 0 ? (
                      <div style={{ padding: '30px', paddingRight: 80 }}>
                        <FormControl>
                          <Label>Show feedback for:</Label>
                          <SimpleFormSelect
                            text={managerFeedbackSubmissionActiveQuestionText}
                            options={managerFeedbackSubmissionQuestions}
                            onSelect={(id) => {
                              setManagerFeedbackSubmissionActiveQuestion(id);
                            }}
                            autoClose
                          />
                        </FormControl>
                        <div style={{ textAlign: 'right' }}>
                          <button
                            style={{
                              textAlign: 'left',
                              border: 'none',
                              backgroundColor: 'white',
                              cursor: 'pointer',
                              margin: 0,
                              marginTop: 10,
                              padding: 0,
                              outline: 0,
                            }}
                            onClick={() => {
                              download_feedback_submissions();
                            }}>
                            <p
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'left',
                                margin: 0,
                                fontSize: '0.7rem',
                                color: '#4f0060',
                              }}>
                              {/*<StyledPauseIcon />*/}
                              <span>Download as Excel</span>
                            </p>
                          </button>
                        </div>
                        <TableWrapper>
                          <Table
                            style={{
                              border: '1px solid rgba(0,0,0,0.1)',
                              marginTop: 10,
                            }}>
                            <TableHead>
                              <CustomTableRow>
                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    Name
                                  </TableSortLabel>
                                </CustomTableCell>

                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    Employee ID
                                  </TableSortLabel>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    E-mail Address
                                  </TableSortLabel>
                                </CustomTableCell>

                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    Manager Name
                                  </TableSortLabel>
                                </CustomTableCell>

                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    Manager Email
                                  </TableSortLabel>
                                </CustomTableCell>

                                <CustomTableCell align="center">
                                  <TableSortLabel hideSortIcon>
                                    {
                                      managerFeedbackSubmissionActiveQuestionText
                                    }
                                  </TableSortLabel>
                                </CustomTableCell>
                              </CustomTableRow>
                            </TableHead>
                            <TableBody>
                              {batchLearners.map((row) => {
                                return (
                                  <CustomTableRow>
                                    <CustomTableCell align="center">
                                      <span>
                                        {`${row.demographics.first_name} `}
                                        {row.demographics.last_name}
                                      </span>
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      {row.demographics.employee_id}
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      <span
                                        style={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                        }}>
                                        {row.demographics.email}
                                      </span>
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      <span
                                        style={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                        }}>
                                        {row.mastero_details.supervisor
                                          ? row.mastero_details.supervisor
                                          : ''}
                                      </span>
                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                      <span
                                        style={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                        }}>
                                        {row.mastero_details.segment_head
                                          ? row.mastero_details.segment_head
                                          : ''}
                                      </span>
                                    </CustomTableCell>

                                    <CustomTableCell>
                                      {get_manager_feedback_by_learner(row.id)}
                                    </CustomTableCell>
                                  </CustomTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableWrapper>
                      </div>
                    ) : (
                      <div>
                        <p style={{ fontSize: '0.9rem', padding: '30px' }}>
                          No Manager Feedback Forms are added for this event.
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
                {/* End Course */}

                {tabValue === 4 &&
                  selectedEvent !== null &&
                  selectedTraining !== null ? (
                  <div style={{ padding: 30, textAlign: 'left' }}>
                    <ChecklistItem>
                      <img
                        alt="Done"
                        src={GreenTick}
                        style={{
                          display: 'block',
                          marginRight: '5px',
                          width: '10px',
                        }}
                      />

                      <ChecklistItemText>
                        Course attendance submitted
                      </ChecklistItemText>
                    </ChecklistItem>

                    <ChecklistItem>
                      <img
                        alt="Done"
                        src={GreenTick}
                        style={{
                          display: 'block',
                          marginRight: '5px',
                          width: '10px',
                        }}
                      />
                      <ChecklistItemText>
                        Course feedback submitted
                      </ChecklistItemText>
                    </ChecklistItem>

                    <ChecklistItem>
                      <img
                        alt="Done"
                        src={GreenTick}
                        style={{
                          display: 'block',
                          marginRight: '5px',
                          width: '10px',
                        }}
                      />

                      <ChecklistItemText>
                        Course reviews submitted
                      </ChecklistItemText>
                    </ChecklistItem>

                    <FlatPrimaryButton onClick={() => lock_training_session()}>
                      Close Batch
                    </FlatPrimaryButton>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}

        {/* Add Observation Dialog */}

        <StyledDialog
          open={addObservationDialog}
          onClose={() => {
            setAddObservationDialog(false);
          }}>
          <div style={{ width: '800px' }}>
            <ObservationDialogTopBar>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ObservationDialogUserTitle>
                  {typeof activeBatchLearnerData !== 'undefined' &&
                    typeof activeBatchLearnerData.demographics !== 'undefined'
                    ? activeBatchLearnerData.demographics.first_name +
                    ' ' +
                    activeBatchLearnerData.demographics.last_name
                    : ''}
                </ObservationDialogUserTitle>
              </div>
              <div style={{ marginLeft: '25px' }}>
                <ObservationDialogKey>Employee ID</ObservationDialogKey>
                <ObservationDialogValue>
                  {activeBatchLearnerData.demographics.employee_id}
                </ObservationDialogValue>
              </div>

              <div style={{ marginLeft: '25px' }}>
                <ObservationDialogKey>E-mail Address</ObservationDialogKey>
                <ObservationDialogValue>
                  {activeBatchLearnerData.demographics.email_id}
                </ObservationDialogValue>
              </div>
            </ObservationDialogTopBar>

            <ObservationDialogBody>
              <TableWrapper>
                <Table
                  style={{
                    border: '1px solid rgba(0,0,0,0.1)',
                    marginTop: 30,
                  }}>
                  <TableHead>
                    <CustomTableRow>
                      <CustomTableCell align="center">
                        <TableSortLabel hideSortIcon>
                          Step to be taken
                        </TableSortLabel>
                      </CustomTableCell>

                      <CustomTableCell align="center">
                        <TableSortLabel hideSortIcon>
                          Action to be taken
                        </TableSortLabel>
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        <TableSortLabel hideSortIcon>
                          Action status
                        </TableSortLabel>
                      </CustomTableCell>

                      <CustomTableCell align="center">
                        <StyledTabelHeaderLabel hideSortIcon>
                          Max. Points
                        </StyledTabelHeaderLabel>
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        <StyledTabelHeaderLabel hideSortIcon>
                          Points Given
                        </StyledTabelHeaderLabel>
                      </CustomTableCell>
                    </CustomTableRow>
                  </TableHead>
                  <TableBody>
                    {typeof observation_forms[0] !== 'undefined' &&
                      observation_forms[0].hasOwnProperty('form_data')
                      ? observation_forms[0].form_data.map((row, index) => (
                        <CustomTableRow key={row.id}>
                          <CustomTableCell align="center">
                            <span>{row.observation_step}</span>
                          </CustomTableCell>

                          <CustomTableCell align="center">
                            {row.observation_action}
                          </CustomTableCell>

                          <CustomTableCell align="center">
                            <SimpleFormSelect
                              width="130px"
                              options={[
                                {
                                  id: 'not_performed',
                                  display_text: 'Not Performed',
                                },
                                {
                                  id: 'performed',
                                  display_text: 'Performed',
                                },
                              ]}
                              autoClose
                              onSelect={(id) => {
                                let copy = [...activeObservationData];
                                copy[index].action_status = id;
                                setActiveObservationData(copy);
                              }}
                              text={
                                typeof activeObservationData[index] !==
                                  'undefined'
                                  ? activeObservationData[index]
                                    .action_status === 'performed'
                                    ? 'Performed'
                                    : 'Not Performed'
                                  : ''
                              }
                            />
                          </CustomTableCell>

                          <CustomTableCell align="center">
                            <SpanPoints>
                              {row.observation_max_points}
                            </SpanPoints>
                          </CustomTableCell>

                          <CustomTableCell align="center">
                            <SpanPointsHalf>
                              <TextInput
                                width="30px"
                                value={
                                  typeof activeObservationData[index] !==
                                    'undefined'
                                    ? activeObservationData[index]
                                      .observation_points
                                    : 0
                                }
                                onChangeText={(text) => {
                                  if (isNaN(text)) {
                                    return;
                                  }

                                  let copy = [...activeObservationData];
                                  copy[index].observation_points = text;
                                  setActiveObservationData(copy);
                                }}
                              />
                            </SpanPointsHalf>
                          </CustomTableCell>
                        </CustomTableRow>
                      ))
                      : null}
                  </TableBody>
                </Table>
              </TableWrapper>

              <div style={{ height: 30, width: '100%' }} />

              <FormControl>
                <Label>Additional Trainer Remarks:</Label>
                <TextArea
                  text={activeObservationRemarks}
                  onChangeText={(text) => {
                    setActiveObservationRemarks(text);
                  }}></TextArea>
              </FormControl>

              <FlatPrimaryButton onClick={() => addObservation()}>
                Save Observation
              </FlatPrimaryButton>

              <Button2
                style={{ marginLeft: 10 }}
                transparent
                onClick={() => setAddObservationDialog(false)}>
                Close
              </Button2>
            </ObservationDialogBody>
          </div>
        </StyledDialog>

        {/* Edit Observation Dialog */}
        <StyledDialog
          open={editObservationDialog}
          onClose={() => {
            setEditObservationDialog(false);
          }}>
          <div style={{ width: '800px' }}>
            <ObservationDialogTopBar>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ObservationDialogUserTitle>
                  {typeof activeBatchLearnerData !== 'undefined' &&
                    typeof activeBatchLearnerData.demographics !== 'undefined'
                    ? activeBatchLearnerData.demographics.first_name +
                    ' ' +
                    activeBatchLearnerData.demographics.last_name
                    : ''}
                </ObservationDialogUserTitle>
              </div>
              <div style={{ marginLeft: '25px' }}>
                <ObservationDialogKey>Employee ID</ObservationDialogKey>
                <ObservationDialogValue>
                  {activeBatchLearnerData.demographics.employee_id}
                </ObservationDialogValue>
              </div>

              <div style={{ marginLeft: '25px' }}>
                <ObservationDialogKey>E-mail Address</ObservationDialogKey>
                <ObservationDialogValue>
                  {activeBatchLearnerData.demographics.email_id}
                </ObservationDialogValue>
              </div>
            </ObservationDialogTopBar>

            <ObservationDialogBody>
              <TableWrapper>
                <Table
                  style={{
                    border: '1px solid rgba(0,0,0,0.1)',
                    marginTop: 30,
                  }}>
                  <TableHead>
                    <CustomTableRow>
                      <CustomTableCell align="center">
                        <TableSortLabel hideSortIcon>
                          Step to be taken
                        </TableSortLabel>
                      </CustomTableCell>

                      <CustomTableCell align="center">
                        <TableSortLabel hideSortIcon>
                          Action to be taken
                        </TableSortLabel>
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        <TableSortLabel hideSortIcon>
                          Action status
                        </TableSortLabel>
                      </CustomTableCell>

                      <CustomTableCell align="center">
                        <StyledTabelHeaderLabel hideSortIcon>
                          Max. Points
                        </StyledTabelHeaderLabel>
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        <StyledTabelHeaderLabel hideSortIcon>
                          Points Given
                        </StyledTabelHeaderLabel>
                      </CustomTableCell>
                    </CustomTableRow>
                  </TableHead>
                  <TableBody>
                    {typeof observation_forms[0] !== 'undefined' &&
                      observation_forms[0].hasOwnProperty('form_data')
                      ? observation_forms[0].form_data.map((row, index) => (
                        <CustomTableRow key={row.id}>
                          <CustomTableCell align="center">
                            <span>{row.observation_step}</span>
                          </CustomTableCell>

                          <CustomTableCell align="center">
                            {row.observation_action}
                          </CustomTableCell>

                          <CustomTableCell align="center">
                            <SimpleFormSelect
                              width="130px"
                              options={[
                                {
                                  id: 'not_performed',
                                  display_text: 'Not Performed',
                                },
                                {
                                  id: 'performed',
                                  display_text: 'Performed',
                                },
                              ]}
                              autoClose
                              onSelect={(id) => {
                                let copy = [...activeObservationData];
                                copy[index].action_status = id;
                                setActiveObservationData(copy);
                              }}
                              text={
                                typeof activeObservationData[index] !==
                                  'undefined'
                                  ? activeObservationData[index]
                                    .action_status === 'performed'
                                    ? 'Performed'
                                    : 'Not Performed'
                                  : ''
                              }
                            />
                          </CustomTableCell>

                          <CustomTableCell align="center">
                            <SpanPoints>
                              {row.observation_max_points}
                            </SpanPoints>
                          </CustomTableCell>

                          <CustomTableCell align="center">
                            <SpanPointsHalf>
                              <TextInput
                                width="30px"
                                value={
                                  typeof activeObservationData[index] !==
                                    'undefined'
                                    ? activeObservationData[index]
                                      .observation_points
                                    : 0
                                }
                                onChangeText={(text) => {
                                  if (isNaN(text)) {
                                    return;
                                  }

                                  let copy = [...activeObservationData];
                                  copy[index].observation_points = text;
                                  setActiveObservationData(copy);
                                }}
                              />
                            </SpanPointsHalf>
                          </CustomTableCell>
                        </CustomTableRow>
                      ))
                      : null}
                  </TableBody>
                </Table>
              </TableWrapper>

              <div style={{ height: 30, width: '100%' }} />

              <FormControl>
                <Label>Additional Trainer Remarks:</Label>
                <TextArea
                  text={activeObservationRemarks}
                  onChangeText={(text) => {
                    setActiveObservationRemarks(text);
                  }}></TextArea>
              </FormControl>

              <FlatPrimaryButton onClick={() => saveObservation()}>
                Save Observation
              </FlatPrimaryButton>

              <Button2
                style={{ marginLeft: 10 }}
                transparent
                onClick={() => setEditObservationDialog(false)}>
                Close
              </Button2>
            </ObservationDialogBody>
          </div>
        </StyledDialog>
      </TopBarWrapper>

      {/* Progress Dialog */}
      <Dialog disableBackdropClick disableEscapeKeyDown open={progressDialog}>
        <ProgressContainer>
          <Loader fill="#f4511e" />

          <ProgressText>Please wait...</ProgressText>
        </ProgressContainer>
      </Dialog>

      <Dialog
        open={successDialog}
        onClose={() => {
          setSuccessDialog(false);
        }}>
        <ProgressContainer>
          <img src={SuccessTick} style={{ width: '80px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{successDialogText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setSuccessDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>
      <Dialog
        open={failureDialog}
        onClose={() => {
          setFailureDialog(false);
        }}>
        <ProgressContainer>
          <img src={FailureCross} style={{ width: '80px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{failureText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setFailureDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      <Dialog
        open={download_xls_dialog_state}
        onClose={(e) => {
          set_download_xls_dialog_state(false);
        }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px', textAlign: 'center' }}>
              <Loader fill="#4f0060" />
              <p>Generating and Downloading...</p>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

async function fetch_feedback_forms(MOClient, token, course_id, event_id) {
  const action = await MOClient.get_feedback_forms_for_event({
    event_id,
    token,
    course_id,
  });

  return action;
}

async function fetch_event_observation_status(
  MOClient,
  token,
  training_session_id,
  event_id,
) {
  const action = await MOClient.get_event_observation_status({
    event_id,
    token,
    training_session_id,
  });

  return action;
}

const DateText = styled.h4`
  opacity: 0.5;
  font-family: 'Open Sans' !important;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  padding: 0;
  text-align: left;
  color: #919191;
`;

const SmallDateText = styled.h5`
  opacity: 0.7;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  margin: 15px 0;
  text-align: left;
  color: #919191;
`;

const CustomTableCell = withStyles((theme) => ({
  root: {
    padding: '10px 10px !important',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '12px',
    color: '#707070',
    textAlign: 'left',
  },
  head: {
    backgroundColor: '#fbfbfb',
    color: '#484848',
    fontWeight: 'bold',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '11px',
  },
}))(TableCell);

const PresentBox = styled.div`
  width: 69px;
  margin: 8px 0;
  height: 18px;
  border-radius: 2px;
  background-color: #00cc2e;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PresentBoxLabel = styled.p`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.3px;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  user-select: none;
`;

const AbsentBox = styled.div`
  width: 69px;
  height: 18px;
  border-radius: 2px;
  background-color: #f63b34;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AbsentBoxLabel = styled.p`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.3px;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  user-select: none;
`;

const CustomTableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
  },
  head: {
    display: 'flex',
  },
}))(TableRow);

const StyledTabelHeaderLabel = styled(TableSortLabel)`
  display: block;
  margin: auto;
`;

const TableWrapper = styled.div`
  min-width: 250px;
  width: 100%;
  overflow: auto;
`;

const CustomTabs = styled(Tabs)`
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 5px;
  background-color: white;
  z-index: 99999;

  .MuiTabs-indicator {
    height: 3px;
  }
`;

const CustomTab = styled(Tab)`
  text-transform: initial !important;
  font-family: Montserrat !important;
  font-weight: 600 !important;
  color: #484848;
  width: 236px;
`;

const WriteObservation = styled.span`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 5;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  text-align: center;
`;

const ObservationDialogTopBar = styled.div`
  width: 100%;
  height: 70px;
  background-color: #efefef;
  display: flex;
  align-items: center;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
`;

const ObservationDialogUserTitle = styled.h3`
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  margin: 0;
  padding-left: 25px;
`;

const ObservationDialogKey = styled.h4`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
`;

const ObservationDialogValue = styled.h4`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
`;

const ObservationDialogBody = styled.div`
  background-color: white;
  padding: 30px;
`;

const SpanPoints = styled.span`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 5;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  width: 100%;
  text-align: center;
`;

const SpanPointsHalf = styled.span`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 5;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  text-align: center;
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const ChecklistItem = styled.div`
  display: flex;
  align-items: center;
`;

const ChecklistItemText = styled.h5`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #43425d;
  padding-left: 8px;
`;
