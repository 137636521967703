import React from "react";

function Sort(props) {
  let fill = "#FFF";

  if(props.hasOwnProperty("fill")) {
    fill = props.fill;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.034" height="10.918" viewBox="0 0 6.034 10.918">
      <defs>
        <style>
          {`.cls-1a{fill:#f4511e; opacity:1}`}
        </style>
      </defs>
      <g id="mastero_chevron_sort" transform="translate(11817.281 1255.37)">
        <path id="Path_5269"
              d="M5.89 82.66l-.29-.29a.5.5 0 0 0-.352-.143.474.474 0 0 0-.349.143l-1.882 1.882-1.882-1.883a.475.475 0 0 0-.349-.143.5.5 0 0 0-.352.143l-.287.29a.481.481 0 0 0-.147.353.46.46 0 0 0 .147.349l2.521 2.521a.46.46 0 0 0 .349.147.481.481 0 0 0 .352-.147l2.521-2.521a.474.474 0 0 0 .143-.349.5.5 0 0 0-.143-.352z"
              className="cls-1a" data-name="Path 5269" transform="translate(-11817.281 -1330.482)"/>
        <path id="Path_5482"
              d="M5.891 85.6l-.29.29a.5.5 0 0 1-.352.143.475.475 0 0 1-.349-.143L3.017 84l-1.882 1.886a.474.474 0 0 1-.349.143.5.5 0 0 1-.352-.143L.147 85.6A.481.481 0 0 1 0 85.243a.46.46 0 0 1 .147-.349l2.521-2.521a.46.46 0 0 1 .349-.147.481.481 0 0 1 .352.147l2.521 2.521a.474.474 0 0 1 .143.349.5.5 0 0 1-.142.357z"
              className="cls-1a" data-name="Path 5482" transform="translate(-11817.281 -1337.596)"/>
      </g>
    </svg>
  )
}

export default Sort;