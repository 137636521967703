import React from 'react';

function Forms(props) {
  let fill = '#FFF';

  if (props.hasOwnProperty('fill')) {
    fill = props.fill;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.48"
      height="11.459"
      viewBox="0 0 14.48 11.459">
      <defs>
        <style>{`.a{fill:#f4511e;} `}</style>
      </defs>
      <path
        className="a"
        d="M6,30.035H4.962V34l-1.553-.884L1.855,34v-3.96H0v8.7H6a1.65,1.65,0,0,1,.813.213v-8.1A.814.814,0,0,0,6,30.035Zm0,0"
        transform="translate(0 -29.186)"
      />
      <path
        className="a"
        d="M271,368.481v.248h6.816v-1.061h-6A.814.814,0,0,0,271,368.481Zm0,0"
        transform="translate(-263.336 -357.27)"
      />
      <path
        className="a"
        d="M6,367.668H0v1.061H6.816v-.248A.814.814,0,0,0,6,367.668Zm0,0"
        transform="translate(0 -357.27)"
      />
      <path
        className="a"
        d="M271.813,30.035a.814.814,0,0,0-.813.813v8.1a1.65,1.65,0,0,1,.813-.213h6v-8.7Zm5,7.321h-4.8v-.848h4.8Zm0-1.7h-4.8V34.81h4.8Zm0-1.7h-4.8v-.848h4.8Zm0-1.7h-4.8v-.848h4.8Zm0,0"
        transform="translate(-263.336 -29.186)"
      />
      <path
        className="a"
        d="M95.57,3.351l.705-.4.705.4V0H95.57Zm0,0"
        transform="translate(-92.867)"
      />
    </svg>
  );
}

export default Forms;
