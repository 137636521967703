import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';
import lodash_orderBy from 'lodash/orderBy.js';

const CustomTableCell = withStyles((theme) => ({
  root: {
    borderRight: '1px solid rgb(224, 224, 224)',
    padding: '10px !important',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '0',
    flexGrow: '1',
  },
  head: {
    backgroundColor: '#f6f6f6',
    color: '#080037',
    fontWeight: 'bold',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '0',
    flexGrow: '1',
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
  },
  head: {
    display: 'flex',
  },
}))(TableRow);

let MOHeaderItems = function (props) {
  let table_header_items = props.headerItems;
  let { order, orderBy, createSortHandler } = props;

  let handleClick = function (id) {
    createSortHandler(id);
  };

  let layout_header_items = table_header_items.map((item) => {
    //let show_drop_arrow = item.id === orderBy ? true : false;

    return (
      <CustomTableCell
        align="center"
        sortDirection={item.id === orderBy ? order : false}
        key={item.name}>
        <TableSortLabel
          active={orderBy === item.id}
          direction={order}
          onClick={() => handleClick(item.id)}>
          {item.name}
        </TableSortLabel>
      </CustomTableCell>
    );
  });

  if (table_header_items.length > 0) {
    return (
      <TableHead>
        <CustomTableRow>{layout_header_items}</CustomTableRow>
      </TableHead>
    );
  } else {
    return (
      <TableHead>
        <CustomTableRow>
          <CustomTableCell align="center">...</CustomTableCell>
        </CustomTableRow>
      </TableHead>
    );
  }
};

let MOTableBody = function (props) {
  let { rows, renderKeys } = props;

  if (rows.length === 0)
    return (
      <p style={{ textAlign: 'center', fontSize: '0.85rem', color: '#707070' }}>
        No data available at the moment, please try again later!
      </p>
    );

  return (
    <TableBody>
      {rows.map((row) => (
        <CustomTableRow key={row.id}>
          {renderKeys.map((key) => {
            let assessmentColumn = key.onClick && row.assessment != 'NA';
            return <CustomTableCell
              onClick={assessmentColumn ? () => key.onClick(row) : null}
              align="center"
              style={assessmentColumn ? { textDecoration: 'underline', color: '#f4511e', cursor: 'pointer' } : {}}
              key={key.render_key}>
              {row[key.render_key]}
              {key.suffix}
            </CustomTableCell>;
          })}
        </CustomTableRow>
      ))}
    </TableBody>
  );
};

let MOTable2 = function (props) {
  let order_default = 'desc';
  let order_by_default = 2;

  if (props.hasOwnProperty('order')) {
    order_default = props.order;
  }

  if (props.hasOwnProperty('orderByColumn')) {
    order_by_default = props.orderByColumn;
  }

  let [order, setOrder] = useState(order_default);
  let [orderBy, setOrderBy] = useState(order_by_default);

  let { headerItems, renderKeys, rows } = props;

  let handleRequestSort = (id) => {
    const orderBy = id;

    if (order === 'desc') {
      setOrder('asc');
    } else setOrder('desc');

    setOrderBy(orderBy);
  };

  React.useEffect(() => {
    setOrderBy(order_by_default);
    setOrder(order_default);
  }, [renderKeys]);

  let sorted_table = rows;

  if (orderBy !== -1) sorted_table = lodash_orderBy(rows, renderKeys[orderBy]['render_key'], [order]);


  return (
    <Table style={{display:'table-header-group'}}>
      <MOHeaderItems
        headerItems={headerItems}
        order={order}
        orderBy={orderBy}
        createSortHandler={handleRequestSort}></MOHeaderItems>
      <MOTableBody rows={sorted_table} renderKeys={renderKeys}></MOTableBody>
    </Table>
  );
};

export default MOTable2;
