import { makeStyles } from '@material-ui/styles';
import { wrap } from 'lodash';
import styled from 'styled-components';
import { HeadingLabel } from '../../components/Label';
import { StyledTextArea } from '../../components/TextArea';
import { StyledInput } from '../../components/TextInput';

const styles = makeStyles({
  top_bar_wrapper: {
    position: 'sticky',
    top: '0',
    zIndex: 999,
  },
  scrollFeed: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    flexWrap: 'wrap',
    overflow: 'auto',
    height: '97%',
    width: '100%',
  },
  top_bar: {
    backgroundColor: '#fcfcfc',
    padding: '10px 30px 10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // height: '67px',
    zIndex: 999,
  },
  top_bar_heading: {
    fontWeight: 700,
    fontSize: '0.85rem',
    color: '#484848',
    margin: 0,
    textAlign: 'left',
    padding: '10px 0',
  },

  headingIconsStyle: {
    marginRight: '5px',
    color: '#707070',
    fontSize: '1rem',
  },
  container: {
    backgroundColor: '#EAEAEA',
    // marginTop: '27px',
    // marginLeft: 0,
    // marginRight: 0,
    // // ipad air width
    // ['@media (max-width:820px)']: {
    //   marginLeft: '0',
    //   marginRight: '0',
    // },
    // height: '91.43%',
  },
  content_bar_container: {
    backgroundColor: '#fff',
    height: '84px',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    // paddingLeft: '17px',
    paddingRight: '25px',
  },
  contentbar_top: {
    height: '53px',
    paddingLeft: '15px',
    paddingRight: '25px',
    justifyContent: 'flex-start',
  },
  contentbar_Right: {
    height: '53px',
    paddingRight: '15px',
    paddingLeft: '25px',
    justifyContent: 'flex-end',
  },

  contentbar_bottom: {
    backgroundColor: '#fff',
    height: '53px',
    justifyContent: 'space-between',
    paddingLeft: '17px',
    paddingRight: '25px',
  },
  heading_container: {
    height: '74px',
    backgroundColor: '#F6F6F6',
    // marginTop: '15px',
    paddingLeft: '41px',
    ['@media (max-width:780px)']: {
      paddingLeft: '15px',
    },
    paddingRight: '25px',
    display: 'flex',
    // justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  challangeContainer: {
    height: '70.89vh',
    backgroundColor: '#fff',
    paddingLeft: '41px',
    paddingRight: 'max(41px, 5.597%)',
    ['@media (max-width:780px)']: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    justifyContent: 'space-between',
  },
  heading_title_style: {
    width: 'calc((100% - 264px)/2)',
    ['@media (max-width:780px)']: {
      width: 'calc((100% - 264px)/1)',
    },
  },
  keywordContainer: {
    height: '70.89vh',
    backgroundColor: '#fff',
    paddingLeft: '41px',
    paddingRight: 'max(41px, 5.597%)',
    ['@media (max-width:780px)']: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    justifyContent: 'space-between',
  },
  leftContainer: {
    height: '100%',
    width: '50%',
    maxWidth: '500px',
    display: 'flex',
    alignContent: 'space-between',
    flexWrap: 'wrap',
    overflow: 'hidden',
    paddingRight: 'max(70px, 0.478%)',
    ['@media (max-width:780px)']: {
      paddingRight: '0',
      width: '100%',
    },
    ['@media (max-width:1024px)']: {
      paddingRight: 'min(40px, 0.478%)',
    },
  },
  leftPage3Container: {
    height: '100%',
    width: '60%',
    //maxWidth: '500px',
    display: 'flex',
    alignContent: 'space-between',
    flexWrap: 'wrap',
    overflow: 'hidden',
    // paddingRight: 'max(70px, 0.478%)',
    ['@media (max-width:780px)']: {
      paddingRight: '0',
      width: '100%',
    },
    ['@media (max-width:1024px)']: {
      paddingRight: 'min(40px, 0.478%)',
    },
  },
  leftTopContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    // height: '95%',
    ['@media (max-height:720px)']: {
      height: '85%',
    },
    paddingRight: '20px',
  },
  smTranscriptContainer: {
    width: '100%',
    ['@media (min-width:780px)']: {
      display: 'none',
    },
  },
  rightpage3Container: {
    height: '100%',
    width: '40%',
    ['@media (max-width:780px)']: {
      display: 'none',
    },
  },
  rightContainer: {
    height: '100%',
    width: '60%',
    ['@media (max-width:780px)']: {
      display: 'none',
    },
  },
  box: {
    marginTop: '42px',
    height: '85%',
  },
  withoutBorderBox: {
    border: 'none !important',
    marginTop: '42px',
    height: '85%',
  },
  half_Width: {
    width: '50%',
  },
  switch_action_container: {
    width: '100%',
  },

  full_Width: {
    width: '100%',
  },
  leftMargin_10: {
    marginLeft: '10px',
  },
  switch_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '215px',
  },
  action_btn_container: {
    paddingLeft: '41px',
    display: 'flex',
    width: '100%',
    background: '#fff',
    position: 'fixed',
    bottom: 0,
    ['@media (max-width:780px)']: {
      paddingLeft: '15px',
    },
  },
  keyword_action_btn: {
    // position: 'fixed',
    background: '#fff',
    // bottom: '30px',
  },
  keyword_next_btn: {
    minWidth: '20%',
    ['@media (max-width:780px)']: {
      minWidth: '40%',
    },
    width: '20%',
    padding: '15px 30px',
  },
  save_btn_container: { marginLeft: '20px' },
  save_btn_style: { padding: '15px 30px' },
  stepTableStyle: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    height: '32px',
  },
  stepItemsStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  stepStyle: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    borderWidth: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
  },
  competeStepStyle: {
    color: '#F4511E',
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  progressPathContainer: {
    minWidth: '50px',
    ['@media (min-width:768)']: {
      minWidth: '60px',
    },
    height: '2px',
    background: '#fff',
    maxWidth: '89px',
    marginLeft: 0,
    marginRight: 0,
    alignSelf: 'center',
    display: 'flex',
  },
  learnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ['@media (max-width:1066px)']: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  learnerAssistContainer: {
    width: '50%',
    ['@media (max-width:1066px)']: {
      width: '100%',
    },
  },
  learnerPracticeContainer: {
    width: '50%',
    marginLeft: 10,
    ['@media (max-width:1066px)']: {
      width: '100%',
      marginLeft: 0,
    },
  },
  toolTipStyle: {
    font: 'normal normal normal 12px Montserrat',
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    opacity: 1,
    height: '20px',
  },
  criteriaMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    ['@media (max-width:820px)']: {
      //TODO: make it column and find out the solution
      flexDirection: 'row',
    },
  },
  descriptionStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    alignItems: 'flex-start',
    marginTop: 50,
    ['@media (max-width:820px)']: {
      width: '100%',
      marginTop: 0,
    },
  },
  criteriaLeftContainerStyle: {
    height: '90%',
    width: '45%',
    ['@media (max-width:820px)']: {
      width: '100%',
    },
  },
  criteriaRightContainerStyle: {
    height: '90%',
    width: '55%',
    justifyContent: 'center',
    display: 'flex',
    ['@media (max-width:820px)']: {
      width: '100%',
    },
  },
});

export const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Box = styled.div`
  width: 100%;
  max-height: ${(props) =>
    props.height >= 730 ? 390 : (props.height * 390) / 730 - 70}px;
  min-height: 200px;
  ${'' /* background: transparent linear-gradient(180deg, #00000066 0%, #000000a6 100%)
    0% 0% no-repeat padding-box; */}
  border: 2px dashed #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  opacity: 0.66;
`;

export const TranscriptHeading = styled(HeadingLabel)`
  text-align: center;
  color: #bcbcbc;
  font-size: 1.05rem;
  ${'' /* font: normal normal bold 40px/82px Open Sans; */}
  margin-top: 4px;
  margin-bottom: 24px;
  margin-left: 15px;
  margin-right: 15px;
`;

export const TranscriptTextArea = styled(StyledTextArea)`
  width: 70%;
  height: 100%;
  minheight: 100%;
  margin-bottom: 30px;
  background-color: transparent;
  font-size: 0.65rem;
  resize: none;
  color: #fff;
`;

export const KeywordInput = styled(StyledInput)`
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  font-weight: bold;
  font-family: 'Montserrat';
  color: #080037;
  padding-left: 0;
  type: text;
`;
export default styles;
