import React from 'react';
import CustomOptions from './CustomOptions';
import styled from 'styled-components';
import ClearButton from './ClearButton';
import PlusIcon from './MDLIcon/icons/Plus';
import TextInput from './TextInput';

const StyledGenericButton = styled(ClearButton)`
  margin-top: 15px;
  color: #4f0060;
  padding: 0;
  font-size: 10px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  > #mastero_icon_add .cls-plus-icon {
    fill: #f4511e !important;
  }
`;

const NumericOptionsQuestion = ({ index, question, options, onChangeText, onChange, onClick }) => {
  return (
    <div>
      <TextInput
        tabIndex={index}
        value={question || ''}
        onChangeText={onChangeText}
      />

      <div style={{ marginTop: 10 }} />

      <div>
        <CustomOptions options={options} onChange={onChange} type="number" />

        <StyledGenericButton onClick={onClick}>
          <div style={{ marginRight: 5, display: 'flex' }}>
            <StyledPlusIcon />
          </div>
          Add New Option
        </StyledGenericButton>
      </div>
    </div>
  );
};

export default NumericOptionsQuestion;
