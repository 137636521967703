import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayUtils from '@date-io/dayjs';
import ClockIcon from '../../components/MDLIcon/icons/Clock';
import { makeStyles } from '@material-ui/core';

const custom_styles = makeStyles({
  icon: {
    height: '25px',
    width: '25px',
  },
});

export default function FormTimePicker(props) {
  let styles = custom_styles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (time) => {
    props.onChange(time);
  };

  return (
    <ComponentWrapper>
      <Label>{props.label}</Label>
      <SelectorWrapper>
        <StyledButtonBase onClick={handleClick}>
          <StyledFlexFirst>
            <StyledClockIcon className={styles.icon} />
            <Text>{props.text}</Text>
          </StyledFlexFirst>
        </StyledButtonBase>
      </SelectorWrapper>
      <div style={{ display: 'none' }}>
        <MuiPickersUtilsProvider utils={DayUtils}>
          <TimePicker
            open={open}
            label="Basic example"
            value={props.value}
            onChange={handleChange}
            onClose={handleClose}
          />
        </MuiPickersUtilsProvider>
      </div>
    </ComponentWrapper>
  );
}

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  color: #707070;
  font-size: 0.75rem;
  font-family: 'Montserrat';
`;

const SelectorWrapper = styled.div`
  border: 0.5px solid #dde0e2;

  max-width: 250px;
  height: 71px;
`;

const StyledButtonBase = styled(ButtonBase)`
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
`;

const Text = styled.span`
  font-family: 'Montserrat';
  font-weight: 600;
  padding-left: 10px;
  font-size: 1rem;
  color: #707070;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  display: block;
`;

const StyledFlexFirst = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  height: 40px;
  width: 100%;
`;

const StyledClockIcon = styled(ClockIcon)`
  .class-clock-icon__cls-1 {
    fill: none;
    opacity: 0.3;
  }

  .class-clock-icon__cls-2 {
    fill: #707070;
  }
`;
