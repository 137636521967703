import React from 'react';
import styled from 'styled-components';
import TextInput from "../../components/TextInput";
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import SimpleFormSelect from "../../components/SimpleFormSelect";
import Button2 from '../../components/Button2';

export default function AddCourseEvent(props) {
  return (
    <ComponentWrapper>
      <FormControl>
        <Label>Event Name:</Label>
        <TextInput
          value={props.eventName}
          onChangeText={props.onChangeEventName}
        />
      </FormControl>

      <FormControl>
        <Label>Select Event Modules:</Label>
        <SimpleFormSelect
          multiple={true}
          options={props.moduleOptions}
          onSelect={props.onModuleSelect}
          selected={props.modulesSelected}
          text={props.selectedModulesText}
        />
      </FormControl>

      <FormControl>
        <Label>Training Hours:</Label>
        <SimpleFormSelect
          options={props.trainingHoursOptions}
          onSelect={props.onTrainingHoursSelect}
          selected={props.trainingHoursSelected}
          text={props.trainingHoursText}
          autoClose
        />

      </FormControl>
      <FormControl>
        <Actions>
          <Button2 onClick={props.onAddEvent}>Save Course Event</Button2>
          <Button2 onClick={props.onDelete} transparent={true} style={{marginLeft: "5px"}}>Close</Button2>
        </Actions>
      </FormControl>

    </ComponentWrapper>
  )
};

const ComponentWrapper = styled.div`
  padding: 20px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;