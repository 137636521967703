import {
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FormControl from '../../components/FormControl';
import StyledLabel from '../../components/Label';
import MOClient from '../../MOClient';
import FormDelivery from './FormDelivery';
import FormFilters from './FormFilters';
import TextArea from '../../components/TextArea';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FlatClearButton from '../../components/FlatClearButton';

import { CSVReader } from 'react-papaparse';
import StyledDownloadLink from '../../components/StyledDownloadLink';

const useStyles = makeStyles({
  container: {
    marginTop: '50px',
  },
});

const targetTypes = ['project', 'csv'];

const FormTargetting = ({
  onBack,
  target,
  formMode,
  setTarget,
  startDate,
  endDate,
  delivery,
  setDelivery,
  onSchedule,
  updateForm,
  learners,
  notificationContent,
  setNotificationContent,
  setUsersList,
  onHandleRadioChange,
  radioValue,
}) => {
  const classes = useStyles();
  const [demographicValues, setDemographicValues] = useState(null);

  const handleTargetChange = (target) => {
    setTarget(target);
  };

  const handleOnDrop = (data) => {
    const usersList = [];
    data.filter((v, i) => {
      if (v.data[0] === null || v.data[0] === '' || i === 0) {
        return false;
      } else {
        usersList.push(v.data[0]);
        return true;
      }
    });
    setUsersList(usersList);
  };

  const handleOnError = (e) => {
    console.log(e);
  };

  const handleOnRemoveFile = (e) => {
    console.log(e);
  };

  const companyInfo = JSON.parse(localStorage.getItem('company-info'));

  const companyId = localStorage.getItem('company-id');
  const projects = companyInfo.projects;
  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    MOClient.get_unique_demographics(token, companyId).then(({ data }) => {
      const demographics =
        (companyInfo && companyInfo.meta && companyInfo.meta.demographics) ||
        [];
      console.log({ demographics });
      const filteredData = [
        {
          name: 'Project',
          key: 'proj_id',
          values: projects.map((project) => project.name),
        },
      ];

      for (let demographic of demographics) {
        if (
          data[demographic.mapping_key] &&
          data[demographic.mapping_key].length > 0
        ) {
          const newData = {
            name: demographic.name,
            key: demographic.mapping_key,
            values: data[demographic.mapping_key],
          };
          filteredData.push(newData);
        }
      }
      setDemographicValues(filteredData);
    });
  }, []);
  const editable = formMode === 'EDIT_FORM';

  return (
    <>
      <hr className={classes.container} />
      <div>
        {!editable && (
          <FormControl>
            <Grid container style={{ width: '500px' }} alignItems="center">
              <Grid item sm={3}>
                <StyledLabel style={{ padding: '0px' }}>Targeting:</StyledLabel>
              </Grid>
              <Grid item sm={9}>
                <RadioGroup value={radioValue} onChange={onHandleRadioChange}>
                  <Grid direction="row" container alignItems="center">
                    <Grid item sm={6}>
                      <FormControlLabel
                        value="project"
                        label="Project-Wise"
                        control={<Radio />}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="csv"
                        label="CSV"
                        control={<Radio />}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          </FormControl>
        )}
        {!editable && (
          <>
            {' '}
            {radioValue !== targetTypes[0] && (
              <StyledDownloadLink
                href="https://mastero-drive.s3.ap-south-1.amazonaws.com/notify_user.csv"
                style={{ marginLeft: 10 }}>
                Download File Format
              </StyledDownloadLink>
            )}
            <div style={{ paddingTop: '20px' }} />
          </>
        )}
        {!editable && (
          <>
            {radioValue === targetTypes[0] ? (
              <FormFilters
                demographics={demographicValues}
                onUpdate={handleTargetChange}
                target={target}
                learners={learners}
              />
            ) : (
              <CSVReader
                onDrop={handleOnDrop}
                onError={handleOnError}
                noDrag
                addRemoveButton
                onRemoveFile={handleOnRemoveFile}>
                <div>
                  <span style={{ marginLeft: 10 }}>Click to upload.</span>
                </div>
              </CSVReader>
            )}
          </>
        )}

        <FormDelivery
          delivery={delivery}
          startDate={startDate}
          endDate={endDate}
          editable={editable}
          onUpdate={setDelivery}
        />
        {!editable && (
          <FormControl style={{ marginTop: '50px' }}>
            <StyledLabel>Push Notification/E-mail Content</StyledLabel>
            <TextArea
              value={notificationContent}
              onChangeText={setNotificationContent}></TextArea>
          </FormControl>
        )}
        <Grid container style={{ width: '500px', marginTop: '50px' }}>
          <Grid item sm={4}>
            <FlatClearButton onClick={onBack}>Back</FlatClearButton>
          </Grid>
          {editable === true ? (
            <Grid item sm={6}>
              <FlatPrimaryButton onClick={updateForm}>UPDATE</FlatPrimaryButton>
            </Grid>
          ) : (
            <Grid item sm={6}>
              <FlatPrimaryButton onClick={onSchedule}>
                SCHEDULE
              </FlatPrimaryButton>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export default FormTargetting;
