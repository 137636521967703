import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import styles from './RewardsTracker.module.css';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Loader from '../../components/Loader';
import MOTable from '../../components/MOTable';
import MOClient from '../../MOClient';
import MOSelect from '../../components/MOSelect';
import Label from '../../components/Label';
import ClearButton from '../../components/ClearButton';
import TopBarWrapper from '../../components/TopBarWrapper';
import MOTopBar from '../../components/MOTopBar';
import MOSelect2 from '../../components/MOSelect2';
import KababMenu from '../../components/KababMenu';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { saveAs } from 'file-saver';
import Utils from '../../utils';

const Wrapper = styled.div`
  padding: 30px;
  padding-top: 10px;
`;

const Header = styled.h4`
  font-size: 30px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
  font: bold normal 800 35px/387px Montserrat;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
`;

function objectify(collection_array, key) {
  let obj = {};
  for (let i = 0; i < collection_array.length; i++) {
    let k = collection_array[i][key];
    obj[k] = collection_array[i];
  }

  return obj;
}

var custom_styles = {
  table_header_icon_style: {
    fill: 'black',
  },
  form_control: {
    minWidth: 150,
    marginTop: 0,
    marginLeft: '10px',
    maxWidth: '170px',
  },
  input_label: {
    fontSize: '0.7rem',
  },
  filled_input: {
    backgroundColor: 'white',
    fontSize: '0.8rem',
  },
  filled_input_menu: {
    fontSize: '0.8rem',
  },
  sub_section_header: {
    fontWeight: 'bold',
  },
  sub_section_action: {
    fontWeight: 'bold',
  },

  icon: {
    color: 'white',
  },
  table_wrapper: {
    overflowX: 'auto',
  },
};
function RewardsTable(props) {
  if (props.loading === true) {
    return <Loader fill="#4f0060" />;
  }
  let learners = 'Learner Name';
  let demographic = props.demographic.toLowerCase();
  let renderKeys = [
    {
      name: demographic,
    },
    {
      name: learners,
    },
    {
      name: 'Coins Earned',
    },
  ];
  let headerItems = props.headerItems;

  if (props.items.length === 0) {
    return (
      <Typography
        variant="caption"
        className={styles.no_data_available_caption}>
        No data is available at the moment
      </Typography>
    );
  } else {
    return (
      <MOTable
        renderKeys={renderKeys}
        order="desc"
        rows={props.items}
        headerItems={headerItems}
        orderByColumn={2}
      />
    );
  }
}
function RewardsTracker(props) {
  const { classes, jwtToken, projects, timestampFeature } = props;

  let [allProjectsSelected, setAllProjectsSelected] = useState(false);
  let [allSelectedCategory, setallSelectedCategory] = useState(false);
  let [filter_demographic, set_demographic] = useState(
    props.demographics[0].name,
  );
  let [allSelected, setAllSelected] = useState(false);
  let [filter_demographic_values, set_demographic_values] = useState([]);
  let [selected_demographic_values, set_selected_demographic_values] = useState(
    'All',
  );
  let [selected_projects, set_selected_projects] = useState([
    props.activeProject,
  ]);
  const [reward_updated_on, set_Reward_updated_on] = React.useState('');

  // List of rewards in category
  let [cat_feature, set_cat_feature] = useState([]);
  let [reward_feature, set_reward_feature] = useState([]);
  // Selected Rewards category
  let [selected_rewards, set_selected_rewards] = useState(['']);
  // Selected Reward feature
  let [selected_reward_features, set_selected_reward_features] = useState([]);

  let [loading, set_loading] = useState(false);
  let [user_data, set_user_data] = useState([]);
  // dialog
  let [download_xls_dialog_state, set_download_xls_dialog_state] = useState(
    false,
  );

  let projects_obj = objectify(projects, 'id');

  useEffect(() => {
    let opt = {
      comp_id: props.companyInfo.company_id,
    };

    let action = MOClient.get_rewards_data(opt);
    action.then((result) => {
      set_Reward_updated_on(result.created_at.split('T')[0]);
      let categories = [];
      const rewardsData = result.rewardsData;
      for (const property in rewardsData) {
        let current = rewardsData[property];
        let rewards = [];
        for (const reward in current) {
          if (current[reward].feature_active === 1) {
            rewards.push(reward);
          }
        }
        categories.push({ [property]: rewards });
      }
      set_cat_feature(categories);
      set_selected_rewards([Object.keys(categories[0])[0]]);
    });
  }, []);

  useEffect(() => {
    if (props.demographics.length > 0) {
      set_demographic(props.demographics[0].name);
    }
  }, [props.demographics]);

  const capitalize = (s) =>
    (s && s[0].toUpperCase() + s.slice(1).replace(/_/g, ' ')) || '';

  let category_options = cat_feature.map((category) => {
    return {
      id: Object.keys(category)[0],
      display_text: capitalize(Object.keys(category)[0]),
    };
  });

  useEffect(() => {
    if (cat_feature.length !== 0 && selected_rewards !== 0) {
      let selected_rewards_matrix = cat_feature.filter((category) => {
        return selected_rewards.includes(Object.keys(category)[0]);
      });
      let rewards = [];
      for (const property of selected_rewards_matrix) {
        rewards.push(...property[Object.keys(property)]);
      }

      set_reward_feature(rewards);
      set_selected_reward_features([rewards[0]]);
    }
  }, [cat_feature, selected_rewards]);

  let matrix_options = reward_feature.map((feature) => {
    return {
      id: feature,
      display_text: capitalize(feature),
    };
  });

  let demographic_options = props.demographics.map((demographic) => {
    return {
      id: demographic.name,
      display_text: demographic.name,
    };
  });

  useEffect(() => {
    if (filter_demographic_values > 0) {
      set_demographic_values(filter_demographic_values[0]);
    }
  }, [filter_demographic]);

  let demographic_values_options = filter_demographic_values.map(
    (demographic) => {
      return {
        id: demographic,
        display_text: demographic,
      };
    },
  );

  let project_options = projects.map((project) => {
    return {
      id: project.id,
      display_text: project.name,
    };
  });

  const selectAllRewards = () => {
    let selected_id = reward_feature.map((mod) => {
      return mod;
    });
    set_selected_reward_features(selected_id);
    setAllSelected(true);
  };

  const deselectAllRewards = () => {
    let selected_id = [];
    selected_id.push(selected_reward_features[0]);
    set_selected_reward_features(selected_id);
    setAllSelected(false);
  };

  const selectAllCategory = () => {
    let selected_id = cat_feature.map((category) => {
      return Object.keys(category)[0];
    });
    set_selected_rewards(selected_id);
    setallSelectedCategory(true);
    setAllSelected(false);
  };

  const deselectAllCategory = () => {
    let selected_id = [];
    selected_id.push(selected_rewards[0]);
    set_selected_rewards(selected_id);
    setallSelectedCategory(false);
  };

  const selectAllProjects = () => {
    let selected_id = projects.map((mod) => mod.id);
    set_selected_projects(selected_id);
    setAllProjectsSelected(true);
  };

  const deselectAllProjects = () => {
    let selected_id = [];
    selected_id.push(props.activeProject);
    set_selected_projects(selected_id);
    setAllProjectsSelected(false);
  };

  useEffect(() => {
    let data = {
      token: jwtToken,
      demographic: filter_demographic,
      company_id: props.companyInfo.company_id,
    };

    const action = MOClient.get_distinct_demographic_values(data);
    action
      .then((result) => {
        set_demographic_values(['All', ...result.distinct_demographic]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filter_demographic]);

  useEffect(() => {
    let isMounted = true;
    if (selected_reward_features.length > 0 && selected_rewards.length > 0) {
      let opt = {
        token: jwtToken,
        demographic: filter_demographic,
        project_ids: [...selected_projects],
        data_filters: {},
        demographic_value: selected_demographic_values,
        company_id: props.companyInfo.company_id,
        rewards_mapper: selected_rewards,
        reward_mappers: selected_reward_features,
        action: 'view',
      };

      if (isMounted === true) {
        set_loading(true);
        let action = MOClient.get_current_rewards_data(opt);
        action.then((result) => {
          if (isMounted === true) {
            set_user_data(result.data);
          }
          set_loading(false);
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    props.activeProject,
    selected_projects,
    filter_demographic,
    selected_rewards,
    selected_reward_features,
    selected_demographic_values,
  ]);

  async function download_as_excel() {
    if (selected_reward_features.length === 0 && selected_rewards.length === 0)
      return;
    let opt = {
      token: jwtToken,
      demographic: filter_demographic,
      project_ids: [...selected_projects],
      data_filters: {},
      demographic_value: selected_demographic_values,
      company_id: props.companyInfo.company_id,
      rewards_mapper: selected_rewards,
      reward_mappers: selected_reward_features,
      action: 'download',
    };

    set_download_xls_dialog_state(true);
    let result = await MOClient.get_current_rewards_data(opt);
    saveAs(result, `Rewards-data-${reward_updated_on}.xlsx`, { autoBOM: true });
    set_download_xls_dialog_state(false);
  }

  let header_items = [
    {
      id: 0,
      name: filter_demographic,
    },
    {
      id: 1,
      name: 'Learner Name',
    },
    {
      id: 2,
      name: 'Coins',
    },
  ];

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <Header color="#0043b5">Rewards & Recognition</Header>
          <div className={classes.top_bar_action_icon}>
            <div className={classes.top_bar_action_button}>
              Last Updated on: {Utils.syncTimer(props.companyInfo.company_id)}
            </div>
          </div>
        </MOTopBar>
      </TopBarWrapper>
      <Wrapper>
        <div className={styles.sub_section_header}>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              {timestampFeature && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '1rem',
                  }}>
                  <FormControl style={{ marginRight: 20 }}>
                    <Label>Project By:</Label>
                    <MOSelect2
                      multiple={true}
                      selected={selected_projects}
                      text={selected_projects
                        .map((s) => projects_obj[s]['name'])
                        .join(', ')}
                      options={project_options}
                      onSelect={(values) => {
                        set_selected_projects(values);
                      }}
                    />
                    <ClearButton
                      onClick={
                        allProjectsSelected
                          ? deselectAllProjects
                          : selectAllProjects
                      }>
                      {allProjectsSelected ? `Undo Selection` : `Select All`}
                    </ClearButton>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>View By:</Label>
                  <MOSelect
                    options={demographic_options}
                    onSelect={(value) => {
                      set_demographic(value);
                      set_selected_demographic_values('All');
                    }}
                    text={filter_demographic}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>{filter_demographic} By:</Label>
                  <MOSelect
                    options={demographic_values_options}
                    onSelect={(value) => {
                      set_selected_demographic_values(value);
                    }}
                    text={selected_demographic_values}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sub_section_header}>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>Category :</Label>
                  <MOSelect2
                    multiple={true}
                    selected={selected_rewards}
                    text={capitalize(selected_rewards.join(', '))}
                    options={category_options}
                    onSelect={(values) => {
                      set_selected_rewards(values);
                    }}
                  />
                  <ClearButton
                    onClick={
                      allSelectedCategory
                        ? deselectAllCategory
                        : selectAllCategory
                    }>
                    {allSelectedCategory ? `Undo Selection` : `Select All`}
                  </ClearButton>
                </FormControl>
              </div>
            </div>
          </div>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>Reward Metrics :</Label>
                  <MOSelect2
                    multiple={true}
                    selected={selected_reward_features}
                    text={capitalize(selected_reward_features.join(', '))}
                    options={matrix_options}
                    onSelect={(values) => {
                      set_selected_reward_features(values);
                    }}
                  />
                  <ClearButton
                    onClick={
                      allSelected ? deselectAllRewards : selectAllRewards
                    }>
                    {allSelected ? `Undo Selection` : `Select All`}
                  </ClearButton>
                </FormControl>
              </div>
            </div>
          </div>
          <div className={styles.sub_section_header_action_wrapper}>
            <KababMenu
              onClick={async () => {
                download_as_excel();
              }}
              options={[
                {
                  display_text: 'Download as xlsx',
                  id: 1,
                },
              ]}
            />
          </div>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}>
                Start Date - {reward_updated_on}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sub_section_content}>
          <Paper elevation={2}>
            <div className={classes.table_wrapper}>
              <RewardsTable
                loading={loading}
                items={user_data}
                demographic={filter_demographic}
                headerItems={header_items}
              />
            </div>
          </Paper>
        </div>
      </Wrapper>
      <Dialog
        open={download_xls_dialog_state}
        onClose={(e) => {
          set_download_xls_dialog_state(false);
        }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px', textAlign: 'center' }}>
              <Loader fill="#4f0060" />
              <p>Generating and Downloading...</p>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withStyles(custom_styles)(RewardsTracker);
