import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Dialog } from '@material-ui/core';
import TextInput from '../../components/TextInput';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import Loader from '../../components/Loader';
import Button2 from '../../components/Button2';

const TickImage = require('../../assets/tick.png');
const CrossImg = require('../../assets/cross.png');

export default function SingleLearnerUpload({ MOClient, batches, jwtToken }) {
  const [errorText, setErrorText] = React.useState(
    'There was an error processing your request, please try again or contact us using the Chat Button',
  );
  const [progressDialog, setProgressDialog] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [failureDialog, setFailureDialog] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [employeeId, setEmployeeId] = React.useState('');
  const [channel, setChannel] = React.useState('');
  const [batchId, setBatchId] = React.useState(0);
  const [contactNumber, setContactNumber] = React.useState('');
  const [designation, setDesignation] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [contactNumberErrorText, setContactNumberErrorText] = React.useState(
    '',
  );

  const batchOptions = batches.map((batch) => {
    let batch_name = batch.batch_code;
    if (batch.batch_name !== null) {
      batch_name += ` ${batch.batch_name}`;
    }
    return {
      id: batch.id,
      display_text: batch_name,
    };
  });

  let selectedBatchName = '';

  if (batchId !== 0) {
    const the_batch = batches.filter((b) => b.id === batchId);
    if (the_batch.length > 0) {
      let batch_name = the_batch[0].batch_code;
      if (the_batch[0].batch_name !== null) {
        batch_name += ` ${the_batch[0].batch_name}`;
      }
      selectedBatchName = batch_name;
    }
  } else {
    selectedBatchName = '';
  }

  function runValidation() {
    // check the form
    if (firstName === '') {
      setErrorText('Please input the First Name');
      setFailureDialog(true);
      return false;
    }

    if (lastName === '') {
      setErrorText('Please input the Last Name');
      setFailureDialog(true);
      return false;
    }

    if (employeeId === '') {
      setErrorText('Please input the Employee ID');
      setFailureDialog(true);
      return false;
    }

    if (batchId === 0) {
      setErrorText('Please select a Batch');
      setFailureDialog(true);
      return false;
    }

    if (contactNumber.length > 0 && contactNumber.length !== 10) {
      setContactNumberErrorText('Contact number should be 10 digits!');
      return false;
    }

    return true;
  }

  function updateContactNumber(text) {
    if (!Number.isNaN(Number(text)) && text.length < 11) {
      setContactNumber(text);
    }
  }

  React.useEffect(() => {
    if (contactNumber.length === 10) {
      setContactNumberErrorText('');
    }
  }, [contactNumber]);

  return (
    <div style={{ textAlign: 'left', paddingLeft: 30, paddingTop: 20 }}>
      <Grid container>
        <Grid item xs={12} lg={5}>
          <FormControl>
            <Label>
              First Name
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <TextInput
              value={firstName}
              onChangeText={(t) => {
                setFirstName(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>
              Last Name
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <TextInput
              value={lastName}
              onChangeText={(t) => {
                setLastName(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>
              Email Id
              <span style={{ color: 'red' }}></span>
            </Label>
            <TextInput
              value={email}
              onChangeText={(t) => {
                setEmail(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>
              Employee ID
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <TextInput
              value={employeeId}
              onChangeText={(t) => {
                setEmployeeId(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>
              Batch
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <SimpleFormSelect
              autoClose
              options={batchOptions}
              text={selectedBatchName}
              onSelect={(id) => {
                setBatchId(id);
              }}
            />
          </FormControl>

          <FormControl style={{ marginTop: '30px' }}>
            <FlatPrimaryButton
              onClick={() => {
                const valid = runValidation();
                if (!valid) return;

                const o = {
                  token: jwtToken,
                  batch_id: batchId,
                  data: {
                    designation,
                    channel,
                    first_name: firstName,
                    last_name: lastName,
                    participant_code: employeeId,
                    email_id: email,
                    employee_id: employeeId,
                    contact_number: contactNumber,
                    bank_name: bankName,
                  },
                };

                const action = MOClient.add_learners_to_batch(o);

                setProgressDialog(true);

                action.then((outcome) => {
                  setProgressDialog(false);
                  if (outcome.status === 'success') {
                    setSuccessDialog(true);
                  } else {
                    setErrorText(outcome.description);
                    setFailureDialog(true);
                  }
                });
              }}>
              Upload Learner
            </FlatPrimaryButton>
          </FormControl>
        </Grid>

        <Grid item lg={5}>
          <FormControl>
            <Label>
              Channel
              <span style={{ color: 'red' }}></span>
            </Label>
            <TextInput
              value={channel}
              onChangeText={(t) => {
                setChannel(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>Designation</Label>
            <TextInput
              value={designation}
              onChangeText={(t) => {
                setDesignation(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>Contact Number</Label>
            <TextInput
              value={contactNumber}
              onChangeText={(t) => {
                updateContactNumber(t);
              }}
              errorText={contactNumberErrorText}
            />
          </FormControl>

          <FormControl>
            <Label>Bank Name</Label>
            <TextInput
              value={bankName}
              onChangeText={(t) => {
                setBankName(t);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Progress Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialog}
        onClose={() => {
          setProgressDialog(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />

          <ProgressText>Please wait...</ProgressText>
        </ProgressContainer>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={successDialog}
        onClose={() => {
          setSuccessDialog(false);
        }}>
        <ProgressContainer>
          <img alt="Success" src={TickImage} style={{ width: '50px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Learner Uploaded Successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setSuccessDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Failure Dialog */}
      <Dialog
        open={failureDialog}
        onClose={() => {
          setFailureDialog(false);
        }}>
        <ProgressContainer>
          <img alt="Failure" src={CrossImg} style={{ width: '50px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setFailureDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;
