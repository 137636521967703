import React from 'react';
import {makeStyles} from "@material-ui/styles";
import ArrowRightIcon from "./MDLIcon/icons/ArrowRight";

const customStyles = makeStyles({
  root: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer"
  },
  content: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    paddingRight: 7,
    display: 'flex',
    transitionProperty: ["transform", "opacity"],
    transitionDuration: 350,
    transitionTimingFunction: "ease",
    transform: props => props.active ? "translateX(0px)" : "translateX(-6px)",
    opacity: props => props.active ? 1 : 0
  },
  title: {
    color: "white",
    fontSize: "0.7rem",
    fontFamily: "Montserrat",
    fontWeight: 600,
    opacity: props => props.active ? 1 : 0.55,
    transform: props => props.active ? "translateX(0px)" : "translateX(-6px)",
    transitionProperty: ["transform","opacity"],
    transitionDuration: 350,
    transitionTimingFunction: "ease",
    margin: 0,
    userSelect: "none"
  }
});

export default function MenuSubItem(props) {
  let styles = customStyles(props);
  let active = false;

  if(props.hasOwnProperty("active")) {
    active = props.active;
  }

  return (
    <div className={styles.root} onClick={props.onClick}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <ArrowRightIcon/>
        </div>
        <div>
          <h5 className={styles.title}>{props.title}</h5>
        </div>
      </div>
    </div>
  )
};