import React, { useRef } from 'react';
import FileSelect from '../../components/FileSelect';
import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import StyledDownloadLink from '../../components/StyledDownloadLink';
import { Dialog } from '@material-ui/core';
import Loader from '../../components/Loader';
import Button2 from '../../components/Button2';
import TopBarWrapper from '../../components/TopBarWrapper';
import styled from 'styled-components';

export default function IncentiveStatusUpload(props) {
  const [loading, setLoading] = React.useState(false);
  const [progressText, setProgressText] = React.useState('Please wait...');
  const [errorText, setErrorText] = React.useState(
    'The CSV file is invalid or contains no learners, please try again!',
  );
  const [uploadFile, setUploadFile] = React.useState(null);
  const [progressDialog, setProgressDialog] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [failureDialog, setFailureDialog] = React.useState(false);
  let fileSelectRef = useRef(null);

  function onFileSelect(file) {
    setUploadFile(file[0]);
  }

  function runValidation() {
    if (uploadFile === null) {
      setErrorText('Please select the upload File');
      setFailureDialog(true);
      return false;
    }

    // commenting this code, because some browsers on Windows do not give the MIME type correctly
    // if(uploadFile.type.toLowerCase() !== "text/csv") {
    //   setErrorText("Please select a valid CSV file!");
    //   setFailureDialog(true);
    //   return false;
    // }

    return true;
  }

  return (
    <div style={{ textAlign: 'left' }}>
      <FormControl>
        <Label>Upload File</Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FileSelect ref={fileSelectRef} onFileSelect={onFileSelect} />
          <StyledDownloadLink
            href="https://mastero-drive.s3.ap-south-1.amazonaws.com/incentive-status-upload.csv"
            style={{ marginLeft: 10 }}>
            Download File Format
          </StyledDownloadLink>
        </div>

        <p style={{ fontSize: '0.85rem', color: '#707070' }}>
          Mandatory Fields:
        </p>
        <ul style={{ fontSize: '0.85rem', color: '#707070' }}>
          <li>User Email</li>
          <p style={{ margin: '0.5rem 0 0.2rem 0' }}>
            Atleast 1 of the below fields
          </p>
          <li>Incentive Status</li>
          <li>CASA</li>
          <li>TD</li>
        </ul>
      </FormControl>

      <FormControl style={{ marginTop: '15px' }}>
        <FlatPrimaryButton
          onClick={() => {
            // determine if the CSV file is valid
            let valid = runValidation();
            if (!valid) return;
            setProgressDialog(true);
            let action_check_csv = props.MOClient.csv_incentive_status_upload_preflight(
              {
                token: props.jwtToken,
                file: uploadFile,
              },
            );

            action_check_csv.then((outcome) => {
              fileSelectRef.current.resetForm();
              if (outcome.status !== 'success') {
                setProgressDialog(false);
                setErrorText(outcome.description);
                setFailureDialog(true);
                setUploadFile(null);
                return;
              }
              let o = {
                token: props.jwtToken,
                file: uploadFile,
              };

              let action = props.MOClient.csv_incentive_status_upload(o);

              action.then((outcome) => {
                setProgressDialog(false);
                if (outcome.status === 'success') {
                  setSuccessDialog(true);
                } else {
                  setErrorText(outcome.description);
                  setFailureDialog(true);
                }
              });
            });
          }}>
          Batch Upload
        </FlatPrimaryButton>
      </FormControl>

      {/* Progress Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialog}
        onClose={() => {
          setProgressDialog(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />

          <ProgressText>{progressText}</ProgressText>
        </ProgressContainer>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={successDialog}
        onClose={() => {
          setSuccessDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/tick.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Incentive Status Uploaded Successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2 onClick={() => setSuccessDialog(false)}>Close</Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Failure Dialog */}
      <Dialog
        open={failureDialog}
        onClose={() => {
          setFailureDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorText}</ProgressText>
          </div>

          <Button2 onClick={() => setFailureDialog(false)}>Close</Button2>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;
