import React, { useState, useRef, useEffect } from 'react';
import styles, {
  FlexRowDiv,
  Box,
  TranscriptHeading,
  TranscriptTextArea,
} from './style';
import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import FileSelect from '../../components/FileSelect';
import FormControl from '../../components/FormControl';
import Label, { SmallFontLabel, HeadingLabel } from '../../components/Label';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FlatClearButton from '../../components/FlatClearButton';
import ToggleSwitch from '../../components/ToggleSwitch';
import NumericInput from '../../components/NumericInput';
import CustomDropdown from '../../components/CustomDropdown';
import Loader from '../../components/Loader';
import Editor from './Editor';
import MSDialog from './MSDialog';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import { Snackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const Page2_challange = (props) => {
  const quillRef = useRef();
  const { setCurrentPage, pages } = props;
  const languages = [
    {
      id: 'en-US',
      display_text: 'English',
    },
    {
      id: 'hi-IN',
      display_text: 'Hindi',
    },
    {
      id: 'bn-IN',
      display_text: 'Bengali',
    },
    {
      id: 'ta-IN',
      display_text: 'Tamil',
    },
    {
      id: 'te-IN',
      display_text: 'Telugu',
    },
    {
      id: 'gu-IN',
      display_text: 'Gujarati',
    },
    {
      id: 'kn-IN',
      display_text: 'Kannada',
    },
    {
      id: 'ml-IN',
      display_text: 'Malayalam',
    },
    {
      id: 'mr-IN',
      display_text: 'Marathi',
    },
  ];
  //TODO: uncomment below to enable other review types
  const ratingMethods = [
    // {
    //   id: '1',
    //   display_text: 'Manager',
    // },
    {
      id: '2',
      display_text: 'Master-O',
    },
    // {
    //   id: '3',
    //   display_text: 'Both',
    // },
  ];

  const classes = styles();
  //microskill global states
  const { microSkillState, setMicroSkillState } = props;
  const { addChallengeAction } = props;
  const [transcript, setTranscript] = useState(
    microSkillState.Formatted_transcript,
  );
  const [selectedLanguages, setSelectedLanguages] = useState(
    microSkillState.languages,
  );
  const [selectedRatingMethods, setSelectedRatingMethods] = useState(
    microSkillState.rating_method,
  );
  const [timeLimit, setTimeLimit] = useState(microSkillState.Time_limit);

  const [selectAttempts, setSelectAttempts] = useState(
    microSkillState.attempts,
  );
  const [attemptLimit, setAttemptLimit] = useState(microSkillState.no_Attempts);
  const [selectLearnerAssist, setSelectLearnerAssist] = useState(
    microSkillState.learner_Assist,
  );
  const [selectLearnerPractice, setSelectLearnerPractice] = useState(
    microSkillState.learner_Practice,
  );

  function getWindowHeight() {
    const { innerHeight: height } = window;
    return height;
  }

  const [windowHeight, setWindowHeight] = useState(getWindowHeight());

  useEffect(() => {
    function handleResize() {
      setWindowHeight(getWindowHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const changeTranscript = (title) => {
    setTranscript(title);
    setMicroSkillState({ ...microSkillState, Formatted_transcript: title });
  };
  const updateSelectedLanguages = (values) => {
    if (values.length > 0) {
      setLanguageErrorText(null);
    }
    setSelectedLanguages(values);
    setMicroSkillState({ ...microSkillState, languages: values });
  };
  const updateSelectedRatingMethods = (values) => {
    if (values.length > 0) {
      setRating_methodErrorText(null);
    }
    setSelectedRatingMethods(values);
    setMicroSkillState({ ...microSkillState, rating_method: values });
  };

  const update_timeLimit = (value) => {
    setTimeLimit(value * 60);
    setMicroSkillState({ ...microSkillState, Time_limit: value * 60 });
  };
  const update_attempt_limit = (value) => {
    setAttemptLimit(value);
    setMicroSkillState({ ...microSkillState, no_Attempts: value });
  };
  const update_attempts = (value) => {
    setSelectAttempts(value);
    setMicroSkillState({ ...microSkillState, attempts: value });
  };
  const update_selectLearnerAssist = (value) => {
    setSelectLearnerAssist(value);
    setMicroSkillState({ ...microSkillState, learner_Assist: value });
  };
  const update_selectLearnerPractice = (value) => {
    setSelectLearnerPractice(value);
    setMicroSkillState({ ...microSkillState, learner_Practice: value });
  };
  //end
  const [forward_xls_toast, set_forward_xls_toast] = useState(false);
  const [forward_xls_toast_msg, set_forward_xls_toast_msg] = useState('');

  const [languageErrorText, setLanguageErrorText] = useState(null);
  const [rating_methodErrorText, setRating_methodErrorText] = useState(null);

  const selected_languages_text = () => {
    let foundItem = languages.filter((item) => item.id == selectedLanguages);
    if (foundItem) {
      return foundItem[0].display_text;
    } else {
      return '';
    }
    // const text = selectedLanguages
    //   .map((s) => {
    //     let foundItem = languages.filter((item) => item.id == s);
    //     if (foundItem) {
    //       return foundItem[0].display_text;
    //     } else {
    //       return '';
    //     }
    //   })
    //   .join(', ');
    // return text;
  };

  let selected_ratingMethod_text = () => {
    let foundItem = ratingMethods.filter(
      (item) => item.id == selectedRatingMethods,
    );
    if (foundItem) {
      return foundItem[0].display_text;
    } else {
      return '';
    }

    // const text = selectedRatingMethods
    //   .map((s) => {
    //     let foundItem = ratingMethods.filter((item) => item.id == s);
    //     if (foundItem) {
    //       return foundItem[0].display_text;
    //     } else {
    //       return '';
    //     }
    //   })
    //   .join(', ');
    // return text;
  };

  const nextAction = () => {
    let currentTranscriptValue = setCurrentTranscriptValue();
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      currentTranscriptValue = editor.getText().trim();
    }

    if (selectedLanguages.length == 0) {
      setLanguageErrorText('Please select a language.');
      return;
    }
    if (selectedRatingMethods.length == 0) {
      setRating_methodErrorText('Please select a Rating Method.');
      return;
    }
    if (currentTranscriptValue.length == 0) {
      set_forward_xls_toast(true);
      set_forward_xls_toast_msg('Transcript text can not be empty.');
      return;
    }
    setCurrentPage(pages[2]);
  };

  const setCurrentTranscriptValue = () => {
    let currentTranscriptValue = transcript;
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      currentTranscriptValue = editor.getText().trim();
    }
    setTranscript(currentTranscriptValue);
    setMicroSkillState({
      ...microSkillState,
      Sample_transcript: currentTranscriptValue,
    });
    return currentTranscriptValue;
  };

  const [shouldsSaveDraft, setShouldSaveDraft] = useState(false);
  const saveDraft = () => {
    setCurrentTranscriptValue();
    setShouldSaveDraft(true);
  };

  useEffect(() => {
    if (shouldsSaveDraft) {
      setShouldSaveDraft(false);
      addChallengeAction();
    }
  }, [microSkillState]);

  const TranscriptContainer = () => {
    return (
      <Box
        height={windowHeight}
        className={`${
          transcript.length == 0 ? classes.box : classes.withoutBorderBox
        }`}>
        {transcript.length == 0 && (
          <TranscriptHeading>
            Upload Best Practice Sample Pitch To Generate Transcript
          </TranscriptHeading>
        )}
        {transcript.length > 0 && (
          <Editor
            quillRef={quillRef}
            handleChange={changeTranscript}
            value={transcript}
            style={{ width: '100%', height: '90%' }}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      {/* <div className={classes.heading_container}>
        <HeadingLabel>Create Roleplay Challenge</HeadingLabel>
      </div> */}
      <FlexRowDiv className={classes.challangeContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.leftTopContainer}>
            {/* language */}
            <FormControl
              className={classes.full_Width}
              style={{ textAlign: 'left' }}>
              <Label>Languages:</Label>
              <SimpleFormSelect
                // style={{ width: '100%' }}
                width={classes.full_Width}
                autoClose={true}
                options={languages}
                text={
                  languages.filter((v) => v.id === selectedLanguages)[0]
                    .display_text
                }
                onSelect={(values) => {
                  updateSelectedLanguages(values);
                }}
              />
              {/* <CustomDropdown
                width={classes.full_Width}
                multiple={false}
                hideFilterIcon={true}
                selected={selectedLanguages}
                text={selected_languages_text()}
                options={languages}
                onSelect={(values) => {
                  updateSelectedLanguages(values);
                }}
              /> */}
              {languageErrorText && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  {languageErrorText}
                </label>
              )}
            </FormControl>
            {/* time limit */}
            {/* <FormControl className={classes.full_Width}>
              <Label>Recommended Time Limit:</Label>
              <NumericInput
                width={classes.full_Width}
                type="number"
                min={1}
                unit={'Minutes'}
                value={timeLimit / 60}
                onChangeText={(t) => update_timeLimit(t)}
              />
            </FormControl> */}
            {/* rating method */}
            <FormControl
              className={classes.full_Width}
              style={{ textAlign: 'left' }}>
              <Label>Rating Method:</Label>
              <SimpleFormSelect
                width={classes.full_Width}
                autoClose={true}
                options={ratingMethods}
                text={selected_ratingMethod_text()}
                onSelect={(values) => {
                  updateSelectedRatingMethods(values);
                }}
              />
              {/* <CustomDropdown
                width={classes.full_Width}
                multiple={false}
                hideFilterIcon={true}
                selected={selectedRatingMethods}
                text={selected_ratingMethod_text()}
                options={ratingMethods}
                onSelect={(values) => {
                  updateSelectedRatingMethods(values);
                }}
              /> */}
              {rating_methodErrorText && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  {rating_methodErrorText}
                </label>
              )}
            </FormControl>

            <div className={classes.switch_action_container}>
              <div className={classes.learnerContainer}>
                {/* learning assist */}
                <FormControl className={classes.learnerAssistContainer}>
                  <div className={classes.switch_container}>
                    <Label style={{ paddingBottom: 0 }}>Learner Assist:</Label>
                    <ToggleSwitch
                      checked={selectLearnerAssist}
                      onChange={() =>
                        update_selectLearnerAssist(!selectLearnerAssist)
                      }
                      name="learnerAssist"
                    />
                  </div>
                </FormControl>
                {/* learner practice */}
                <FormControl className={classes.learnerPracticeContainer}>
                  <div className={classes.switch_container}>
                    <Label style={{ paddingBottom: 0 }}>
                      Learner Practice:
                    </Label>
                    <ToggleSwitch
                      checked={selectLearnerPractice}
                      onChange={() =>
                        update_selectLearnerPractice(!selectLearnerPractice)
                      }
                      name="learnerAssist"
                    />
                  </div>
                </FormControl>
              </div>

              {/* attempts */}
              <div className={classes.learnerContainer}>
                <FormControl className={classes.learnerAssistContainer}>
                  <div className={classes.switch_container}>
                    <Label style={{ paddingBottom: 0 }}>Attempts:</Label>
                    <ToggleSwitch
                      checked={selectAttempts}
                      onChange={() => update_attempts(!selectAttempts)}
                      name="learnerAssist"
                    />
                  </div>
                </FormControl>
                <div className={classes.learnerPracticeContainer}></div>
              </div>
            </div>

            {/* number of attempts */}
            {selectAttempts && (
              <FormControl className={classes.full_Width}>
                <Label>Number of Attempts:</Label>
                <NumericInput
                  width={classes.full_Width}
                  type="number"
                  min={1}
                  value={attemptLimit}
                  unit={'Max.'}
                  onChangeText={(t) => update_attempt_limit(t)}
                />
              </FormControl>
            )}
            <FormControl>
              <div className={classes.smTranscriptContainer}>
                {TranscriptContainer()}
              </div>
            </FormControl>
          </div>
        </div>
        <div className={classes.rightContainer}>{TranscriptContainer()}</div>
      </FlexRowDiv>
      <FormControl className={classes.action_btn_container}>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          onClick={() => {
            nextAction();
          }}>
          Next
        </FlatPrimaryButton>
        <div className={classes.save_btn_container}>
          <FlatClearButton
            style={{ padding: '15px 30px', minWidth: '20%' }}
            onClick={() => {
              saveDraft();
            }}>
            Save Draft
          </FlatClearButton>
        </div>
      </FormControl>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => {
          set_forward_xls_toast(false);
          set_forward_xls_toast_msg('');
        }}
        message={<span id="message-id">{forward_xls_toast_msg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              set_forward_xls_toast(false);
              set_forward_xls_toast_msg('');
            }}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};
