import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Analytics from '../../components/MDLIcon/icons/Analytics';
import Audience from '../../components/MDLIcon/icons/Audience';
import Course from '../../components/MDLIcon/icons/Course';
import MenuItem from '../../components/MenuItem';
import NavProjectSelector from '../../components/NavProjectSelector';
import LogoutIcon from '../../components/MDLIcon/icons/LogoutIcon';
import MenuSubItem from '../../components/MenuSubItem';
import MasterOlogo from '../../assets/logo-black.svg';
import Forms from '../../components/MDLIcon/icons/Forms';

const useStyles = makeStyles({
  drawerRoot: {
    height: '100vh',
  },
  mobile_root: {
    backgroundColor: '#4f0060 !important',
    color: 'white !important',
    '@media (min-width: 1024px)': {
      position: 'static',
      display: 'block',
    },
  },
  root: {
    backgroundColor: '#4f0060 !important',
    color: 'white !important',
    position: 'initial !important',
    '@media (min-width: 1024px)': {
      position: 'static',
      display: 'block',
    },
  },
  paperRoot: {
    backgroundColor: 'transparent',
  },
  expansion_panel: {
    backgroundColor: 'transparent',
    color: 'white !important',
    textAlign: 'left',
    transitionProperty: 'background-color',
    transitionDuration: 300,
    transitionTimingFunction: 'ease',
    '&:before': {
      display: 'none',
      opacity: '0 !important',
    },
  },
  expandIcon: {
    color: '#f4511e !important',
    minWidth: '30px !important',
    padding: '0 !important',
    transform: 'rotate(-90deg) !important',
  },
  expansion_panel_expanded: {
    minHeight: 'auto !important',
    transform: 'rotate(0deg) !important',
  },
  icon: {
    color: '#f4511e',
    minWidth: '30px !important',
    padding: '0',
  },
  expansion_panel_details: {
    alignItems: 'center !important',
    margin: '12px 0 !important',
  },
  panel_header: {
    fontSize: '0.70rem !important',
    fontFamily: 'Montserrat !important',
    fontWeight: '600 !important',
    display: 'block',
  },
  expansion_panel_root: {
    paddingLeft: '15px !important',
    transition: 'ease all 0.5s',
    minHeight: 38,
  },
  expansion_panel_root_expanded: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
    backgroundColor: 'rgba(0,0,0,0.20)',
  },
  expansion_panel_root_expanded_null: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  expansion_panel_details_root: {
    paddingBottom: 12,
  },
});

function StyledExpansionPanel(props) {
  let { classes, children } = props;
  return (
    <Accordion
      classes={{
        root: classes.expansion_panel,
        expanded: classes.expansion_panel_root_expanded,
      }}
      onClick={props.onClick}
      elevation={0}
      expanded={props.expanded}>
      {children}
    </Accordion>
  );
}

function NavigationDrawer(props) {
  let permissions = props.permissions;

  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(props.activeTab);

  React.useEffect(() => {
    console.log(props.location.pathname);
    setActiveTab(props.location.pathname);
  }, [props.location.pathname]);

  const [expandedTabs, setExpandedTabs] = useState([]);

  React.useEffect(() => {
    if (props.activeTab.indexOf('microskillstudio') > -1) {
      setExpandedTabs(['microskillstudio']);
    } else if (props.activeTab.indexOf('audience') > -1) {
      setExpandedTabs(['audience']);
    } else if (props.activeTab.indexOf('courses-and-training') > -1) {
      setExpandedTabs(['courses-and-training']);
    } else if (props.activeTab.indexOf('training/') > -1) {
      setExpandedTabs(['training']);
    } else if (props.activeTab.indexOf('analytics/') > -1) {
      setExpandedTabs(['analytics']);
    } else if (props.activeTab.indexOf('enagagement') > -1) {
      setExpandedTabs(['enagagement']);
    } else if (props.activeTab.indexOf('incentives') > -1) {
      setExpandedTabs(['incentives']);
    }
  }, [props.activeTab]);

  let microskillStudioExpanded = expandedTabs.indexOf('microskillstudio') > -1;
  let audienceExpanded = expandedTabs.indexOf('audience') > -1;
  let trainingExpanded = expandedTabs.indexOf('training') > -1;
  let formsExpanded = expandedTabs.indexOf('forms') > -1;
  let coursesTrainingExpanded =
    expandedTabs.indexOf('courses-and-training') > -1;
  let analyticsExpanded = expandedTabs.indexOf('analytics') > -1;
  let incentivesExpanded = expandedTabs.indexOf('incentives') > -1;
  let enagagementExpanded = expandedTabs.indexOf('enagagement') > -1;

  function handle_submenu_item_click(id) {
    setActiveTab(id);
  }

  let meta = {
    company_logo_url: MasterOlogo,
    demographics: [],
  };

  if (
    props.companyInfo.hasOwnProperty('meta') &&
    props.companyInfo.meta.hasOwnProperty('company_logo_url')
  ) {
    meta.company_logo_url = props.companyInfo.meta.company_logo_url;
  }

  const drawer = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}>
      <div style={{ height: 'calc(100% - 78px)', overflow: 'auto' }}>
        <Divider />

        <NavProjectSelector
          heading={props.activeProjectText}
          subheading={props.activeProjectDescription}
          onClick={props.onProjectSelector}
        />
        {/* TODO: ashish uncomment below to show microskill roleplay in navigation drawer */}
        {/* <StyledExpansionPanel
            classes={classes}
            expanded={microskillStudioExpanded}
            onClick={() => {
              setExpandedTabs(['microskillstudio']);
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ fontSize: '0.9rem' }} />}
              classes={{
                root: classes.expansion_panel_root,
                expanded: classes.expansion_panel_expanded,
                content: classes.expansion_panel_details,
                expandIcon: classes.expandIcon,
              }}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Audience />
              </ListItemIcon>
              <ListItemText
                primary="Microskill Studio"
                classes={{ primary: classes.panel_header }}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <MenuSubItem
                  title="Roleplay"
                  active={activeTab === '/roleplay/create-scenario'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/roleplay/create-scenario');
                    props.history.push({
                      pathname: '/roleplay/create-scenario',
                      state: {},
                    });
                  }}
                />
              </div>
            </ExpansionPanelDetails>
          </StyledExpansionPanel> */}

        {permissions.indexOf('trainer_manage_batch_learners') > -1 ? (
          <StyledExpansionPanel
            classes={classes}
            expanded={audienceExpanded}
            onClick={() => {
              setExpandedTabs(['audience']);
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ fontSize: '0.9rem' }} />}
              classes={{
                root: classes.expansion_panel_root,
                expanded: classes.expansion_panel_expanded,
                content: classes.expansion_panel_details,
                expandIcon: classes.expandIcon,
              }}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Audience />
              </ListItemIcon>
              <ListItemText
                primary="Audience"
                classes={{ primary: classes.panel_header }}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <MenuSubItem
                  title="Manage Learners"
                  active={activeTab === '/audience/manage-learners'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/audience/manage-learners');
                    props.history.push({
                      pathname: '/audience/manage-learners',
                      state: {},
                    });
                  }}
                />
              </div>
            </ExpansionPanelDetails>
          </StyledExpansionPanel>
        ) : null}

        {permissions.indexOf('create_training_course') > -1 ? (
          <StyledExpansionPanel
            classes={classes}
            expanded={coursesTrainingExpanded}
            onClick={() => {
              setExpandedTabs(['courses-and-training']);
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ fontSize: '0.9rem' }} />}
              classes={{
                root: classes.expansion_panel_root,
                expanded: classes.expansion_panel_expanded,
                content: classes.expansion_panel_details,
                expandIcon: classes.expandIcon,
              }}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Course />
              </ListItemIcon>
              <ListItemText
                primary="Courses"
                classes={{ primary: classes.panel_header }}
              />
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
              classes={{ root: classes.expansion_panel_details_root }}>
              <div>
                {permissions.indexOf('create_training_course') > -1 ? (
                  <MenuSubItem
                    title="Create New Course"
                    active={
                      activeTab === '/courses-and-training/create-new-course'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handle_submenu_item_click(
                        '/courses-and-training/create-new-course',
                      );
                      props.history.push(
                        '/courses-and-training/create-new-course',
                        {},
                      );
                    }}
                  />
                ) : null}

                {permissions.indexOf('create_training_course') > -1 ? (
                  <MenuSubItem
                    title="Course Log"
                    active={activeTab === '/courses-and-training/course-log'}
                    onClick={(e) => {
                      e.stopPropagation();
                      handle_submenu_item_click(
                        '/courses-and-training/course-log',
                      );
                      props.history.push(
                        '/courses-and-training/course-log',
                        {},
                      );
                    }}
                  />
                ) : null}

                {permissions.indexOf('create_training_course') > -1 ? (
                  <MenuSubItem
                    title="Course Feedback"
                    active={
                      activeTab === '/courses-and-training/course-feedback'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handle_submenu_item_click(
                        '/courses-and-training/course-feedback',
                      );
                      props.history.push(
                        '/courses-and-training/course-feedback',
                        {},
                      );
                    }}
                  />
                ) : null}
              </div>
            </ExpansionPanelDetails>
          </StyledExpansionPanel>
        ) : null}

        {permissions.indexOf('create_form') > -1 ? (
          <StyledExpansionPanel
            classes={classes}
            expanded={formsExpanded}
            onClick={() => {
              setExpandedTabs(['forms']);
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ fontSize: '0.9rem' }} />}
              classes={{
                root: classes.expansion_panel_root,
                expanded: classes.expansion_panel_expanded,
                content: classes.expansion_panel_details,
                expandIcon: classes.expandIcon,
              }}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Forms />
              </ListItemIcon>
              <ListItemText
                primary="Forms"
                classes={{ primary: classes.panel_header }}
              />
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
              classes={{ root: classes.expansion_panel_details_root }}>
              <div>
                <MenuSubItem
                  title="Create New Form"
                  active={activeTab === '/forms/create-new-form'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/forms/create-new-form');
                    props.history.push('/forms/create-new-form', {});
                  }}
                />

                <MenuSubItem
                  title="Form Responses"
                  active={activeTab === '/forms/responses'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/forms/responses');
                    props.history.push({
                      pathname: '/forms/responses',
                      state: {},
                    });
                  }}
                />

                <MenuSubItem
                  title="All Forms"
                  active={activeTab === '/forms/all-forms'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/forms/all-forms');
                    props.history.push({
                      pathname: '/forms/all-forms',
                      state: {},
                    });
                  }}
                />
              </div>
            </ExpansionPanelDetails>
          </StyledExpansionPanel>
        ) : null}

        {permissions.indexOf('create_training_session') > -1 ? (
          <StyledExpansionPanel
            classes={classes}
            expanded={trainingExpanded}
            onClick={() => {
              setExpandedTabs(['training']);
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ fontSize: '0.9rem' }} />}
              classes={{
                root: classes.expansion_panel_root,
                expanded: classes.expansion_panel_expanded,
                content: classes.expansion_panel_details,
                expandIcon: classes.expandIcon,
              }}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Course />
              </ListItemIcon>
              <ListItemText
                primary="Training"
                classes={{ primary: classes.panel_header }}
              />
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
              classes={{ root: classes.expansion_panel_details_root }}>
              <div>
                {permissions.indexOf('create_training_session') > -1 ? (
                  <MenuSubItem
                    title="New Training Session"
                    active={
                      activeTab === '/training/create-new-training-session'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handle_submenu_item_click(
                        '/training/create-new-training-session',
                      );
                      props.history.push(
                        '/training/create-new-training-session',
                        {},
                      );
                    }}
                  />
                ) : null}

                {permissions.indexOf('manage_training_sessions') > -1 ? (
                  <MenuSubItem
                    title="Training Log"
                    active={activeTab === '/training/training-log'}
                    onClick={(e) => {
                      e.stopPropagation();
                      handle_submenu_item_click('/training/training-log');
                      props.history.push({
                        pathname: '/training/training-log',
                        state: {},
                      });
                    }}
                  />
                ) : null}

                {permissions.indexOf('create_training_session') > -1 ||
                permissions.indexOf('mark_attendance') > -1 ? (
                  <MenuSubItem
                    title="Training Tool"
                    active={activeTab === '/training/training-tool'}
                    onClick={(e) => {
                      e.stopPropagation();
                      handle_submenu_item_click('/training/training-tool');
                      props.history.push({
                        pathname: '/training/training-tool',
                        state: {},
                      });
                    }}
                  />
                ) : null}
              </div>
            </ExpansionPanelDetails>
          </StyledExpansionPanel>
        ) : null}

        <StyledExpansionPanel
          classes={classes}
          expanded={analyticsExpanded}
          onClick={() => {
            setExpandedTabs(['analytics']);
          }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: '0.9rem' }} />}
            classes={{
              root: classes.expansion_panel_root,
              expanded: classes.expansion_panel_expanded,
              content: classes.expansion_panel_details,
              expandIcon: classes.expandIcon,
            }}>
            <ListItemIcon classes={{ root: classes.icon }}>
              <Analytics />
            </ListItemIcon>
            <ListItemText
              primary="Analytics"
              classes={{ primary: classes.panel_header }}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansion_panel_details_root }}>
            <div>
              <MenuSubItem
                title="Login & Completion"
                active={activeTab === '/analytics/login-completion'}
                onClick={(e) => {
                  e.stopPropagation();
                  handle_submenu_item_click('/analytics/login-completion');
                  props.history.push({
                    pathname: '/analytics/login-completion',
                    state: {},
                  });
                }}
              />
              {props.companyInfo.company_id !== 106 && (
                <MenuSubItem
                  title="Learning Engagement"
                  active={activeTab === '/analytics/learning-and-engagement'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click(
                      '/analytics/learning-and-engagement',
                    );
                    props.history.push({
                      pathname: '/analytics/learning-and-engagement',
                      state: {},
                    });
                  }}
                />
              )}
              <MenuSubItem
                title="Learning Effectiveness"
                active={activeTab === '/analytics/learning-effectiveness'}
                onClick={(e) => {
                  e.stopPropagation();
                  handle_submenu_item_click(
                    '/analytics/learning-effectiveness',
                  );
                  props.history.push({
                    pathname: '/analytics/learning-effectiveness',
                    state: {},
                  });
                }}
              />
              {permissions.indexOf('create_training_session') > -1 ||
              permissions.indexOf('create_training_course') > -1 ? (
                <MenuSubItem
                  title="Course Feedback"
                  active={activeTab === '/analytics/course-feedback'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/analytics/course-feedback');
                    props.history.push({
                      pathname: '/analytics/course-feedback',
                      state: {},
                    });
                  }}
                />
              ) : null}
              {props.showRewardSection && (
                <MenuSubItem
                  title="Rewards & Recognition"
                  active={activeTab === '/analytics/reward-analytics'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/analytics/reward-analytics');
                    props.history.push({
                      pathname: '/analytics/reward-analytics',
                      state: {},
                    });
                  }}
                />
              )}
              {props.showLearnerJourney && (
                <MenuSubItem
                  title="Learner Journey"
                  active={activeTab === '/analytics/learner-journey'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/analytics/learner-journey');
                    props.history.push({
                      pathname: '/analytics/learner-journey',
                      state: {},
                    });
                  }}
                />
              )}
              {props.showTeamReport && (
                <MenuSubItem
                  title="Team Report"
                  active={activeTab === '/analytics/team-report'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/analytics/team-report');
                    props.history.push({
                      pathname: '/analytics/team-report',
                      state: {},
                    });
                  }}
                />
              )}
            </div>
          </ExpansionPanelDetails>
        </StyledExpansionPanel>
        {props.showIncentiveSection ? (
          <StyledExpansionPanel
            classes={classes}
            expanded={incentivesExpanded}
            onClick={() => {
              setExpandedTabs(['incentives']);
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ fontSize: '0.9rem' }} />}
              classes={{
                root: classes.expansion_panel_root,
                expanded: classes.expansion_panel_expanded,
                content: classes.expansion_panel_details,
                expandIcon: classes.expandIcon,
              }}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Audience />
              </ListItemIcon>
              <ListItemText
                primary="Incentives"
                classes={{ primary: classes.panel_header }}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <MenuSubItem
                  title="Incentive Status"
                  active={activeTab === '/incentives/incentive-status'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click('/incentives/incentive-status');
                    props.history.push({
                      pathname: '/incentives/incentive-status',
                      state: {},
                    });
                  }}
                />
                <MenuSubItem
                  title="Incentive Sheet Upload"
                  active={activeTab === '/incentives/incentive-sheet-upload'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click(
                      '/incentives/incentive-sheet-upload',
                    );
                    props.history.push({
                      pathname: '/incentives/incentive-sheet-upload',
                      state: {},
                    });
                  }}
                />
              </div>
            </ExpansionPanelDetails>
          </StyledExpansionPanel>
        ) : null}
        {/* CHANGE THIS */}
        {props.showRewardSection && (
          <StyledExpansionPanel
            classes={classes}
            expanded={enagagementExpanded}
            onClick={() => {
              setExpandedTabs(['enagagement']);
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ fontSize: '0.9rem' }} />}
              classes={{
                root: classes.expansion_panel_root,
                expanded: classes.expansion_panel_expanded,
                content: classes.expansion_panel_details,
                expandIcon: classes.expandIcon,
              }}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Audience />
              </ListItemIcon>
              <ListItemText
                primary="Enagagement"
                classes={{ primary: classes.panel_header }}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <MenuSubItem
                  title="Rewards & Recognition"
                  active={activeTab === '/enagagement/rewards-recognition'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handle_submenu_item_click(
                      '/enagagement/rewards-recognition',
                    );
                    props.history.push({
                      pathname: '/enagagement/rewards-recognition',
                      state: {},
                    });
                  }}
                />
              </div>
            </ExpansionPanelDetails>
          </StyledExpansionPanel>
        )}
        <MenuItem
          icon={<Audience />}
          text="Notifications"
          onClick={() => props.history.push('/notify')}
        />
        <MenuItem
          icon={<LogoutIcon />}
          text="Logout"
          onClick={props.logoutHandler}
        />
      </div>

      <div
        style={{
          backgroundColor: 'white',
          height: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 5,
          paddingTop: 5,
        }}>
        <img
          alt="Master-O"
          src={meta.company_logo_url}
          style={{ height: '40px', width: 'auto', display: 'block' }}
        />
      </div>
    </div>
  );

  return (
    <nav aria-label="Mailbox folders" style={{}}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          classes={{ root: classes.drawerRoot, paper: classes.mobile_root }}
          variant="temporary"
          open={props.open}
          onClose={props.toggleMenu}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{ root: classes.drawerRoot, paper: classes.root }}
          variant="permanent">
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavigationDrawer;
