import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '../components/FormControl';
import Label from '../components/Label';
import MOSelect from '../components/MOSelect';
import MODatePicker from '../components/MODatePicker';
import dayjs from 'dayjs';
import MOTable2 from '../components/MOTable2';
import { TableContainer, TablePagination } from '@material-ui/core';
import Button2 from '../components/Button2';
import Utils from '../utils';
import Loader from '../components/Loader';
import KababMenu from '../components/KababMenu';
import styles from './LearnerJourneyTracker/LearnerJourneyTracker.module.css';
import { saveAs } from 'file-saver';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

let LearnerFeedbackTable = function(props) {
  if (props.loading === true) {
    return <Loader fill="#4f0060" />;
  }

  let { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = props;

  let { headerItems, items } = props;

  if (items.length === 0) {
    if (props.loading === false) {
      return (
        <MOTable2
          renderKeys={headerItems}
          rows={[]}
          headerItems={headerItems}
          orderByColumn={2}
        />
      );
    }
    return (
      <Typography
        variant="caption"
        className={styles.no_data_available_caption}>
        No data is available at the moment
      </Typography>
    );
  } else {
    return (
      <TableContainer style={{ paddingBottom: '40px' }}>
        <MOTable2
          renderKeys={headerItems}
          rows={items.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
          )}
          headerItems={headerItems}
          orderByColumn={2}
        />
        <TablePagination
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableContainer>
    );
  }
};

function AnalyticsCourseLearnerFeedback({
  jwtToken,
  courses,
  courseEvents,
  classes,
  userInfo,
  MOClient,
  driveUsers,
}) {
  const permissions = [];

  let driveUsersObj = Utils.objectify(driveUsers, 'id');

  if (userInfo.permissions.hasOwnProperty('features')) {
    for (const permission of userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }

  const [loadingData, setLoadingData] = React.useState(false);
  let [
    download_xls_dialog_state,
    set_download_xls_dialog_state,
  ] = React.useState(false);
  const [activeCourse, setActiveCourse] = React.useState(null);
  const [batches, setBatches] = React.useState([]);
  const [batchId, setBatchId] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  let [startDate, setStartDate] = React.useState(null);
  let [endDate, setEndDate] = React.useState(null);
  let [startDateDialogOpen, setStartDateDialogOpen] = React.useState(false);
  const [endDateDialogOpen, setEndDateDialogOpen] = React.useState(false);
  const [filterTrainer, setFilterTrainer] = React.useState(0);
  let [page, setPage] = React.useState(0);
  let [rowsPerPage, setRowsPerPage] = React.useState(25);
  let [forward_xls_dialog_state, set_forward_xls_dialog_state] = React.useState(
    false,
  );
  let [forward_email_to_error, set_forward_email_to_error] = React.useState(
    false,
  );
  let [
    forward_email_to_helper_text,
    set_forward_email_to_helper_text,
  ] = React.useState('');
  let [forward_email_to, set_forward_email_to] = React.useState(null);
  let [
    forward_xls_button_disabled,
    set_forward_xls_button_disabled,
  ] = React.useState(false);
  let [forward_xls_button_text, set_forward_xls_button_text] = React.useState(
    'Send',
  );
  let [forward_xls_progress, set_forward_xls_progress] = React.useState(false);
  let [forward_xls_toast, set_forward_xls_toast] = React.useState(false);
  let [
    forward_xls_toast_message,
    set_forward_xls_toast_message,
  ] = React.useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [headerItems, setHeaderItems] = React.useState([
    {
      id: 0,
      name: 'Participant Name',
      render_key: 'participant_name',
    },
    {
      id: 1,
      name: 'Batch',
      render_key: 'batch_name',
    },
    {
      id: 2,
      name: 'Attendance(%)',
      render_key: 'attendance',
    },
    {
      id: 3,
      name: 'Course Feedback',
      render_key: 'course_feedback',
    },
    {
      id: 4,
      name: 'Observation Score',
      render_key: 'observation_score',
    },
    {
      id: 5,
      name: 'Microskill Completion (%)',
      render_key: 'microskill_completion',
    },
    {
      id: 6,
      name: 'Microskill Score',
      render_key: 'microskill_score',
    },
  ]);

  const [rows, setRows] = React.useState([]);

  async function fetch_report(req_obj) {
    if (
      activeCourse === null &&
      batchId === 0 &&
      startDate === null &&
      endDate === null &&
      filterTrainer === 0
    ) {
      setRows([]);
      return;
    }

    let params = {
      response_type: req_obj.type,
    };
    if (req_obj.hasOwnProperty('to_email_address')) {
      params.to_email_address = req_obj.to_email_address;
    }

    if (activeCourse !== null) {
      params.course_id = activeCourse;
    }

    if (startDate !== null) {
      params.training_starts_after = startDate.format('YYYY-MM-DD');
    }

    if (endDate !== null) {
      params.training_starts_before = endDate.format('YYYY-MM-DD');
    }

    if (batchId !== 0) {
      params.batch_id = batchId;
    }

    if (filterTrainer !== 0) {
      params.trainer = filterTrainer;
    }

    let fetch_data = await MOClient.get_course_feedback_report_table({
      token: jwtToken,
      options: {
        params,
      },
    });

    return fetch_data;
  }

  async function download_as_excel() {
    if (
      activeCourse === null &&
      batchId === 0 &&
      startDate === null &&
      endDate === null &&
      filterTrainer === 0
    ) {
      setRows([]);
      return;
    }
    set_download_xls_dialog_state(true);
    let result = await fetch_report({ type: 'excel' });
    set_download_xls_dialog_state(false);

    let file_name = `Report_${activeCourseObj.name}_`;

    if (batchId !== 0) {
      file_name += `${selectedBatchName}_`;
    }

    if (filterTrainer !== 0) {
      file_name += `${driveUsersObj[filterTrainer].first_name}_`;
    }

    if (startDate !== null) {
      file_name += `${startDate.format('YYYY-MM-DD')}_`;
    }

    if (endDate !== null) {
      file_name += `${endDate.format('YYYY-MM-DD')}_`;
    }

    file_name += 'Learner-Feedback.xlsx';

    saveAs(result, file_name, { autoBOM: true });
  }

  async function forward_in_email() {
    if (
      activeCourse === null &&
      batchId === 0 &&
      startDate === null &&
      endDate === null &&
      filterTrainer === 0
    ) {
      set_forward_xls_dialog_state(false);
      setRows([]);
      return;
    }
    set_forward_xls_progress(true);
    let result = await fetch_report({
      type: 'forward_email',
      to_email_address: forward_email_to,
    });
    set_forward_xls_dialog_state(false);
    set_forward_xls_progress(false);
    set_forward_email_to('');

    if (result.ok === true) {
      set_forward_xls_dialog_state(false);
      set_forward_xls_toast_message('Email sent successfully!');
      set_forward_xls_toast(true);
    } else {
      set_forward_xls_toast_message(
        'Email could not be sent, please try again?',
      );
      set_forward_xls_toast(true);
    }

    set_forward_xls_progress(false);
  }
  let gettingData = false;

  const getFeedbacksData = async () => {
    if (gettingData === false) {
      gettingData = true;

      if (
        activeCourse === null &&
        batchId === 0 &&
        startDate === null &&
        endDate === null &&
        filterTrainer === 0
      ) {
        setRows([]);
        return;
      }

      setLoadingData(true);

      let outcome = await fetch_report({ type: 'json' });
      setLoadingData(false);

      if (outcome.status !== 'success') {
        setHeaderItems(outcome.data.header_items);
        setRows([]);
      } else {
        setHeaderItems(outcome.data.header_items);
        setRows(outcome.data.rows);
      }

      gettingData = false;
    }
  };

  React.useEffect(() => {
    let options = {
      token: jwtToken,
    };

    if (permissions.indexOf('view_all_training_batches') > -1) {
      options.get_all = true;
    }

    const action = MOClient.get_training_batches(options);

    action.then((result) => {
      if (result.status === 'success') {
        const { fetch_request } = result;

        const extract_data = fetch_request.json();

        extract_data.then((json_data) => {
          if (json_data.status === 'success') {
            setBatches(json_data.data);
          }
        });
      }
    });
  }, []);

  React.useEffect(() => {
    if (forward_xls_progress === true) {
      set_forward_xls_button_disabled(true);
      set_forward_xls_button_text('Sending');
    } else {
      set_forward_xls_button_disabled(false);
      set_forward_xls_button_text('Send');
    }
  }, [forward_xls_progress]);

  let activeCourseObj = { name: '' };

  let filterCourses = courses.filter((c) => c.id === activeCourse);

  if (filterCourses.length > 0) {
    activeCourseObj = filterCourses[0];
  }

  const batch_options = batches.map((batch) => {
    let batch_name = batch.batch_code;
    if (batch.batch_name !== null) {
      batch_name += ` ${batch.batch_name}`;
    }
    return {
      id: batch.id,
      display_text: batch_name,
    };
  });

  let selectedBatchName = '';

  if (batchId !== 0) {
    const the_batch = batches.filter((b) => b.id === batchId);
    if (the_batch.length > 0) {
      let batch_name = the_batch[0].batch_code;
      if (the_batch[0].batch_name !== null) {
        batch_name += ` ${the_batch[0].batch_name}`;
      }
      selectedBatchName = batch_name;
    }
  } else {
    selectedBatchName = '';
  }

  function clearFilters() {
    setActiveCourse(null);
    setBatchId(0);
    setStartDate(null);
    setEndDate(null);
    setFilterTrainer(0);
  }

  let trainers = driveUsers.filter((u) => {
    let access = false;
    for (let permission of u.permissions.features) {
      if (permission.key === 'create_training_session') {
        access = true;
        break;
      }
    }
    return access;
  });

  let trainerOptions = trainers.map((t) => {
    return {
      id: t.id,
      display_text: t.first_name + ' ' + t.last_name,
    };
  });

  return (
    <div style={{ textAlign: 'left' }}>
      <div style={{ marginTop: 10, display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ marginRight: 30 }}>
          <FormControl>
            <Label>Course: </Label>
            <MOSelect
              options={courses.map((c) => ({
                id: c.id,
                display_text: c.name,
              }))}
              onSelect={(id) => {
                setActiveCourse(id);
              }}
              text={activeCourseObj.name}
            />
          </FormControl>
        </div>

        <div style={{ marginRight: 30 }}>
          <FormControl>
            <Label>Batch:</Label>
            <MOSelect
              options={batch_options}
              text={selectedBatchName}
              onSelect={(id) => {
                setBatchId(id);
              }}
            />
          </FormControl>
        </div>

        {permissions.indexOf('view_all_training_sessions') > -1 ? (
          <div style={{ marginRight: 30 }}>
            <FormControl>
              <Label>Trainer:</Label>
              <MOSelect
                options={trainerOptions}
                onSelect={(id) => {
                  setFilterTrainer(id);
                }}
                text={
                  filterTrainer > 0
                    ? driveUsersObj[filterTrainer].first_name +
                      ' ' +
                      driveUsersObj[filterTrainer].last_name
                    : ''
                }
              />
            </FormControl>
          </div>
        ) : null}

        <div style={{ marginRight: 30 }}>
          <FormControl>
            <Label>Training Duration:</Label>
            <DurationWrapper>
              <MODatePicker
                open={startDateDialogOpen}
                text={startDate === null ? '' : startDate.format('DD-MM-YYYY')}
                value={startDate}
                onClick={() => {
                  setStartDateDialogOpen(!startDateDialogOpen);
                }}
                onClose={() => {
                  setStartDateDialogOpen(false);
                }}
                handleDateChange={(d) => {
                  setStartDate(d);
                }}
                minDate={dayjs(new Date()).subtract(5, 'year')}
              />

              <CourseFromToLabel>TO</CourseFromToLabel>

              <MODatePicker
                open={endDateDialogOpen}
                text={endDate === null ? '' : endDate.format('DD-MM-YYYY')}
                value={endDate}
                onClick={() => {
                  setEndDateDialogOpen(!endDateDialogOpen);
                }}
                onClose={() => {
                  setEndDateDialogOpen(false);
                }}
                handleDateChange={(d) => {
                  setEndDate(d);
                }}
                minDate={dayjs(new Date()).subtract(5, 'year')}
              />
            </DurationWrapper>
          </FormControl>
        </div>

        <div>
          <FormControl>
            <Label>Options</Label>
            <KababMenu
              onClick={async (id) => {
                if (id === 1) {
                  download_as_excel();
                } else if (id === 2) {
                  set_forward_xls_dialog_state(true);
                }
              }}
              options={[
                {
                  display_text: 'Download as xlsx',
                  id: 1,
                },
                {
                  display_text: 'Forward in Email',
                  id: 2,
                },
              ]}
            />
          </FormControl>
        </div>
      </div>

      <div className={styles.sub_section_header}>
        <div className={styles.sub_section_header_title}>
          <div className={styles.sub_section_header_filters}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <FormControl style={{ marginRight: 20 }}>
                <Button2 transparent={false} onClick={() => getFeedbacksData()}>
                  Submit
                </Button2>
              </FormControl>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <FormControl style={{ marginRight: 20 }}>
                <Button2 transparent={true} onClick={clearFilters}>
                  Clear Filters
                </Button2>
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sub_section_content}>
        <Paper elevation={2}>
          <div className={classes.table_wrapper}>
            <LearnerFeedbackTable
              loading={loadingData}
              items={rows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              headerItems={headerItems}
            />
          </div>
        </Paper>
      </div>

      <Dialog
        open={forward_xls_dialog_state}
        onClose={(e) => set_forward_xls_dialog_state(false)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px' }}>
              <Typography variant="h5" style={{ marginTop: '25px' }}>
                Forward the data to your e-mail
              </Typography>
              <p>The e-mail contains a spreadsheet attachment.</p>
              <TextField
                id="standard-name"
                label="Email"
                error={forward_email_to_error}
                value={forward_email_to}
                onChange={(e) => set_forward_email_to(e.target.value)}
                margin="normal"
                type="email"
                helperText={forward_email_to_helper_text}
                required
                onKeyPress={(event) => {
                  if (event.key === 'Enter') forward_in_email();
                }}
              />

              <br />

              <Button
                style={{ marginTop: '25px' }}
                variant="contained"
                color="primary"
                disabled={forward_xls_button_disabled}
                onClick={forward_in_email}>
                {forward_xls_button_text}
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => set_forward_xls_toast(false)}
        message={<span id="message-id">{forward_xls_toast_message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => set_forward_xls_toast(false)}>
            <CloseIcon />
          </IconButton>,
        ]}
      />

      <Dialog
        open={download_xls_dialog_state}
        onClose={(e) => {
          set_download_xls_dialog_state(false);
        }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px', textAlign: 'center' }}>
              <Loader fill="#4f0060" />
              <p>Generating and Downloading...</p>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;

export default AnalyticsCourseLearnerFeedback;
