import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';
import TrashIcon from '../../components/MDLIcon/icons/Trash';
import MOTable2 from '../../components/MOTable2';
import MOClient from '../../MOClient';
import { saveAs } from 'file-saver';
import CreateForm from './CreateForm';
import EditIcon from '../../components/MDLIcon/icons/EditIcon';
import DuplicateIcon from '../../components/MDLIcon/icons/Duplicate';
import TopBarWrapper from '../../components/TopBarWrapper';
import MOTopBar from '../../components/MOTopBar';
import { Dialog } from '@material-ui/core';
import FormControl from '../../components/FormControl';
import Button2 from '../../components/Button2';
import Loader from '../../components/Loader';
import FlatClearButton from '../../components/FlatClearButton';

const viewModes = ['VIEW_ALL_FORMS', 'EDIT_FORM', 'COPY_FORM', 'VIEW_FORM']; // 'VIEW_FORM'
const FORMS_EXPIRE_TIME = 10 * 60 * 10000; // 10 Minutes

const AllForms = (props) => {
  const handleDownload = (form) => {
    if (form.assessment !== 'Yes') return console.log('Not an assessment');
    const token = localStorage.getItem('auth-token');
    const formName = `${form.name}-responses-${new Date().getTime()}`;
    MOClient.getFormQuestionsAsExcel(token, form.id)
      .then((data) => {
        saveAs(data, `${formName}.xlsx`, { autoBOM: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openForm = (form_mode, form_id, form_active) => {
    const token = localStorage.getItem('auth-token');
    MOClient.getFormData(token, form_id)
      .then(({ data }) => {
        const { form, target, questions } = data;
        if (form_mode === 'EDIT_FORM' && form_active === false)
          form_mode = 'VIEW_FORM';
        setMode(form_mode);
        const editForm = { form, target, questions, form_mode, form_active };
        setSelectedForms(editForm);
        let subTitle = '';
        switch (form_mode) {
          case 'VIEW_FORM':
            subTitle = 'View Form';
            break;
          case 'EDIT_FORM':
            subTitle = 'Edit Form';
            break;
          case 'COPY_FORM':
            subTitle = 'Copy Form';
            break;
          default:
            subTitle = 'All Forms';
            break;
        }
        setTitle(`${subTitle}: ${form.name}`);
      })
      .catch((err) => {
        console.log(err);
        setMode(viewModes[0]);
      });
  };

  const headerItems = [
    { id: 0, name: 'Form Name', render_key: 'name' },
    { id: 1, name: 'Description', render_key: 'description' },
    { id: 2, name: 'Start Date', render_key: 'start' },
    { id: 3, name: 'End Date', render_key: 'end' },
    { id: 4, name: 'Assessment', render_key: 'assessment', onClick: handleDownload, },
    { id: 5, name: 'Status', render_key: 'status' },
    { id: 6, name: 'Actions', render_key: 'actions' },
  ];

  const [forms, setForms] = useState([]);
  const [title, setTitle] = useState('All Forms');
  const [selectedForms, setSelectedForms] = useState({});
  const [mode, setMode] = useState(viewModes[0]);
  const [activeForm, setActiveForm] = React.useState(0);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorDialogText, setErrorDialogText] = React.useState(
    'There was an unknown error, please try again?',
  );

  useEffect(() => {
    getFormsData();
  }, []);

  const getFormsData = () => {
    const token = localStorage.getItem('auth-token');
    const companyId = localStorage.getItem('company-id');
    MOClient.getAllForms(token, companyId)
      .then(({ data }) => {
        const formRows = [];
        for (const form of data) {
          const { id, name, description, assessment, status } = form;
          const start = new Date(form.start)
            .toLocaleString('en-IN', { dateStyle: 'short', timeStyle: 'short' })
            .toUpperCase();
          const end = new Date(form.end)
            .toLocaleString('en-IN', { dateStyle: 'short', timeStyle: 'short' })
            .toUpperCase();
          const formActive = status == 'Active' ? true : false;
          const actions = (
            <ActionCell>
              <CurvedButtonBase
                data-toggle="tooltip"
                title="Edit"
                onClick={() => openForm(viewModes[1], id, formActive)}>
                <EditIcon fill="#707070" />
              </CurvedButtonBase>
              <CurvedButtonBase
                data-toggle="tooltip"
                title="Copy"
                onClick={() => openForm(viewModes[2], id, formActive)}>
                <DuplicateIcon fill="#707070" />
              </CurvedButtonBase>
              <CurvedButtonBase
                data-toggle="tooltip"
                title="Delete"
                onClick={() => { setActiveForm(id); setDeletePopup(true); }}>
                <StyledTrashIcon />
              </CurvedButtonBase>
            </ActionCell>
          );
          formRows.push({ id, name, description, assessment, status, start, end, actions });
        }
        setForms(formRows);
        setMode("VIEW_ALL_FORMS");
        setTitle("All Forms");
      })
      .catch((err) => {
        console.log(err);
        // setMode(viewModes[0]);
      });
  };

  const deleteCourse = () => {
    setDeletePopup(false);
    setProgressDialogOpen(true);
    const token = localStorage.getItem('auth-token');
    MOClient.delete_form_by_id({ token, formId: activeForm })
      .then((result) => {
        setProgressDialogOpen(false);
        if (result.status !== 'success') {
          setErrorDialogOpen(true);
          setErrorDialogText(result.description);
        } else {
          getFormsData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <Header color="#0043b5">{title}</Header>
          {title != 'All Forms' ? (
            <FlatClearButton onClick={getFormsData}>
              GO BACK
            </FlatClearButton>
          ) : null}
        </MOTopBar>
      </TopBarWrapper>
      <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
        <ProgressContainer>
          <ProgressText>
            Are you sure you want to delete this Form?
          </ProgressText>
          <ProgressText>
            <FormControl>
              <Actions>
                <Button2 onClick={deleteCourse}>Yes Delete</Button2>
                <Button2
                  onClick={() => setDeletePopup(false)}
                  transparent={true}
                  style={{ marginLeft: '5px' }}>
                  Cancel
                </Button2>
              </Actions>
            </FormControl>
          </ProgressText>
        </ProgressContainer>
      </Dialog>

      <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorDialogText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2 onClick={() => setErrorDialogOpen(false)}>Close</Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialogOpen}
        onClose={() => setProgressDialogOpen(false)}>
        <ProgressContainer>
          <Loader fill="#f4511e" />
          <ProgressText>Please wait...</ProgressText>
        </ProgressContainer>
      </Dialog>

      <Box p={3}>
        {mode === 'VIEW_ALL_FORMS' && (<MOTable2 rows={forms} renderKeys={headerItems} headerItems={headerItems} orderByColumn={-1} order={'asc'} />)}
        {mode === 'VIEW_FORM' && <CreateForm {...selectedForms} />}
        {mode === 'EDIT_FORM' && <CreateForm {...selectedForms} />}
        {mode === 'COPY_FORM' && <CreateForm {...selectedForms} />}
      </Box>
    </div>
  );
};

const Header = styled.h4`
  font-size: 30px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
  font: bold normal 800 35px/387px Montserrat;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
`;

const CurvedButtonBase = styled(ButtonBase)`
  border-radius: 20px;
  margin: 7px;
  padding: 5px;
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const ActionCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledTrashIcon = styled(TrashIcon)`
  .class-trash-icon-2 {
    fill: #707070;
  }
`;

export default AllForms;
