import React from 'react';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const ToggleSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 16,
    padding: 0,
    paddingBottom: '2px',
  },
  switchBaseByDirectional: {
    padding: 1,
    color: '#f4511e',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#f4511e',
      '& + $track': {
        backgroundColor: '#f0f0f0',
        opacity: 1,
        border: '0.5px solid #484848',
      },
    },
  },
  switchBase: {
    padding: 1,
    color: '#484848',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#f4511e',
      '& + $track': {
        backgroundColor: '#f0f0f0',
        opacity: 1,
        border: '0.5px solid #484848',
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    //color: '#484848',
    transition: theme.transitions.create('color'),
  },
  track: {
    borderRadius: 16 / 2,
    border: '0.5px solid #484848',
    backgroundColor: '#f0f0f0',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  const { bidirectional } = props;
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: bidirectional
          ? classes.switchBaseByDirectional
          : classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default ToggleSwitch;
