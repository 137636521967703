import React from 'react';
import FileSelect from '../../components/FileSelect';
import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import StyledDownloadLink from '../../components/StyledDownloadLink';
import { Dialog } from '@material-ui/core';
import Loader from '../../components/Loader';
import Button2 from '../../components/Button2';
import TopBarWrapper from '../../components/TopBarWrapper';
import styled from 'styled-components';
import MultipleFileSelect from '../../components/MultipleFileSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function IncentiveStatusUpload(props) {
  const [loading, setLoading] = React.useState(false);
  const [progressText, setProgressText] = React.useState('Please wait...');
  const [errorText, setErrorText] = React.useState(
    'The CSV file is invalid or contains no learners, please try again!',
  );
  const [uploadFiles, setUploadFiles] = React.useState(null);
  const [mappingFile, setMappingFile] = React.useState(null);
  const [progressDialog, setProgressDialog] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [failureDialog, setFailureDialog] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());

  function onFileSelect(files) {
    setUploadFiles(files);
  }

  function onSingleSelect(file) {
    setMappingFile(file[0]);
  }

  function runValidation() {
    if (mappingFile === null || uploadFiles == null) {
      setErrorText('Please select the upload File');
      setFailureDialog(true);
      return false;
    }

    // commenting this code, because some browsers on Windows do not give the MIME type correctly
    // if(uploadFile.type.toLowerCase() !== "text/csv") {
    //   setErrorText("Please select a valid CSV file!");
    //   setFailureDialog(true);
    //   return false;
    // }

    return true;
  }

  const multipleFileUploadHandler = () => {
    // if (!runValidation()) {
    //   return;
    // }
    const data = new FormData();
    let selectedFiles = uploadFiles;
    console.log(selectedFiles);
    // If file selected
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        console.log(selectedFiles[i]);
        data.append('incentiveSheets', selectedFiles[i], selectedFiles[i].name);
      }
      console.log(mappingFile);
      data.append('incentiveMapping', mappingFile, mappingFile.name);
      console.log(data.entries());
      for (const item of data.entries()) {
        console.log(item);
      }
      setProgressDialog(true);
      let action_upload_incentive = props.MOClient.upload_incentive_sheets({
        token: props.jwtToken,
        files: data,
        boundary: data._boundary,
        year: startDate.getFullYear(),
        month: startDate.getMonth(),
      });

      action_upload_incentive.then((outcome) => {
        setProgressDialog(false);
        if (outcome.status !== 'success') {
          setProgressDialog(false);
          setErrorText(outcome.description);
          setFailureDialog(true);
          return;
        } else if (outcome.status == 'success') {
          setSuccessDialog(true);
        }
      });
    }
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <FormControl>
        <Label>Upload PDF File(s)</Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MultipleFileSelect onFileSelect={onFileSelect} />
        </div>

        <p style={{ fontSize: '0.85rem', color: '#707070' }}>
          Only PDFs allowed
        </p>
      </FormControl>

      <FormControl>
        <Label>Upload Mapping Sheet</Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FileSelect onFileSelect={onSingleSelect} />
          <StyledDownloadLink
            href="https://mastero-drive.s3.ap-south-1.amazonaws.com/incentive-sheets-template.csv"
            style={{ marginLeft: 10 }}>
            Download File Format
          </StyledDownloadLink>
        </div>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="MM/yyyy"
          maxDate={startDate}
          minDate={new Date().setMonth(startDate.getMonth() - 12)}
          showMonthYearPicker
        />
        <p style={{ fontSize: '0.85rem', color: '#707070' }}>
          Mandatory Fields:
        </p>
        <ul style={{ fontSize: '0.85rem', color: '#707070' }}>
          <li>User Email</li>
          <li>File Name</li>
        </ul>
      </FormControl>

      <FormControl style={{ marginTop: '15px' }}>
        <FlatPrimaryButton
          onClick={() => {
            multipleFileUploadHandler();
          }}>
          Batch Upload
        </FlatPrimaryButton>
      </FormControl>

      {/* Progress Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialog}
        onClose={() => {
          setProgressDialog(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />

          <ProgressText>{progressText}</ProgressText>
        </ProgressContainer>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={successDialog}
        onClose={() => {
          setSuccessDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/tick.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Incentive Sheet Uploaded Successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2 onClick={() => setSuccessDialog(false)}>Close</Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Failure Dialog */}
      <Dialog
        open={failureDialog}
        onClose={() => {
          setFailureDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorText}</ProgressText>
          </div>

          <Button2 onClick={() => setFailureDialog(false)}>Close</Button2>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;
