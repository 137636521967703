import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import styles from './LearnerJourneyTracker.module.css';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import KababMenu from '../../components/KababMenu';
import Loader from '../../components/Loader';
import MOTable from '../../components/MOTable';
import MOClient from '../../MOClient';
import { saveAs } from 'file-saver';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MOSelect from '../../components/MOSelect';
import MOSelect2 from '../../components/MOSelect2';
import Label from '../../components/Label';
import MODatePicker from '../../components/MODatePicker';
import ClearButton from '../../components/ClearButton';
import { TableContainer, TablePagination } from '@material-ui/core';
import TopBarWrapper from '../../components/TopBarWrapper';
import MOTopBar from '../../components/MOTopBar';
import Button2 from '../../components/Button2';
import Utils from '../../utils';

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;

const Wrapper = styled.div`
  padding: 30px;
  padding-top: 10px;
`;

const Header = styled.h4`
  font-size: 30px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
  font: bold normal 800 35px/387px Montserrat;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
`;

var custom_styles = {
  input_label: {
    fontSize: '0.7rem',
  },
  filled_input: {
    backgroundColor: 'white',
    fontSize: '0.8rem',
  },
  filled_input_menu: {
    fontSize: '0.8rem',
  },
  form_control: {
    width: '100%',
    marginTop: 0,
  },
  sub_section_header: {
    fontWeight: 'bold',
  },
  sub_section_action: {
    fontWeight: 'bold',
  },

  icon: {
    color: 'white',
  },
  table_wrapper: {
    overflowX: 'auto',
  },
};

function objectify(collection_array, key) {
  let obj = {};
  for (let i = 0; i < collection_array.length; i++) {
    let k = collection_array[i][key];
    obj[k] = collection_array[i];
  }

  return obj;
}

let LearnerJourneyTable = function (props) {
  if (props.loading === true) {
    return <Loader fill="#4f0060" />;
  }

  let { page, rowsPerPage, onChangePage, onChangeRowsPerPage, filter_demographic } = props;

  let renderKeys = [
    {
      name: 'learnerName',
    },
    {
      name: filter_demographic,
    },
    {
      name: 'completionRatio'
    },
    {
      name: 'total_first_attempt_score',
    },
    {
      name: 'average_first_attempt_score',
    },
    {
      name: 'average_best_attempt_score',
    },
    {
      name: 'average_completion_in_days',
    },
  ];

  let { headerItems, items } = props;

  if (items.length === 0) {
    return (
      <Typography
        variant="caption"
        className={styles.no_data_available_caption}>
        No data is available at the moment
      </Typography>
    );
  } else {
    return (
      <TableContainer style={{ paddingBottom: '40px' }}>
        <MOTable
          renderKeys={renderKeys}
          rows={items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          headerItems={headerItems}
          order={'asc'}
          orderByColumn={2}
        />
        <TablePagination
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableContainer>
    );
  }
};

function LearnerJourneyTracker(props) {
  let [filter_demographic, set_demographic] = useState('');
  let [loading, set_loading] = useState(false);
  let [allProjectsSelected, setAllProjectsSelected] = useState(false);
  let [data, setData] = useState([]);
  let [page, setPage] = React.useState(0);
  let [rowsPerPage, setRowsPerPage] = React.useState(25);
  let demographics = [];
  // let [allSelected, setAllSelected] = useState(false);

  const handleChangePage = (event, newPage) => { setPage(newPage); };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // categories, categoryFeature,
  const { activeProject, classes, jwtToken, timestampFeature, projects } = props;

  // let [selectedCategories, setSelectedCategories] = useState('All');
  // let [selectedModules, setSelectedModules] = useState([]);
  // let [filtered_modules, setFilteredModules] = useState([]);
  // let [selectedChallenges, setSelectedChallenges] = useState([]);
  // let modules = [];

  let [selected_demographic_values, set_selected_demographic_values] = useState('All');
  let [selected_projects, set_selected_projects] = useState([activeProject]);

  let projects_obj = objectify(projects, 'id');

  // dialog
  let [download_xls_dialog_state, set_download_xls_dialog_state] = useState(false);
  let [forward_xls_dialog_state, set_forward_xls_dialog_state] = useState(false);
  let [forward_email_to, set_forward_email_to] = useState(null);
  let [forward_xls_toast_message, set_forward_xls_toast_message] = useState('');

  let [forward_xls_toast, set_forward_xls_toast] = useState(false);
  let [forward_email_to_error, set_forward_email_to_error] = useState(false);
  let [forward_email_to_helper_text, set_forward_email_to_helper_text] = useState('');

  let [forward_xls_progress, set_forward_xls_progress] = useState(false);
  let [forward_xls_button_disabled, set_forward_xls_button_disabled] = useState(false);

  let [forward_xls_button_text, set_forward_xls_button_text] = useState('Send');

  let [startDate, setStartDate] = React.useState(null);
  let [endDate, setEndDate] = React.useState(null);

  let [startDateDialogOpen, setStartDateDialogOpen] = React.useState(false);
  const [endDateDialogOpen, setEndDateDialogOpen] = React.useState(false);

  // if (props.hasOwnProperty('modules')) {
  //   if (typeof props.modules !== 'undefined') {
  //     modules = props.modules;
  //   }
  // }

  if (props.hasOwnProperty('demographics')) {
    if (typeof props.demographics !== 'undefined') {
      demographics = props.demographics;
    }
  }

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  let gettingLearnerJourneyData = false;

  const getLearnerJourney = async () => {
    if (gettingLearnerJourneyData === false) {
      gettingLearnerJourneyData = true;
      let isMounted = true;

      if (filter_demographic !== '') {
        let opt = {
          // module_id: selectedModules,
          token: jwtToken,
          company_id: props.companyInfo.company_id,
          demographic: filter_demographic,
          demographic_value: 'All',
          project_id: activeProject,
          project_ids: selected_projects,
          filters: {},
        };

        //if (selectedModules.length === 0) return;
        // if (selectedChallenges.length > 0) opt.filters.challenge_id = selectedChallenges;

        if (startDate !== null) opt.filters.start_date = startDate;
        if (endDate !== null) opt.filters.end_date = endDate;

        let demographic_key = filter_demographic.toLowerCase();
        if (props.userInfo.permissions.demographics.hasOwnProperty(demographic_key)) {
          opt.demographic_value = props.userInfo.permissions.demographics[demographic_key];
          if (props.userInfo.permissions.demographics[demographic_key] === 'All') {
            opt.demographic_value = selected_demographic_values;
          }
        }

        set_loading(true);
        let result = await MOClient.get_learner_journey_data(opt);
        if (isMounted === true) {
          if (result != null) {
            setData(result);
          } else {
            setData([]);
          }
          set_loading(false);
        }
      }
      gettingLearnerJourneyData = false;
    }
  };

  async function download_as_excel() {
    // if (selectedModules.length === 0) return;
    set_download_xls_dialog_state(true);

    let opt = {
      token: jwtToken,
      company_id: props.companyInfo.company_id,
      demographic: filter_demographic,
      project_id: props.activeProject,
      project_ids: selected_projects,
      demographic_value: 'All',
      // module_id: selectedModules,
      filters: {},
    };

    // if (selectedChallenges.length > 0) opt.filters.challenge_id = selectedChallenges;

    if (startDate !== null) opt.filters.start_date = startDate;
    if (endDate !== null) opt.filters.end_date = endDate;

    let demographic_key = filter_demographic.toLowerCase();
    if (props.userInfo.permissions.demographics.hasOwnProperty(demographic_key)) {
      opt.demographic_value = props.userInfo.permissions.demographics[demographic_key];
      if (props.userInfo.permissions.demographics[demographic_key] === 'All') {
        opt.demographic_value = selected_demographic_values;
      }
    }

    let result = await MOClient.get_learner_journey_data_excel(opt);
    saveAs(result, "Learner-Journey-data.xlsx", { autoBOM: true });
    set_download_xls_dialog_state(false);
  }

  async function forward_in_email() {
    // if (selectedModules.length === 0) return;

    let opt = {
      token: jwtToken,
      demographic: filter_demographic,
      demographic_value: 'All',
      project_id: props.activeProject,
      project_ids: selected_projects,
      // module_id: selectedModules,
      to_email_address: forward_email_to,
      filters: {},
    };

    // if (selectedChallenges.length > 0) opt.filters.challenge_id = selectedChallenges

    if (startDate !== null) opt.filters.start_date = startDate;
    if (endDate !== null) opt.filters.end_date = endDate;

    let demographic_key = filter_demographic.toLowerCase();

    if (props.userInfo.permissions.demographics.hasOwnProperty(demographic_key)) {
      opt.demographic_value = props.userInfo.permissions.demographics[demographic_key];
      if (props.userInfo.permissions.demographics[demographic_key] === 'All') {
        opt.demographic_value = selected_demographic_values;
      }
    }

    if (forward_email_to === '' || forward_email_to === null) {
      // special error handling
      set_forward_email_to_error(true);
      set_forward_email_to_helper_text('Email is requried');
    } else {
      set_forward_xls_progress(true);
      var action = await MOClient.forward_email_completion_data_excel(opt);

      if (action === true) {
        set_forward_xls_dialog_state(false);
        set_forward_xls_toast_message('Email sent successfully!');
        set_forward_xls_toast(true);
      } else {
        set_forward_xls_toast_message('Email could not be sent, please try again?',);
        set_forward_xls_toast(true);
      }

      set_forward_xls_progress(false);
    }
  }

  useEffect(() => {
    if (forward_email_to === '') {
      set_forward_email_to_error(true);
      set_forward_email_to_helper_text('Email is requried');
    } else {
      set_forward_email_to_error(false);
      set_forward_email_to_helper_text('');
    }
  }, [forward_email_to]);

  useEffect(() => {
    if (forward_xls_progress === true) {
      set_forward_xls_button_disabled(true);
      set_forward_xls_button_text('Sending');
    } else {
      set_forward_xls_button_disabled(false);
      set_forward_xls_button_text('Send');
    }
  }, [forward_xls_progress]);

  useEffect(() => {
    if (props.demographics.length > 0) {
      set_demographic(props.demographics[0].name);
    }
  }, [props.demographics]);

  // useEffect(() => {
  //   let filtered = props.modules.filter(
  //     (o) => o.project_id === Number(activeProject),
  //   );
  //   let _modules = filtered.filter((o) => {
  //     if (selectedCategories !== 'All') {
  //       return o.category === selectedCategories;
  //     } else {
  //       return true;
  //     }
  //   });
  //   setFilteredModules(_modules);
  //   setAllSelected(false);
  // }, [activeProject, selectedCategories]);

  // useEffect(() => {
  //   setSelectedModules([]);
  //   setSelectedChallenges([]);
  //   if (filtered_modules.length > 0) {
  //     let selected_id = [];
  //     selected_id.push(filtered_modules[0]['id']);
  //     setSelectedModules(selected_id);
  //     modules_obj = objectify(filtered_modules, 'id');
  //   }
  //   setAllSelected(false);
  // }, [filtered_modules]);

  useEffect(() => {
    let data = {
      token: jwtToken,
      demographic: filter_demographic,
      company_id: props.companyInfo.company_id,
    };

    const action = MOClient.get_distinct_demographic_values(data);
    action
      .then((result) => {
        set_demographic_values(['All', ...result.distinct_demographic]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filter_demographic]);

  let [filter_demographic_values, set_demographic_values] = useState([]);

  let demographic_values_options = filter_demographic_values.map(
    (demographic) => {
      return { id: demographic, display_text: demographic };
    },
  );

  // const selectAllMicroskills = () => {
  //   let selected_id = filtered_modules.map((mod) => mod.id);
  //   setSelectedModules(selected_id);
  //   setAllSelected(true);
  // };

  // const deselectAllMicroskills = () => {
  //   let selected_id = [];
  //   selected_id.push(filtered_modules[0]['id']);
  //   setSelectedModules(selected_id);
  //   setAllSelected(false);
  // };

  const selectAllProjects = () => {
    let selected_id = projects.map((mod) => mod.id);
    set_selected_projects(selected_id);
    setAllProjectsSelected(true);
  };

  const deselectAllProjects = () => {
    let selected_id = [];
    selected_id.push(activeProject);
    set_selected_projects(selected_id);
    setAllProjectsSelected(false);
  };

  // let filtered_challenges = [];

  // let challenges_obj = {};

  // if (Array.isArray(props.challenges)) {
  //   filtered_challenges = props.challenges.filter((c) => {
  //     if (selectedModules.indexOf(c.module_id) > -1) return true;
  //     return false;
  //   });

  //   challenges_obj = objectify(props.challenges, 'id');
  // }

  // let challenge_menu_items = filtered_challenges.map((challenge) => {
  //   return { id: challenge.id, display_text: challenge.name };
  // });

  let demographic_options = demographics.map((demographic) => {
    return { display_text: demographic.name, id: demographic.name };
  });

  let project_options = projects.map((project) => {
    return { display_text: project.name, id: project.id };
  });

  // let microskill_options = filtered_modules.map((microskill) => {
  //   return { id: microskill.id, display_text: microskill.name };
  // });

  // let category_options = categories.map((category) => {
  //   return { id: category, display_text: category };
  // });

  // let selected_modules_text = selectedModules
  //   .map((s) => {
  //     return modules_obj[s]['name'];
  //   })
  //   .join(', ');

  let header_items = [
    {
      id: 0,
      name: 'Learner Name',
    },
    {
      id: 1,
      name: filter_demographic,
    },
    {
      id: 2,
      name: 'Completion Ratio',
    },
    {
      id: 3,
      name: 'Total First Attempt Score',
    },
    {
      id: 4,
      name: 'Average First Attempts Score',
    },
    {
      id: 5,
      name: 'Average Best Attempts Score',
    },
    {
      id: 6,
      name: 'Average Completion Time in Days',
    },
  ];

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <Header color="#0043b5">Learner Journey</Header>
          <div className={classes.top_bar_action_icon}>
            <div className={classes.top_bar_action_button}>
              Last Updated on: {Utils.syncTimer(props.companyInfo.company_id)}
            </div>
          </div>
        </MOTopBar>
      </TopBarWrapper>
      <Wrapper>
        <div className={styles.sub_section_header}>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>View By:</Label>
                  <MOSelect
                    options={demographic_options}
                    onSelect={(value) => set_demographic(value)}
                    text={filter_demographic}
                  />
                </FormControl>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>{filter_demographic} By:</Label>
                  <MOSelect
                    options={demographic_values_options}
                    onSelect={(value) => {
                      set_selected_demographic_values(value);
                    }}
                    text={selected_demographic_values}
                  />
                </FormControl>
              </div>
              {timestampFeature && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '1rem',
                  }}>
                  <FormControl style={{ marginRight: 20 }}>
                    <Label>Project By:</Label>
                    <MOSelect2
                      multiple={true}
                      selected={selected_projects}
                      text={selected_projects
                        .map((s) => projects_obj[s]['name'])
                        .join(', ')}
                      options={project_options}
                      onSelect={(values) => {
                        set_selected_projects(values);
                      }}
                    />
                    <ClearButton
                      onClick={
                        allProjectsSelected
                          ? deselectAllProjects
                          : selectAllProjects
                      }>
                      {allProjectsSelected ? `Undo Selection` : `Select All`}
                    </ClearButton>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.sub_section_header}>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}>
                {/* {categoryFeature && (
                  <FormControl style={{ marginRight: 20 }}>
                    <Label>Categories:</Label>
                    <MOSelect
                      text={selectedCategories}
                      options={category_options}
                      onSelect={(values) => setSelectedCategories(values)}
                    />
                  </FormControl>
                )}
                <FormControl style={{ marginRight: 20 }}>
                  <Label>Microskill:</Label>
                  <MOSelect2
                    multiple={true}
                    selected={selectedModules}
                    text={selected_modules_text}
                    options={microskill_options}
                    onSelect={(values) => {
                      setSelectedModules(values);
                      setSelectedChallenges([]);
                    }}
                  />
                  <ClearButton
                    onClick={
                      allSelected ? deselectAllMicroskills : selectAllMicroskills
                    }>
                    {allSelected ? `Undo Selection` : `Select All`}
                  </ClearButton>
                </FormControl>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>Challenge:</Label>
                  <MOSelect2
                    multiple={true}
                    selected={selectedChallenges}
                    options={challenge_menu_items}
                    text={selectedChallenges
                      .map((s) => challenges_obj[s]['name'])
                      .join(', ')}
                    onSelect={(values) => setSelectedChallenges(values)}
                  />
                </FormControl> */}
                <div style={{ marginRight: 30 }}>
                  <FormControl>
                    <Label>Duration:</Label>
                    <DurationWrapper>
                      <MODatePicker
                        open={startDateDialogOpen}
                        text={
                          startDate === null ? '' : startDate.format('DD-MM-YYYY')
                        }
                        value={startDate}
                        onClick={() => {
                          setStartDateDialogOpen(!startDateDialogOpen);
                        }}
                        onClose={() => {
                          setStartDateDialogOpen(false);
                        }}
                        handleDateChange={(d) => {
                          setStartDate(d);
                        }}
                        minDate={dayjs(new Date()).subtract(1, 'year')}
                      />

                      <CourseFromToLabel>TO</CourseFromToLabel>

                      <MODatePicker
                        open={endDateDialogOpen}
                        text={
                          endDate === null ? '' : endDate.format('DD-MM-YYYY')
                        }
                        value={endDate}
                        onClick={() => {
                          setEndDateDialogOpen(!endDateDialogOpen);
                        }}
                        onClose={() => {
                          setEndDateDialogOpen(false);
                        }}
                        handleDateChange={(d) => {
                          setEndDate(d);
                        }}
                        minDate={dayjs(new Date()).subtract(1, 'year')}
                      />
                    </DurationWrapper>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sub_section_header_action_wrapper}>
            <KababMenu
              onClick={async (id) => {
                if (id === 1) { download_as_excel(); }
                else if (id === 2) { set_forward_xls_dialog_state(true); }
              }}
              options={[{ display_text: 'Download as xlsx', id: 1 },
              { display_text: 'Forward in e-mail', id: 2 }]}
            />
          </div>
        </div>

        <div className={styles.sub_section_header}>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Button2 transparent={false} onClick={() => getLearnerJourney()}>
                    Submit
                  </Button2>
                </FormControl>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Button2 transparent={true} onClick={clearFilters}>
                    Clear Filters
                  </Button2>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sub_section_content}>
          <Paper elevation={2}>
            <div className={classes.table_wrapper}>
              <LearnerJourneyTable
                filter_demographic={filter_demographic}
                loading={loading}
                items={data}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                headerItems={header_items}
              />
            </div>
          </Paper>
        </div>
      </Wrapper>

      <Dialog
        open={download_xls_dialog_state}
        onClose={(e) => {
          set_download_xls_dialog_state(false);
        }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px', textAlign: 'center' }}>
              <Loader fill="#4f0060" />
              <p>Generating and Downloading...</p>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={forward_xls_dialog_state}
        onClose={(e) => {
          set_forward_xls_dialog_state(false);
        }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px' }}>
              <Typography variant="h5" style={{ marginTop: '25px' }}>
                Forward the data to your e-mail
              </Typography>
              <p>The e-mail contains a spreadsheet attachment.</p>
              <TextField
                id="standard-name"
                label="Email"
                error={forward_email_to_error}
                value={forward_email_to}
                onChange={(e) => {
                  set_forward_email_to(e.target.value);
                }}
                margin="normal"
                type="email"
                helperText={forward_email_to_helper_text}
                required
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    forward_in_email();
                  }
                }}
              />

              <br />

              <Button
                style={{ marginTop: '25px' }}
                variant="contained"
                color="primary"
                disabled={forward_xls_button_disabled}
                onClick={forward_in_email}>
                {forward_xls_button_text}
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => set_forward_xls_toast(false)}
        message={<span id="message-id">{forward_xls_toast_message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => set_forward_xls_toast(false)}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}

export default withStyles(custom_styles)(LearnerJourneyTracker);
