import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import { Dialog } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ButtonBase from '@material-ui/core/ButtonBase';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import FormControl from '../../components/FormControl';
import Label from '../../components/Label';
import ClearButton from '../../components/ClearButton';
import TrashIcon from '../../components/MDLIcon/icons/Trash';
import BlockSelector from '../../components/BlockSelector';
import Loader from '../../components/Loader';
import Button2 from '../../components/Button2';

function fnsorter(array_of_objects, property, property_type, direction) {
  const sorted_rows = Array.prototype.sort.call(array_of_objects, (a, b) => {
    let outcome = -1;

    if (property_type === 'string') {
      outcome = a.demographics[property] < b.demographics[property] ? -1 : 1;
    } else if (property_type === 'date') {
      outcome =
        new Date(a.demographics[property]) < new Date(b.demographics[property])
          ? -1
          : 1;
    }
    if (direction === 'desc') return -1 * outcome;
    return outcome;
  });

  return sorted_rows;
}

export default function BatchManagement({
  batches,
  MOClient,
  jwtToken,
  onDeleteBatch,
}) {
  const [rows, setRows] = React.useState([]);
  const [recentBatch, setRecentBatch] = useState(0);
  const [loadingBatches, setLoadingBatches] = useState(false);
  const [text, setText] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('employee_id');
  const [batchId, setBatchId] = React.useState(0);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = React.useState(false);
  const [
    confirmDeleteBatchDialog,
    setConfirmDeleteBatchDialog,
  ] = React.useState(false);
  const [activeLearner, setActiveLearner] = React.useState(null);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorDialogText, setErrorDialogText] = React.useState('');

  useEffect(() => {
    if (batches.length > 0) {
      setBatchId(batches[0].id);
    }
  }, [batches]);

  useEffect(() => {
    const filtered_elems = batches.filter((elem) => elem.id === recentBatch);
    if (filtered_elems.length > 0) setText(filtered_elems[0].display_text);
  }, [batches, recentBatch]);

  // on batch change, get the learners
  React.useEffect(() => {
    function fetch_learners() {
      if (batchId === 0) return;

      setLoadingBatches(true);

      const action = MOClient.get_training_batch_learners({
        token: jwtToken,
        batch_id: batchId,
      });

      action.then((response) => {
        setLoadingBatches(false);
        if (response.status === 'success') {
          setRows(response.data.data);
        }
      });
    }

    fetch_learners();
  }, [batchId, jwtToken, MOClient]);

  let selectedBatchName = '';

  if (batchId !== 0) {
    const the_batch = batches.filter((b) => b.id === batchId);
    if (the_batch.length > 0) {
      let batch_name = the_batch[0].batch_code;
      if (the_batch[0].batch_name !== null) {
        batch_name += ` ${the_batch[0].batch_name}`;
      }
      selectedBatchName = batch_name;
    }
  } else {
    selectedBatchName = '';
  }

  function handleSortClick(sorter) {
    if (order === 'desc') {
      setOrder('asc');
    } else setOrder('desc');

    setOrderBy(sorter);
  }

  function deleteLearner() {
    setConfirmDeleteDialog(false);
    const action_delete = MOClient.delete_training_batch_learner({
      participant_code: activeLearner,
      token: jwtToken,
      batch_id: batchId,
    });

    action_delete.then((outcome) => {
      if (outcome.status === 'success') {
        const temp_rows = [...rows];
        const new_rows = temp_rows.filter(
          (r) => r.participant_code !== activeLearner,
        );
        setRows(new_rows);
      } else {
        setErrorDialogOpen(true);
        setErrorDialogText(outcome.description);
      }
    });
  }

  function deleteBatch() {
    setConfirmDeleteBatchDialog(false);
    onDeleteBatch(batchId);
  }

  const batch_options = batches.map((batch) => {
    let batch_name = batch.batch_code;
    if (batch.batch_name !== null) {
      batch_name += ` ${batch.batch_name}`;
    }
    return {
      id: batch.id,
      display_text: batch_name,
    };
  });

  const sorted_rows = (() => {
    let property_type = 'string';
    if (orderBy === 'launch_date') property_type = 'date';

    return fnsorter(rows, orderBy, property_type, order);
  })();

  return (
    <div style={{ textAlign: 'left' }}>
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <FormControl>
          <Label>Select Batch</Label>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SimpleFormSelect
              autoClose
              options={batch_options}
              text={selectedBatchName}
              onSelect={(id) => {
                setBatchId(id);
              }}
            />

            <ClearButton
              onClick={() => {
                setConfirmDeleteBatchDialog(true);
              }}>
              <div style={{ marginRight: 5, display: 'flex' }}>
                <TrashIcon style={{ fill: '#f4511e' }} />
              </div>
              Delete Batch
            </ClearButton>
          </div>
        </FormControl>

        <FormControl style={{ marginTop: 20 }}>
          <RecentlyAccessedLabel>Recently Accessed:</RecentlyAccessedLabel>
          <BatchSelectorWrapper>
            {batches.slice(batches.length - 3, batches.length).map((b) => (
              <BlockSelector
                key={b.id}
                active={batchId === b.id}
                onClick={() => setBatchId(b.id)}>
                {b.batch_code}
              </BlockSelector>
            ))}
          </BatchSelectorWrapper>
        </FormControl>
      </div>

      <div>
        <hr
          style={{
            border: 'none',
            borderTop: '1px solid rgba(112, 112, 112, 0.2)',
            margin: '30px 0',
          }}
        />
      </div>

      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Table style={{ border: '1px solid rgba(0,0,0,0.1)' }}>
          <TableHead>
            <CustomTableRow>
              <CustomTableCell align="center">
                <TableSortLabel
                  active={orderBy === 'first_name'}
                  direction={order}
                  onClick={() => handleSortClick('first_name')}>
                  Name
                </TableSortLabel>
              </CustomTableCell>

              <CustomTableCell align="center">
                <TableSortLabel
                  active={orderBy === 'employee_id'}
                  direction={order}
                  onClick={() => handleSortClick('employee_id')}>
                  Employee ID
                </TableSortLabel>
              </CustomTableCell>
              <CustomTableCell align="center">
                <TableSortLabel
                  active={orderBy === 'email_id'}
                  direction={order}
                  onClick={() => handleSortClick('email_id')}>
                  E-mail Address
                </TableSortLabel>
              </CustomTableCell>
              <CustomTableCell align="center">
                <TableSortLabel
                  hideSortIcon
                  active={orderBy === 'launch_date'}
                  direction={order}
                  onClick={() => handleSortClick('launch_date')}>
                  Launch Date
                </TableSortLabel>
              </CustomTableCell>

              <CustomTableCell align="center">
                <StyledTabelHeaderLabel hideSortIcon>
                  Learner Control Panel
                </StyledTabelHeaderLabel>
              </CustomTableCell>
            </CustomTableRow>
          </TableHead>
          <TableBody>
            {sorted_rows.map((row) => (
              <CustomTableRow key={row.id}>
                <CustomTableCell align="center">
                  <span style={{ textAlign: 'left' }}>
                    {`${row.demographics.first_name} ${row.demographics.last_name}`}
                  </span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  {row.demographics.employee_id}
                </CustomTableCell>

                <CustomTableCell align="center">
                  <span
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}>
                    {row.demographics.email_id}
                  </span>
                </CustomTableCell>

                <CustomTableCell align="center">
                  {new Date(row.created_at).toLocaleString()}
                </CustomTableCell>

                <CustomTableCell align="center">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    {/* <CurvedButtonBase><EditIcon fill="#707070" /></CurvedButtonBase> */}
                    {/* <CurvedButtonBase><DuplicateIcon fill="#707070" /></CurvedButtonBase> */}
                    <CurvedButtonBase
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setConfirmDeleteDialog(true);
                        setActiveLearner(row.participant_code);
                      }}>
                      <StyledTrashIcon />
                    </CurvedButtonBase>
                  </div>
                </CustomTableCell>

                {/* <CustomTableCell align="center">{row.precision}%</CustomTableCell> */}
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {loadingBatches === true ? <Loader fill="#f4511e" /> : null}
        </div>
      </div>

      <div>
        <hr
          style={{
            border: 'none',
            borderTop: '1px solid rgba(112, 112, 112, 0.2)',
            margin: '30px 0',
          }}
        />
      </div>

      <Dialog
        open={confirmDeleteDialog}
        onClose={() => {
          setConfirmDeleteDialog(false);
        }}>
        <ProgressContainer>
          <ProgressText>
            Are you sure you want to delete this learner?
          </ProgressText>
          <ProgressText>
            <FormControl>
              <Actions>
                <Button2
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteLearner();
                  }}>
                  Yes Delete
                </Button2>
                <Button2
                  onClick={() => setConfirmDeleteDialog(false)}
                  transparent={true}
                  style={{ marginLeft: '5px' }}>
                  Cancel
                </Button2>
              </Actions>
            </FormControl>
          </ProgressText>
        </ProgressContainer>
      </Dialog>

      <Dialog
        open={confirmDeleteBatchDialog}
        onClose={() => {
          setConfirmDeleteBatchDialog(false);
        }}>
        <ProgressContainer>
          <ProgressText>
            Are you sure you want to delete this batch?
          </ProgressText>
          <ProgressText>
            <FormControl>
              <Actions>
                <Button2
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteBatch();
                  }}>
                  Yes Delete
                </Button2>
                <Button2
                  onClick={() => setConfirmDeleteBatchDialog(false)}
                  transparent={true}
                  style={{ marginLeft: '5px' }}>
                  Cancel
                </Button2>
              </Actions>
            </FormControl>
          </ProgressText>
        </ProgressContainer>
      </Dialog>

      <Dialog
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorDialogText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setErrorDialogOpen(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const CurvedButtonBase = styled(ButtonBase)`
  border-radius: 20px;
  margin: 3px;
  padding: 5px;
`;

const RecentlyAccessedLabel = styled(Label)`
  color: #f4511e;
  text-transform: uppercase;
`;

const BatchSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomTableCell = withStyles((theme) => ({
  root: {
    padding: '10px 10px !important',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    justifyContent: '',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '12px',
    color: '#707070',
  },
  head: {
    backgroundColor: '#fbfbfb',
    color: '#484848',
    fontWeight: 'bold',
    display: 'flex',
    minWidth: '100px',
    alignItems: 'center',
    flexBasis: '0',
    flexGrow: '1',
    fontSize: '11px',
  },
}))(TableCell);

const CustomTableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
  },
  head: {
    display: 'flex',
  },
}))(TableRow);

const StyledTrashIcon = styled(TrashIcon)`
  .class-trash-icon-2 {
    fill: #707070;
  }
`;

const StyledTabelHeaderLabel = styled(TableSortLabel)`
  display: block;
  margin: auto;
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;
