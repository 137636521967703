import styled from 'styled-components';

const Flex = styled.div`
  margin-left: ${props => props.gap ? props.gap*-1 : -12}px;
  margin-right: ${props => props.gap ? props.gap*-1 : -12}px;
  @media (min-width: 1024px) {
    display: flex;
    align-items: ${props => props.alignItems ? props.alignItems : "center"};
  }
`;

export default Flex;