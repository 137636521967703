import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import ClearButton from '../../components/ClearButton';

const LearnersModal = ({ learners }) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const renderButton = (
    <ClearButton
      style={{ textDecoration: 'underline', textTransform: 'lowercase' }}
      onClick={openModal}>
      view list
    </ClearButton>
  );
  return (
    <>
      {renderButton}
      <Dialog open={open} fullWidth maxWidth="lg" onClose={closeModal}>
        <DialogTitle>Learners</DialogTitle>
        <DialogContent>
          {learners.map((learner, index) => (
            <div key={index} style={{ marginTop: '10px' }}>
              {index + 1}. {learner}
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

const TargetLearners = ({ learners }) => {
  return (
    <Grid
      container
      style={{ width: '500px', marginTop: '20px' }}
      alignItems="center">
      <Grid item sm={6}>
        {learners.length} learners found
      </Grid>
      <Grid item sm={6}>
        <LearnersModal learners={learners} />
      </Grid>
    </Grid>
  );
};

export default TargetLearners;
