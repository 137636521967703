import React, { useEffect, useState } from 'react';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import SimpleFormSelect from '../../components/SimpleFormSelect';
import MOClient from '../../MOClient';
import { saveAs } from 'file-saver';
import FormResponsesTable from './FormResponsesTable';

const FormResponses = () => {
  const [options, setOptions] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);

  const handleSelect = (id) => {
    const selectedOption = options.find((option) => option.id === id);
    setSelectedForm(selectedOption);
  };

  const handleDownload = () => {
    const token = localStorage.getItem('auth-token');
    const formName = `${
      selectedForm.display_text
    }-responses-${new Date().getTime()}`;
    MOClient.getFormResponsesAsExcel(token, selectedForm.id).then((data) => {
      console.log(data);
      saveAs(data, `${formName}.xlsx`, { autoBOM: true });
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    const companyId = localStorage.getItem('company-id');
    MOClient.getAllForms(token, companyId).then(({ data }) => {
      const formIds = data.map((row) => ({
        ...row,
        display_text: row.name,
      }));
      setOptions(formIds);
    });
  }, []);

  return (
    <div>
      <div>
        <SimpleFormSelect
          onSelect={handleSelect}
          autoClose={true}
          options={options}
          selected={(selectedForm && selectedForm.display_text) || ''}
          text={(selectedForm && selectedForm.display_text) || ''}
        />
      </div>
      <div
        style={{
          textAlign: 'right',
          display: selectedForm ? 'block' : 'none',
        }}>
        <FlatPrimaryButton
          // disabled={selectedForm !== null}
          onClick={handleDownload}>
          Download Excel
        </FlatPrimaryButton>
      </div>
      {selectedForm && <FormResponsesTable formId={selectedForm.id} />}
    </div>
  );
};

export default FormResponses;
