import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import styles from './MISLeaderboard.module.css';
import PrimaryButton from '../../components/PrimaryButton';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';

// imports for dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';

// imports for select
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';

let custom_styles = {
  form_control: {
    minWidth: 150,
    marginTop: 20,
    marginLeft: '10px',
    maxWidth: '250px',
  },
};

function objectify(collection_array, key) {
  let obj = {};
  for (let i = 0; i < collection_array.length; i++) {
    let k = collection_array[i][key];
    obj[k] = collection_array[i];
  }

  return obj;
}

async function fetch_leaderboard_data(
  company_id,
  project_id,
  module_id,
  challenge_ids,
  jwtToken,
) {
  var result = await fetch(
    'https://analytics.masteroapp.com/drive-api/mis-reports/mrn2',
    {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company_id: company_id,
        module_id: module_id,
        project_id: project_id,
        challenge_ids: challenge_ids,
      }),
    },
  );

  var result_blob = await result.blob();

  return {
    headers: result.headers,
    data: result_blob,
  };
}

let challenge_options_obj = {};

function MISLeaderboard(props) {
  let {
    companyId,
    projects,
    modules,
    challenges,
    classes,
    jwtToken,
    reportTitle,
    reportDescription,
  } = props;

  if (typeof projects == 'undefined') {
    projects = [];
  }

  if (typeof modules == 'undefined') {
    modules = [];
  }

  if (typeof challenges == 'undefined') {
    challenges = [];
  }

  let [dialogOpen, setDialogOpen] = useState(false);
  let [selectedProject, setSelectedProject] = useState(0);
  let [selectedModule, setSelectedModule] = useState(0);
  let [selectedChallenge, setSelectedChallenge] = useState([]);
  let [filtered_challenges, setFilteredChallenges] = useState([]);
  let [filtered_modules, setFilteredModules] = useState([]);
  let [downloading, setDownloading] = useState(false);

  let handleClose = () => {
    setDialogOpen(false);
  };

  let handleOpen = () => {
    setDialogOpen(true);
  };

  let handle_project_selection = (e) => {
    let value = e.target.value;

    setSelectedProject(value);
  };

  let handle_module_selection = (e) => {
    let value = e.target.value;

    setSelectedModule(value);
  };

  let handle_challenge_selection = (e) => {
    let value = e.target.value;

    setSelectedChallenge(value);
  };

  let handleDownload = async (e) => {
    setDownloading(true);

    var result = await fetch_leaderboard_data(
      companyId,
      selectedProject,
      selectedModule,
      selectedChallenge,
      jwtToken,
    );

    let title =
      project_options_obj[selectedProject]['name'] +
      '-' +
      module_options_obj[selectedModule]['name'] +
      '-leaderboard.xlsx';

    saveAs(result.data, title, { autoBOM: true });

    setDownloading(false);
  };

  // effects

  let project_options_obj = objectify(projects, 'id');
  let module_options_obj = objectify(modules, 'id');

  useEffect(() => {
    // memoize challenge ids
    challenge_options_obj = objectify(challenges, 'id');
  }, [challenges]);

  // effect for project change
  useEffect(() => {
    setSelectedModule(0);
    let filtered_modules = modules.filter(
      (o) => o.project_id === Number(selectedProject),
    );
    setFilteredModules(filtered_modules);
  }, [selectedProject]);

  useEffect(() => {
    setSelectedChallenge([]);
    let filtered_challenges = challenges.filter(
      (o) => o.module_id === Number(selectedModule),
    );
    setFilteredChallenges(filtered_challenges);
  }, [selectedModule]);

  // layout items

  let project_options = projects.map((project) => {
    return (
      <MenuItem key={project.id} value={project.id}>
        {project.name}
      </MenuItem>
    );
  });

  let module_options = filtered_modules.map((item) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    );
  });

  let challenge_options = filtered_challenges.map((item) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        <Checkbox checked={selectedChallenge.indexOf(item.id) > -1} />
        <ListItemText primary={item.name} />
      </MenuItem>
    );
  });

  return (
    <Paper elevation={2}>
      <h5 className={styles.title}>{reportTitle}</h5>
      <p className={styles.caption}>{reportDescription}</p>

      <div className={styles.primary_action}>
        <PrimaryButton text="Download" onClick={handleOpen}></PrimaryButton>
      </div>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Download Leaderboard'}
        </DialogTitle>
        <DialogContent>
          <div>
            <FormControl classes={{ root: classes.form_control }}>
              <InputLabel>Project</InputLabel>

              <Select
                value={selectedProject}
                onChange={handle_project_selection}>
                {project_options}
              </Select>
            </FormControl>
          </div>

          <div>
            <FormControl classes={{ root: classes.form_control }}>
              <InputLabel>Microskill</InputLabel>

              <Select value={selectedModule} onChange={handle_module_selection}>
                {module_options}
              </Select>
            </FormControl>
          </div>

          <div>
            <FormControl classes={{ root: classes.form_control }}>
              <InputLabel>Challenge</InputLabel>

              <Select
                multiple
                value={selectedChallenge}
                onChange={handle_challenge_selection}
                renderValue={(selected) => {
                  let o = [];

                  o = selected.map((s) => {
                    return challenge_options_obj[s]['name'];
                  });

                  return o.join(', ');
                }}>
                {challenge_options}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDownload}
            color="primary"
            autoFocus
            disabled={downloading === true ? true : false}>
            {downloading ? 'Downloading' : 'Download'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default withStyles(custom_styles)(MISLeaderboard);
