import { Grid } from '@material-ui/core';
import React from 'react';
import FormControl from '../../components/FormControl';
import Questions from './Questions';
import SubmitForm from './SubmitForm';
import { makeStyles } from '@material-ui/core';
import FlatClearButton from '../../components/FlatClearButton';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles({
  button: {
    padding: '10px 20px !important',
    width: '200px',
    height: '46px',
    marginRight: '1rem',
  },
});

function AddQuestion({ onClick, secondary }) {
  const classes = useStyles();
  return (
    <>
      {secondary ? (
        <FlatClearButton onClick={onClick} className={classes.button}>
          <Grid container alignItems="center">
            <Grid item md={2}>
              <Add />
            </Grid>
            <Grid item md={10}>
              Add Question
            </Grid>
          </Grid>
        </FlatClearButton>
      ) : (
        <FlatPrimaryButton onClick={onClick} className={classes.button}>
          <Grid container alignItems="center">
            <Grid item md={2}>
              <Add />
            </Grid>
            <Grid item md={10}>
              Add Question
            </Grid>
          </Grid>
        </FlatPrimaryButton>
      )}
    </>
  );
}

const FormDetails = ({
  questions,
  formMode,
  onAddQuestion,
  onSelectQuestionType,
  onQuestionUpdate,
  onSubmit,
  isAssessment,
  onDeleteQuestion,
  name,
}) => {
  const viewOnly = formMode !== 'VIEW_FORM';

  const validateForm = (name) => {
    console.log(name);
  };
  validateForm(name);
  const validateQuestions = (question) => {
    console.log(question);
    if (question.type == null || question.type == undefined) return false;
    if (question.question == '' || question.question.length < 3) return false;
    if (question.type == 'SINGLE_CHOICE_QUESTION' || question.type == 'MULTI_CHOICE_QUESTION') {
      if (question.choices.length < 1) return false;
      const validateChoices = question.choices.every((choice) => {
        if (choice.type == 'open') return true;
        if (choice.value.length < 1) return false;
        return true;
      });
      return validateChoices;
    };
    return true;
  };

  const validFormName = name.length > 3;
  const validQuestions = questions.length > 0 ? questions.every(validateQuestions) : false;

  return (
    <>
      <Questions
        isAssessment={isAssessment}
        questions={questions}
        onSelectQuestionType={onSelectQuestionType}
        onQuestionUpdate={onQuestionUpdate}
        onDeleteQuestion={onDeleteQuestion}
      />
      <Grid container alignItems="center">
        {viewOnly &&
          <>
            <FormControl>
              <AddQuestion
                onClick={onAddQuestion}
                secondary={questions.length > 0}
              />
            </FormControl>
            {validQuestions && validFormName ?
              <SubmitForm onSubmit={onSubmit} /> :
              <SubmitForm titleText = {'disabled icon'} onSubmit={() => { }} style={{ backgroundColor: 'grey' }} />}
          </>}
      </Grid>
    </>
  );
};

export default FormDetails;
