import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import styles from './TeamReportTracker.module.css';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import KababMenu from '../../components/KababMenu';
import Loader from '../../components/Loader';
import MOTable from '../../components/MOTable';
import MOClient from '../../MOClient';
import { saveAs } from 'file-saver';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MOSelect from '../../components/MOSelect';
import MOSelect2 from '../../components/MOSelect2';
import Label from '../../components/Label';
import MODatePicker from '../../components/MODatePicker';
import ClearButton from '../../components/ClearButton';
import { TableContainer, TablePagination } from '@material-ui/core';
import TopBarWrapper from '../../components/TopBarWrapper';
import MOTopBar from '../../components/MOTopBar';
import Button2 from '../../components/Button2';
import Utils from '../../utils';

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;

const Wrapper = styled.div`
  padding: 30px;
  padding-top: 10px;
`;

const Header = styled.h4`
  font-size: 30px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
  font: bold normal 800 35px/387px Montserrat;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
`;

var custom_styles = {
  input_label: {
    fontSize: '0.7rem',
  },
  filled_input: {
    backgroundColor: 'white',
    fontSize: '0.8rem',
  },
  filled_input_menu: {
    fontSize: '0.8rem',
  },
  form_control: {
    width: '100%',
    marginTop: 0,
  },
  sub_section_header: {
    fontWeight: 'bold',
  },
  sub_section_action: {
    fontWeight: 'bold',
  },

  icon: {
    color: 'white',
  },
  table_wrapper: {
    overflowX: 'auto',
  },
};

function objectify(collection_array, key) {
  let obj = {};
  for (let i = 0; i < collection_array.length; i++) {
    let k = collection_array[i][key];
    obj[k] = collection_array[i];
  }

  return obj;
}

let TeamReportTable = function (props) {
  if (props.loading === true) {
    return <Loader fill="#4f0060" />;
  }

  let { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = props;

  let renderKeys = [
    {
      name: 'first_name',
    },
    {
      name: 'role',
    },
    {
      name: 'totalMembers'
    },
    {
      name: 'challenges_launched',
    },
    {
      name: 'challenges_completed',
    },
    {
      name: 'averageCompletionsPercentageForGames', suffix: '%'
    },
    {
      name: 'total_first_attempt_score',
    },
  ];

  let { headerItems, items } = props;

  if (items.length === 0) {
    return (
      <Typography
        variant="caption"
        className={styles.no_data_available_caption}>
        No data is available at the moment
      </Typography>
    );
  } else {
    return (
      <TableContainer style={{ paddingBottom: '40px' }}>
        <MOTable
          renderKeys={renderKeys}
          rows={items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          headerItems={headerItems}
          order={'desc'}
          orderByColumn={2}
        />
        <TablePagination
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableContainer>
    );
  }
};

function TeamReportTracker(props) {
  let [loading, set_loading] = useState(false);
  let [data, setData] = useState([]);
  let [page, setPage] = React.useState(0);
  let [rowsPerPage, setRowsPerPage] = React.useState(25);
  let [allProjectsSelected, setAllProjectsSelected] = useState(false);
  let [allHeadsSelected, setAllHeadsSelected] = useState(false);
  let [allRolesSelected, setAllRolesSelected] = useState(false);
  let [allLevelSelected, setAllLevelSelected] = useState(false);

  const handleChangePage = (event, newPage) => { setPage(newPage); };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // categories, categoryFeature,
  const { activeProject, company_id, classes, jwtToken, timestampFeature, projects } = props;

  let [teamHeads, setTeamHeads] = useState({});

  let [user_roles, set_user_roles] = useState([]);
  let [get_column_in, set_get_column_in] = useState([]);
  let [seleted_user_roles, set_selected_user_roles] = useState([]);
  let [selected_get_column_in, set_selected_get_column_in] = useState([]);

  let [list_team_heads_by, set_list_team_heads_by] = useState([]);
  let [list_team_heads, set_list_team_heads] = useState([]);

  let [selected_team_heads_by, set_selected_team_heads_by] = useState('');
  let [selected_team_heads, set_selected_team_heads] = useState([]);

  let [selected_projects, set_selected_projects] = useState([activeProject]);

  let projects_obj = objectify(projects, 'id');

  // dialog
  let [download_xls_dialog_state, set_download_xls_dialog_state] = useState(false);
  let [forward_xls_dialog_state, set_forward_xls_dialog_state] = useState(false);
  let [forward_email_to, set_forward_email_to] = useState(null);
  let [forward_xls_toast_message, set_forward_xls_toast_message] = useState('');

  let [forward_xls_toast, set_forward_xls_toast] = useState(false);
  let [forward_email_to_error, set_forward_email_to_error] = useState(false);
  let [forward_email_to_helper_text, set_forward_email_to_helper_text] = useState('');

  let [forward_xls_progress, set_forward_xls_progress] = useState(false);
  let [forward_xls_button_disabled, set_forward_xls_button_disabled] = useState(false);

  let [forward_xls_button_text, set_forward_xls_button_text] = useState('Send');

  let [startDate, setStartDate] = React.useState(null);
  let [endDate, setEndDate] = React.useState(null);

  let [startDateDialogOpen, setStartDateDialogOpen] = React.useState(false);
  let [endDateDialogOpen, setEndDateDialogOpen] = React.useState(false);


  useEffect(() => {
    async function fetchData() {
      let data = await MOClient.get_team_report_options({ company_id, token: jwtToken, project_id: activeProject });
      if (data.status === 'success') {
        let mappedHeads = data.mappedTeamHeads;
        let mappedRoles = data.teamRoles['user_role'];
        setTeamHeads(mappedHeads);
        set_user_roles(mappedRoles);
        set_get_column_in(Object.keys(mappedHeads));
        if (Object.keys(mappedHeads).length > 0) {
          set_list_team_heads_by(Object.keys(mappedHeads));
          set_selected_team_heads_by(Object.keys(mappedHeads)[0]);
          set_list_team_heads(Object.values(mappedHeads)[0]);
          set_selected_team_heads([Object.values(mappedHeads)[0][0]]);
        }
      }
      set_selected_projects([activeProject]);
    }

    fetchData();
    return () => { };
  }, [activeProject]);

  let display_list_team_heads_by = list_team_heads_by.map((heads_by) => {
    return { display_text: heads_by, id: heads_by };
  });

  let display_list_team_heads = list_team_heads.map((heads) => {
    return { display_text: heads, id: heads };
  });

  let display_user_roles = user_roles.map((heads) => {
    return { display_text: heads, id: heads };
  });

  let display_get_column_in = get_column_in.map((heads) => {
    return { display_text: heads, id: heads };
  });

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  let gettingTeamReportData = false;

  const getTeamReport = async () => {
    if (gettingTeamReportData === false) {
      gettingTeamReportData = true;
      let isMounted = true;

      if (selected_team_heads_by !== '') {
        let opt = {
          token: jwtToken,
          company_id: props.companyInfo.company_id,
          head_column: selected_team_heads_by,
          heads: selected_team_heads,
          project_ids: selected_projects,
          head_in_member_column: selected_get_column_in,
          member_roles: seleted_user_roles,
          filters: {},
        };

        if (startDate !== null) opt.filters.start_date = startDate;
        if (endDate !== null) opt.filters.end_date = endDate;

        set_loading(true);
        let result = await MOClient.get_team_report_data(opt);
        if (isMounted === true) {
          if (result != null) {
            setData(result);
          } else {
            setData([]);
          }
          set_loading(false);
        }
      }
      gettingTeamReportData = false;
    }
  };

  async function download_as_excel() {
    set_download_xls_dialog_state(true);

    let opt = {
      token: jwtToken,
      company_id: props.companyInfo.company_id,
      head_column: selected_team_heads_by,
      heads: selected_team_heads,
      project_ids: selected_projects,
      head_in_member_column: selected_get_column_in,
      member_roles: seleted_user_roles,
      filters: {},
    };

    if (startDate !== null) opt.filters.start_date = startDate;
    if (endDate !== null) opt.filters.end_date = endDate;

    let result = await MOClient.team_report_excel(opt);
    saveAs(result, "Team-Report.xlsx", { autoBOM: true });
    set_download_xls_dialog_state(false);
  }

  async function forward_in_email() {
    // if (selectedModules.length === 0) return;

    let opt = {
      token: jwtToken,
      demographic: selected_team_heads_by,
      demographic_value: 'All',
      project_id: props.activeProject,
      project_ids: selected_projects,
      // module_id: selectedModules,
      to_email_address: forward_email_to,
      filters: {},
    };

    // if (selectedChallenges.length > 0) opt.filters.challenge_id = selectedChallenges

    if (startDate !== null) opt.filters.start_date = startDate;
    if (endDate !== null) opt.filters.end_date = endDate;

    let demographic_key = selected_team_heads_by.toLowerCase();

    if (props.userInfo.permissions.demographics.hasOwnProperty(demographic_key)) {
      opt.demographic_value = props.userInfo.permissions.demographics[demographic_key];
      if (props.userInfo.permissions.demographics[demographic_key] === 'All') {
        // opt.demographic_value = selected_demographic_values;
      }
    }

    if (forward_email_to === '' || forward_email_to === null) {
      // special error handling
      set_forward_email_to_error(true);
      set_forward_email_to_helper_text('Email is requried');
    } else {
      set_forward_xls_progress(true);
      var action = await MOClient.forward_email_completion_data_excel(opt);

      if (action === true) {
        set_forward_xls_dialog_state(false);
        set_forward_xls_toast_message('Email sent successfully!');
        set_forward_xls_toast(true);
      } else {
        set_forward_xls_toast_message('Email could not be sent, please try again?',);
        set_forward_xls_toast(true);
      }

      set_forward_xls_progress(false);
    }
  }

  useEffect(() => {
    if (forward_email_to === '') {
      set_forward_email_to_error(true);
      set_forward_email_to_helper_text('Email is requried');
    } else {
      set_forward_email_to_error(false);
      set_forward_email_to_helper_text('');
    }
  }, [forward_email_to]);

  useEffect(() => {
    if (forward_xls_progress === true) {
      set_forward_xls_button_disabled(true);
      set_forward_xls_button_text('Sending');
    } else {
      set_forward_xls_button_disabled(false);
      set_forward_xls_button_text('Send');
    }
  }, [forward_xls_progress]);

  const selectAllProjects = () => {
    let selected_id = projects.map((mod) => mod.id);
    set_selected_projects(selected_id);
    setAllProjectsSelected(true);
  };

  const deselectAllProjects = () => {
    let selected_id = [];
    selected_id.push(activeProject);
    set_selected_projects(selected_id);
    setAllProjectsSelected(false);
  };

  useEffect(() => {
    if (selected_team_heads_by !== '') {
      set_selected_team_heads([teamHeads[selected_team_heads_by][0]]);
    }
  }, [selected_team_heads_by]);

  const selectAllHeads = () => {
    set_selected_team_heads(teamHeads[selected_team_heads_by]);
    setAllHeadsSelected(true);
  };

  const deselectAllHeads = () => {
    set_selected_team_heads([teamHeads[selected_team_heads_by][0]]);
    setAllHeadsSelected(false);
  };

  const selectAllRoles = () => {
    set_selected_user_roles([...user_roles]);
    setAllRolesSelected(true);
  };

  const deselectAllRoles = () => {
    set_selected_user_roles([]);
    setAllRolesSelected(false);
  };

  const selectAllLevel = () => {
    set_selected_get_column_in([...get_column_in]);
    setAllLevelSelected(true);
  };

  const deselectAllLevel = () => {
    set_selected_get_column_in([]);
    setAllLevelSelected(false);
  };

  let project_options = projects.map((project) => {
    return { display_text: project.name, id: project.id };
  });

  let header_items = [
    {
      id: 0,
      name: 'Team Head Name',
    },
    {
      id: 1,
      name: 'Role',
    },
    {
      id: 2,
      name: 'Members',
    },
    {
      id: 3,
      name: 'Challenges Launched',
    },
    {
      id: 4,
      name: 'Challenges Completed',
    },
    {
      id: 5,
      name: 'Average Score Percentage',
    },
    {
      id: 6,
      name: 'Average Total Score',
    },
  ];

  return (
    <div>
      <TopBarWrapper>
        <MOTopBar>
          <Header color="#0043b5">Team Report</Header>
          <div className={classes.top_bar_action_icon}>
            <div className={classes.top_bar_action_button}>
              Last Updated on: {Utils.syncTimer(props.companyInfo.company_id)}
            </div>
          </div>
        </MOTopBar>
      </TopBarWrapper>
      <Wrapper>
        <div className={styles.sub_section_header}>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>View By:</Label>
                  <MOSelect
                    options={display_list_team_heads_by}
                    onSelect={(value) => {
                      set_selected_team_heads_by(value);
                      set_list_team_heads(teamHeads[value]);
                    }}
                    text={selected_team_heads_by}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>{selected_team_heads_by} By:</Label>
                  <MOSelect2
                    multiple={true}
                    selected={selected_team_heads}
                    text={selected_team_heads
                      .map((s) => s)
                      .join(', ')}
                    options={display_list_team_heads}
                    onSelect={(values) => {
                      set_selected_team_heads(values);
                    }}
                  />
                  <ClearButton
                    onClick={
                      allHeadsSelected
                        ? deselectAllHeads
                        : selectAllHeads
                    }>
                    {allHeadsSelected ? `Undo Selection` : `Select All`}
                  </ClearButton>
                </FormControl>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>Learners by Role:</Label>
                  <MOSelect2
                    multiple={true}
                    selected={seleted_user_roles}
                    text={seleted_user_roles
                      .map((s) => s)
                      .join(', ')}
                    options={display_user_roles}
                    onSelect={(values) => {
                      set_selected_user_roles(values);
                    }}
                  />
                  <ClearButton
                    onClick={
                      allRolesSelected
                        ? deselectAllRoles
                        : selectAllRoles
                    }>
                    {allRolesSelected ? `Undo Selection` : `Select All`}
                  </ClearButton>
                </FormControl>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Label>Head as in:</Label>
                  <MOSelect2
                    multiple={true}
                    selected={selected_get_column_in}
                    text={selected_get_column_in
                      .map((s) => s)
                      .join(', ')}
                    options={display_get_column_in}
                    onSelect={(values) => {
                      set_selected_get_column_in(values);
                    }}
                  />
                  <ClearButton
                    onClick={
                      allLevelSelected
                        ? deselectAllLevel
                        : selectAllLevel
                    }>
                    {allLevelSelected ? `Undo Selection` : `Select All`}
                  </ClearButton>
                </FormControl>
              </div>
              {timestampFeature && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '1rem',
                  }}>
                  <FormControl style={{ marginRight: 20 }}>
                    <Label>Project By:</Label>
                    <MOSelect2
                      multiple={true}
                      selected={selected_projects}
                      text={selected_projects
                        .map((s) => projects_obj[s]['name'])
                        .join(', ')}
                      options={project_options}
                      onSelect={(values) => {
                        set_selected_projects(values);
                      }}
                    />
                    <ClearButton
                      onClick={
                        allProjectsSelected
                          ? deselectAllProjects
                          : selectAllProjects
                      }>
                      {allProjectsSelected ? `Undo Selection` : `Select All`}
                    </ClearButton>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.sub_section_header_title}>
          <div className={styles.sub_section_header_filters}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                marginTop: '1rem',
              }}>
              <div style={{ marginRight: 30 }}>
                <FormControl>
                  <Label>Duration:</Label>
                  <DurationWrapper>
                    <MODatePicker
                      open={startDateDialogOpen}
                      text={
                        startDate === null ? '' : startDate.format('DD-MM-YYYY')
                      }
                      value={startDate}
                      onClick={() => {
                        setStartDateDialogOpen(!startDateDialogOpen);
                      }}
                      onClose={() => {
                        setStartDateDialogOpen(false);
                      }}
                      handleDateChange={(d) => {
                        setStartDate(d);
                      }}
                      minDate={dayjs(new Date()).subtract(1, 'year')}
                    />

                    <CourseFromToLabel>TO</CourseFromToLabel>

                    <MODatePicker
                      open={endDateDialogOpen}
                      text={
                        endDate === null ? '' : endDate.format('DD-MM-YYYY')
                      }
                      value={endDate}
                      onClick={() => {
                        setEndDateDialogOpen(!endDateDialogOpen);
                      }}
                      onClose={() => {
                        setEndDateDialogOpen(false);
                      }}
                      handleDateChange={(d) => {
                        setEndDate(d);
                      }}
                      minDate={dayjs(new Date()).subtract(1, 'year')}
                    />
                  </DurationWrapper>
                </FormControl>
              </div>
              <div className={styles.sub_section_header_action_wrapper}>
                <KababMenu
                  onClick={async (id) => {
                    if (id === 1) { download_as_excel(); }
                    else if (id === 2) { set_forward_xls_dialog_state(true); }
                  }}
                  options={[{ display_text: 'Download as xlsx', id: 1 },
                    // { display_text: 'Forward in e-mail', id: 2 }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sub_section_header}>
          <div className={styles.sub_section_header_title}>
            <div className={styles.sub_section_header_filters}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Button2 transparent={false} onClick={() => getTeamReport()}>
                    Submit
                  </Button2>
                </FormControl>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FormControl style={{ marginRight: 20 }}>
                  <Button2 transparent={true} onClick={clearFilters}>
                    Clear Filters
                  </Button2>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sub_section_content}>
          <Paper elevation={2}>
            <div className={classes.table_wrapper}>
              <TeamReportTable
                loading={loading}
                items={data}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                headerItems={header_items}
              />
            </div>
          </Paper>
        </div>
      </Wrapper>

      <Dialog
        open={download_xls_dialog_state}
        onClose={(e) => {
          set_download_xls_dialog_state(false);
        }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px', textAlign: 'center' }}>
              <Loader fill="#4f0060" />
              <p>Generating and Downloading...</p>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={forward_xls_dialog_state}
        onClose={(e) => {
          set_forward_xls_dialog_state(false);
        }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '320px' }}>
              <Typography variant="h5" style={{ marginTop: '25px' }}>
                Forward the data to your e-mail
              </Typography>
              <p>The e-mail contains a spreadsheet attachment.</p>
              <TextField
                id="standard-name"
                label="Email"
                error={forward_email_to_error}
                value={forward_email_to}
                onChange={(e) => {
                  set_forward_email_to(e.target.value);
                }}
                margin="normal"
                type="email"
                helperText={forward_email_to_helper_text}
                required
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    forward_in_email();
                  }
                }}
              />

              <br />

              <Button
                style={{ marginTop: '25px' }}
                variant="contained"
                color="primary"
                disabled={forward_xls_button_disabled}
                onClick={forward_in_email}>
                {forward_xls_button_text}
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => set_forward_xls_toast(false)}
        message={<span id="message-id">{forward_xls_toast_message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => set_forward_xls_toast(false)}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}

export default withStyles(custom_styles)(TeamReportTracker);
