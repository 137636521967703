import React, { useRef, useEffect, useState } from 'react';
import {
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import FileSelect from '../components/FileSelect';
import SimpleFormSelect from '../components/SimpleFormSelect';
import FlatPrimaryButton from '../components/FlatPrimaryButton';
import FormControl from '../components/FormControl';
import Label from '../components/Label';
import StyledDownloadLink from '../components/StyledDownloadLink';
import { Dialog } from '@material-ui/core';
import Loader from '../components/Loader';
import Button2 from '../components/Button2';
import TopBarWrapper from '../components/TopBarWrapper';
import styled from 'styled-components';
import StyledLabel from '../components/Label';
import TextArea from '../components/TextArea';
import TextInput from '../components/TextInput';
import FormFilters from './FormsTab/FormFilters';
import MOClient from '../MOClient';
import { CSVReader } from 'react-papaparse';


export default function Notify(props) {
  console.log(props);
  const [loading, setLoading] = React.useState(false);
  const [progressText, setProgressText] = React.useState('Please wait...');
  const [errorText, setErrorText] = React.useState(
    'The CSV file is invalid or contains no learners, please try again!',
  );
  const [uploadFile, setUploadFile] = React.useState(null);
  const [progressDialog, setProgressDialog] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [failureDialog, setFailureDialog] = React.useState(false);

  const [demographicValues, setDemographicValues] = React.useState(null);

  const [radioValue, setRadioValue] = React.useState('project');
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [target, setTarget] = useState({});
  const [learners, setLearners] = useState([]);
  // const [usersList, setUsersList] = useState([]);
  const targetTypes = ['project', 'csv'];

  let fileSelectRef = useRef(null);
  const handleTargetChange = (target) => {
    setTarget(target);
  };

  // const handleOnDrop = (data) => {
  //   const userList = [];
  //   data.filter((v, i) => {
  //     if (v.data[0] === null || v.data[0] === '' || i === 0) {
  //       return false;
  //     } else {
  //       userList.push(v.data[0]);
  //       return true;
  //     }
  //   });
  //   setUsersList(userList);
  // };

  // const handleOnError = (e) => {
  //   console.log(e);
  // };

  // const handleOnRemoveFile = (e) => {
  //   console.log(e);
  // };

  const companyInfo = JSON.parse(localStorage.getItem('company-info'));
  const companyId = localStorage.getItem('company-id');
  const token = localStorage.getItem('auth-token');
  const projects = companyInfo.projects;

  useEffect(() => {
    let updatedTarget = {
      ...target,
    };
    console.log(target);

    const allProjectsSelected = (updatedTarget.proj_id && updatedTarget.proj_id === '*') || updatedTarget.proj_id == null;
    // console.log(allProjectsSelected);
    const companyInfo = JSON.parse(localStorage.getItem('company-info'));
    const projects = companyInfo.projects;

    if (allProjectsSelected) {
      const projectIds = projects.map((project) => project.id);
      updatedTarget = {
        ...target,
        proj_id: projectIds,
      };
    } else {
      updatedTarget = {
        ...target,
        proj_id: [
          projects.find((project) => project.name === target.proj_id).id,
        ],
      };
    }
    console.log({ updatedTarget });

    MOClient.getTargetLearnersForForm(token, {
      target: updatedTarget,
      companyId,
    }).then(({ data }) => {
      setLearners(data.map((learner) => learner.email));
    });
  }, [target]);

  useEffect(() => {
    MOClient.get_unique_demographics(token, companyId).then(({ data }) => {
      const demographics =
        (companyInfo && companyInfo.meta && companyInfo.meta.demographics) ||
        [];
      console.log({ demographics });
      const filteredData = [
        {
          name: 'Project',
          key: 'proj_id',
          values: projects.map((project) => project.name),
        },
      ];

      for (let demographic of demographics) {
        if (
          data[demographic.mapping_key] &&
          data[demographic.mapping_key].length > 0
        ) {
          const newData = {
            name: demographic.name,
            key: demographic.mapping_key,
            values: data[demographic.mapping_key],
          };
          filteredData.push(newData);
        }
      }
      setDemographicValues(filteredData);
    });
  }, []);
  function onFileSelect(file) {
    setUploadFile(file[0]);
  }

  const handleTextChange = (setFunc) => {
    return (text) => {
      setFunc(text);
    };
  };

  const handleTitleChange = (text) => {
    if (text.length <= 80) {
      setTitle(text);
    }
  };

  const handleDescriptionChange = (text) => {
    if (text.length <= 500) {
      setDescription(text);
    }
  };

  function runValidation() {
    if (uploadFile === null) {
      setErrorText('Please select the upload File');
      setFailureDialog(true);
      return false;
    }
    return true;
  }

  const onHandleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleDesignationAndProj = (fieldsData) => {
    const action = props.MOClient.notify_user_projects(token,
      {
        target,
        companyId,
        fieldsData,
      }
    );
    // console.log({ target })
    action.then((outcome) => {
      if (outcome.status !== 'success') {
      } else {
        console.log(outcome.message);
      }
    });
  }
  // console.log({ target })

  return (
    <div style={{ textAlign: 'left', padding: '10px 30px 30px' }}>
      <FormControl>
        <Grid container style={{ width: '500px' }} alignItems="center">
          <Grid item sm={3}>
            <StyledLabel style={{ padding: '0px' }}>Targeting:</StyledLabel>
          </Grid>
          <Grid item sm={9}>
            <RadioGroup value={radioValue} onChange={(e) => onHandleRadioChange(e)}>
              <Grid direction="row" container alignItems="center">
                <Grid item sm={6}>
                  <FormControlLabel
                    value="project"
                    label="Project-Wise"
                    control={<Radio />}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value="csv"
                    label="CSV"
                    control={<Radio />}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
        <>
          {radioValue !== targetTypes[0] && (
            <>
              <FileSelect ref={fileSelectRef} onFileSelect={onFileSelect} />
              <StyledDownloadLink
                href="https://mastero-drive.s3.ap-south-1.amazonaws.com/notify_user.csv"
                style={{ marginLeft: 10 }}
              >
                Download File Format
              </StyledDownloadLink>
            </>
          )}
          <div style={{ paddingTop: '20px' }} />
        </>
        <>
          {radioValue === targetTypes[0] ? (
            <FormFilters
              demographics={demographicValues}
              onUpdate={handleTargetChange}
              target={target}
              learners={learners}
            />
          ) : (
            <div>
              <span style={{ fontSize: '0.85rem', color: '#707070' }}>
                Please select a CSV file to upload.
              </span>
            </div>
          )}
        </>
        {radioValue === targetTypes[1] && (
          <>
            <p style={{ fontSize: '0.85rem', color: '#707070' }}>
              Mandatory Fields:
            </p>
            <ul style={{ fontSize: '0.85rem', color: '#707070' }}>
              <li>User Email</li>
            </ul>
          </>
        )}
      </FormControl>

      <FormControl>
        <StyledLabel>Notification Title</StyledLabel>
        <TextInput
          value={title}
          onChangeText={handleTextChange(handleTitleChange)}
        />
      </FormControl>
      <FormControl>
        <StyledLabel>Notification Description</StyledLabel>
        <TextArea
          value={description}
          text={description}
          onChangeText={handleTextChange(handleDescriptionChange)}
        />
      </FormControl>

      <FormControl style={{ marginTop: '15px' }}>
        <FlatPrimaryButton
          onClick={() => {
            // determine if the CSV file is valid
            // check for value in radioValue
            if (radioValue === targetTypes[1]) {
              let valid = runValidation();
              if (!valid) return;
            }
            if (title === '' || description === '') {
              setErrorText('Please fill in the notification details.');
              setFailureDialog(true);
              return;
            }
            setProgressDialog(true);
            var fieldsData = new FormData();
            fieldsData.append('mode', radioValue);
            if (radioValue === targetTypes[0]) {
              fieldsData.append('userEmails', learners);
              fieldsData.append('user_list', null);
            } else {
              fieldsData.append('userEmails', []);
              fieldsData.append('user_list', uploadFile);
            }
            fieldsData.append('notif_title', title);
            fieldsData.append('notif_body', description);

            if (radioValue === targetTypes[1]) {
              let action_check_csv = MOClient.csv_notify_users_form_upload({
                token: props.jwtToken,
                body: fieldsData,
              });

              action_check_csv.then((outcome) => {
                if (radioValue === targetTypes[1]) fileSelectRef.current.resetForm();
                setDescription('');
                setTitle('');
                if (outcome.status !== 'success') {
                  setProgressDialog(false);
                  setErrorText(outcome.description);
                  setFailureDialog(true);
                  setUploadFile(null);
                  return;
                } else {
                  setErrorText('outcome');
                  setSuccessDialog(true);
                }
              });
            }
            else {
              fieldsData['notif_title'] = title;
              fieldsData['notif_body'] = description;
              handleDesignationAndProj(fieldsData);
              setSuccessDialog(true);
            }
          }}
        >

          Notify Users
        </FlatPrimaryButton>
      </FormControl>

      {/* Progress Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialog}
        onClose={() => {
          setProgressDialog(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />

          <ProgressText>{progressText}</ProgressText>
        </ProgressContainer>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={successDialog}
        onClose={() => {
          setSuccessDialog(false);
          setProgressDialog(false);
        }}>
        <ProgressContainer>
          <img src={require('../assets/tick.png')} style={{ width: '50px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Notification rolled out Successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setSuccessDialog(false);
                setProgressDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Failure Dialog */}
      <Dialog
        open={failureDialog}
        onClose={() => {
          setFailureDialog(false);
          setProgressDialog(false);
        }}>
        <ProgressContainer>
          <img src={require('../assets/cross.png')} style={{ width: '50px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorText}</ProgressText>
          </div>

          <Button2
            onClick={() => {
              setFailureDialog(false);
              setProgressDialog(false);
            }}>
            Close
          </Button2>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;