import React from "react";
import styled from "styled-components";

const LabelWrapper = styled.div`
  display: flex;
  background-color: ${props => props.backgroundColor};
  color: white;
  width: ${props => props.multiplier * 156}px;
  height: ${props => props.multiplier * 27}px;
  display: flex;
  align-items: center;
`;

const Grip = styled.div`
  width: ${props => props.multiplier * 9}px;
  height: 100%;
`;

const Label = styled.div`
  height: 100%;
  flex-grow: 1;
  background-color: rgba(255,255,255,0.80);
  display: flex;
  align-items: center;
  padding-left: ${props => props.multiplier * 9}px;
  
`;

const Text = styled.h4`
  font-family: "Open Sans";
  font-size: ${props => props.multiplier * 10}px;
  text-align: left;
  color: #707070;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${props => props.multiplier * 130}px;
`;


export default (props) => {
  let multiplier = 1;
  if(props.hasOwnProperty("multiplier")) {
    multiplier = props.multiplier;
  }

  return (
    <LabelWrapper backgroundColor={props.backgroundColor} multiplier={multiplier}>
      <Grip multiplier={multiplier}></Grip>
      <Label multiplier={multiplier}>
        <Text multiplier={multiplier}>{props.text}</Text>
      </Label>
    </LabelWrapper>
  )
}


