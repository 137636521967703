import React from 'react';
import { ChoiceQuestionOption } from './ChoiceQuestionOption';
import { ChoiceQuestion } from './ChoiceQuestion';
import { RatingQuestionOption } from './RatingQuestionOption';
import { RatingQuestion } from './RatingQuestion';
import { DateQuestionOption } from './DateQuestionOption';
import { DateQuestion } from './DateQuestion';
import { FileUploadOption } from './FileUploadOption';
import { FileUploadQuestion } from './FileUploadQuestion';

const QuestionTypes = ({
  question,
  index,
  onSelect,
  onUpdateQuestion,
  isAssessment,
  onDelete,
}) => {
  const selectedOption = React.useMemo(() => {
    switch (question.type) {
      case 'SINGLE_CHOICE_QUESTION':
        return (props) => <ChoiceQuestion {...props} />;
      case 'MULTI_CHOICE_QUESTION':
        return (props) => <ChoiceQuestion {...props} />;
      case 'NUMBER_RATING':
        return (props) => <RatingQuestion {...props} />;
      case 'STAR_RATING':
        return (props) => <RatingQuestion {...props} />;
      case 'DATE':
        return (props) => <DateQuestion {...props} />;
      case 'FILE_UPLOAD':
        return (props) => <FileUploadQuestion {...props} />;
      default:
        return null;
    }
  }, [question.type]);

  const questionProps = {
    question,
    index,
    onUpdate: onUpdateQuestion,
    onDelete,
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          columnGap: '1rem',
        }}>
        {!isAssessment && (
          <ChoiceQuestionOption
            selected={
              question.type === 'SINGLE_CHOICE_QUESTION' ||
              question.type === 'MULTI_CHOICE_QUESTION'
            }
            onSelect={onSelect}
          />
        )}
        {!isAssessment && (
          <RatingQuestionOption
            selected={
              question.type === 'STAR_RATING' ||
              question.type === 'NUMBER_RATING'
            }
            onSelect={onSelect}
          />
        )}
        {!isAssessment && (
          <DateQuestionOption
            selected={question.type === 'DATE'}
            onSelect={onSelect}
          />
        )}
        {/* <FileUploadOption
          selected={question.type === 'FILE_UPLOAD'}
          onSelect={onSelect}
        /> */}
      </div>
      {selectedOption && selectedOption(questionProps)}
    </div>
  );
};

export default QuestionTypes;
