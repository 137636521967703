import React, { useEffect, useState } from 'react';
import MOTable2 from '../../components/MOTable2';
import MOClient from '../../MOClient';

const FormResponsesTable = ({ formId }) => {
  const [headerItems, setHeaderItems] = useState([
    { id: 0, name: 'Submitted By', render_key: 'email' },
    { id: 1, name: 'Submission Date', render_key: 'responseOn' },
  ]);
  const [responses, setResponses] = useState([]);

  const formResponses = (responses) => {
    if (responses.length === 0) {
      setResponses([]);
      return;
    }

    const userResponses = responses.map((response) => {
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Asia/Kolkata',
        timeZoneName: 'short',
      };
      const data = {
        email: response.email,
        responseOn: new Date(response.responseOn).toLocaleString('en-US'),
      };

      if (response.response == null) {
        return data;
      }

      for (let res of response.response) {
        let answer = 'N/A';
        if (res.type === 'SINGLE_CHOICE_QUESTION') {
          answer = res.answer && res.answer.value;
        } else if (res.type === 'MULTI_CHOICE_QUESTION') {
          answer = res.answer.map((ans) => ans.value).join();
        } else if (res.type === 'NUMBER_RATING') {
          answer = res.answer;
        } else if (res.type === 'STAR_RATING') {
          answer = res.answer;
        } else if (res.type === 'DATE') {
          answer = res.answer;
        }
        data[res.question] = answer;
      }

      return data;
    });

    setResponses(userResponses);
  };

  const formNewColumns = (questions) => {
    if (questions.length === 0) {
      return;
    }
    const newHeaders = questions.map((question, index) => ({
      id: index + 2,
      name: question.question,
      render_key: question.question,
    }));
    const headers = [
      { id: 0, name: 'Submitted By', render_key: 'email' },
      { id: 1, name: 'Submission Date', render_key: 'responseOn' },
      ...newHeaders,
    ];
    setHeaderItems(headers);
  };

  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    MOClient.getFormResponses(token, formId).then(({ data }) => {
      formNewColumns(data.questions);
      formResponses(data.responses);
    });
  }, [formId]);

  return (
    <div style={{ marginTop: '30px' }}>
      <MOTable2
        orderByColumn={1}
        headerItems={headerItems}
        renderKeys={headerItems}
        rows={responses}
      />
    </div>
  );
};

export default FormResponsesTable;
