const HOST = process.env.REACT_APP_API_HOST;
const ROOT = `${HOST}/drive-api`;
const ROOTV1 = `${HOST}/v1`;
const GET_TOKEN_URL = `${ROOT}/get-token`;
const DRIVE_USER_INFO = `${ROOT}/drive-user-info`;
const COMPANY_META = `${ROOT}/company-meta`;
const LOGIN_DATA_URL = `${ROOT}/login-data`;
const LOGIN_DATA_EXCEL_URL = `${ROOT}/login-data-excel/download`;
const LEARNER_JOURNEY_EXCEL_URL = `${ROOT}/learner-journey-report`;
const LEARNER_JOURNEY_DATA_URL = `${ROOT}/get-learner-journey`;
const TEAM_REPORT_OPTIONS = `${ROOT}/team-report-filters`;
const GET_TEAM_REPORT_DATA = `${ROOT}/get-team-report`;
const TEAM_REPORT_EXCEL = `${ROOT}/team-report`;
const FORWARD_EMAIL_LOGIN_DATA_URL = `${ROOT}/login-data-excel/forward_email`;
const LEADERBOARD_DATA_URL = `${ROOT}/leaderboard-data`;
const LEADERBOARD_DATA_EXCEL = `${ROOT}/leaderboard-data-excel`;
const COMPLETION_DATA_URL = `${ROOT}/completion-data`;
const COMPLETION_DATA_EXCEL = `${ROOT}/completion-data-excel`;
const LEARNER_ENGAGEMENT_DATA_URL = `${ROOT}/learner-engagement-data`;
const LEARNER_ENGAGEMENT_EXCEL_URL = `${ROOT}/learner-engagement-excel`;
const SKILL_GAP_ANALYSIS_DATA_URL = `${ROOT}/module-retention-data`;
const SKILL_GAP_ANALYSIS_EXCEL_URL = `${ROOT}/module-retention-excel`;
const TOUGH_QUESTIONS_DATA_URL = `${ROOT}/tough-questions-data`;
const TOUGH_QUESTIONS_EXCEL_URL = `${ROOT}/tough-questions-excel`;
const CREATE_TRAINING_COURSE = `${ROOT}/create-training-course`;
const GET_TRAINING_COURSES = '/training-courses';
const UPDATE_TRAINING_COURSE = `${ROOT}/training-courses`;
const COURSE_FEEDBACK_REPORT_TABLE = `${ROOT}/course-feedback-report/table`;
const TRAINER_FEEDBACK_REPORT_TABLE = `${ROOT}/trainer-feedback-report/table`;
const FEATURE_ACCESS = `${ROOT}/feature-access`;
const GET_REWARDS_DATA = `${ROOTV1}/get-rewards-data`;
const GET_DISTINCT_DEMOGRAPHIC_VALUES = `${ROOT}/get-distinct-demographic-options`;
const GET_DEMOGRAPHIC_FILTERS = `${ROOT}/demographic-filters`;
const GET_DEMOGRAPHIC_FILTER_OPTIONS = `${ROOT}/demographic-filter-options`;
const GET_CURRENT_REWARDS_DATA = `${ROOT}/get-current-rewards-data`;
const UPDATE_REWARDS_DATA = `${ROOTV1}/update-rewards-data`;
const POST_SAMPLE_TRAINING_VIDEO = `${ROOTV1}/post-sample-training-video`;
const GET_CREATOR_COACHING_DETAIL = `${ROOTV1}/get-creator-coaching-details`;
const LS_TOKEN_KEY = 'mo-drive-token';
async function get_token(options) {
  const url = GET_TOKEN_URL;

  const result = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: options.email,
      password: options.password,
    }),
  });

  return result;
}

async function get_login_data(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  try {
    const url = LOGIN_DATA_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();

    if (result_json.hasOwnProperty('data')) {
      return result_json.data;
    }
    return {};
  } catch (ex) {
    return null;
  }
}

async function get_login_data_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  try {
    const url = LOGIN_DATA_EXCEL_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_data = await result.blob();

    return result_data;
  } catch (ex) {
    return null;
  }
}

async function get_learner_journey_data_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  try {
    const url = LEARNER_JOURNEY_EXCEL_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_data = await result.blob();

    return result_data;
  } catch (ex) {
    return null;
  }
}

async function get_learner_journey_data(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  try {
    const url = LEARNER_JOURNEY_DATA_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();

    if (result_json.hasOwnProperty('data')) {
      return result_json.data;
    }
  } catch (ex) {
    return null;
  }
}

async function get_team_report_options(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('company_id') &&
      options.company_id !== ''
    )
  ) {
    return null;
  }

  try {
    const url = TEAM_REPORT_OPTIONS;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();
    return result_json;
  } catch (ex) {
    return null;
  }
}

async function get_team_report_data(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('head_column') &&
      options.head_column !== ''
    )
  ) {
    return null;
  }

  try {
    const url = GET_TEAM_REPORT_DATA;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();

    if (result_json.hasOwnProperty('consolidatedAllTeamsData')) {
      return result_json.consolidatedAllTeamsData;
    }
  } catch (ex) {
    return null;
  }
}

async function team_report_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('head_column') &&
      options.head_column !== ''
    )
  ) {
    return null;
  }

  try {
    const url = TEAM_REPORT_EXCEL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_data = await result.blob();

    return result_data;
  } catch (ex) {
    return null;
  }
}

async function forward_email_login_data_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  if (!options.hasOwnProperty('to_email_address')) {
    return false;
  }

  try {
    const url = FORWARD_EMAIL_LOGIN_DATA_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    if (result.ok) {
      return true;
    }
    return false;
  } catch (ex) {
    return false;
  }
}

async function get_completion_data(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  if (options.module_ids.length === 0) {
    return null;
  }

  try {
    const url = COMPLETION_DATA_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();

    if (result_json.hasOwnProperty('data')) {
      return result_json.data;
    }
    return {};
  } catch (ex) {
    return null;
  }
}

async function get_completion_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }
  if (options.module_ids.length === 0) {
    return null;
  }
  try {
    const url = DRIVE_USER_INFO;
    const action_user_info = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });
    const user_info_data = await action_user_info.json();
    const company_id = user_info_data.data.permissions.company_id;
    const module_ids = options.module_ids;
    if (company_id === 86 || company_id === 170) {
      let body = { company_id, module_ids };
      const url = `${ROOT}/mis-reports/get-completion-data-BFL`;

      const result = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${options.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(options),
      });

      const result_body = await result.blob();
      return result_body;
    } else {
      const url = `${COMPLETION_DATA_EXCEL}/download`;

      const result = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${options.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(options),
      });

      const result_body = await result.blob();
      return result_body;
    }
  } catch (ex) {
    return null;
  }
}

async function forward_email_completion_data_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  if (options.module_ids.length === 0) {
    return null;
  }

  let result = null;
  const url = DRIVE_USER_INFO;
  const action_user_info = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });
  const user_info_data = await action_user_info.json();
  const company_id = user_info_data.data.permissions.company_id;
  const module_ids = options.module_ids;
  if (company_id === 86) {
    let body = { company_id, module_ids };
    const url = `${ROOT}/mis-reports/get-completion-data-BFL`;
    result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const result_body = await result.blob();
    return result_body;
  } else {
    const url = `${COMPLETION_DATA_EXCEL}/forward_email`;
    result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });
  }

  if (result.ok) {
    return true;
  }
  return false;
}

async function get_leaderboard_data(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  options.demographic_value = 'All';

  try {
    const url = LEADERBOARD_DATA_URL;
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();

    if (result_json.hasOwnProperty('data')) {
      return result_json.data;
    }
    return {};
  } catch (ex) {
    return null;
  }
}

async function get_leaderboard_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  if (options.module_ids.length === 0) {
    return null;
  }

  options.demographic_value = 'All';

  try {
    const url = `${LEADERBOARD_DATA_EXCEL}/download`;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_body = await result.blob();
    return result_body;
  } catch (ex) {
    return null;
  }
}

async function forward_email_leaderboard_data_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  if (options.module_ids.length === 0) {
    return null;
  }

  options.demographic_value = 'All';
  try {
    const url = `${LEADERBOARD_DATA_EXCEL}/forward_email`;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    if (result.ok) {
      return true;
    }
    return false;
  } catch (ex) {
    return null;
  }
}

async function get_learner_engagement_data(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  options.demographic_value = 'All';

  try {
    const url = LEARNER_ENGAGEMENT_DATA_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();

    if (result_json.hasOwnProperty('data')) {
      return result_json.data;
    }
    return {};
  } catch (ex) {
    return null;
  }
}

async function get_learner_engagement_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  const url = `${LEARNER_ENGAGEMENT_EXCEL_URL}/download`;

  options.demographic_value = 'All';

  const result = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  const result_body = await result.blob();

  return result_body;
}

async function forward_email_learner_engagement_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  const url = `${LEARNER_ENGAGEMENT_EXCEL_URL}/forward_email`;
  options.demographic_value = 'All';

  const result = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  if (result.ok) {
    return true;
  }
  return false;
}

async function get_skill_gap_analysis_data(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return {};
  }
  options.demographic_value = 'All';
  try {
    const url = SKILL_GAP_ANALYSIS_DATA_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();

    if (result_json.hasOwnProperty('data')) {
      return result_json.data;
    }
    return {};
  } catch (ex) {
    return null;
  }
}

async function get_skill_gap_analysis_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return {};
  }

  const url = `${SKILL_GAP_ANALYSIS_EXCEL_URL}/download`;
  options.demographic_value = 'All';
  const result = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  const result_body = await result.blob();

  return result_body;
}

async function forward_skill_gap_analysis_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return {};
  }

  const url = `${SKILL_GAP_ANALYSIS_EXCEL_URL}/forward_email`;
  options.demographic_value = 'All';
  const result = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  if (result.ok) {
    return true;
  }
  return false;
}

async function get_tough_questions_data(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }

  options.demographic_value = 'All';

  try {
    const url = TOUGH_QUESTIONS_DATA_URL;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const result_json = await result.json();

    if (result_json.hasOwnProperty('data')) {
      return result_json.data;
    }
    return {};
  } catch (ex) {
    return null;
  }
}

async function get_tough_questions_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }
  options.demographic_value = 'All';
  const url = `${TOUGH_QUESTIONS_EXCEL_URL}/download`;
  const result = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  const result_body = await result.blob();
  return result_body;
}

async function forward_email_tough_questions_excel(options) {
  if (
    !(
      options.hasOwnProperty('token') &&
      options.hasOwnProperty('demographic') &&
      options.demographic !== ''
    )
  ) {
    return null;
  }
  options.demographic_value = 'All';
  const url = `${TOUGH_QUESTIONS_EXCEL_URL}/forward_email`;
  const result = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  });

  if (result.ok) return true;
  return false;
}

async function fetch_user_info(token) {
  const url = DRIVE_USER_INFO;
  const action_user_info = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const user_info_data = await action_user_info.json();

  return user_info_data.data;
}

async function fetch_company_info(token) {
  const url = COMPANY_META;
  const action = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await action.json();

  return data.data;
}

async function search_training_locations(token, search_term) {
  const url = `${ROOT}/company-meta/training-locations/search/${search_term}`;
  const action = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await action.json();

  return data.data;
}

async function create_training_course(options) {
  const url = CREATE_TRAINING_COURSE;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again?',
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function update_training_course(options) {
  const url = `${UPDATE_TRAINING_COURSE}/${options.data.course_id}`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    return {
      status: 'failure',
      description:
        'Course cannot be modified if it has active training sessions',
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function delete_training_course(options) {
  const url = `${UPDATE_TRAINING_COURSE}/${options.course_id}`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  return await return_response(action);
}

async function get_training_courses(options) {
  const url = `${ROOT}/projects/${options.project_id}${GET_TRAINING_COURSES}`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again?',
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function get_training_batches(options) {
  let url = `${ROOT}/my/training-batches`;

  if (options.hasOwnProperty('get_all')) {
    url = `${ROOT}/training-batches/all`;
  }

  let action = null;
  try {
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again?',
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function pause_training_module(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/pause_microskills`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again?',
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}
async function launch_training_module(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/launch_microskills`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again?',
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function csv_batch_upload_learners(options) {
  const url = `${ROOT}/training-batches/${options.batch_id}/csv-add-learners`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'text/csv',
      },
      body: options.file,
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  return await return_response(action);
}

async function csv_batch_upload_learners_preflight(options) {
  const url = `${ROOT}/training-batches/csv-add-learners/preflight`;
  let action = null;
  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'text/csv',
      },
      body: options.file,
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  return await return_response(action);
}

async function csv_incentive_status_upload_preflight(options) {
  const url = `${ROOTV1}/add-incentive-status/preflight`;
  let action = null;
  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'text/csv',
      },
      body: options.file,
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  return await return_response(action);
}

async function csv_incentive_status_upload(options) {
  const url = `${ROOTV1}/add-incentive-status`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'text/csv',
      },
      body: options.file,
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  return await return_response(action);
}

async function upload_incentive_sheets(options) {
  const url = `${ROOTV1}/incentive-sheets-upload/${options.year}/${options.month}`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
      body: options.files,
    });
  } catch (ex) {
    console.log(ex);
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  return await return_response(action);
}

async function add_learners_to_batch(options) {
  const url = `${ROOT}/training-batches/${options.batch_id}/learners`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    let server_response = 'There was an unknown error, please try again!';

    try {
      const parse_response = await action.json();
      server_response = parse_response.description;
    } catch (ex) {
      return {
        status: 'failure',
      };
    }

    return {
      status: 'failure',
      description: server_response,
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function create_training_batch(options) {
  const url = `${ROOT}/projects/${options.project_id}/training-batches`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function get_training_batch_learners(options) {
  const url = `${ROOT}/training-batches/${options.batch_id}/learners`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function delete_training_batch_learner(options) {
  const url = `${ROOT}/training-batches/${options.batch_id}/learners/${options.participant_code}`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      description: ex.message,
    };
  }

  const x = await return_response(action);
  return x;
}

async function delete_training_batch(options) {
  const url = `${ROOT}/training-batches/${options.batch_id}`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  const x = await return_response(action);
  return x;
}

async function create_training_session(options) {
  const url = `${ROOT}/training-courses/${options.course_id}/training-sessions`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  const x = await return_response(action);
  return x;
}
async function edit_training_session(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/edit`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  const x = await return_response(action);
  return x;
}

async function get_training_sessions(options) {
  let url = `${ROOT}/training-sessions`;

  if (options.hasOwnProperty('get_all')) {
    url += '/all';
  }

  let action = null;

  try {
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function get_attendance(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/attendance`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function clear_attendance(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/attendance`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function mark_attendance(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/attendance`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        batch_learner_id: options.batch_learner_id,
        attendance_data: options.attendance,
      }),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  const data = await action.json();

  if (action.ok === false) {
    return {
      status: 'failure',
      description: data.description,
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function bulk_mark_attendance(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/bulk-mark-attendance`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  const data = await action.json();

  if (action.ok === false) {
    return {
      status: 'failure',
      description: data.description,
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function mark_as_valid_attendance(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/mark-event-days-validation`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event_days: options.event_days,
        valid: options.valid,
      }),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  const data = await action.json();

  if (action.ok === false) {
    return {
      status: 'failure',
      description: data.description,
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function return_response(action) {
  if (action.ok === false) {
    let server_response = 'There was an unknown error, please try again!';

    try {
      const parse_response = await action.json();
      server_response = parse_response.description;
    } catch (ex) {
      return {
        status: 'failure',
      };
    }

    return {
      status: 'failure',
      description: server_response,
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function get_feedback_forms_for_event(options) {
  try {
    const url = `${ROOT}/training-courses/${options.course_id}/events/${options.event_id}/feedback-forms`;
    const action = await fetch(url, {
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });

    const response_json = await action.json();

    return {
      status: 'success',
      data: response_json.data,
    };
  } catch (ex) {
    return {
      status: 'failure',
      description: ex.message,
    };
  }
}

async function get_feedback_form_submissions(options) {
  try {
    let url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/feedback-submissions`;

    if (options.hasOwnProperty('response_type')) {
      url += '?response_type=' + options.response_type;
    }

    const action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });

    if (
      options.hasOwnProperty('response_type') &&
      options.response_type == 'excel'
    ) {
      const result_json_obj = await action.blob();
      return result_json_obj;
    } else {
      const result_json_obj = await action.json();
      return result_json_obj;
    }
  } catch {
    return {
      status: 'failure',
      description: 'There was an error fetching feedback form submissions',
    };
  }
}

async function get_event_observation_status(options) {
  try {
    const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/observation-status`;
    const action = await fetch(url, {
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });

    const response_json = await action.json();

    return {
      status: 'success',
      data: response_json.data,
    };
  } catch (ex) {
    return {
      status: 'failure',
      description: ex.message,
    };
  }
}

async function get_all_feedback_forms(options) {
  try {
    let url = `${ROOT}/feedback-forms/all`;
    if (options.hasOwnProperty('fields')) {
      url += `?fields=${options.fields}`;
    }
    const action = await fetch(url, {
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });

    const response_json = await action.json();

    return {
      status: 'success',
      data: response_json.data,
    };
  } catch (ex) {
    return {
      status: 'failure',
      description: ex.message,
    };
  }
}

async function add_feedback_form(options) {
  const url = `${ROOT}/training-courses/${options.course_id}/events/${options.event_id}/feedback-forms`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form_type: options.form_type,
        form_name: options.form_name,
        form_data: options.form_data,
        form_objective: options.form_objective,
      }),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function csv_notify_users_form_upload(options) {
  const url = `${ROOTV1}/forms/notify`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
      body: options.body,
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  return await return_response(action);
}

async function notify_user_projects(token, data) {
  const url = `${ROOTV1}/forms/notify/project`;

  try {
    const action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return await action.json();
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function update_feedback_form(options) {
  const url = `${ROOT}/feedback-forms/${options.form_id}`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form_type: options.form_type,
        form_data: options.form_data,
      }),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function delete_feedback_form(options) {
  const url = `${ROOT}/feedback-forms/${options.form_id}`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  let res = await return_response(action);
  return res;
}

async function launch_feedback_form(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/launch-feedback-forms`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function add_event_observation(options) {
  let action = null;
  try {
    const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/learners/${options.batch_learner_id}/observation`;
    action = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        form_id: options.form_id,
        form_data: options.form_data,
        remarks: options.remarks,
      }),
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      description: ex.message,
    };
  }

  let outcome = await return_response(action);
  return outcome;
}

async function update_event_observation(options) {
  let action = null;
  try {
    const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/learners/${options.batch_learner_id}/observation`;
    action = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify({
        form_id: options.form_id,
        form_data: options.form_data,
        remarks: options.remarks,
      }),
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      description: ex.message,
    };
  }

  let outcome = await return_response(action);
  return outcome;
}

async function read_event_observation(options) {
  try {
    const url = `${ROOT}/training-sessions/${options.training_session_id}/events/${options.event_id}/learners/${options.batch_learner_id}/forms/${options.form_id}/observation`;
    const action = await fetch(url, {
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
    });

    const response_json = await action.json();

    return {
      status: 'success',
      data: response_json,
    };
  } catch (ex) {
    return {
      status: 'failure',
      description: ex.message,
    };
  }
}

async function lock_training_session({ token, training_session_id }) {
  try {
    const URL = `${ROOT}/training-sessions/${training_session_id}/lock`;

    const action = await fetch(URL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result_json = await action.json();

    return result_json;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'Unknown Error occurred',
    };
  }
}

async function get_course_feedback_report_table({ token, options }) {
  let action = null;
  try {
    let url =
      COURSE_FEEDBACK_REPORT_TABLE +
      '?' +
      new URLSearchParams(options.params).toString();
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    if (options.params.response_type === 'json') {
      let response = await action.json();
      return response;
    } else if (options.params.response_type === 'excel') {
      let response = await action.blob();
      return response;
    } else if (options.params.response_type === 'forward_email') {
      return action;
    }
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_trainer_feedback_report_table({ token, options }) {
  let action = null;
  try {
    let url =
      TRAINER_FEEDBACK_REPORT_TABLE +
      '?' +
      new URLSearchParams(options.params).toString();
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_feature_access(token) {
  let action = null;
  try {
    let url = `${FEATURE_ACCESS}`;
    action = await fetch(url, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });

    let response = await action.json();
    return response.features;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_demographic_filters(data) {
  let action = null;
  try {
    let url = `${GET_DEMOGRAPHIC_FILTERS}`;
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + data.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_demographic_filter_options(data) {
  let action = null;
  try {
    let url = `${GET_DEMOGRAPHIC_FILTER_OPTIONS}`;
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + data.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_distinct_demographic_values(data) {
  let action = null;
  try {
    let url = `${GET_DISTINCT_DEMOGRAPHIC_VALUES}`;
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + data.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_current_rewards_data(data) {
  let action = null;
  try {
    let url = `${GET_CURRENT_REWARDS_DATA}`;
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + data.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let response;
    if (data.action === 'download') response = await action.blob();
    if (data.action === 'view') response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function update_rewards_data(data) {
  let action = null;
  try {
    let url = `${UPDATE_REWARDS_DATA}`;
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + data.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_rewards_data(data) {
  let action = null;
  try {
    let url = `${GET_REWARDS_DATA}`;
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + data.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_drive_users({ token, company_id }) {
  let action = null;
  try {
    let url = `${ROOT}/companies/${company_id}/drive-users`;
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function send_push_notification(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/send_push_notification`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    let server_response = 'There was an unknown error, please try again!';

    try {
      const parse_response = await action.json();
      server_response = parse_response.description;
    } catch (ex) {
      return {
        status: 'failure',
      };
    }

    return {
      status: 'failure',
      description: server_response,
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function send_email_notification(options) {
  const url = `${ROOT}/training-sessions/${options.training_session_id}/send_email_notification`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options.data),
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  if (action.ok === false) {
    let server_response = 'There was an unknown error, please try again!';

    try {
      const parse_response = await action.json();
      server_response = parse_response.description;
    } catch (ex) {
      return {
        status: 'failure',
      };
    }

    return {
      status: 'failure',
      description: server_response,
      fetch_request: action,
    };
  }

  return {
    status: 'success',
    fetch_request: action,
  };
}

async function get_token_from_sso(client_id, params) {
  const url = `${ROOT}/sso/training-calendar-login`;
  const result = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      client_id,
      query_string: params,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  let result_json = await result.json();

  return result_json;
}

async function get_feedback_excluded_participants({
  token,
  training_session_id,
  event_id,
  form_id,
}) {
  let action = null;
  try {
    let url = `${ROOT}/training-sessions/${training_session_id}/events/${event_id}/feedback-forms/${form_id}/participants_excluded`;
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function update_feedback_excluded_participants(
  token,
  training_session_id,
  event_id,
  form_id,
  participants,
) {
  console.log(participants);
  let action = null;
  try {
    let url = `${ROOT}/training-sessions/${training_session_id}/events/${event_id}/feedback-forms/${form_id}/participants_excluded`;
    action = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify({
        participant_ids: participants,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function clone_feedback_form({ token, course_id, event_id, form_id }) {
  let action = null;
  try {
    let url = `${ROOT}/training-courses/${course_id}/events/${event_id}/feedback-forms`;
    action = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        clone: true,
        form_id: form_id,
      }),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    });

    let response = await action.json();

    return response;
  } catch (ex) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
    };
  }
}

async function get_feature_toggles(token) {
  const url = `${ROOTV1}/get-feature-toggles/drive-dashboard`;

  let action = null;

  try {
    action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }
  if (action.ok === false) {
    return {
      status: 'failure',
      description: 'There was an unknown error, please try again!',
      fetch_request: action,
    };
  }

  const data = await action.json();

  return {
    status: 'success',
    fetch_request: action,
    data,
  };
}

async function get_unique_demographics(token, companyId) {
  const url = `${ROOTV1}/demographics/unique?companyId=${companyId}`;

  try {
    const action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    const data = await action.json();

    return {
      status: 'success',
      fetch_request: action,
      data,
    };
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function createForm(token, form) {
  const url = `${ROOTV1}/forms`;

  try {
    const action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(form),
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return 'Success';
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function updateForm(token, form) {
  const url = `${ROOTV1}/update-forms`;

  try {
    const action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(form),
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return 'Success';
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function delete_form_by_id(options) {
  const url = `${ROOTV1}/forms/${options.formId}`;
  let action = null;

  try {
    action = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${options.token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (ex) {
    return {
      status: 'failure',
      fetch_request: action,
      description: ex.message,
    };
  }

  return await return_response(action);
}

async function getAllForms(token, companyId) {
  const url = `${ROOTV1}/forms?companyId=${companyId}`;

  try {
    const action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return await action.json();
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function getFormData(token, formId) {
  const url = `${ROOTV1}/forms/${formId}/data`;

  try {
    const action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return await action.json();
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function getFormResponsesAsExcel(token, formId) {
  const url = `${ROOTV1}/forms/${formId}/responses-excel`;

  try {
    const action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return await action.blob();
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function getFormQuestionsAsExcel(token, formId) {
  const url = `${ROOTV1}/forms/${formId}/questions-excel`;

  try {
    const action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return await action.blob();
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function getFormResponses(token, formId) {
  const url = `${ROOTV1}/forms/${formId}/responses-data`;

  try {
    const action = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return await action.json();
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

async function getTargetLearnersForForm(token, data) {
  const url = `${ROOTV1}/forms/learners`;

  try {
    const action = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (action.ok === false) {
      return {
        status: 'failure',
        description: 'There was an unknown error, please try again!',
        fetch_request: action,
      };
    }

    return await action.json();
  } catch (ex) {
    console.log({ ex });
    console.log('Something went wrong');
    return null;
  }
}

//upload sample video
async function uploadSampleTranscript(formdata) {
  const url = `${ROOTV1}/post-sample-training-video`;
  try {
    const token = window.localStorage.getItem(LS_TOKEN_KEY);
    console.log('token is ' + token);

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
      body: formdata,
      redirect: 'follow',
    });

    return result;
  } catch (error) {
    console.log({ error });
    return error;
  }
}

async function addChallenge(data) {
  const url = `${ROOTV1}/addChallenge`;
  try {
    const token = window.localStorage.getItem(LS_TOKEN_KEY);
    console.log('token is ' + token);

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return result;
  } catch (error) {
    console.log({ error });
    return error;
  }
}

async function saveSelfCriteria(data) {
  const url = `${ROOTV1}/save-self-coaching-details`;
  try {
    const token = window.localStorage.getItem(LS_TOKEN_KEY);
    console.log('token is ' + token);

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return result;
  } catch (error) {
    console.log({ error });
    return error;
  }
}

async function getTranscriptText(data) {
  const url = `${ROOTV1}/serve-transcript-response`;
  try {
    const token = window.localStorage.getItem(LS_TOKEN_KEY);
    console.log('token is ' + token);

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return result;
  } catch (error) {
    console.log({ error });
    return error;
  }
}

async function getSimilarWords(keyword) {
  try {
    const rapidapiKey = `${process.env.REACT_APP_X_RAPID_API_KEY}`;
    const rapidapiHost = `${process.env.REACT_APP_X_RAPID_API_HOST}`;

    const url = `https://wordsapiv1.p.rapidapi.com/words/${keyword}/synonyms`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': rapidapiKey, //'4a2ba583c7msh27656851f9d4ca0p18ff55jsnaf758ee876f8',
        'X-RapidAPI-Host': rapidapiHost, //'wordsapiv1.p.rapidapi.com',
        'Content-Type': 'application/json',
      },
    });
    return result;
  } catch (error) {
    console.log({ error });
    return error;
  }
}

async function getCreatorCoachingDetails(selfId) {
  const url = `${GET_CREATOR_COACHING_DETAIL}?challengeId=${selfId}`;
  const result = await fetch(url, {
    method: 'GET',
  });

  return result;
}

export default {
  get_feature_toggles,
  get_feature_access,
  get_token,
  get_login_data,
  get_login_data_excel,
  get_learner_journey_data,
  get_learner_journey_data_excel,
  get_team_report_options,
  get_team_report_data,
  team_report_excel,
  forward_email_login_data_excel,
  get_completion_data,
  get_completion_excel,
  forward_email_completion_data_excel,
  get_leaderboard_data,
  get_learner_engagement_data,
  get_skill_gap_analysis_data,
  get_tough_questions_data,
  fetch_user_info,
  fetch_company_info,
  get_leaderboard_excel,
  forward_email_leaderboard_data_excel,
  get_learner_engagement_excel,
  forward_email_learner_engagement_excel,
  get_skill_gap_analysis_excel,
  forward_skill_gap_analysis_excel,
  get_tough_questions_excel,
  forward_email_tough_questions_excel,
  create_training_course,
  update_training_course,
  delete_training_course,
  create_training_session,
  edit_training_session,
  get_training_courses,
  get_training_batches,
  csv_batch_upload_learners,
  add_learners_to_batch,
  create_training_batch,
  get_training_batch_learners,
  delete_training_batch_learner,
  delete_training_batch,
  get_training_sessions,
  pause_training_module,
  launch_training_module,
  get_attendance,
  mark_attendance,
  clear_attendance,
  get_feedback_forms_for_event,
  get_all_feedback_forms,
  add_feedback_form,
  launch_feedback_form,
  get_event_observation_status,
  add_event_observation,
  read_event_observation,
  update_event_observation,
  get_feedback_form_submissions,
  lock_training_session,
  update_feedback_form,
  csv_incentive_status_upload,
  delete_feedback_form,
  bulk_mark_attendance,
  mark_as_valid_attendance,
  csv_batch_upload_learners_preflight,
  search_training_locations,
  get_course_feedback_report_table,
  get_trainer_feedback_report_table,
  get_drive_users,
  get_demographic_filters,
  get_demographic_filter_options,
  get_distinct_demographic_values,
  get_rewards_data,
  update_rewards_data,
  get_current_rewards_data,
  send_email_notification,
  send_push_notification,
  get_token_from_sso,
  update_feedback_excluded_participants,
  get_feedback_excluded_participants,
  clone_feedback_form,
  csv_incentive_status_upload_preflight,
  upload_incentive_sheets,
  get_unique_demographics,
  createForm,
  updateForm,
  getAllForms,
  delete_form_by_id,
  getFormData,
  getFormResponsesAsExcel,
  getFormResponses,
  getTargetLearnersForForm,
  csv_notify_users_form_upload,
  getFormQuestionsAsExcel,
  uploadSampleTranscript,
  addChallenge,
  saveSelfCriteria,
  notify_user_projects,
  getTranscriptText,
  getSimilarWords,
  getCreatorCoachingDetails,
};
