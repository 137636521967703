import React, { useState, useEffect, useRef } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import DriveScreen from './Screens/Drive';
import LoginScreen from './Screens/Login';
import jwtDecode from 'jwt-decode';
import FreshChat from 'react-freshchat';
import MOClient from './MOClient';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { HashRouter as Router, Route } from 'react-router-dom';
import Loader from './components/Loader';
import { useLocation } from 'react-router-dom';
import { PASSED_ROLEPLAY_DATA } from './Fragments/MicroskillTab/msConstants';
import MicroskillTab from './Fragments/MicroskillTab';
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Open Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    textRendering: 'optimizelegibility',
  },
  palette: {
    primary: {
      main: '#4f0060',
    },
    secondary: {
      main: '#f4511e',
    },
  },
  status: {
    danger: 'orange',
  },
});

const gradient_constants = [
  ['#1E88E5', '#0043B5'],
  ['#F63B34', '#D93731'],
  ['#00CC2E', '#43A047'],
  ['#FFDD00', '#FFB300'],
  ['#56DAB8', '#00897B'],
  ['#F35DCB', '#FF2596'],
];

const LS_TOKEN_KEY = 'mo-drive-token';
const FRESHCHAT_TOKEN = '3d0017c5-0a60-4693-bdae-3def0407cf94';

function ProjectSelectorDialog(props) {
  if (props.projects === null || typeof props.projects === 'undefined') {
    return null;
  }

  const { onClose, selectedValue, ...other } = props;

  function handleClose() {
    onClose();
  }

  function handleListItemClick(value, name, discription) {
    props.onSelect(value, name, discription);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      {...other}>
      <DialogTitle id="simple-dialog-title" style={{ fontWeight: 600 }}>
        Select Project
      </DialogTitle>
      <List>
        {props.projects.map((project) => {
          let description =
            project.description !== null
              ? project.description
              : 'Sales Training';
          return (
            <ListItem
              button
              onClick={() =>
                handleListItemClick(project.id, project.name, description)
              }
              key={project.id}>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundImage: `linear-gradient(${
                      gradient_constants[project.id % 5][0]
                    }, ${gradient_constants[project.id % 5][1]})`,
                  }}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={project.name} />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
}

function App(props) {
  // app level login status tracker
  let [loading, set_loading] = useState(true);
  let [sso_loading, set_sso_loading] = useState(false);
  let [activeProject, setActiveProject] = useState(0);
  let [activeProjectText, setActiveProjectText] = useState(0);
  let [showIncentiveSection, setShowIncentiveSection] = useState(false);
  let [showRewardSection, setShowRewardSection] = useState(false);
  let [showLearnerJourney, setShowLearnerJourney] = useState(false);
  let [showTeamReport, setShowTeamReport] = useState(false);
  let [showChallengeType, setShowChallengeType] = useState(false);
  let [showLevelFilters, setShowLevelFilterType] = useState(false);
  let [showProjectFilter, setShowProjectFilter] = useState(false);
  let [activeProjectDescription, setActiveProjectDescription] = useState(
    'Sales Training',
  );
  let [logged_in, set_login_status] = useState(false);
  let [sso_logged_in, set_sso_logged_in] = useState(false);
  let [showProjectSelector, setShowProjectSelector] = useState(false);
  let [user_info, set_user_info] = useState({
    first_name: '',
    last_name: '',
    email: '',
    permissions: '',
    preferences: [],
    created_on: '',
  });
  let [company_info, set_company_info] = useState({
    demographics: [],
    company_logo_url: '',
  });

  let [category_feature, set_category_feature] = useState(true);
  let [classification_feature, set_classfication_feature] = useState(true);
  let [timestamp_feature, set_timestamp_feature] = useState(true);
  let [driveUsers, setDriveUsers] = useState([]);

  let [jwt_token, set_jwt_token] = useState(null);

  const search = useLocation().search;

  const userID = new URLSearchParams(search).get('userID');
  const userEmail = new URLSearchParams(search).get('userEmail');
  const modID = new URLSearchParams(search).get('modID');
  const prID = new URLSearchParams(search).get('prID');
  const pass = new URLSearchParams(search).get('pass');
  const compID = new URLSearchParams(search).get('compID');
  const challengeSeq = new URLSearchParams(search).get('challengeSeq');
  const isEdit = new URLSearchParams(search).get('isEdit'); //false for new and true for edit draft
  const challengeId = new URLSearchParams(search).get('challengeID'); //selfcoaching_detail_id
  /**
   * userID=16520&userEmail=admin.io1@masteroapp.com&modID=10551&prID=958&pass=$UbF@E$YRlYS)7e#/wtEz&compID=13&challengeSeq=3
   */

  // fetch store the token in localstorage and fetch the user info
  async function on_login_success(data) {
    if (data.status !== 'success') {
      return;
    }
    window.localStorage.setItem(LS_TOKEN_KEY, data.token);
    // finally set the login status as true
    set_login_status(true);
  }

  function logout_handler(data) {
    reset_login();
    props.history.push({
      pathname: '/',
      state: {},
    });
    document.location.reload();
  }

  function initFreshchat(widget) {
    window.fcc = widget;
    setFreshchatProperties();
  }

  function setFreshchatProperties() {
    if (typeof window.user === 'undefined') return;
    if (window.fcc != null) {
      window.fcc.user.setProperties({
        email: window.user.email,
        first_name: window.user.first_name,
        last_name: window.user.last_name,
      });
      window.fcc.user.setFirstName(window.user.first_name);
      window.fcc.user.setEmail(window.user.email);
    }
  }

  function reset_login() {
    set_jwt_token(null);
    set_login_status(false);
    set_user_info({
      first_name: '',
      last_name: '',
      email: '',
      permissions: '',
      created_on: '',
    });
    set_company_info({});

    window.localStorage.removeItem(LS_TOKEN_KEY);
    window.localStorage.removeItem(PASSED_ROLEPLAY_DATA);
    set_loading(false);
  }

  // token tracker effect
  useEffect(() => {
    //event listener to get event from other .net code
    setTimeout(() => {
      let token = window.localStorage.getItem(LS_TOKEN_KEY);
      if (token != null) {
        // token exists so verify for its expiry
        try {
          var decoded = jwtDecode(token);
          let epoch = Math.floor(new Date().getTime() / 1000);

          if (decoded.exp < epoch) {
            reset_login();
          } else {
            set_login_status(true);
            set_jwt_token(token);
          }
        } catch (ex) {
          reset_login();
        }
      } else {
        reset_login();
      }
    }, 5000);
  });

  const handlemessage = (event) => {
    if (
      !event.origin.includes('http://localhost:8080') ||
      typeof event.data != 'string'
    )
      return;

    set_jwt_token(null);
    set_user_info({
      first_name: '',
      last_name: '',
      email: '',
      permissions: '',
      created_on: '',
    });
    set_company_info({});
    window.localStorage.removeItem(LS_TOKEN_KEY);
    window.localStorage.removeItem(PASSED_ROLEPLAY_DATA);
    set_loading(false);

    try {
      let challangeData = JSON.parse(event.data);
      if (
        challangeData.userEmail &&
        challangeData.userEmail.length > 0 &&
        challangeData.password &&
        challangeData.password.length
      ) {
        const email = challangeData.userEmail;
        const password = challangeData.password;
        const fetch_result = MOClient.get_token({
          email,
          password,
        });
        set_loading(true);
        fetch_result.then((result) => {
          if (result.status !== 200) {
            const result_json = result.json();
            result_json.then((result_json) => {
              //do nothing for now
              set_loading(false);
              return;
            });
          } else {
            const async_result_json = result.json();
            async_result_json.then((result_json) => {
              const jwtToken = result_json.token;
              set_loading(false);
              //set data to localstorage
              const passed_roleplayData = { ...challangeData, password: '' };
              saveChallengeData(passed_roleplayData);
              window.removeEventListener('message', handlemessage);
              set_sso_logged_in(true);
              on_login_success({
                token: jwtToken,
                status: 'success',
              });
              return;
            });
          }
        });
      } else {
        set_loading(false);
        return;
      }
    } catch (err) {
      console.log('err:', err);
      set_loading(false);
      return;
    }
  };

  const handleCMSRoute = () => {
    if (!userEmail && !pass) return;

    set_jwt_token(null);
    set_user_info({
      first_name: '',
      last_name: '',
      email: '',
      permissions: '',
      created_on: '',
    });
    set_company_info({});
    window.localStorage.removeItem(LS_TOKEN_KEY);
    window.localStorage.removeItem(PASSED_ROLEPLAY_DATA);
    set_loading(false);
    try {
      let challangeData = {
        userID: userID,
        userEmail: userEmail,
        moduleID: modID,
        projID: prID,
        password: pass,
        companyID: compID,
        Challenge_Seq: challengeSeq,
        isEdit: isEdit,
        challengeId: challengeId,
      };
      if (
        challangeData.userEmail &&
        challangeData.userEmail.length > 0 &&
        challangeData.password &&
        challangeData.password.length
      ) {
        const email = challangeData.userEmail;
        const password = challangeData.password;
        const fetch_result = MOClient.get_token({
          email,
          password,
        });
        set_loading(true);
        fetch_result.then((result) => {
          if (result.status !== 200) {
            const result_json = result.json();
            result_json.then((result_json) => {
              //do nothing for now
              set_loading(false);
              return;
            });
          } else {
            const async_result_json = result.json();
            async_result_json.then((result_json) => {
              const jwtToken = result_json.token;
              set_loading(false);
              //set data to localstorage
              const passed_roleplayData = { ...challangeData, password: '' };
              window.localStorage.setItem(
                PASSED_ROLEPLAY_DATA,
                JSON.stringify(passed_roleplayData),
              );
              window.removeEventListener('message', handlemessage);
              set_sso_logged_in(true);
              on_login_success({
                token: jwtToken,
                status: 'success',
              });
              return;
            });
          }
        });
      } else {
        set_loading(false);
        return;
      }
    } catch (err) {
      console.log('err:', err);
      set_loading(false);
      return;
    }
  };

  const getChallengeData = () => {
    try {
      const roleplayChallangeData = JSON.parse(
        window.localStorage.getItem(PASSED_ROLEPLAY_DATA),
      );
      return roleplayChallangeData;
    } catch (error) {
      throw Error('no data found');
    }
  };

  const saveChallengeData = (data) => {
    window.localStorage.setItem(PASSED_ROLEPLAY_DATA, JSON.stringify(data));
  };

  useEffect(() => {
    if (!sso_logged_in) {
      //window.addEventListener('message', handlemessage);
      handleCMSRoute();
    }
    // check for url params and SSO.
    var url = new URL(window.location.href);

    var sso = url.searchParams.get('sso');

    if (sso !== null && sso == 1) {
      // sso is present, get the JWT token
      set_sso_loading(true);
      let client_id = url.searchParams.get('client_id');
      url.searchParams.delete('client_id');
      let params = url.searchParams.toString();

      let fetch_jwt_token = MOClient.get_token_from_sso(client_id, params);

      fetch_jwt_token.then((res) => {
        window.localStorage.setItem(LS_TOKEN_KEY, res.token);
        window.location.href = window.location.href.split('?')[0];
        set_sso_loading(false);
      });
    }
    // return () => {
    //   window.removeEventListener('message', handlemessage);
    // };
  }, []);

  useEffect(() => {
    if (company_info)
      localStorage.setItem('company-info', JSON.stringify(company_info));
  }, [company_info]);

  useEffect(() => {
    if (user_info.permissions.company_id)
      localStorage.setItem(
        'company-id',
        JSON.stringify(user_info.permissions.company_id),
      );
  }, [user_info]);

  useEffect(() => {
    if (jwt_token) localStorage.setItem('auth-token', jwt_token);
  }, [jwt_token]);

  function dispatch_user_info(token) {
    set_loading(true);
    let async_user_info = MOClient.fetch_user_info(token);
    let async_company_info = MOClient.fetch_company_info(token);
    let async_feature_access = MOClient.get_feature_access(token);

    Promise.all([
      async_user_info,
      async_company_info,
      async_feature_access,
    ]).then(([info, company_info, feature_access]) => {
      feature_access.incentive_status === 0
        ? setShowIncentiveSection(false)
        : setShowIncentiveSection(true);
      feature_access.rewards_recognition === 0
        ? setShowRewardSection(false)
        : setShowRewardSection(true);
      feature_access.dashboard_module_categories === 0
        ? set_category_feature(false)
        : set_category_feature(true);
      feature_access.dashboard_module_classifications === 0
        ? set_classfication_feature(false)
        : set_classfication_feature(true);
      feature_access.timestamp_access === 0
        ? set_timestamp_feature(false)
        : set_timestamp_feature(true);
      feature_access.learner_challenge_journey === 0
        ? setShowLearnerJourney(false)
        : setShowLearnerJourney(true);
      feature_access.team_challenge_report === 0
        ? setShowTeamReport(false)
        : setShowTeamReport(true);
      feature_access.challenge_type_filter === 0
        ? setShowChallengeType(false)
        : setShowChallengeType(true);
      feature_access.level_filters === 0
        ? setShowLevelFilterType(false)
        : setShowLevelFilterType(true);
      feature_access.project_filter === 0
        ? setShowProjectFilter(false)
        : setShowProjectFilter(true);
      set_loading(false);
      set_user_info(info);

      window.user = info;

      setFreshchatProperties();
      set_company_info(company_info);
      let data = getChallengeData();
      if (company_info.hasOwnProperty('projects')) {
        //set project name in local storage
        const project = company_info.projects.find((elem) => elem.id == prID);
        if (project && data) {
          const projectName = project.name;
          data['projectName'] = projectName;
        }
        setActiveProject(company_info.projects[0]['id']);
        setActiveProjectText(company_info.projects[0]['name']);
        if (company_info.projects[0]['description'] !== null)
          setActiveProjectDescription(company_info.projects[0]['description']);
      }
      if (company_info.hasOwnProperty('modules')) {
        const module = company_info.modules.find((elem) => elem.id == modID);
        if (module && data) {
          const moduleName = module.name;
          data['moduleName'] = moduleName;
        }
      }
      if (data) {
        saveChallengeData(data);
      }
      let fetch_drive_users = MOClient.get_drive_users({
        token,
        company_id: info.permissions.company_id,
      });

      fetch_drive_users.then((outcome) => {
        if (outcome.status === 'success') {
          setDriveUsers(outcome.data);
        }
      });
    });
  }

  function projectChangeHandler(projectId) {
    setActiveProject(projectId);
  }

  // effect that checks if the user_info is loaded and loads it
  useEffect(() => {
    let token = window.localStorage.getItem(LS_TOKEN_KEY);
    if (token != null && logged_in === true) {
      dispatch_user_info(token);
    }
  }, [logged_in]);

  if (loading === true || sso_loading === true) {
    return (
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          left: 0,
          width: '100%',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}>
        <Loader fill="#4f0060" />
      </div>
    );
  }

  let permissions = [];

  if (
    user_info.permissions &&
    user_info.permissions.hasOwnProperty('features')
  ) {
    for (let permission of user_info.permissions.features) {
      permissions.push(permission.key);
    }
  }

  if (!logged_in) {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <LoginScreen onSuccess={on_login_success} />
        </div>
      </MuiThemeProvider>
    );
  }

  const generateRoute = (routeProps) => {
    const path = routeProps.location.pathname;
    if (path == '/roleplay/create-scenario') {
      return <MicroskillTab {...routeProps} />;
    }
    return (
      <DriveScreen
        {...routeProps}
        logoutHandler={logout_handler}
        userInfo={{
          permission_features: permissions,
          ...user_info,
        }}
        loading={loading}
        jwtToken={jwt_token}
        companyInfo={company_info}
        activeProject={activeProject}
        activeProjectText={activeProjectText}
        activeProjectDescription={activeProjectDescription}
        projectChangeHandler={projectChangeHandler}
        onProjectSelector={() => {
          setShowProjectSelector(true);
        }}
        showIncentiveSection={showIncentiveSection}
        showRewardSection={showRewardSection}
        showLearnerJourney={showLearnerJourney}
        showTeamReport={showTeamReport}
        showChallengeType={showChallengeType}
        showLevelFilters={showLevelFilters}
        showProjectFilter={showProjectFilter}
        MOClient={MOClient}
        categoryFeature={category_feature}
        classificationFeature={classification_feature}
        timestampFeature={timestamp_feature}
        driveUsers={driveUsers}
      />
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App" style={{ height: '100%' }}>
        <FreshChat token={FRESHCHAT_TOKEN} onInit={initFreshchat} />
        <Router>
          <div id="AppRouter" style={{ height: '100%' }}>
            <Route
              path="/"
              render={(routeProps) => generateRoute(routeProps)}
            />
          </div>
        </Router>
        <ProjectSelectorDialog
          onClose={(projectId, projectText) => {
            setShowProjectSelector(false);
          }}
          projects={company_info.projects}
          open={showProjectSelector}
          onSelect={(projectId, projectText, projectDescription) => {
            setActiveProject(projectId);
            setActiveProjectText(projectText);
            setActiveProjectDescription(projectDescription);
            setShowProjectSelector(false);
          }}
          selected={activeProject}
        />
      </div>
    </MuiThemeProvider>
  );
}

function AppRouter() {
  return (
    <Router>
      <div id="MainRouter" style={{ height: '100%' }}>
        <Route path="/" component={App} />
      </div>
    </Router>
  );
}

export default AppRouter;
