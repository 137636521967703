import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import React from 'react';
import TargetLearners from './TargetLearners';

const FormFilter = ({ value, targetKey, onUpdate, label, options }) => {
  const handleChange = ({ target }) => {
    onUpdate(targetKey, target.value);
  };
  return (
    <FormControl variant="outlined">
      <InputLabel id="form-dropdown-label">{label}</InputLabel>
      <Select
        labelId="form-dropdown-label"
        id="form-dropdown"
        value={value}
        onChange={handleChange}
        label={label}
        style={{ width: '200px' }}>
        <MenuItem value="*">
          <em>All</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const FormFilters = ({ demographics, target, onUpdate, learners }) => {
  const handleChange = (key, value) => {
    const newTarget = {
      ...target,
      [key]: value,
    };
    onUpdate(newTarget);
  };

  return (
    <div>
      <Grid container style={{ width: '120%' }}>
        {demographics &&
          demographics.map((demographic, index) => (
            <Grid item sm={2} key={index}>
              <FormFilter
                value={target[demographic.key] || '*'}
                options={demographic.values}
                label={demographic.name}
                onUpdate={handleChange}
                targetKey={demographic.key}
              />
            </Grid>
          ))}
      </Grid>
      <TargetLearners learners={learners} />
    </div>
  );
};

export default FormFilters;
