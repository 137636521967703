import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { HashRouter as Router, Route } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ButtonBase from '@material-ui/core/ButtonBase';
import MenuIcon from '@material-ui/icons/Menu';
import BatchLearnerUpload from './BatchLearnerUpload';
import IncentiveStatusUpload from '../IncentiveTab/IncentiveStatusUpload';
import IncentiveSheetsUpload from '../IncentiveTab/IncentiveSheetsUpload';
import SingleLearnerUpload from './SingleLearnerUpload';
import BatchManagement from './BatchManagement';
import ReloadIcon from '../../components/MDLIcon/icons/Reload';
import Mobile from '../../components/Mobile';
import Loader from '../../components/Loader';
import Button2 from '../../components/Button2';

const custom_styles = makeStyles({
  top_bar_wrapper: {
    position: 'sticky',
    top: '0',
    zIndex: 999,
  },
  top_bar: {
    backgroundColor: '#fcfcfc',
    padding: '10px 30px 10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 999,
  },
  tab_bar_indicator: {
    height: '3px',
  },
  top_bar_heading: {
    fontWeight: 700,
    fontSize: '0.85rem',
    color: '#484848',
    margin: 0,
    textAlign: 'left',
    padding: '10px 0',
  },
  top_bar_actions: {
    display: 'flex',
  },
  top_bar_action_button: {
    padding: '10px',
    borderRadius: '25px',
    transitionProperty: 'background-color',
    transitionDuration: 350,
    transitionTimingFunction: 'ease',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
  tabs_root: {
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
    marginBottom: '5px',
    backgroundColor: 'white',
    zIndex: 99999,
  },
  tab_label: {
    textTransform: 'initial !important',
    fontFamily: 'Montserrat !important',
    fontWeight: '600 !important',
    color: '#484848',
    width: '236px',
  },
  top_bar_action_icon: {
    cursor: 'pointer',
  },
  expansion_panel_details: {
    padding: 30,
    paddingTop: 10,
    display: 'block',
  },
  sub_section_header: {
    fontWeight: 'bold',
  },
  sub_section_action: {
    fontWeight: 'bold',
  },
  icon: {
    color: 'white',
  },
  table_wrapper: {
    overflowX: 'auto',
  },
});

function AudienceTab(props) {
  const classes = custom_styles();
  const user_info = props.userInfo;
  const company_info = props.companyInfo;
  const project_id = props.activeProject;

  const [batches, setBatches] = React.useState([]);
  const [tabValue, setTabValue] = useState(0);

  const jwt_token = props.jwtToken;

  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorDialogText, setErrorDialogText] = React.useState('');

  const meta = {
    demographics: [],
  };
  let permissions = [];

  if (props.userInfo.permissions.hasOwnProperty('features')) {
    for (let permission of props.userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }
  if (props.companyInfo.hasOwnProperty('meta')) {
    const user_level_demographics = [];

    for (let i = 0; i < props.companyInfo.meta.demographics.length; i += 1) {
      const elem = props.companyInfo.meta.demographics[i];
      const demographic = elem.name.toLowerCase();
      if (
        user_info.hasOwnProperty('permissions') &&
        user_info.permissions.hasOwnProperty('demographics') &&
        user_info.permissions.demographics.hasOwnProperty(demographic)
      ) {
        user_level_demographics.push(elem);
      }
    }

    meta.demographics = user_level_demographics;
  }

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  // layout elements
  React.useEffect(() => {
    let options = {
      token: props.jwtToken,
    };

    if (permissions.indexOf('view_all_training_batches') > -1) {
      options.get_all = true;
    }

    let action = props.MOClient.get_training_batches(options);

    action.then((result) => {
      if (result.status === 'success') {
        const { fetch_request } = result;

        const extract_data = fetch_request.json();

        extract_data.then((json_data) => {
          if (json_data.status === 'success') {
            setBatches(json_data.data);
          }
        });
      }
    });
  }, []);

  return (
    <div id="AudienceApp">
      <div>
        <Router>
          <div>
            {/*= = Manage Trainers === */}
            <Route
              path="/audience/manage-trainers"
              render={(routeProps) => (
                <div>
                  <div className={classes.top_bar_wrapper}>
                    <div className={classes.top_bar}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Mobile>
                          <ButtonBase
                            className={classes.top_bar_action_button}
                            style={{ marginRight: 5 }}
                            onClick={() => {
                              props.toggleMenu();
                            }}>
                            <MenuIcon size={10} style={{ fill: '#484848' }} />
                          </ButtonBase>
                        </Mobile>
                        <h2 className={classes.top_bar_heading}>
                          Manage Trainers
                        </h2>
                      </div>
                    </div>

                    {/* Manage Trainers */}
                  </div>
                </div>
              )}
            />

            {/*= = Manage Learners === */}

            <Route
              path="/audience/manage-learners"
              render={(routeProps) => (
                <div>
                  <div className={classes.top_bar_wrapper}>
                    <div className={classes.top_bar}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Mobile>
                          <ButtonBase
                            className={classes.top_bar_action_button}
                            style={{ marginRight: 5 }}
                            onClick={() => {
                              props.toggleMenu();
                            }}>
                            <MenuIcon size={10} style={{ fill: '#484848' }} />
                          </ButtonBase>
                        </Mobile>
                        <h2 className={classes.top_bar_heading}>
                          Manage Learners
                        </h2>
                      </div>
                      <div className={classes.top_bar_actions}>
                        <div className={classes.top_bar_action_icon}>
                          <ButtonBase className={classes.top_bar_action_button}>
                            <ReloadIcon />
                          </ButtonBase>
                        </div>
                      </div>
                    </div>

                    <Tabs
                      variant="scrollable"
                      scrollButtons="off"
                      value={tabValue}
                      onChange={handleTabChange}
                      classes={{
                        root: classes.tabs_root,
                        indicator: classes.tab_bar_indicator,
                      }}>
                      <Tab
                        label="Batch Learner Upload"
                        classes={{ root: classes.tab_label }}
                      />
                      <Tab
                        label="Single Learner Upload"
                        classes={{ root: classes.tab_label }}
                      />
                      {/* <Tab label="Bulk Learner Update" classes={{root: classes.tab_label}}/> */}
                      <Tab
                        label="Batch Management"
                        classes={{ root: classes.tab_label }}
                      />
                    </Tabs>
                  </div>

                  {tabValue === 0 ? (
                    <div>
                      <div style={{ padding: '30px', paddingTop: 10 }}>
                        <BatchLearnerUpload
                          {...props}
                          jwtToken={props.jwtToken}
                          MOClient={props.MOClient}
                          batches={batches}
                          {...routeProps}
                        />
                      </div>
                    </div>
                  ) : null}

                  {tabValue === 1 ? (
                    <div>
                      <div>
                        <SingleLearnerUpload
                          {...props}
                          jwtToken={props.jwtToken}
                          MOClient={props.MOClient}
                          batches={batches}
                        />
                      </div>
                    </div>
                  ) : null}

                  {tabValue === 2 ? (
                    <div>
                      <div style={{ padding: '0px', paddingTop: 10 }}>
                        <BatchManagement
                          onDeleteBatch={(id) => {
                            const action_delete = props.MOClient.delete_training_batch(
                              {
                                token: props.jwtToken,
                                batch_id: id,
                              },
                            );

                            action_delete.then((outcome) => {
                              if (outcome.status === 'success') {
                                const temp_rows = [...batches];
                                const new_rows = temp_rows.filter(
                                  (r) => r.id !== id,
                                );
                                setBatches(new_rows);
                              } else {
                                setErrorDialogOpen(true);
                                setErrorDialogText(outcome.description);
                              }
                            });
                          }}
                          jwtToken={props.jwtToken}
                          MOClient={props.MOClient}
                          batches={batches}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            />
          </div>
        </Router>
      </div>

      <Dialog
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorDialogText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setErrorDialogOpen(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export default AudienceTab;
