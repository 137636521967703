import { makeStyles } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import FlatPrimaryButton from '../../components/FlatPrimaryButton';

const SubmitForm = ({ editable = false, onSubmit, style, titleText }) => {
  const [title, setTitle] = useState(null);
  const buttonText = editable ? 'Submit' : 'Next';
  useEffect(() => { setTitle(titleText) },[titleText]);

  return (
    <FlatPrimaryButton
      title={title}
      onClick={onSubmit}
      style={style}
    >
      <div>{buttonText}</div>
    </FlatPrimaryButton>
  );
};

export default SubmitForm;
