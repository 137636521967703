import React from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import FormControl from '../components/FormControl';
import Label from '../components/Label';
import Excel from 'exceljs';
import MOSelect from '../components/MOSelect';
import MODatePicker from '../components/MODatePicker';
import dayjs from 'dayjs';
import MOTable2 from '../components/MOTable2';
import Button2 from '../components/Button2';
import Utils from '../utils';
import KababMenu from '../components/KababMenu';
import Loader from '../components/Loader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { TableContainer, TablePagination } from '@material-ui/core';
import styles from './LearnerJourneyTracker/LearnerJourneyTracker.module.css';

let TrainerFeedbackTable = function (props) {
  if (props.loading === true) {
    return <Loader fill="#4f0060" />;
  }

  let { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = props;

  let { headerItems, items } = props;

  if (items.length === 0) {
    if (props.loading === false) {
      return (
        <MOTable2
          renderKeys={headerItems}
          rows={[]}
          headerItems={headerItems}
          orderByColumn={2}
        />
      );
    }
    return (
      <Typography
        variant="caption"
        className={styles.no_data_available_caption}>
        No data is available at the moment
      </Typography>
    );
  } else {
    return (
      <TableContainer style={{ paddingBottom: '40px' }}>
        <MOTable2
          renderKeys={headerItems}
          rows={items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          headerItems={headerItems}
          orderByColumn={2}
        />
        <TablePagination
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableContainer>
    );
  }
};

function AnalyticsCourseTrainerFeedback({
  jwtToken,
  courses,
  courseEvents,
  userInfo,
  classes,
  MOClient,
  driveUsers,
}) {
  const permissions = [];

  let driveUsersObj = Utils.objectify(driveUsers, 'id');

  if (userInfo.permissions.hasOwnProperty('features')) {
    for (const permission of userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }
  const [activeCourse, setActiveCourse] = React.useState(null);
  const [batches, setBatches] = React.useState([]);
  const [courseOptions, setCourseOptions] = React.useState([]);
  const [seletedCourseType, setSelectedCourseType] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  let [startDate, setStartDate] = React.useState(null);
  let [endDate, setEndDate] = React.useState(null);
  let [startDateDialogOpen, setStartDateDialogOpen] = React.useState(false);
  const [endDateDialogOpen, setEndDateDialogOpen] = React.useState(false);
  const [filterTrainer, setFilterTrainer] = React.useState(0);
  const [loadingData, setLoadingData] = React.useState(false);
  let [download_xls_dialog_state, set_download_xls_dialog_state] = React.useState(false);
  let [page, setPage] = React.useState(0);
  let [rowsPerPage, setRowsPerPage] = React.useState(25);

  const courseTypes = [{ id: 0, display_text: 'Blended Learning Journey' }, { id: 1, display_text: 'Standalone Journey' },
  { id: 2, display_text: 'Webinar' }, { id: 3, display_text: 'Internal' }, { id: 4, display_text: 'Inhouse' }, { id: 5, display_text: 'External' }];

  const handleChangePage = (event, newPage) => { setPage(newPage); };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [headerItems, setHeaderItems] = React.useState([
    {
      id: 0,
      name: 'Training Days',
      render_key: 'training_days',
    },
    {
      id: 1,
      name: 'Training Hours',
      render_key: 'training_hours',
    },
    {
      id: 2,
      name: 'Attendance(%)',
      render_key: 'attendance',
    },
    {
      id: 3,
      name: 'Course Feedback',
      render_key: 'course_feedback',
    },
    {
      id: 4,
      name: 'Observation Score',
      render_key: 'observation_score',
    },
    {
      id: 5,
      name: 'Microskill Completion (%)',
      render_key: 'microskill_completion',
    },
    {
      id: 6,
      name: 'Microskill Score',
      render_key: 'microskill_score',
    },
  ]);

  const [rows, setRows] = React.useState([]);

  let gettingData = false;

  const getFeedbacksData = async () => {
    if (gettingData === false) {
      gettingData = true;

      if (activeCourse === null
        && startDate === null
        && endDate === null
        && filterTrainer === 0) {
        setRows([]);
        return;
      }

      let params = {};

      if (activeCourse !== null) {
        params.course_id = activeCourse;
      }

      if (startDate !== null) {
        params.training_starts_after = startDate.format('YYYY-MM-DD');
      }

      if (endDate !== null) {
        params.training_starts_before = endDate.format('YYYY-MM-DD');
      }

      if (filterTrainer !== 0) {
        params.filter_trainer = filterTrainer;
      }

      setLoadingData(true);

      let fetch_data = MOClient.get_trainer_feedback_report_table({
        token: jwtToken,
        options: {
          params,
        },
      });

      let outcome = await fetch_data;

      setLoadingData(false);
      if (outcome.status !== 'success') {
      } else {
        setHeaderItems(outcome.data.header_items);
        setRows(outcome.data.rows);
      }

      gettingData = false;
    }
  };

  let activeCourseObj = { name: '' };

  let filterCourses = courses.filter((c) => c.id === activeCourse);

  if (filterCourses.length > 0) {
    activeCourseObj = filterCourses[0];
  }

  function clearFilters() {
    setActiveCourse(null);
    setStartDate(null);
    setEndDate(null);
    setFilterTrainer(0);
  }

  let trainers = driveUsers.filter((u) => {
    let access = false;
    for (let permission of u.permissions.features) {
      if (permission.key === 'create_training_session') {
        access = true;
        break;
      }
    }
    return access;
  });

  let trainerOptions = trainers.map((t) => {
    return {
      id: t.id,
      display_text: t.first_name + ' ' + t.last_name,
    };
  });

  let xlsxHeaders = headerItems.map((h) => ({
    header: h.name,
    key: h.render_key
  }));

  async function download_as_excel() {

    set_download_xls_dialog_state(true);

    let workbook = new Excel.Workbook();

    let sheet = workbook.addWorksheet('Training Learner Feedback - Report');

    sheet.columns = xlsxHeaders;

    for (let row of rows) {
      sheet.addRow(row);
    }

    let array_buff = await workbook.xlsx.writeBuffer();

    let buff = new Blob([array_buff]);
    //console.log(new Blob(buff));

    saveAs(buff, "Course-Feedback-Trainer-Report.xlsx", { autoBOM: true });
    set_download_xls_dialog_state(false);
  }

  React.useEffect(() => {
    let options = courses.filter((c) => seletedCourseType === c.course_type).map((c) => ({ id: c.id, display_text: c.name }));
    setCourseOptions(options);
  }, [courses, seletedCourseType]);


  return (
    <div style={{ textAlign: 'left' }}>
      <div style={{ marginTop: 10, display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ marginRight: 30 }}>
          <FormControl>
            <Label>Course Type: </Label>
            <MOSelect
              options={courseTypes}
              onSelect={(id) => { setSelectedCourseType(id); }}
              text={courseTypes[seletedCourseType].display_text}
            />
          </FormControl>
        </div>

        <div style={{ marginRight: 30 }}>
          <FormControl>
            <Label>Course:</Label>
            <MOSelect
              options={courseOptions}
              onSelect={(id) => { setActiveCourse(id); }}
              text={activeCourseObj.name}
            />
          </FormControl>
        </div>

        {permissions.indexOf('view_all_training_sessions') > -1 ? (
          <div style={{ marginRight: 30 }}>
            <FormControl>
              <Label>Trainer:</Label>
              <MOSelect
                options={trainerOptions}
                onSelect={(id) => { setFilterTrainer(id); }}
                text={filterTrainer > 0 ? driveUsersObj[filterTrainer].first_name + ' ' + driveUsersObj[filterTrainer].last_name : ''}
              />
            </FormControl>
          </div>
        ) : null}

        <div style={{ marginRight: 30 }}>
          <FormControl>
            <Label>Training Duration:</Label>
            <DurationWrapper>
              <MODatePicker
                open={startDateDialogOpen}
                text={startDate === null ? '' : startDate.format('DD-MM-YYYY')}
                value={startDate}
                onClick={() => { setStartDateDialogOpen(!startDateDialogOpen); }}
                onClose={() => { setStartDateDialogOpen(false); }}
                handleDateChange={(d) => { setStartDate(d); }}
                minDate={dayjs(new Date()).subtract(1, 'year')}
              />

              <CourseFromToLabel>TO</CourseFromToLabel>

              <MODatePicker
                open={endDateDialogOpen}
                text={endDate === null ? '' : endDate.format('DD-MM-YYYY')}
                value={endDate}
                onClick={() => { setEndDateDialogOpen(!endDateDialogOpen); }}
                onClose={() => { setEndDateDialogOpen(false); }}
                handleDateChange={(d) => { setEndDate(d); }}
                minDate={dayjs(new Date()).subtract(1, 'year')}
              />
            </DurationWrapper>
          </FormControl>
        </div>

        <div>
          <FormControl>
            <Label>Options</Label>
            <KababMenu
              onClick={async (id) => { if (id === 1) { download_as_excel(); } }}
              options={[{ display_text: "Download as xlsx", id: 1 }]}
            />
          </FormControl>
        </div>
      </div>

      <div className={styles.sub_section_header}>
        <div className={styles.sub_section_header_title}>
          <div className={styles.sub_section_header_filters}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <FormControl style={{ marginRight: 20 }}>
                <Button2 transparent={false} onClick={() => getFeedbacksData()}>
                  Submit
                </Button2>
              </FormControl>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <FormControl style={{ marginRight: 20 }}>
                <Button2 transparent={true} onClick={clearFilters}>
                  Clear Filters
                </Button2>
              </FormControl>
            </div>
          </div>
        </div>
      </div>


      <div className={styles.sub_section_content}>
        <Paper elevation={2}>
          <div className={classes.table_wrapper}>
            <TrainerFeedbackTable
              loading={loadingData}
              items={rows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              headerItems={headerItems}
            />
          </div>
        </Paper>
      </div>

    </div>
  );
}

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;

export default AnalyticsCourseTrainerFeedback;
