import React from 'react';
import styled from 'styled-components';
import TextInput from "../../components/TextInput";
import FormControl from '../../components/FormControl';
import MODatePicker from "../../components/MODatePicker";
import Label from '../../components/Label';
import MOTimePicker from "../../components/MOTimePicker";
import Button2 from '../../components/Button2';

export default function EditCourseEvent(props) {
  return (
    <ComponentWrapper>
      <FormControl>
        <Label>Event Name:</Label>
        <h5 style={{margin: 0}}>{props.eventName}</h5>

      </FormControl>

      <FormControl>
        <Label>Event Date:</Label>
        <DurationWrapper>
          <MODatePicker
            minDate={props.minDate}
            open={props.startDateOpen}
            text={props.startDate.format('DD-MM-YYYY')}
            value={props.startDate}
            onClick={() => {
              props.onStartDateClick()
            }}

            onClose={() => {
              props.onStartDateClose()
            }}

            handleDateChange={(d) => {
              props.onStartDateChange(d)
            }}
          />

          <CourseFromToLabel>TO</CourseFromToLabel>

          <MODatePicker
            minDate={props.minDate}
            open={props.endDateOpen}
            text={props.endDate.format('DD-MM-YYYY')}
            value={props.endDate}
            onClick={() => {
              props.onEndDateClick()
            }}

            onClose={() => {
              props.onEndDateClose()
            }}

            handleDateChange={(d) => {
              props.onEndDateChange(d)
            }}
          />

        </DurationWrapper>

        <ErrorText>{props.durationErrorText}</ErrorText>
      </FormControl>

      <FormControl>
        <Label>Event Time:</Label>
        <MOTimePicker

          open={props.timeOpen}
          text={props.eventTime.format('HH:mm [Hrs]')}
          value={props.eventTime}
          onClick={() => {
            props.onTimeClick()
          }}

          onClose={() => {
            props.onTimeClose()
          }}

          handleTimeChange={(d) => {
            props.onTimeChange(d)
          }}

        />
      </FormControl>

      {
        props.hasOwnProperty('enableWebinarLink') && props.enableWebinarLink === true ? (

          <FormControl>
            <Label>Webinar Link:</Label>
            <TextInput
              value={props.webinarLink ? props.webinarLink : ''}
              onChangeText={props.onChangeWebinarLink ? props.onChangeWebinarLink : () => {}}
            />

          </FormControl>

        ) : null
      }

      <FormControl>
        <Actions>
          <Button2 onClick={props.onAddEvent}>Save</Button2>
          <Button2 onClick={props.onClose} transparent={true} style={{marginLeft: "5px"}}>Close</Button2>
        </Actions>
      </FormControl>



    </ComponentWrapper>
  )
};

const ComponentWrapper = styled.div`
  padding: 20px;
`;

const ErrorText = styled.span`
  font-size: 0.7rem;
  font-family: "Montserrat";
  font-weight: 500;
  color: #f63b34;
  margin-top: 5px;
`;

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const CourseFromToLabel = styled.h4`
  font-family: "Open Sans";
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;
