import React from "react";

function LogoutIcon(props) {
  let fill = "#FFF";

  if(props.hasOwnProperty("fill")) {
    fill = props.fill;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <defs>
        <style>
          {`.cls-logouticon{fill:#fff;opacity:0}.cls-2{fill:#f4511e}`}
        </style>
      </defs>
      <g id="mastero_icon_logout" transform="translate(-10 -602)">
        <g id="Group_2810" data-name="Group 2810" transform="translate(-9 431)">
          <path id="Rectangle_1547" d="M0 0h15v15H0z" className="cls-logouticon" data-name="Rectangle 1547"
                transform="translate(19 171)"/>
        </g>
        <g id="logout" transform="translate(12.691 605.168)">
          <g id="Group_2747" data-name="Group 2747">
            <g id="Group_2746" data-name="Group 2746">
              <path id="Path_5484"
                    d="M302.494 150.729a.389.389 0 0 0-.083-.125l-1.153-1.153a.384.384 0 1 0-.543.543l.5.5h-2.147a.384.384 0 1 0 0 .769h2.147l-.5.5a.384.384 0 1 0 .544.543l1.153-1.153a.385.385 0 0 0 .083-.419z"
                    className="cls-2" data-name="Path 5484" transform="translate(-293.299 -146.648)"/>
              <path id="Path_5485"
                    d="M6.548 5.381a.384.384 0 0 0-.384.384v1.922H4.626v-6.15a.385.385 0 0 0-.274-.368l-1.335-.4h3.147v1.922a.384.384 0 0 0 .769 0V.384A.384.384 0 0 0 6.548 0H.4a.343.343 0 0 0-.04.007.38.38 0 0 0-.16.057C.187.07.177.07.169.076s0 .007-.007.009a.381.381 0 0 0-.1.121.314.314 0 0 0-.013.033.365.365 0 0 0-.03.09.239.239 0 0 0 0 .033C.019.37.014.377.014.384v7.688a.384.384 0 0 0 .309.377l3.844.769a.358.358 0 0 0 .075.008.385.385 0 0 0 .384-.384v-.386h1.922a.384.384 0 0 0 .384-.384V5.766a.384.384 0 0 0-.384-.385z"
                    className="cls-2" data-name="Path 5485" transform="translate(-.014)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogoutIcon;