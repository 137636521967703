import React, { useState, useEffect } from 'react';
import styles from './LoginScreen.module.css';
import { withStyles } from '@material-ui/core/styles';
import drive_login_intro from './mastero-drive-login-illustration.png';
import mastero_logo from './new-logo-dark-theme.svg';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonBase from '@material-ui/core/ButtonBase';
import Loader from '../../components/Loader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import MOClient from '../../MOClient';

const override_styles = {
  button_ripple: {
    width: '100%',
    borderRadius: '10px',
  },
};

function LoginButton(props) {
  let login_progress = props.loginProgress;
  let { classes } = props;
  let click_handler = props.onClick;
  let disabled = false;

  if (props.hasOwnProperty('disabled')) {
    disabled = props.disabled;
  }

  if (login_progress === true) {
    return (
      <ButtonBase classes={{ root: classes.button_ripple }}>
        <button className={styles.submit_button_progress}>
          <Loader />
        </button>
      </ButtonBase>
    );
  } else {
    return (
      <ButtonBase
        disabled={disabled}
        classes={{ root: classes.button_ripple }}
        onClick={click_handler}>
        <button className={styles.submit_button}>LOGIN TO YOUR ACCOUNT</button>
      </ButtonBase>
    );
  }
}

let StyledLoginButton = withStyles(override_styles)(LoginButton);

function SimpleDialog(props) {
  let open = props.open;
  let handle_close = props.handleClose;
  return (
    <Dialog
      open={open}
      onClose={handle_close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handle_close} color="primary" autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Login(props) {
  let [email, set_email] = useState('');
  let [password, set_password] = useState('');
  let [login_progress, set_login_progress] = useState(false);
  let [dialog_open, set_dialog_visibility] = useState(false);
  let [dialog_content, set_dialog_content] = useState('');
  let [remember_me, set_remember_me] = useState(false);
  const [startLogin, setStartLogin] = React.useState(false);
  const [recapToken, setRecapToken] = useState('');

  useEffect(() => {
    let remember_me_email = window.localStorage.getItem('remember_me_email');

    if (remember_me_email === null) {
      // nothing to do
    } else {
      set_remember_me(true);
      if (email !== '') {
        window.localStorage.setItem('remember_me_email', email);
      } else {
        set_email(window.localStorage.getItem('remember_me_email'));
      }
    }
  }, [email]);

  function on_submit() {
    // basic form validation

    if (email === '' || password === '') {
      set_dialog_content('Please enter email/password before continuing');
      set_dialog_visibility(true);
      set_login_progress(false);
      return;
    }

    set_login_progress(true);

    const fetch_result = MOClient.get_token({
      email,
      password,
    });

    fetch_result.then((result) => {
      if (result.status !== 200) {
        const result_json = result.json();
        result_json.then((result_json) => {
          set_login_progress(false);
          set_dialog_content(result_json.error);
          set_dialog_visibility(true);
        });
      } else {
        const async_result_json = result.json();
        async_result_json.then((result_json) => {
          const jwtToken = result_json.token;

          set_login_progress(false);

          props.onSuccess({
            token: jwtToken,
            status: 'success',
          });
        });
      }
    });
  }

  function handle_email_change(event) {
    set_email(event.target.value);
  }

  function handle_password_change(event) {
    set_password(event.target.value);
  }

  function handle_dialog_close() {
    set_dialog_visibility(false);
  }

  function handle_remember_me(value) {
    if (value === true) {
      window.localStorage.setItem('remember_me_email', email);
    } else {
      window.localStorage.removeItem('remember_me_email');
    }

    set_remember_me(value);
  }

  return (
    <div id="LoginScreen" className={styles.login_screen}>
      <SimpleDialog
        open={dialog_open}
        content={dialog_content}
        handleClose={handle_dialog_close}></SimpleDialog>
      <div className={styles.card}>
        <div className={styles.login_container}>
          <div className={styles.login_header}>
            <img
              src={mastero_logo}
              className={styles.mastero_logo}
              alt="Master-O"
            />
            <h3 className={styles.dashboard_title}>DASHBOARD</h3>

            <img
              src={drive_login_intro}
              className={styles.drive_login_intro}
              alt="Master-O Intro"
            />

            {/* <div>
              <span className={styles.copyright}>
                © InspireOne Technologies Pvt. Ltd. All rights reserved.
              </span>
            </div> */}
          </div>

          <div className={styles.login_body}>
            <div className={styles.login_body_padder}>
              <div className={styles.input_wrapper}>
                <label>User Name</label>
                <input
                  type="email"
                  placeholder="john@example.com"
                  value={email}
                  onChange={handle_email_change}
                />
              </div>

              <div className={styles.input_wrapper}>
                <label>Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={handle_password_change}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      on_submit();
                    }
                  }}
                />
              </div>

              <div className={styles.input_wrapper}>
                <StyledLoginButton
                  loginProgress={login_progress}
                  onClick={on_submit}
                />
              </div>

              <FormGroup row className={styles.form_group}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember_me}
                      color="primary"
                      onChange={(e, checked) => handle_remember_me(checked)}
                    />
                  }
                  label="Remember me on this device"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(override_styles)(Login);
