import styled from "styled-components";

const TopBarHeading = styled.h2`
    font-weight: 700;
    font-size: 0.85rem;
    color: #484848;
    margin: 0;
    text-align: left;
    padding: 10px 0;
`;

export default TopBarHeading;