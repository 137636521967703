import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  RadioGroup,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FormControl from '../../components/FormControl';
import StyledLabel from '../../components/Label';
import TextInput from '../../components/TextInput';
import { Add, Delete } from '@material-ui/icons';

const RadioChoice = ({ choice, checked, onChange, onChangeSelection, handleDelete } ) => (
  <Grid container style={{ width: '460px' }} alignItems="center">
    <Grid item sm={1}>
      <Radio onChange={onChangeSelection} checked={checked} />
    </Grid>
    <Grid item sm={9}>
      <TextInput
        value={choice.value}
        disabled={choice.type === 'open'}
        onChangeText={(text) => onChange(text)}
        placeholder={choice.type === 'open' ? 'Open Ended ...' : ''}
      />
    </Grid>
    <Grid item sm={2}>
        <Delete style={{ cursor: 'pointer' }} onClick={() => handleDelete(choice)} />
    </Grid>
  </Grid>
);

const RadioChoices = ({ choices, onUpdate, handleDelete }) => {
  const handleChange = (text, index) => {
    const updatedChoice = {
      ...choices[index],
      value: text,
    };
    const updatedChoices = [
      ...choices.slice(0, index),
      updatedChoice,
      ...choices.slice(index + 1),
    ];
    onUpdate(updatedChoices);
  };

  const handleChangeSelection = (index) => {
    const updatedChoice = {
      ...choices[index],
      selected: !choices[index].selected,
    };

    choices.map((choice) => (choice.selected = false));

    const updatedChoices = [
      ...choices.slice(0, index),
      updatedChoice,
      ...choices.slice(index + 1),
    ];

    onUpdate(updatedChoices);
  };

  return (
    <>
      {choices.map((choice, index) => (
        <RadioChoice
          key={index}
          choice={choice}
          checked={choice.selected}
          onChange={(text) => handleChange(text, index)}
          onChangeSelection={() => handleChangeSelection(index)}
          handleDelete={handleDelete}
        />
      ))}
    </>
  );
};

const CheckboxChoice = ({ choice, checked, onChange, onChangeSelection, handleDelete }) => (
  <Grid container style={{ width: '460px' }} alignItems="center">
    <Grid item sm={1}>
      <Checkbox onChange={onChangeSelection} checked={checked} />
    </Grid>
    <Grid item sm={9}>
      <TextInput
        value={choice.value}
        disabled={choice.type === 'open'}
        onChangeText={(text) => onChange(text)}
        placeholder={choice.type === 'open' ? 'Open Ended ...' : ''}
      />
    </Grid>
    <Grid item sm={2}>
        <Delete style={{ cursor: 'pointer' }} onClick={() => handleDelete(choice)} />
    </Grid>
  </Grid>
);

const CheckboxChoices = ({ choices, onUpdate, handleDelete }) => {
  const handleChange = (text, index) => {
    const updatedChoice = {
      ...choices[index],
      value: text,
    };
    const updatedChoices = [
      ...choices.slice(0, index),
      updatedChoice,
      ...choices.slice(index + 1),
    ];
    onUpdate(updatedChoices);
  };

  const handleChangeSelection = (index) => {
    const updatedChoice = {
      ...choices[index],
      selected: !choices[index].selected,
    };
    const updatedChoices = [
      ...choices.slice(0, index),
      updatedChoice,
      ...choices.slice(index + 1),
    ];
    onUpdate(updatedChoices);
  };

  return (
    <>
      {choices.map((choice, index) => (
        <CheckboxChoice
          key={index}
          choice={choice}
          checked={choice.selected}
          onChange={(text) => handleChange(text, index)}
          onChangeSelection={() => handleChangeSelection(index)}
          handleDelete={handleDelete}
        />
      ))}
    </>
  );
};

const AddChoices = ({ onUpdate, choices }) => {
  const [selected, setSelected] = useState(false);

  const handleChoiceAdd = (type) => {
    const newChoice = {
      type,
      value: '',
      id: choices.length + 1,
      selected: false,
    };

    const updatedChoices = [...choices, newChoice];
    onUpdate(updatedChoices);
    setSelected(false);
  };

  const handleFixed = () => {
    handleChoiceAdd('fixed');
  };

  const handleOpenEnded = () => {
    handleChoiceAdd('open');
  };

  const renderAddChoice = (
    <>
      <Grid item sm={1}>
        <Add onClick={() => setSelected(true)} style={{ cursor: 'pointer' }} />
      </Grid>
      <Grid item sm={3}>
        <div onClick={() => setSelected(true)} style={{ cursor: 'pointer' }}>
          Add Option
        </div>
      </Grid>
    </>
  );

  const renderChoiceSelect = (
    <>
      <Grid item sm={1}>
        <Add style={{ cursor: 'pointer' }} onClick={handleFixed} />
      </Grid>
      <Grid item sm={3}>
        <div onClick={handleFixed} style={{ cursor: 'pointer' }}>
          Fixed
        </div>
      </Grid>
      <Grid item sm={1}>
        <Add style={{ cursor: 'pointer' }} onClick={handleFixed} />
      </Grid>
      <Grid item sm={3}>
        <div onClick={handleOpenEnded} style={{ cursor: 'pointer' }}>
          Open Ended
        </div>
      </Grid>
    </>
  );

  return (
    <Grid container alignItems="center" style={{ width: '500px' }}>
      {selected ? renderChoiceSelect : renderAddChoice}
    </Grid>
  );
};

export const ChoiceQuestion = ({
  question,
  index,
  onUpdate,
  isAssessment,
  onDelete,
}) => {

  const [optionChoice, setOptionChoice] = useState(question.choices);

  const handleDelete = (choice) => {
    let choices = question.choices;
    const updatedChoice = choices.filter((q) => q.id != choice.id)
    setOptionChoice(updatedChoice);
    question.choices = updatedChoice;

  }

  const questionUpdate = (key, value) => {
    const updatedQuestion = {
      ...question,
      [key]: value,
    };
    onUpdate(updatedQuestion);
  };

  const handleQuestionChange = (text) => {
    questionUpdate('question', text);
  };

  const handleQuestionTypeChange = ({ target }) => {
    questionUpdate('type', target.value);
  };

  const handleRequiredChange = ({ target }) => {
    questionUpdate('mandatory', target.checked);
  };

  const handleChoicesChange = (choices) => {
    questionUpdate('choices', choices);
  };

  return (
    <div>
      <FormControl>
        <Grid container alignItems="center">
          <Grid item sm={8}>
            <StyledLabel style={{ padding: '0px' }}>
              Question {index + 1}:
            </StyledLabel>
          </Grid>
          <Grid item sm={4}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={question.mandatory}
                    onChange={handleRequiredChange}
                  />
                }
                label="Required"
              />
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ display: 'flex' }}>
          <TextInput
            value={question.question}
            onChangeText={handleQuestionChange}
          />
          <Delete
            onClick={() => onDelete(question)}
            style={{
              alignSelf: 'center',
              marginLeft: '1rem',
              cursor: 'pointer',
            }}
          />
        </div>
      </FormControl>

      <FormControl>
        <Grid container style={{ width: '500px' }} alignItems="center">
          <Grid item sm={3}>
            <StyledLabel style={{ padding: '0px' }}>Choice Type:</StyledLabel>
          </Grid>
          <Grid item sm={9}>
            <RadioGroup
              value={question.type}
              onChange={handleQuestionTypeChange}>
              <Grid container alignItems="center">
                <Grid item sm={6}>
                  <FormControlLabel
                    value="SINGLE_CHOICE_QUESTION"
                    label="Single Selection"
                    control={<Radio />}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormControlLabel
                    value="MULTI_CHOICE_QUESTION"
                    label="Multiple Selection"
                    control={<Radio />}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl>
        {question.type === 'SINGLE_CHOICE_QUESTION' ? (
          <RadioChoices
            handleDelete={handleDelete}
            choices={question.choices || []}
            onUpdate={handleChoicesChange}
          />
        ) : (
          <CheckboxChoices
            handleDelete={handleDelete}
            choices={question.choices || []}
            onUpdate={handleChoicesChange}
          />
        )}
      </FormControl>
      <FormControl>
        <AddChoices onUpdate={handleChoicesChange} choices={question.choices} />
      </FormControl>
    </div>
  );
};
