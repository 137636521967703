import React from "react";

function ReloadIcon(props) {
  let fill = "#FFF";

  if(props.hasOwnProperty("fill")) {
    fill = props.fill;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <defs>
        <style>
          {`.cls-reload-icon{fill:#4f0060}.cls2-reload-icon{fill:none;opacity:.1}`}
        </style>
      </defs>
      <g id="mastero_icon_fields_reload" transform="translate(-233 -382)">
        <path id="Path_5560"
              d="M10.273 4.92v2.353l3.136-3.136L10.273 1v2.352a6.262 6.262 0 0 0-5.3 9.613l1.144-1.145a4.6 4.6 0 0 1-.549-2.2 4.708 4.708 0 0 1 4.705-4.7zm5.3 1.364L14.428 7.43a4.7 4.7 0 0 1-4.156 6.9v-2.353l-3.136 3.137 3.136 3.136V15.9a6.262 6.262 0 0 0 5.3-9.613z"
              className="cls-reload-icon" data-name="Path 5560" transform="translate(232 382)"/>
        <path id="Rectangle_1692" d="M0 0h20v20H0z" className="cls2-reload-icon" data-name="Rectangle 1692"
              transform="translate(233 382)"/>
      </g>
    </svg>

  )
}

export default ReloadIcon;