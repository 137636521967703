import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DayUtils from '@date-io/dayjs';
import CalendarIcon from '../../components/MDLIcon/icons/Calendar';

const custom_styles = makeStyles({
  text: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    paddingLeft: 5,
    fontSize: '1rem',
    color: '#707070',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100px',
    display: 'block',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  label: {
    color: '#707070',
    fontSize: '0.75rem',
    fontFamily: 'Montserrat',
  },
  selector_wrapper: {
    border: '0.5px solid #dde0e2',
    width: '250px',
    maxWidth: '100%',
    height: '71px',
  },
  selector_button_base: {
    width: '100% !important',
    height: '100% !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
  },
  selector_first_flex: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4px',
    height: '40px',
    width: '100%',
  },
  selector_second_flex: {
    display: 'flex',
    alignItems: 'center',
  },
  expand_more: {
    fontSize: '0.9rem !important',
    fill: '#f4511e !important',
    paddingRight: '8px !important',
  },
  icon: {
    height: '30px',
    width: '30px',
  },
});

const StyledCalendarIcon = styled(CalendarIcon)`
  .cls-1 {
    fill: #fff;
    opacity: 0;
  }

  .cls-2 {
    fill: #707070;
    fill-rule: evenodd;
  }
`;

export default function FormDatePicker(props) {
  let styles = custom_styles(props);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (date) => {
    props.onChange(date);
  };

  let min_date = new Date();

  if (props.hasOwnProperty('minDate')) {
    min_date = props.minDate;
  }

  return (
    <div className={styles.container}>
      <span className={styles.label}>{props.label}</span>
      <div className={styles.selector_wrapper}>
        <ButtonBase
          className={styles.selector_button_base}
          onClick={handleClick}>
          <div className={styles.selector_first_flex}>
            <StyledCalendarIcon className={styles.icon} />
            <span className={styles.text}>{props.text}</span>
          </div>
        </ButtonBase>
      </div>
      <div style={{ display: 'none' }}>
        <MuiPickersUtilsProvider utils={DayUtils}>
          <DatePicker
            open={open}
            label="Basic example"
            value={props.value}
            minDate={min_date}
            format="dd/MM/yyyy"
            onChange={handleChange}
            animateYearScrolling
            onClose={handleClose}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}
