import React from 'react';

function EditIcon(props) {
  let fill = '#f4511e';

  if (props.hasOwnProperty('fill')) {
    fill = props.fill;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="mastero_icon_edit"
      width="21.5"
      height="21.5"
      viewBox="0 0 13.352 13.352"
      className={props.className}>
      <defs>
        <style>
          {`.class-edit-icon-1,.class-edit-icon-2{fill:${fill}}.class-edit-icon-1{opacity:0}`}
        </style>
      </defs>
      <path
        id="Rectangle_926"
        d="M0 0h13.352v13.352H0z"
        className="class-edit-icon-1"
        data-name="Rectangle 926"
      />
      <g id="pencil-edit-button" transform="translate(1.061 1.074)">
        <path
          id="Path_5255"
          d="M6.832 3.113l2.235 2.235-5.657 5.657L1.176 8.77zm3.931-.539l-1-1a.989.989 0 0 0-1.4 0l-.955.955 2.241 2.238 1.114-1.114a.762.762 0 0 0 0-1.079zM.007 11.911a.254.254 0 0 0 .308.3l2.49-.6L.571 9.375z"
          className="class-edit-icon-2"
          data-name="Path 5255"
          transform="translate(-.001 -1.289)"
        />
      </g>
    </svg>
  );
}

export default EditIcon;
