import React, { useState } from 'react';
import AnalyticsTab from '../../Fragments/AnalyticsTab';
import Loader from '../../components/Loader';
import styles from './Drive.module.css';
import NavigationDrawer from '../../Fragments/NavigationDrawer';
import { HashRouter as Router, Route, Link } from 'react-router-dom';
import AudienceTab from '../../Fragments/AudienceTab';
import MicroskillTab from '../../Fragments/MicroskillTab';
import CourseTab from '../../Fragments/CourseTab';
import IncentiveTab from '../../Fragments/IncentiveTab';
import RewardsTab from '../../Fragments/RewardsTab';
import FormsTab from '../../Fragments/FormsTab';
import Notify from '../../Fragments/Notify';

function Base(props) {
  return <h1>.</h1>;
}

function DriveScreen(props) {
  let [menuOpen, setMenuOpen] = useState(false);
  let [activeTab, setActiveTab] = useState('');
  let loading = false;

  if (props.hasOwnProperty('loading')) {
    loading = props.loading;
  }

  function toggleMenu() {
    if (menuOpen === true) {
      setMenuOpen(false);
    } else setMenuOpen(true);
  }

  React.useEffect(() => {
    console.log('permission_features,', props.userInfo);
    if (
      props.userInfo.hasOwnProperty('permission_features') &&
      props.userInfo.permissions !== ''
    ) {
      if (props.userInfo.permission_features.length > 1) {
        if (
          props.userInfo.permission_features.indexOf('create_training_course') >
          -1
        ) {
          if (props.location.pathname === '/') {
            let tab = '/courses-and-training/create-new-course';
            props.history.push(tab, {});
            setActiveTab(tab);
          }
        } else if (
          props.userInfo.permission_features.indexOf(
            'create_training_session',
          ) > -1
        ) {
          if (props.location.pathname === '/') {
            let tab = '/audience/manage-learners';
            props.history.push(tab, {});
            setActiveTab(tab);
          }
        } else {
          let tab = '/analytics/login-completion';
          props.history.push(tab, {});
          setActiveTab(tab);
        }
      } else {
        // no permissions assigned
        if (props.location.pathname === '/') {
          let tab = '/analytics/login-completion';
          props.history.push(tab, {});
          setActiveTab(tab);
          // let microSkillTab = '/roleplay/create-scenario';
          // props.history.push(microSkillTab, {});
          // setActiveTab(microSkillTab);
        }
      }
    }
  }, [props.userInfo]);

  if (loading === true) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader fill="#4f0060" />
      </div>
    );
  } else {
    return (
      <div id="DriveScreen" className={styles.drive_screen}>
        <div className={styles.content}>
          <NavigationDrawer
            {...props}
            open={menuOpen}
            toggleMenu={toggleMenu}
            activeTab={activeTab}
            permissions={props.userInfo.permission_features}
            showIncentiveSection={props.showIncentiveSection}
            showRewardSection={props.showRewardSection}
          />
          <div
            style={{
              height: '100vh',
              overflow: 'scroll',
              flex: 1,
              backgroundColor: 'white',
            }}>
            <div className="">
              <Router>
                <div>
                  <Route
                    path="/analytics"
                    render={(routeProps) => (
                      <AnalyticsTab {...routeProps} {...props} />
                    )}
                  />
                  {/* TODO: ashish uncomment toglemenu to enable roleplay from NavigationDrawer */}
                  {/* <Route
                    path="/roleplay"
                    render={(routeProps) => (
                      <MicroskillTab
                        {...routeProps}
                        {...props}
                        toggleMenu={() => setMenuOpen(false)}
                      />
                    )}
                  /> */}

                  <Route
                    path="/audience"
                    render={(routeProps) => (
                      <AudienceTab
                        {...routeProps}
                        {...props}
                        toggleMenu={toggleMenu}
                      />
                    )}
                  />

                  <Route
                    path="/courses-and-training"
                    render={(routeProps) => (
                      <CourseTab {...routeProps} {...props} />
                    )}
                  />

                  <Route
                    path="/forms"
                    render={(routeProps) => (
                      <FormsTab {...routeProps} {...props} />
                    )}
                  />

                  <Route
                    path="/notify"
                    render={(routeProps) => (
                      <Notify {...routeProps} {...props} />
                    )}
                  />

                  <Route
                    path="/training"
                    render={(routeProps) => (
                      <CourseTab {...routeProps} {...props} />
                    )}
                  />
                  {props.showIncentiveSection ? (
                    <Route
                      path="/incentives"
                      render={(routeProps) => (
                        <IncentiveTab {...routeProps} {...props} />
                      )}
                    />
                  ) : null}

                  {/* CHANGE THIS */}
                  {props.showRewardSection ? (
                    <Route
                      path="/enagagement"
                      render={(routeProps) => (
                        <RewardsTab {...routeProps} {...props} />
                      )}
                    />
                  ) : null}

                  <Route
                    path="/"
                    exact
                    render={(routeProps) => <Base {...routeProps} />}
                  />
                </div>
              </Router>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DriveScreen;
